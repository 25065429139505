import { useEffect, useRef, useState } from "react";
import '../../app-params/param-app.css'
import React from 'react';
import { Input, Modal, Tabs, addToast } from 'kls-ui';
import { ModalDelete } from 'kls-ui/modal/ModalDelete';
import { SelectTabs } from 'kls-ui/selectTabs/SelectTabs';
import TreeElementsModules from 'kls-ui/treeModules/TreeElementsModules';
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";
import { Model } from "kls-commons/types/application-model";
import { useLocation, useParams } from "react-router-dom";
import { fetchKlsResource, getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { GroupesTable } from 'kls-ui/table/table';
import { SearchInput } from "kls-ui/data entry/search-input";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { currentUser } from "kls-commons/service/userService";
import localforage from "localforage";
import { createRole, deleteRole, getAllRoles, getDetailsRole, updateRole } from "../../app-params/role-service";
import { getAppsDetails } from "kls-catalog/my-applications/myapps.service";
import { group } from "console";
import { OrganizationSettingsService } from "../organization-settings-service";


interface TreeNode {
  id: number;
  name: string;
  isOpen: boolean;
  children: TreeNode[];
  checked?: boolean;
  display?: boolean;

}
export function Groups(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [displayNode, setDisplayNode] = useState(false);
  const [groupeName, setGroupeName] = useState('');
  const [helpText, setHelpText] = useState('');
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const [activeTabEdit, setActiveTabEdit] = useState<string>('general');
  const { moduleName } = useParams() as any;
  const [appObj, setAppObj] = useState<WorkingDirTree>();
  const [direc, setDirec] = useState<string[]>([]);
  const [treeProfiles, setTreeProfiles] = useState<TreeNode[]>([]);
  const [trRessource, setTrRessource] = useState<TreeNode[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 5;
  const [codeInputs, setCodeInputs] = useState<string[]>(["", "", "", "", "", ""]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageValidation, setErrorMessageValidation] = useState<string>('');
  const [currentInputIndex, setCurrentInputIndex] = useState<number>(0);
  const [isInputModified, setIsInputModified] = useState(false);
  const [groupesData, setGroupesData] = useState<{ groupe: string; profils: number; ressources: number; utilisateurs: number; }[]>([]);
  const [groupeBackData, setGroupesBackData] = useState<{ id?:string;groupe: string; profils: number; ressources: number; utilisateurs: number; dateCreation: string; }[]>([]);
  const [randomNumber, setRandomNumber] = useState('');
  const location = useLocation();
  const [myOrganisationName,setMyOrganisationName] = useState('');
  const[groupeIdEdit,setGroupeIdEdit]=useState('');
  const [groupeNameError, setGroupeNameError] = useState('');
  const [rolesData, setRolesData] = useState<TreeNode[]>([]);
  const [aboutData, setAboutData] = useState<any>({
    creationDate: '05/01/2024 22:15',
    lastModification: '10/01/2024 08:44',
    numberOfUsers: 15
  });


  const [treeData, setTreeData] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Module : Gestion des demandes de projets',
      isOpen: false,
      checked: false,
      children: [
        {
          id: 2,
          name: 'Processus : Demande de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 3,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 4,
              name: 'Évaluation Initiale',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 5,
              name: 'Vérification Budgétaire',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 6,
              name: 'Examen Juridique',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 7,
              name: 'Préparation de la Proposition',
              isOpen: false,
              checked: false,
              children: []
            }
          ]
        },
        {
          id: 8,
          name: 'Processus : Correction de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 14,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
          ]
        },
        {
          id: 9,
          name: 'Processus : Annulation de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 10,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 11,
              name: 'Examen du comité',
              isOpen: false,
              checked: false,
              children: []
            }
          ]
        }
      ]
    },
    {
      id: 12,
      name: 'Gestion des ventes',
      isOpen: false,
      checked: false,
      children: [
        {
          id: 13,
          name: 'Processus : Demande de vente',
          isOpen: false,
          checked: false,
          children: []
        }
      ]
    }
  ]);
  const [treeDataResource, setTreeDataResource] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Module : Gestion des demandes de projets',
      isOpen: false,
      children: [
        {
          id: 2,
          name: 'Processus',
          isOpen: false,
          children: [
            {
              id: 3,
              name: 'Demande de projet',
              isOpen: false,
              children: []
            },
          ]
        },
        {
          id: 4,
          name: 'Tasklist',
          isOpen: false,
          children: [

            {
              id: 5,
              name: 'Initiation de la Demande',
              isOpen: false,
              children: []
            },
            {
              id: 6,
              name: 'Vérification Budgétaire',
              isOpen: false,
              children: []
            },

          ]
        },
        {
          id: 7,
          name: 'Recherches',
          isOpen: false,
          children: [
            {
              id: 8,
              name: 'Liste de toutes les demandes reçues',
              isOpen: false,
              children: []
            },
            {
              id: 9,
              name: 'Liste des demandes reçues - Statut = Urgent',
              isOpen: false,
              children: []
            }
          ]
        }
      ]
    },

  ]);
  const [rolesStatiqueData, setRolesStatiqueData] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Application : Gestion des demandes de projets',
      isOpen: false,
      children: [
        {
          id: 2,
          name: 'Demandeur de projet ',
          isOpen: false,
          children: []
        },
        {
          id: 3,
          name: 'Controleur de gestion',
          isOpen: false,
          children: []
        },
        {
          id: 4,
          name: 'Gestionnaire des opérations',
          isOpen: false,
          children: [
          ]
        },
        {
          id: 5,
          name: 'Auditeur de conformité',
          isOpen: false,
          children: [
          ]
        }
      ]
    },
  ]);

  const [groupeDataStatique, setGroupeDataStatique] = useState<any[]>([
    {
      groupe: 'Group-1',
      profils: 5,
      ressources: 10,
      utilisateurs: 20,
    },
    {
      groupe: 'Group-2',
      profils: 3,
      ressources: 8,
      utilisateurs: 15,
    },
    {
      groupe: 'Group-3',
      profils: 3,
      ressources: 8,
      utilisateurs: 15,
    },
  ]);
  const sortedGroupesBackData = [...groupeBackData].sort((a, b) => {
    return new Date(b.dateCreation).getTime() - new Date(a.dateCreation).getTime();
  });

  const filteredGroupeData = sortedGroupesBackData.filter(r =>
    r && r.groupe.toLowerCase().includes(filter.toLowerCase())
  );


  useEffect(() => {
    const cleanedAndFormattedName = cleanAndFormatNameResource(groupeName);
    setHelpText(`ID : ${cleanedAndFormattedName}`);
  }, [groupeName]);


  useEffect(() => {
    getWorkingDirectorTree(moduleName).then(
      (tree: WorkingDirTree) => {
        setAppObj(tree);
      }
    )
    listModules().then(
      modules => {
        setDirec(modules);
      }
    )

  }, [moduleName])

 

 
  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);
  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };
  const handlePageChange = (start: number, end: number) => {
    setCurrentPage(start / itemsPerPage + 1);
  };
  const fetchData = async () => {
    try {

      const fetchDataResult = await OrganizationSettingsService(currentUser().userId, location.pathname.split("/")?.[2]);
    //  console.log("fetchDataResult", fetchDataResult.data);
     setMyOrganisationName(fetchDataResult.data.name);
      // console.log("MyOrganisationName", myOrganisationName);
    
    
    }
    catch (error) {
      console.error("Error fetching organization settings:", error);
    }
  }
  useEffect(() => {
    fetchData();
    // console.log("myOrganisationName inside useEffect", myOrganisationName);
  })
  ////////////////////////////////////////////////////////////////////

  const parseXmlMenu = (xmlContent: string): any[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xmlContent, "application/xml");
    const elements = Array.from(doc.documentElement.children);
    return elements.map(parseElement);
  };

  const parseResource = (resourceElement: Element): any => {
    return {
      type: resourceElement.getAttribute("type"),
      content: resourceElement?.textContent?.trim()
    };
  };
  const parseElement = (element: Element): any => {
    const tagName = element.tagName;
    if (tagName === "menu") {
      return parseMenu(element);
    } else if (tagName === "resource") {
      return parseResource(element);
    }
  }
  const parseMenu = (menuElement: Element): any => {
    const id = menuElement.getAttribute("relId" || "absId");
    const label = menuElement.getAttribute("label");
    const childrenElements = Array.from(menuElement.getElementsByTagName("menu"));
    const resourcesElements = Array.from(menuElement.getElementsByTagName("resource"));
    const children = childrenElements.map(parseMenu);
    const resources = resourcesElements.map(parseResource);

    return { id, label, children, resources };
  };
  const getActivitiesFromXml = (xml) => {
    const activities = Array();
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "application/xml");
    Array.from(doc.getElementsByTagName("bpmn2:userTask")).map((task) => {
      const id = task.getAttribute("id");
      const name = task.getAttribute("name");
      const mask = task.getAttribute("tns:screenMask");
      activities.push({ id, name, mask });
    });

    return activities;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleNouveauGroupeClick = () => {
    setShowModal(true);

  };
  const handleGroupeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGroupeName(event.target.value);
    setIsInputModified(true);
  };
  const resetCheckedNodes = (nodes) => {
    return nodes.map(node => {
      if (node.checked) {
        node.checked = false;
        console.log("the node is checked", node)
        resetCheckedNodes(node.children);
        return { ...node, checked: false };
      } else if (node.children && node.children.length > 0) {
        return { ...node, children: resetCheckedNodes(node.children) };
      } else {
        return node;
      }
    });
  };

  const resetProfileTreeCheckedNodes = () => {
    const updatedProfileTree = resetCheckedNodes(treeProfiles);
    setTreeProfiles(updatedProfileTree);
  };
  const resetResourceTreeCheckedNodes = () => {

    const updatedResourceTree = resetCheckedNodes(trRessource);
    setTrRessource(updatedResourceTree);
  };
  const closeModal = () => {
    setShowModal(false);
    setShowModalEdit(false);
    setActiveTabEdit('general');
    setIsInputModified(false);
    setGroupeName('');
    resetProfileTreeCheckedNodes();
    resetResourceTreeCheckedNodes();
    setShowSelectedOnly(false);
    setShowSelectedOnlyResources(false);


  };
  const closeModalDelete = () => {
    setShowModalDelete(false);
    setErrorMessage('');
  };
  
  const handleDeleteButtonClick = (enteredCode: string) => {
   
    console.log('random number:', randomNumber);
    if (enteredCode !== randomNumber) {
      setErrorMessage("Le code saisi n'est pas valide. Essayez-le à nouveau !");
    } else {
      //const updatedRolesData = rolesData.filter(grp => grp.groupe !== groupeNameDeleted);
      const updatedRolesDataBack = groupeBackData.filter(grp => grp.id !== groupeNameDeleted);
      
     deleteRole("AB90G-BH903-W4EE1-Z66Q9-907E6",currentUser().userId,groupeNameDeleted).then(() => {
    //  fetchDataRoles();
     }).catch(error => {
       console.log(' deleting role:', error);
     });
     
     // setRolesData(updatedRolesData);
      setShowModalDelete(false);
      setErrorMessage('');
      setGroupesBackData(updatedRolesDataBack);
      //fetchDataRoles();
      addToast(' Le groupe a été supprimé avec succès', 'success');
    }
  };
  const handleTabsSelect = (tabName: string) => {
    setActiveTab(tabName);
  }
  const handleTabsEdit = (tabName: string) => {
    setActiveTabEdit(tabName);
  }
  const handleTabChange = (tabName: string) => {
    if (tabName === 'edit') {
      setShowModalEdit(true);
      setShowModal(true);

    }
    else if (tabName === 'delete') {
      setShowModalEdit(false);
      setShowModalDelete(true);

    }
    else if (tabName === 'duplicate') {
      setShowModal(true);

    }


  };

  const toggleChildren = (parentId: number) => {
    setTreeProfiles(prevData =>
      prevData.map(node => {
        if (node.id === parentId) {
          return { ...node, isOpen: !node.isOpen };
        } else {
          return node;
        }
      })
    );
  };


  const handleCheckboxChangeRoles = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(myroles, childId, checked);
    setMyroles(updatedTreeData);
  };
  const handleCheckboxChange = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(treeProfiles, childId, checked);
    setTreeProfiles(updatedTreeData);
  };

  const handleCheckboxChangeResource = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(trRessource, childId, checked);
    setTrRessource(updatedTreeData);
  };
  const updateCheckedState = (nodes: TreeNode[], childId: number, checked: boolean): TreeNode[] => {
    return nodes.map(node => {
      if (node.id === childId) {
        return { ...node, checked: checked,isOpen: true };
      } else if (node.children && node.children.length > 0) {
        const updatedChildren = updateCheckedState(node.children, childId, checked);
        const someChildrenChecked = updatedChildren.some(child => child.checked);
        const allChildrenChecked = updatedChildren.every(child => child.checked);
        return {
          ...node,
          isOpen: true,
          checked: someChildrenChecked ? true : false,
          indeterminate: someChildrenChecked && !allChildrenChecked ? true : false,
          children: updatedChildren
        };
      } else {
        return node;
      }
    });
  };
  const [myroles, setMyroles] = useState<TreeNode[]>([]);
  var idrolesNode=1;
  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        const roles = await getAllRoles("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, currentUser().email);
            const myLocalEntity = (currentUser().entity as string).split("/kls/")[1].split("/dev")[0];

        const entityNodesMap: { [key: string]: TreeNode } = {};
  
        roles.data.forEach(role => {
          const entityName = role['Entité'].split('/')[3]||'';
          const entityFilter = role['Entité'].split('/')[1]||'';
          const roleName = role.Description || role.Nom;

          if (entityName.trim() !== '' && entityFilter.trim() === myLocalEntity.trim()) {
            console.log("truuuuuuuuuuuuee",role.Nom,role,role.Description);
            if (!entityNodesMap[entityName]) {
              entityNodesMap[entityName] = {
                id: idrolesNode++,
                name: entityName,
                isOpen: false,
                children: []
              };
            }
            // console.log("entitymap", entityNodesMap,"--------",Object.values(entityNodesMap));
            // setMyroles(Object.values(entityNodesMap))
            const entityNode = entityNodesMap[entityName];
            const existingRole = entityNode.children.find(child => child.name === roleName);
            if (!existingRole) {
              entityNode.children.push({
                id: idrolesNode++,
                name: roleName,
                isOpen: false,
                children: []
              });
            }
            console.log('entitynode', entityNode, 'entityNode.children', entityNode.children);
          }
        });
        console.log("gooooook",entityNodesMap)
  
       const formattedRoles: TreeNode[] = Object.values(entityNodesMap);
        console.log("formatted:", formattedRoles,Object.values(entityNodesMap));
       setMyroles(formattedRoles);
  
        //console.log("myroles:", formattedRoles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
  
    fetchRolesData();
  }, []);
  

  const [allModules, setAllModules] = useState<WorkingDirTree[]>([]);

  useEffect(
    () => {
      getAppsDetails(null).then((apps) => { setAllModules(apps) })
    }, []
  )     
  var moduleIdCounter = 1;

  useEffect(() => {
    // console.log("allModules from use:", allModules);
    const uniqueAppNames = new Set();

    allModules.map((app) => {
      if (!uniqueAppNames.has(app.name)) {
        // console.log("appname", app.name)
       
        getWorkingDirectorTree(app.name).then(
          (trees: WorkingDirTree) => {
            // setAppObj(tree);
          // console.log("tree from grp", trees);
            const appNode: TreeNode = {
              id: moduleIdCounter++,
              name: `Application: ${app.name}`,
              isOpen: false,
              children: [],
            };

            const fetchDataProfilesGroupes = async () => {
              try {
                const processModulesChildren = (modulesChildren: any) => {
                  const tree: TreeNode[] = [];

                  //console.log("modulesChildreLog", modulesChildren)
                  for (const m in modulesChildren) {
                    if (!m.endsWith(".json")) {
                      const modeleName = m.split(".")[0];
                      const moduleChildren = modulesChildren[m]?.children;
                      const moduleNode: TreeNode = {
                        id: moduleIdCounter++,
                        name: `Module : ${modeleName}`,
                        isOpen: false,
                        children: [],
                      };
                      const processChildren = moduleChildren?.process?.children;
                      //  console.log("ProcessLOG", processChildren);
                      for (const c in processChildren) {
                        if (c.endsWith(".bpmn")) {
                          const processName = c.split(".")[0];
                          const processNode: TreeNode = {
                            id: moduleIdCounter++,
                            name: `Processus : ${processName}`,
                            isOpen: false,
                            children: [],
                          };
                          moduleNode.children.push(processNode);
                          let activities: any[] = [];
                          const processFilePath = trees?.children?.[modeleName].children?.process?.children?.[`${processName}.bpmn`]?.info?.filePath ?? "";
                          // console.log("processFilePath ", modeleName, processFilePath);
                          fetchKlsResource(app.name, processFilePath)
                            .then((resource) => {
                              // console.log("resource tttttt for", resource, "Process", processName);
                              activities = getActivitiesFromXml(resource.content ?? "");
                              // console.log("resresres",resource.content);
                              //console.log("activities ggg", activities);
                              //  console.log(`Activities for process work ${processName}:`, activities);
                              for (const activity of Object.values(activities)) {
                                //    console.log(`Activity afficher : ${activity.name}`);
                                processNode.children.push({
                                  id: moduleIdCounter++,
                                  name: `Activite : ${activity.name}`,
                                  isOpen: false,
                                  children: [],
                                });
                              }
                            })
                            .catch((error) => {
                              console.error(`Error fetching activities for process ${processName}:`, error);
                            });
                        }
                      }

                      if (moduleNode.children.length > 0) {
                        appNode.children.push(moduleNode);
                      }
                    }
                  }
                  if (appNode.children.length > 0) {
                    tree.push(appNode);

                  }

                  return tree;
                };

                // const treeDaaBack = processModulesChildren(trees?.children);
                // setTreeProfiles(prevData=>{
                //   return [...prevData, ...treeDaaBack];
                // });
                // setTreeProfiles(treeDaaBack);
                const treeDaaBack = processModulesChildren(trees?.children);
                setTreeProfiles(prevData => {
                  const existingIds = new Set(prevData.map(node => node.name));
                  const newData = treeDaaBack.filter(node => !existingIds.has(node.name));
                  const newDataWithIds = newData.map(node => ({ ...node, id: moduleIdCounter++ }));
                  return [...prevData, ...newDataWithIds];
                });
             //   console.log("treeDaaBackkk", treeDaaBack);
           //     console.log("myTr", treeProfiles)
                //setTrRessource([appNodeResource]);
              } catch (error) {
                console.error('Error fetching data nana:', error);

              }
            };

            fetchDataProfilesGroupes();
            uniqueAppNames.add(app.name);
          }
        )
      }
    })
  }, [allModules]);
  useEffect(() => {
   // console.log("allModules from use:", allModules);
    const uniqueAppNamesR = new Set();

    var moduleIdCounters = 1;

    const fetchMenusForAllApps = async () => {
      try {
        let menuNodesAccumulated: TreeNode[] = [];

        for (const app of allModules) {
          //console.log("appname", app.name);

          const trees: WorkingDirTree = await getWorkingDirectorTree(app.name);

          const processMenusForApp = async (modulesChildrenRessources: any) => {
            const menuNodes: TreeNode[] = [];
            for (const moduleName in modulesChildrenRessources) {
              if (!moduleName.endsWith(".json")) {
                const moduleChildren = modulesChildrenRessources[moduleName]?.children;
                const resourcesNodes: Set<string> = new Set();
                await processMenus(moduleChildren, menuNodes, resourcesNodes, new Set<string>());
              }
            }
            return menuNodes;
          };

          const processMenus = async (menus: any, menuNodes: TreeNode[], processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode) => {
            if (!menus) return;
            for (const menuName in menus) {
              if (menuName.endsWith("-menu.xml")) {
                const menuFilePath = menus[menuName]?.info?.filePath;
                try {
                  const resource = await fetchKlsResource(app.name, menuFilePath);
                  const xmlContent = resource?.content ?? '';
                  const parsedMenus = parseXmlMenu(xmlContent);
                  for (const menu of parsedMenus) {
                    const menuNode = await buildMenuTree(menu, processedMenuIds, processResourcesIds);
                    if (menuNode) {
                      menuNodes.push(menuNode);
                    }
                  }
                } catch (error) {
                  console.error(`Error fetching resources for menu ${menuName}:`, error);
                }
              }
            }
          };

          const buildMenuTree = async (menu: any, processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode): Promise<TreeNode | null> => {
            if (processedMenuIds.has(menu.label)) {
              for (const resource of menu.resources) {
                if (processResourcesIds.has(resource.content)) {
                  return null;
                }
              }
            }

            const menuNode: TreeNode = {
              id: moduleIdCounters++,
              name: menu.label,
              isOpen: false,
              children: [],
            };

            processedMenuIds.add(menu.label);
            const menuChildren = menu.children || [];
            for (const childMenu of menuChildren) {
              const childNode = await buildMenuTree(childMenu, processedMenuIds, processResourcesIds, menuNode);
              if (childNode) {
                menuNode.children.push(childNode);
              }
            }

            if (menuChildren.length === 0 && menu.resources) {
              for (const resource of menu.resources) {
                if (!resource) {
                  setDisplayNode(false);
                }
                processResourcesIds.add(resource.content);
                const resourceNode: TreeNode = {
                  id: moduleIdCounters++,
                  name: `Resource:${resource.content} Type: ${resource.type}`,
                  isOpen: false,
                  children: [],
                  display: false
                };
                menuNode.children.push(resourceNode);
              }
            }

            return menuNode;
          };

          const menuNodesForApp = await processMenusForApp(trees?.children);

          const appNodeWithMenus: TreeNode = {
            id: moduleIdCounters++,
            name: `Application: ${app.name}`,
            isOpen: false,
            children: menuNodesForApp,
          };
          menuNodesAccumulated.push(appNodeWithMenus);
        }

        setTrRessource(menuNodesAccumulated);
        //console.log("myTrRes", trRessource)
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenusForAllApps();
  }, [allModules]);

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [showSelectedOnlyRoles, setShowSelectedOnlyRoles] = useState(false);
  const [showSelectedOnlyResources, setShowSelectedOnlyResources] = useState(false);
  const generateRandomNumber = () => {
    const randomNum = Math.floor(100000 + Math.random() * 900000);
    setRandomNumber(randomNum.toString());
  };
  const renderContent = () => {
    let filteredTreeData = treeProfiles;
    if (showSelectedOnly) {
      filteredTreeData = filterCheckedNodes(treeProfiles);
    }
    let filteredTreeDataResource = trRessource;
    if (showSelectedOnlyResources) {
      filteredTreeDataResource = filterCheckedNodes(trRessource);
    }
    let filteredTreeDataRoles = myroles;
    if (showSelectedOnlyRoles) {
      filteredTreeDataRoles = filterCheckedNodes(myroles);
    }
    switch (activeTab) {

      case 'tab1':
        const totalChildrenRoles = countLastChildren(myroles);
        const checkedChildrenRoles = countCheckedChildren(myroles);

        return myroles.length > 0 ? (
          <>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildrenRoles}/{totalChildrenRoles} roles alloués
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnlyRoles(!showSelectedOnlyRoles);

                }}>
                {showSelectedOnlyRoles ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataRoles}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeRoles}
            /></>
        ) : (
          <div style={{ color: '#94A3B8', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>Aucun résultat</div>
        );
      case 'tab2':
        const totalChildren = countLastChildren(treeProfiles);
        const checkedChildren = countCheckedChildren(treeProfiles);
      //  console.log("myTr inside render", treeProfiles)

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildren}/{totalChildren} activités allouées
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnly(!showSelectedOnly);

                }}>
                {showSelectedOnly ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeData}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChange}
              nameTab="profiles"

            />
          </div>
        );
      case 'tab3':
        const totalChildrenR = countLastChildren(trRessource);
        const checkedChildrenR = countCheckedChildren(trRessource);
   //     console.log("myTrRessource inside render", trRessource)

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildrenR}/{totalChildrenR} ressources allouées
              </span>
              <span className="text-select-tree" onClick={() =>
                setShowSelectedOnlyResources(!showSelectedOnlyResources)
              }>
                {showSelectedOnlyResources ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataResource}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeResource}
              nameTab="ressources"

            />
          </div>
        ); default:
        return null;
    }
  };
  function MoreActions({ actions, closeCallback, style }: { actions: any, closeCallback?: Function, style?: React.CSSProperties }) {
    const moreOptions = useRef<HTMLDivElement>(null);
    const listObjActions = [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },

      {
        label: 'Dupliquer',
        value: 'duplicate',
        icon: 'content_copy',
        color: '#64748B',
        handleClick: actions?.['duplicate']
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: '#EF4444',
        handleClick: actions?.['delete']
      },

    ]

    return <div ref={moreOptions}>
      <AdvancedDropDown data={listObjActions} enableSearch={false}
        light={true}
        handleChange={(item) => { item?.handleClick?.() }}
        size={1}
        selectedValue={''}
        style={style}
        closeWhenClickOutside={closeCallback}
        closeWhenSelect={closeCallback} />
    </div>
  }
  const [groupeNameDeleted, setGroupeNameDeleted] = useState('');

  const handleDeleteOnTable = (groupeDeleted) => {
    setShowModalEdit(false);
    setShowModalDelete(true);
    setGroupeNameDeleted(groupeDeleted.id);
    generateRandomNumber();


  }
  const handleDuplicateOnTable = async (groupeDuplicated) => {
    setShowModal(true);
    try {
      const rolesDataToEdit = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, groupeDuplicated);
      const treeProfilesEdit = rolesDataToEdit.Profile;
      const treeResourcesEdit = rolesDataToEdit.Permission;
  
      const treeProfilesEditJson = JSON.parse(treeProfilesEdit);
      const profileData = prepareProfileData(treeProfilesEditJson);
  
      const treeResourcesEditJson = JSON.parse(treeResourcesEdit);
      const resourceData = prepareResourceData(treeResourcesEditJson);
  
      treeProfiles.forEach(node => {
        traverseAndUpdate(node, treeProfilesEditJson);
        let updatedTreeData = updateCheckedState(treeProfiles, node.id, node.checked || false);
        setTreeProfiles(updatedTreeData);
      });
  
      const updatedTreeResources = trRessource.map((node) => {
        traverseAndUpdateRes(node, treeResourcesEditJson);
        return node;
      });
  
      const finalTreeResources = updateCheckedStateTemp(updatedTreeResources);
      setTrRessource(finalTreeResources);
  
      createGroupeObject(groupeName);
  
    } catch (error) {
      console.error("Error fetching role details:", error);
    }

  }
  const traverseAndUpdate = (node, treeProfilesEditJson) => {
    treeProfilesEditJson.forEach(profile => {
      const nameApp = "Application: " + profile.refProfile.description.split("Application:")[1]?.trim().split("/ Module :")[0].trim()||'';
      const moduleNameApp = "Module : " + profile.refProfile.description.split("Module :")[1].trim().split("/ Processus :")[0].trim();
      const processusName = "Processus : " + profile.refProfile.description.split("Processus :")[1].trim().split("/ Activite :")[0].trim();
      const activiteName = "Activite : " + profile.refProfile.description.split("Activite :")[1].trim();
  
      if(nameApp && nameApp === node.name){
        const moduleNode = node.children.find(child => child.name === moduleNameApp);
      if (moduleNode && moduleNode.children.length>0) {
        
          const processusNode = moduleNode.children.find(child => child.name === processusName);
          if (processusNode && processusNode.children && processusNode.children.length > 0) {
            const activiteNode = processusNode.children.find(child => child.name === activiteName);
            if (activiteNode) {
              activiteNode.checked = true;
              processusNode.checked = true;
              moduleNode.checked = true;
              node.checked = true;
            }
          
        }
      }}
    });
  
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => {
        traverseAndUpdate(child, treeProfilesEditJson);
      });
    }
  };
  
  const traverseAndUpdateRes = (node, treeResourcesEditJson) => {
    treeResourcesEditJson.forEach((resource) => {
      if (resource.resource && node.name.includes(resource.resource)) {
        node.checked = true;
      }
    });
  
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => traverseAndUpdateRes(child, treeResourcesEditJson));
    }
  };
  
  
  function AppTable({ servicesList }) {

    const data = servicesList.map((s) => {
      return {
        groupe: s?.groupe || '',
        profils: s?.profils || '',
        ressources: s?.ressources || '',
        utilisateurs: s?.utilisateurs || '',
        actions: <MoreActions
          actions={{
            'edit': () => handleEditOnTable(s),
            'delete': () => handleDeleteOnTable(s),
            'duplicate': () => handleDuplicateOnTable(s?.groupe)
          }}
          style={{ cursor: 'pointer', marginTop: '30px', }}
        />
      }
    })

    return <GroupesTable data={data} />
  }
  const filterCheckedNodes = (nodes: TreeNode[]): TreeNode[] => {
    let filteredNodes: TreeNode[] = [];
    nodes.forEach(node => {
      if (node.checked) {
        filteredNodes.push({
          ...node,
          children: node.children ? filterCheckedNodes(node.children) : []
        });
      }
    });
    return filteredNodes;
  };
  const countLastChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0) {
        count++;
      } else {
        count += countLastChildren(node.children);
      }
    });
    return count;
  };

  const countCheckedChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0 && node.checked) {
        count++;
      } else if (node.children.length > 0) {
        count += countCheckedChildren(node.children);
      }
    });
    return count;
  };

  const getLastCheckedNodeNames = (node, names: string[] = []) => {
    if (node.children && node.children.length > 0) {
      const lastCheckedChildren = node.children.filter(child => child.checked);
      if (lastCheckedChildren.length > 0) {
        lastCheckedChildren.forEach(child => {
          getLastCheckedNodeNames(child, names);
        });
      }
    } else if (node.checked) {
      names.push(node.name);
    }
    return names;
  };
  const getLastCheckedNodeNamesN = (node, namesN: TreeNode[] = []) => {
    if (node.children && node.children.length > 0) {
      const lastCheckedChildren = node.children.filter(child => child.checked);
      if (lastCheckedChildren.length > 0) {
        lastCheckedChildren.forEach(child => {
          getLastCheckedNodeNamesN(child, namesN);
        });
      }
    } else if (node.checked) {
      namesN.push(node);
    }
    return namesN;
  };

 
  const fetchDataGroupes = async () => {
    try {
      const dataRoles = await getAllRoles("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, currentUser().email).catch((error) => {
        console.error("Error fetching data:", error);
      });
      const myLocalEntity = (currentUser().entity as string).split("/kls/")[1].split("/dev")[0];

      console.log("dataRoles",dataRoles);
      setGroupesBackData([]);
      await Promise.all(dataRoles.data.map(async (role) => {
        
        // console.log("role name:", role.Nom);
        const entity = role['Entité'].split('/')[2];
        const entityFilter = role['Entité'].split('/')[1]||'';

        if (role.Nom  && typeof role.Nom === 'string' || role.Nom.trim() !== '') {
        let temporaryRPermission = 0;
        let temporaryProfiles = 0;
        try {
          const DataDetailsRoles = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, role.Nom).catch((error) => {
            console.error("Error fetching data:", error);
          });
          // console.log("DataDetailsRoles",DataDetailsRoles);
          try{
              
          const permissions = JSON.parse(DataDetailsRoles.Permission);
          // console.log("permissions", permissions);
          // console.log("Number of permissions:", role.Nom, permissions.length);
          temporaryRPermission = permissions.length;
    
          const profileDetails = JSON.parse(DataDetailsRoles.Profile);
          // console.log("profileDetails", profileDetails);
          // console.log("Number of profiles:", role.Nom, profileDetails.length);
          temporaryProfiles = profileDetails.length;
      } catch (error) {
          console.error("Error parsing JSON data:", error);
      }
      fetchData();
      // console.log("check value entity , ornasitionanme",entity,myOrganisationName)
      if(entity && myOrganisationName && entity===myOrganisationName){
        console.log('here test entity')
          setGroupesBackData(prevData => [...prevData, {
            id:role.Nom,
            groupe: role.Description || role.Nom,
            profils: temporaryProfiles,
            ressources: temporaryRPermission,
            utilisateurs: 0,
            dateCreation: role["Date de Création"]
          }]);}
        } catch (error) {
          console.error("Error fetching role details:", error);
        }}
      }));
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

useEffect(() => {
  fetchDataGroupes();
  
}, [myOrganisationName]);

  const handleAjouterClick = async () => {
    if (groupeName.trim() === '') {
      setGroupeNameError('Ce champ est requis');
      return;
    }
    setGroupeNameError('');
    const helpTextCleaned = helpText.split("ID :")[1].trim();
    const newRoleObject = await createGroupeObject(helpTextCleaned);
    const currentdate = new Date();
    const formattedDate = currentdate.getFullYear() + "-" + (currentdate.getMonth() + 1) + "-" + currentdate.getDate();
    const newRole = {
      groupe: newRoleObject.nameRole,
      profils: newRoleObject.profile.length,
      ressources: newRoleObject.resources.length,
      utilisateurs: 0,
      dateCreation: formattedDate,
    };
    setGroupesBackData(prevData => [...prevData, newRole]);
    fetchDataGroupes();
    localforage.setItem('rolesBackData', [...groupeBackData, newRole]);
    addToast('Le rôle a été ajouté avec succès', 'success');
    closeModal();
  };

  const getFullPath = (node, path = '') => {
    const currentPath = `${path} / ${node.name}`;
    if (!node.children || node.children.length === 0) {
      return currentPath;
    }

    const checkedChildren = node.children.filter(child => child.checked);

    return checkedChildren.reduce((acc, child) => getFullPath(child, acc), currentPath);
  };

 

  const createGroupeObject = async (grp) => {
    const apiKey = "AB90G-BH903-W4EE1-Z66Q9-907E6";
    type ProfileData = {
      profilename: string;
      penable: string;
      ptag: string;
      asset: string;
      addedby: string;
    };
    type ResourceData = {
      rname: string;
      type: string;
      disabled: string;
      afficheMenu: string;
      asset: string;

    }
    const profileRoles: ProfileData[] = [];
    const resourcesRoles: ResourceData[] = [];
    console.log("filterCheckedNodes", filterCheckedNodes(myroles));
    const checkedRoles = filterCheckedNodes(myroles);
    for (const checkRole of checkedRoles) {
      console.log("role name check:", checkRole.name);
      const lastChildCheck= getLastCheckedNodeNames(checkRole);
      console.log("Last child",lastChildCheck);
      try {
        const DataDetailsRoles = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, cleanAndFormatNameResource(lastChildCheck.toString())).catch((error) => {
          console.error("Error fetching data:", error);
        });
        console.log("DataDetailsRoles", DataDetailsRoles);
        try {

          const permissions = JSON.parse(DataDetailsRoles.Permission);
          // console.log("permissions inside add", permissions);
          for (const permission of permissions) {
            // console.log("resource of permission", permission.resource);
            resourcesRoles.push({
              rname: permission.resource,
              type: permission.type,
              disabled: "true",
              afficheMenu: "true",
              asset: "BASIC",
            })

          }

          const profileDetails = JSON.parse(DataDetailsRoles.Profile);
          console.log("profileDetails inside add", profileDetails);
          for (const profile of profileDetails) {
            console.log("profile of profile", profile.refProfile.description);
            profileRoles.push({
              profilename: profile.refProfile.description,
              penable: "true",
              ptag: "true",
              asset: "BASIC",
              addedby: currentUser().id || '',
            })
          }


        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }

      } catch (error) {
        console.error("Error fetching role details:", error);
      }
    }
    console.log("profileRoles", profileRoles);
    console.log("resourcesRoles", resourcesRoles);
    const profileData = prepareProfileData(treeProfiles);
    profileData.push(...profileRoles);
    console.log("prepared profile data:", profileData);
    const resourceData = prepareResourceData(trRessource);
    resourceData.push(...resourcesRoles);

    const userId = currentUser().userId!;
    const roleObject = {
      userId: userId,
      nameRole: grp,
      profile: profileData,
      resources: resourceData
    };
    const data = {
      apiKey: apiKey,
      userId: userId,
      name: grp,
      appname:'',
      orgname:myOrganisationName,
      description: groupeName,
      profiles: profileData,
      resources: resourceData
    };


    console.log("Profile data and resource data:", data);
    try {
      const response = await createRole(data);
      console.log("Role created successfully:", response);
    } catch (error) {
      console.error("Failed to create role:", error);
    }
    return roleObject;
  };
  const prepareProfileData = (treeProfiles) => {
    const traverseAndAddProfileData = (node, path = '') => {
      type ProfileData = {
        profilename: string;
        penable: string;
        ptag: string;
        asset: string;
        addedby: string;
      };
      const currentPath = `${path} / ${node.name}`;
      let profileData: ProfileData[] = [];

      if (node.checked && node.name.startsWith("Activite")) {
        profileData.push({
          profilename: currentPath,
          penable: "true",
          ptag: "true",
          asset: "BASIC",
          addedby: currentUser().id || ''
        });
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          profileData = profileData.concat(traverseAndAddProfileData(child, currentPath));
        });
      }

      return profileData;
    };

    return filterCheckedNodes(treeProfiles).flatMap(node => {
      const lastCheckedNodeNames = getLastCheckedNodeNamesN(node);
      return traverseAndAddProfileData(node);
    });
  };

  const prepareResourceData = (trRessource) => {
    const resourceData = filterCheckedNodes(trRessource).flatMap(node => {
      const lastCheckedNodeNames = getLastCheckedNodeNames(node);
      return lastCheckedNodeNames.map(name => ({
        rname: name?.split("Resource:")[1]?.split("Type")[0]?.trim() || '',
        type: name?.split(" Type: ")[1] || "",
        disabled: "false",
        afficheMenu: "true",
        asset: "BASIC"
      }));
    });

    return resourceData;
  };

  const [groupeNameEdit, setGroupeNameEdit] = useState('');

  const handleEdit = async () => {
    try {  
      const updatedRolesData = updateGroupesData(groupesData, groupeNameEdit);
      const { treeProfilesEditJson, treeResourcesEditJson } = await fetchAndProcessRolesData(cleanAndFormatNameResource(groupeNameEdit));
      updateTreeProfilesAndResources(treeProfiles, trRessource, treeProfilesEditJson, treeResourcesEditJson);
      const dataRoleToUpdate = prepareDataForRoleUpdate(groupeIdEdit, groupeIdEdit, treeProfiles, trRessource,groupeName);
      console.log("dataRoleToUpdate vc rolename", dataRoleToUpdate,groupeName,groupeIdEdit);
      await updateRole(dataRoleToUpdate);
      fetchDataGroupes();
      addToast('Le role a été modifié avc succès', 'success');
      closeModal();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const prepareDataForRoleUpdate = (roleNameEdit, roleName, treeProfiles, trRessource,description) => {
    const profileData = prepareProfileData(treeProfiles);
    const resourceData = prepareResourceData(trRessource);
    const userId : string = currentUser().userId!;
    console.log("rolesName and rolenameedit",  roleName,roleNameEdit);
    return {
      apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
      userId,
      name: cleanAndFormatNameResource(roleNameEdit),
      NewName: cleanAndFormatNameResource(roleNameEdit),
      description:description,
      profiles: profileData,
      resources: resourceData
    };
  };
  const updateTreeProfilesAndResources = (treeProfiles, trRessource, treeProfilesEditJson, treeResourcesEditJson) => {
    updateTreeProfiles(treeProfiles, treeProfilesEditJson);
    updateTreeResources(trRessource, treeResourcesEditJson);
  };
  const updateTreeProfiles = (treeProfiles, treeProfilesEditJson) => {
  treeProfiles.forEach(node => {
    traverseAndUpdate(node, treeProfilesEditJson);
    let updatedTreeData = updateCheckedState(treeProfiles, node.id, node.checked || false);
    setTreeProfiles(updatedTreeData);
  });
};

const updateTreeResources = (trRessource, treeResourcesEditJson) => {
  trRessource.forEach(async node => {
    traverseAndUpdateRes(node, treeResourcesEditJson);
    let updatedTreeDataR = updateCheckedState(trRessource, node.id, node.checked || false);
    setTrRessource(updatedTreeDataR);
  });
};
  const updateGroupesData = (rolesData, roleNameEdit) => {
    return rolesData.map(async role => {
      if (role.role === roleNameEdit) {
        const roles = await getAllRoles("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, currentUser().email);
        const roleCreationDate = roles.find(roleData => roleData.Nom  === roleNameEdit)?.["Date de Création"] || "N/A";
        return {
          ...role,
          role: groupeName,
          creationDate: roleCreationDate 
        };
      }
      return role;
    });
  };
  const fetchAndProcessRolesData = async (roleNameEdit) => {
    console.log("rolenmedit", roleNameEdit);
    console.log("roleidedit", groupeIdEdit);
    const rolesDataToEdit = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, cleanAndFormatNameResource(groupeIdEdit));
    const treeProfilesEditJson = JSON.parse(rolesDataToEdit.Profile);
    const treeResourcesEditJson = JSON.parse(rolesDataToEdit.Permission);
    return { treeProfilesEditJson, treeResourcesEditJson };
  };
 
const updateCheckedStateTemp = (nodes) => {
  const updateNode = (node) => {
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        updateNode(child);
        if (child.checked) {
          node.checked = true;
        }
      });
    }
  };

  nodes.forEach((node) => updateNode(node));
  return [...nodes];
};
  const handleEditOnTable = async (groupeEdited) => {
    setShowModalEdit(true);
    setShowModal(true);
    setGroupeName(groupeEdited.groupe);
    setGroupeNameEdit(groupeEdited.groupe);
    setGroupeIdEdit(groupeEdited.id);

    try {
    const rolesDataToEdit = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, groupeEdited.id);
    console.log("gaga",groupeEdited);
    const treeProfilesEdit = rolesDataToEdit.Profile;
      console.log("treeProfilesEdit", treeProfilesEdit);
      const treeProfilesEditJson = JSON.parse(treeProfilesEdit);
      console.log("treeProfilesEditJson", treeProfilesEditJson);
      const treeResourcesEdit = rolesDataToEdit.Permission;
      console.log("treeResourcesEditDuplicate", treeResourcesEdit);
      const treeResourcesEditJson = JSON.parse(treeResourcesEdit);
      console.log("treeresourcesEditJsonDuplicate", treeResourcesEditJson);
      
      const traverseAndUpdate = (node) => {
        treeProfilesEditJson.forEach(profile => {
          // console.log("my profile", profile);
          const nameApp = "Application: " + profile.refProfile.description.split("Application:")[1].trim().split("/ Module :")[0].trim()||'';
          const moduleNameApp = "Module : " + profile.refProfile.description.split("Module :")[1].trim().split("/ Processus :")[0].trim();
          const processusName = "Processus : " + profile.refProfile.description.split("Processus :")[1].trim().split("/ Activite :")[0].trim();
            const activiteName = "Activite : " + profile.refProfile.description.split("Activite :")[1].trim();
          //  console.log("------NameApp--------",nameApp,"------moduleNameApp--------",moduleNameApp, "------processusName------", processusName,  "------activiteName-------", activiteName,"------noooooode---",node.name,"------");

            if(nameApp && nameApp === node.name){
              const moduleNode = node.children.find(child => child.name === moduleNameApp);
            if (moduleNode && moduleNode.children.length>0) {
              
                const processusNode = moduleNode.children.find(child => child.name === processusName);
                if (processusNode && processusNode.children && processusNode.children.length > 0) {
                  const activiteNode = processusNode.children.find(child => child.name === activiteName);
                  if (activiteNode) {
                    activiteNode.checked = true;
                    processusNode.checked = true;
                    moduleNode.checked = true;
                    node.checked = true;
                  }
                
              }
            }}
        });
    
        if (node.children && node.children.length > 0) {
            node.children.forEach(child => {
                traverseAndUpdate(child);
            });
        }
    };
    
    const traverseAndUpdateRes = (node, treeResourcesEditJson) => {
      treeResourcesEditJson.forEach((resource) => {
        if (resource.resource && node.name.includes(resource.resource)) {
          node.checked = true;
        }
      });
    
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => traverseAndUpdateRes(child, treeResourcesEditJson));
      }
    };
    
      treeProfiles.forEach(node => {
        console.log("node profile", node);
        traverseAndUpdate(node);
        let updatedTreeData = updateCheckedState(treeProfiles, node.id, node.checked || false);
        setTreeProfiles(updatedTreeData);
      });
     // Process resources
     const updatedTreeResources = trRessource.map((node) => {
      traverseAndUpdateRes(node, treeResourcesEditJson);
      return node;
    });

    const finalTreeResources = updateCheckedStateTemp(updatedTreeResources);
    setTrRessource(finalTreeResources);
  } catch (error) {
    console.error("Error fetching role details:", error);
 
  }
};


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, groupeBackData.length);

  return (
    <div className='roles-empty-container'>
      {/* div1 */}
      <div style={{ display: 'block' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 500, fontSize: '15px' }}>Groupes</span>

          <div className="add-role">
            <span className="material-icon" >
              add
            </span><span
              style={{ fontWeight: 600, fontSize: '13px' }}
              onClick={handleNouveauGroupeClick}
            >
              Nouveau groupe
            </span></div>
        </div>
        <div className="title-400">
          Regroupez les accès de vos utilisateurs à l'aide de groupes
        </div>
      </div>
      {/* horizontal line */}
      <hr className="horizontal-line" />
      {/* div2 */}

      {groupeBackData.length <= 0 ? (
        <div className="container-roles">
          <div className="container-icon-role">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.9567 19.5753L26.8605 16.3565L29.125 20.025C30.9701 21.5011 33.4651 22.5 36.1562 22.5C38.8381 22.5 41.3345 21.5074 43.1875 20.025L46 15.4688L41.0759 12.5143C41.5112 11.716 41.7812 10.8154 41.7812 9.84375C41.7812 6.74147 39.2571 4.21875 36.1562 4.21875C33.0554 4.21875 30.5312 6.74147 30.5312 9.84375C30.5312 10.8154 30.8012 11.716 31.2366 12.5143L28.2564 14.3025C28.2312 14.2228 28.2262 14.1404 28.1857 14.0639C27.8218 13.3773 26.9745 13.1122 26.2851 13.4789L19.6412 17.0897C18.9545 17.4536 18.6922 18.3037 19.0562 18.9903C19.4219 19.6801 20.2746 19.9362 20.9567 19.5753ZM28.4892 33.1945C28.9108 32.5422 28.724 31.673 28.0717 31.25L21.8617 27.138L17.5324 24.4596L17.875 22.5938C17.875 19.4915 15.3509 16.9688 12.25 16.9688C9.14913 16.9688 6.625 19.4915 6.625 22.5938L6.96766 24.4596L2.40625 28.2188L5.21875 32.775C7.06384 34.2511 9.55881 35.25 12.25 35.25C14.9318 35.25 17.4283 34.2574 19.2812 32.775L21.0265 29.9478L26.5445 33.6121C27.2011 34.0357 28.0698 33.8427 28.4892 33.1945ZM41.7812 35.3438C41.7812 32.2415 39.2571 29.7188 36.1562 29.7188C33.0554 29.7188 30.5312 32.2415 30.5312 35.3438C30.5312 36.3154 30.8012 37.216 31.2366 38.0143L26.3125 40.9688L29.125 45.525C30.9701 47.0011 33.4651 48 36.1562 48C38.8381 48 41.3345 47.0074 43.1875 45.525L46 40.9688L41.0759 38.0143C41.5112 37.216 41.7812 36.3154 41.7812 35.3438Z" fill="#FFEDD5" />
              <path d="M29.125 20.025V18.2812C29.125 15.9469 31.0093 14.0625 33.3438 14.0625H36.1562C33.8299 14.0625 31.9375 12.1701 31.9375 9.84375C31.9375 7.51744 33.8299 5.625 36.1562 5.625C38.4826 5.625 40.375 7.51744 40.375 9.84375C40.375 12.1701 38.4826 14.0625 36.1562 14.0625H38.9688C41.3032 14.0625 43.1875 15.9469 43.1875 18.2812V20.025C45.7749 17.972 47.4062 14.7937 47.4062 11.25C47.4062 5.03428 42.372 0 36.1562 0C29.9405 0 24.9062 5.03428 24.9062 11.25C24.9062 14.7937 26.5376 17.9719 29.125 20.025ZM12.25 12.75C6.03428 12.75 1 17.7843 1 24C1 27.5438 2.63134 30.7219 5.21875 32.775V31.0312C5.21875 28.6969 7.10303 26.8125 9.4375 26.8125H12.25C9.92369 26.8125 8.03125 24.9201 8.03125 22.5938C8.03125 20.2674 9.92369 18.375 12.25 18.375C14.5763 18.375 16.4688 20.2674 16.4688 22.5938C16.4688 24.9201 14.5763 26.8125 12.25 26.8125H15.0625C17.397 26.8125 19.2812 28.6969 19.2812 31.0312V32.775C21.8687 30.722 23.5 27.5438 23.5 24C23.5 17.7843 18.4657 12.75 12.25 12.75ZM36.1562 25.5C29.9405 25.5 24.9062 30.5343 24.9062 36.75C24.9062 40.2938 26.5376 43.4719 29.125 45.525V43.7812C29.125 41.4469 31.0093 39.5625 33.3438 39.5625H36.1562C33.8299 39.5625 31.9375 37.6701 31.9375 35.3438C31.9375 33.0174 33.8299 31.125 36.1562 31.125C38.4826 31.125 40.375 33.0174 40.375 35.3438C40.375 37.6701 38.4826 39.5625 36.1562 39.5625H38.9688C41.3032 39.5625 43.1875 41.4469 43.1875 43.7812V45.525C45.7749 43.472 47.4062 40.2938 47.4062 36.75C47.4062 30.5343 42.372 25.5 36.1562 25.5Z" fill="#EA580C" />
            </svg>
          </div>
          <span className="empty-role-text">Aucun groupe pour l'instant</span>
          <span className="title-400">
            La liste des groupes créés sera affichée ici
          </span>
        </div>
      ) : (
        <div style={{ padding: '35px' }}>
          <div style={{ marginBottom: '30px' }}> <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} /></div>
          <div style={{ marginBottom: '20px' }}>
            <AppTable servicesList={filteredGroupeData.slice(startIndex, endIndex)} /></div>
          <div style={{ height: '50px' }}>
            <Pagination
              light={true}
              totalItems={filteredGroupeData.length}
              itemsPerPage={itemsPerPage}
              maxPage={Math.ceil(filteredGroupeData.length / itemsPerPage)}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>)}

      {showModal && (
        <Modal
          icon=""
          leftBtnArgs={{
            args: { onClick: closeModal },
            color: 'neutral',
            label: 'Annuler',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            args: { onClick: showModalEdit ? handleEdit : handleAjouterClick },
            color: 'primary',
            label: { 'Ajouter': 'Ajouter', 'Enregistrer': 'Enregistrer' }[showModalEdit ? 'Enregistrer' : 'Ajouter'],
            size: 'sm',
            styling: 'solid'
          }}
          title={showModalEdit ? "Détails du groupe" : "Nouveau groupe"}
          onClose={closeModal}
          bodyStyle={{ padding: '20px' }}
        >
          {showModalEdit && (
            <div className='tabs-roles-edit'>
              <Tabs current={'general'}
                align="horizontal"
                defSelected="general"
                icon={[]}
                iconAndName={[]}
                icons={[]}
                item={[]}
                onSelect={handleTabsEdit}
                size="small"
                tabs={[
                  {
                    label: 'Général',
                    name: 'general'
                  },
                  {
                    label: 'À propos',
                    name: 'propos'
                  },

                ]}
              /></div>)}
          {activeTabEdit === "general" ? (
            <>
              <span className="add-name-modal-text">Nom du groupe</span>
              <Input
                size='sm'
                args={{
                  placeholder: 'Entrer le nom du groupe',
                  onChange: handleGroupeNameChange,
                  value: groupeName
                }}
                helpText={helpText}
                errorMessage={!isInputModified ? groupeNameError : ''}
              />

              <SelectTabs current={activeTab}
                align="horizontal"
                defSelected={activeTab}
                icon={[]}
                iconAndName={[]}
                icons={[]}
                item={[]}
                onSelect={handleTabsSelect}
                size="small"
                tabs={[
                  {
                    label: 'Roles',
                    name: 'tab1'
                  },
                  {
                    label: 'profils',
                    name: 'tab2'
                  },
                  {
                    label: 'Ressources',
                    name: 'tab3'
                  },
                ]}
              />
              <div>
                {renderContent()}
              </div>
            </>
          ) : (activeTabEdit === "propos" &&
            <div className="about-tabs-informations">

              <div className='flex-space'><span className='title-propos'>Date de création:</span> <span className='descp-propos'>{aboutData.creationDate}</span></div>
              <div className='flex-space'><span className='title-propos'>Dernière modification: </span><span className='descp-propos'>{aboutData.lastModification}</span></div>
              <hr style={{ border: 'none', borderTop: '1px solid #cdd4e0', margin: '10px 0' }} />
              <div className='flex-space'><span className='title-propos'>Nombre d'utilisateurs:</span> <span className='descp-propos'>{aboutData.numberOfUsers}</span></div>
            </div>)}
          <div />
        </Modal>
      )}
      {showModalDelete && (
        <ModalDelete
        closeModalDelete={closeModalDelete}
        handleDeleteButtonClick={handleDeleteButtonClick}
        errorMessage={errorMessage}
        randomNumber={randomNumber&&randomNumber}></ModalDelete>

      )}
    </div>
  );
}