interface JoinOrganizationData {
    apiKey: string;
    userId: string;
    role: string;
    organizations: { organisationId: string; name: string }[];
  }
  
  export async function sendRequestToJoinOrganization(joinData: JoinOrganizationData) {
    try {
      const response = await fetch("/karazal/ow-kls-extrat/usermanager/sendRequestToJoinOrganization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(joinData)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Response from sendRequestToJoinOrganization service:", responseData);
  
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
  
  interface RequestData {
    apiKey: string;
    userId: string;
    ownerlogin?:string,
    organizations: { organisationId: string; name: string }[];
  }
  
  export async function getAllRequestToJoinOrganization(data: RequestData) {
    try {
      const response = await fetch("/karazal/ow-kls-extrat/usermanager/allRequestToJoinOrganization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Response from allRequestToJoinOrganization service:", responseData);
  
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
  export async function acceptRequestToJoinOrganization(data: RequestData) {
    try {
      const response = await fetch("/karazal/ow-kls-extrat/usermanager/acceptRequestToJoinOrganization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Response from acceptRequestToJoinOrganization service:", responseData);
  
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
  
  
export async function refuseRequestToJoinOrganization(data: RequestData) {
    try {
      const response = await fetch("/karazal/ow-kls-extrat/usermanager/refuseRequestToJoinOrganization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Response from refuseRequestToJoinOrganization service:", responseData);
  
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }