import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Model } from "kls-commons/types/application-model";
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { NewModelWizard } from "./new-model-wizard";
import { getLabelDirectory, loadAllModel } from "./module.service";

export function LocalModelesSearch( {module, model, setCurrentResource}:LocalModelesSearchType ) {

    const [showModelsPropositions, setShowModelsPropositions] = useState<boolean>( false ) ;
    const history = useHistory() ;
    const [showCreationModelWizar, setShowCreationModelWizar] = useState(false);
    const [labelModule,setLabelModule] = useState<string>( module );
    const [labelModel,setLabelModel] = useState<string>( model||'' );
    const [models, setModels] = useState<Model[]> ();
    
    useEffect(
        () => { 
            loadAllModel( (m: any)=>setModels([...m]),  module) ;
            setShowModelsPropositions(false) ;
        }, [module, showCreationModelWizar]
    );

    useEffect(
        () => { 
            console.log( "debuging :::: " , module )
            getLabelDirectory( module ).then( label => { setLabelModule( label ) ; console.log( "debuging :::: " , module, label ) } )
        }, [module]
    );

    useEffect(
        () => {
            getLabelDirectory( module, model ).then(
                label => {
                    setLabelModel( label ) ;
                }
            )
            if( model )
                history.push( "/editService/" + module + "/" + model ) ;
            setShowModelsPropositions(false) ;
        } ,[model]
    )

    const headerModel:React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%"
    }
    
    return <>
        {showCreationModelWizar && <NewModelWizard onClose={() => {setShowCreationModelWizar(false);}} moduleName={module}></NewModelWizard>}
        <div className="nav-module">
            <span style={headerModel} >
                <span className="header-module" title={labelModule}>{labelModule}</span>
                <span className="header-model" onClick={() => {setShowModelsPropositions(true)}}  title={labelModel}>
                    <span className='label-module'>{labelModel || "Choisir un module"}</span> 
                    <span className='icon-container-nav-module' style={{position:"relative"}} >
                          <span className="material-icon">expand_more</span>
                    </span>
                    {
                        showModelsPropositions && models && 
                        <AdvancedDropDown   data={models.map(model => ({ value: model.modelName, label: model.label, icon: <span className="material-icon" style={{color: "#8B5CF6"}}>folder</span> }))}
                                            handleChange={(model) => { history.push( "/editService/" + module + "/" + model.value ) ; setCurrentResource('')  } } 
                                            size={3} 
                                            light={true} 
                                            enableSearch={true} 
                                            addNew={{ label: "Nouveau module", handleClick: () => { setShowCreationModelWizar(true); }}} 
                                            selectedValue={model||""}
                                            style={{top:'60px', minWidth: '270px'}}
                                            closeWhenClickOutside={() => {setShowModelsPropositions(false)}} />
                    }
                </span>
            </span>
        </div>
    </>
}

interface LocalModelesSearchType {
    module:string,
    model :string|undefined,
    setCurrentResource: Function,
}

