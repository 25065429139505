import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter, Link, Route, Switch, useLocation, } from "react-router-dom";
import { currentUser, fetchLocalUserProfile, signout, storeLocalUserProfile } from "kls-commons/service/userService";
import { GS, setToStorage } from "kls-commons/state/global-state";
import { getTemplatesDirectoryTree, listModules } from "kls-commons/service/repo-service";
import ModuleEditor from "./module/module-editor";
import { Header } from "./layout/header";
import { ProfileHeader } from 'kls-ui';
import { ParamApp } from "./app-params/param-app";
import { PwdReset, Signup, Login, FirstLogin } from "kls-auth";
import MyAppsPage from "./on-boarding-pages/my-apps";
import { Home } from "./on-boarding-pages/home-page";
import { SettingsModule } from "./setting-organization/settings-module";
import { CataloguePage, MyOrganization } from "kls-catalog";
import ExcelEditor from "./module/excel-editor";
import { StepsBar, StepsBarMobile } from "./on-boarding-pages/steps-bar";
import { NewModelWizardViaAssistant } from "./module/new-model-wizard-assisted";
import { ExcelUploadModal } from "./module/excel-upload-modal";
// import { MyOrganization } from "kls-catalog";

function AppHome(props: any) {

  const [itemToDisplay, setItemToDisplay] = useState<string>();
  const location = useLocation();

  useEffect(
    () => {
      setItemToDisplay(location.pathname.split("/")?.[1]);
    }, [location]
  )

  const [firstConnection, setFirstConnection] = useState<boolean>( false ) ;

  useEffect( 
    () => {
      let user = JSON.parse( localStorage.getItem('USER_DETAILS') || '{}' ) ;

      if( user && user['firstConnect'] ) {
        setFirstConnection( user['firstConnect'] )
      }
    } , []
  )

  const [themeHandler, setthemeHandler] = useState(false)
  const [hintType, sethintType] = useState("")
  const [mobilescreen, setmobilescreen] = useState(false)
  useEffect(() => {
    !themeHandler && sethintType("")
    if (window.innerWidth <= 700) {
      setmobilescreen(true)
    }
    else {
      setmobilescreen(false)
    }
  }, [themeHandler])


  return <Switch>
    <Route path="/editService/:moduleName" exact strict>
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/editService/:moduleName/:modelName" exact strict>
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/editService/:moduleName/:modelName/:resourceType/:resourceName" exact strict>
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/parametreApp/:moduleName" exact>
      <CommonHeader selectedItem={'application'} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      <ParamApp />
    </Route>
    <Route path="/editExcel/:modelName/:moduleName" exact>
          <Header>
            <NavBar selectedItem={'application'}/>
            <ProfileHeader profileName={currentUser().fullName} profileImage={currentUser().avatarUrl ?? ""} profileEmail={currentUser().id ?? ""} klsVersion="KLS version 0.7" logoutHandler={()=>{storeLocalUserProfile(null); signout(); document.location = "/";}}  />
          </Header>
          <ExcelEditor></ExcelEditor>
      </Route>
    <Route path="/">

      {
        firstConnection ? 
        <FirstLogin gs={GS} connected={true}></FirstLogin>
        :
        <>        
          <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
    
          {itemToDisplay === 'application' && <MyAppsPage gs={GS} />}
    
          {itemToDisplay === 'catalog' && <CataloguePage />}

          {
            itemToDisplay === 'wizard' && <NewModelWizardViaAssistant></NewModelWizardViaAssistant>
          }
    
          {itemToDisplay === "settings" && <SettingsModule type="settings" tab="workspace" gs={GS} />}
    
          {itemToDisplay === "organisation-settings" && <SettingsModule type="organizationSettings" tab="Organisation" gs={GS} />}
    
          {itemToDisplay === "organisation" && <MyOrganization gs={GS} ></MyOrganization>}

          {itemToDisplay === "" && <Home gs={GS} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />}
        </>
      }


    </Route>
  </Switch>

}



function App() {
  let [userProfile, setUserProfile] = useState(fetchLocalUserProfile());

  useEffect(() => {
    getTemplatesDirectoryTree(undefined, undefined).then((s) => {
      if (s) {
        setToStorage("TEMPLATE_MODELS", s);
      }
    });

  }, []);

  return (
    <HashRouter>
      <div className="App">
        <Switch>
          <Route path="/reset-pwd/:activationId/:email">
            <PwdReset></PwdReset>
          </Route>
          <Route path="/login">
            <Login postSignIn={setUserProfile}></Login>
          </Route>
          <Route path="/signup">
            <Signup postSignIn={setUserProfile}></Signup>
          </Route>
          <Route path="/">
            {userProfile && <AppHome gs={GS}></AppHome>}
            {!userProfile && <Login postSignIn={setUserProfile} ></Login>}
          </Route>
        </Switch>
        <div className="toasts-container"></div>
      </div>
    </HashRouter>
  );
}


function NavBar({ selectedItem }: { selectedItem: string }) {

  const navBar = [
    {
      link: '',
      label: 'Accueil'
    },
    {
      link: 'organisation',
      label: 'Organisations'
    },
    {
      link: 'application',
      label: 'Applications'
    },
    {
      link: 'catalog',
      label: 'Catalogue'
    },
    {
      link: 'resource',
      label: 'Ressources'
    }
  ]


  return <div className="navbar-container">
    {
      navBar.map(
        (item: any, index: number) => <Link key={index} className={`link-container ${selectedItem === item.link ? 'selected' : ''}`} to={`/${item.link}`}><span className="color-link">{item.label}</span></Link>
      )
    }
  </div>
}





function CommonHeader(props: any) {
  return (
    <>
      <Header>
        <NavBar selectedItem={props.selectedItem} />

        <StepsBar themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} sethintType={props?.sethintType} />
        <div className="profile-notifications">
          <div className='notificaitons'>
            <span className='icon notifications-icon' style={{ fontSize: '24px' }}>notifications</span>
          </div>
          <ProfileHeader profileName={currentUser().fullName} profileImage={currentUser().avatarUrl ?? ""} profileEmail={currentUser().id ?? ""} klsVersion="KLS version 0.7" settingsHandler={() => document.location = "/#/settings"} logoutHandler={() => { storeLocalUserProfile(null); signout(); document.location = "/"; }} themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} />
        </div>
      </Header>
      <StepsBarMobile themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} sethintType={props?.sethintType} />
    </>
  )
}





















export default App;
