import { useState, useEffect, useRef } from "react";
import { CourseCard } from 'kls-ui';

export function CourseDetails(props: any) {

  return (
    <div className="course-details">
      <div className="course-cards">
        <h3 className="title">Cours et tutoriaux</h3>
        <span className="sub-head">Afficher tous</span>
      </div>
      <div className="course-section">
        <CourseCard content="Créez votre première Application" color="#3B82F6" />
        <CourseCard content="Créez vos pages avec La bibliothèque de composants" color="#10B981" />
        <CourseCard content="Concevez vos processus selon Les standards BPMN" color="#F97316" />
        <CourseCard content="Créez et configurez des Groupes d’utilisateurs" color="#8B5CF6" />
      </div>
    </div>
  );
}
