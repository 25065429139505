import { useHistory, useParams, useLocation} from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import AdvancedTable from 'kls-ui/advancedTable/advanced-Table';
import { Tabs } from 'kls-ui/tabs/Tabs';
import { EditorHeaderBar } from "kls-view-editor/editor-header-bar";
import { UndoRedo } from "kls-view-editor/undo-redo";
import {PageHeader, AutoSaveBadge, Dropdown, ToggleButton, HeaderBloc, Button, Input} from 'kls-ui'
import { OnePilTab } from "kls-ui/pilTabs/PilTabs";
import { IconButton } from 'kls-ui';
import { COMPONENT_CATALOG } from "kls-view-editor/services/component-catalog-service"
import {LoadingApplication} from './loading-module';
import { Modal } from 'kls-ui';
import { PreviewWizard } from "kls-view-editor/preview/preview-wizard";
import {  cloneModel, fetchKlsResource, NewModel, writeKlsResource } from "kls-commons/service/repo-service";
import { getTenant } from "kls-commons/service/resource-service";
import { ResourceContent } from 'kls-commons/types/repo-model';
import { getFromStorage, GS, saveGlobaleState } from 'kls-commons/state/global-state';
import { currentUser } from 'kls-commons/service/userService';
import { xmlElement2str } from 'kls-commons/help/tools';



interface FileState {
    eFile?: File; 
}



//const API_BASE_URL = 'http://localhost:5000'; 
//const API_BASE_URL = 'https://excel.karaz.org'; 
const API_BASE_URL = 'https://internship.karaz.org/excelapi'; 

export default function ExcelEditor() {
    
    let history = useHistory();

    const {modelName, moduleName, resourceType, resourceName} = useParams() as any;  
    const location = useLocation();
    const fileState = location.state as FileState;
    const file : File | undefined  = fileState.eFile;
    
    const [excelData, setExcelData] = useState<any | null>(null);
    const [dataTypes, setDataTypes] = useState<any | null>(null);
    const [attr, setAttr] = useState<any | null>(null);

    const [selectedSheet, setSelectedSheet] = useState<string>('');
    const [initialDataOrder, setInitialDataOrder] = useState<any | null>(null);
 
    const [selectedColumn, setSelectedColumn] = useState('');

    const handleReader = async (file) => {

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${API_BASE_URL}/reader`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const data = await response.json();
            console.log("data1 z", data);
            console.log("data2 z", data.sheets);
            console.log("data3 z",data.sheets[0].name)
            console.log("data4 z", data.types);
            setExcelData(data.sheets);
            setDataTypes(data.types)
            setAttr(data.col_attrs);
            setSelectedSheet(data.sheets[0].name); 
            setInitialDataOrder(data.sheets);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        handleReader(file);
        console.log(dataTypes)
    },[file])

    useEffect(()=>{
        setSelectedColumn('');
        handleColumnSelect('');
    },[selectedSheet])

    const [filterText, setFilterText] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[][]>([]);
    
    const handleFilter = () => {
        if (excelData && excelData.data) {
            if (!filterText) {
                setFilteredData([]);
                return;
            }
    
            const filtered = excelData.data.filter(row =>
                row.some(cell => String(cell).toLowerCase().includes(filterText.toLowerCase()))
            );
            setFilteredData(filtered); 
        }
    };

    const initialSortingState = excelData ? excelData.find(sheet => sheet.name === selectedSheet)?.columns.map(() => ({ direction: '' })) : [];

    const [sorting, setSorting] = useState(initialSortingState);

    const handleSort = (columnIndex, sortType) => {
        const sortedData = excelData.map(sheet => {
            if (sheet.name === selectedSheet) {
                const sortedSheetData = [...sheet.data];
                sortedSheetData.sort((a, b) => {
                    const aValue = a[columnIndex];
                    const bValue = b[columnIndex];
    
                    if (aValue === bValue) return 0;
    
                    if (sortType === 'asc') {
                        return aValue < bValue ? -1 : 1;
                    } else {
                        return aValue > bValue ? -1 : 1;
                    }
                });
                
    
                return { ...sheet, data: sortedSheetData };
            }
            return sheet;
        });
    
        setExcelData(sortedData);
    };

    const renderInputIcon = (columnName) => {
        const selectedSheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
        console.log("selectedSheetIndex: ", selectedSheetIndex);
        const selectedSheetDataTypes = dataTypes[selectedSheetIndex];
        console.log("selectedSheetDataTypes: ", selectedSheetDataTypes);

        const dataType = selectedSheetDataTypes;
        console.log("dataType: ", dataType);

        //const dataType = dataTypes.find((type) => type.data_types[columnName]);
    
        if (dataType) {
            switch(dataType.data_types[columnName]) {
                case 'object':
                    return <span className="material-symbols-outlined" style={{fontSize:'medium',color:'#5095f8',paddingLeft:'2px'}}>title</span>
                case 'int64':
                    return <span className="material-symbols-outlined" style={{fontSize:'medium',color:'#5095f8',paddingLeft:'2px'}}>tag</span>;
                case 'datetime':
                    return <span className="material-symbols-outlined" style={{fontSize:'medium',color:'#5095f8',paddingLeft:'2px'}}>event</span>;
                case 'radio':
                    return <span className="material-symbols-outlined" style={{fontSize:'medium',color:'#5095f8',paddingLeft:'2px'}}>radio_button_checked</span>;
                case 'dropdown':
                    return <span className="material-symbols-outlined" style={{fontSize:'medium',color:'#5095f8',paddingLeft:'2px'}}>dropdown</span>;
                case 'prefilled':
                    return <span className="material-symbols-outlined" style={{fontSize:'medium',color:'#5095f8',paddingLeft:'2px'}}>variables</span>;
                default:
                    return null;
            }
        }
    
        return null;
    }
    const handleColumnSelect = (columnName) => {
        setSelectedColumn(columnName);
      };

      const [currentPageMap, setCurrentPageMap] = useState<{ [key: string]: number }>({});

      useEffect(() => {
        setCurrentPageMap(prevState => ({
          ...prevState,
          [selectedSheet]: 1, 
        }));
      }, [selectedSheet]);
    
      const handlePageChange = (page: number) => {
        setCurrentPageMap(prevState => ({
          ...prevState,
          [selectedSheet]: page,
        }));
      };

      const [lastTimeSaved, setlastTimeSaved] = useState("none");
      let [currentTabModel, setCurrentTabModel] = useState("atr");

      const [searchQuery, setSearchQuery] = useState('');
      const [replaceText, setReplaceText] = useState('');

      //
      const [isReplace, setisReplace] = useState(false);
      const [isReplaceAll, setisReplaceAll] = useState(false);
      //

      const [currentOccurrenceIndex, setCurrentOccurrenceIndex] = useState(-1);
      const [totalOccurrences, setTotalOccurrences] = useState(0);

      const handleFind = () => {
        let total = 0;
      
        if (selectedColumn) {
          const selectedSheetData = excelData.find(sheet => sheet.name === selectedSheet)?.data || [];
          //const columnIdx = selectedSheetData.length > 0 ? selectedSheetData[0].indexOf(selectedColumn) : -1;
          const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
          const columnIdx = excelData[sheetIndex].columns.indexOf(selectedColumn);

          if (columnIdx !== -1) {
            selectedSheetData.forEach(row => {
              const cellString = String(row[columnIdx]);
              const regex = new RegExp(searchQuery, 'gi');
              const occurrences = cellString.match(regex);
              if (occurrences) total += occurrences.length;
            });
          }
        } else {
          excelData.forEach(sheet => {
            if (sheet.name === selectedSheet) {
              sheet.data.forEach(row => {
                row.forEach(cell => {
                  const cellString = String(cell);
                  const regex = new RegExp(searchQuery, 'gi');
                  const occurrences = cellString.match(regex);
                  if (occurrences) total += occurrences.length;
                });
              });
            }
          });
        }
      
        setTotalOccurrences(total);
        setCurrentOccurrenceIndex(0);
      };

      useEffect(() => {
        if(searchQuery){
            handleFind();
        }
      },[excelData])
      
    
      const handleReplace = (isReplace,isReplaceAll) => {
        
        setisReplace(isReplace)
        setisReplaceAll(isReplaceAll)
        
      };
      
      
      const goToNextOccurrence = () => {
        if (currentOccurrenceIndex < totalOccurrences - 1) {
          setCurrentOccurrenceIndex(prevIndex => prevIndex + 1);
        }
      };
    
      const goToPreviousOccurrence = () => {
        if (currentOccurrenceIndex > 0) {
          setCurrentOccurrenceIndex(prevIndex => prevIndex - 1);
        }
      };

/*
        const handleFind = () => {
            setSearchQuery(searchQuery)
        };

        const handleReplace = () => {
            const updatedExcelData = excelData.map(sheet =>
                sheet.name === selectedSheet
                  ? { ...sheet, data: sheet.data.map(row =>
                      row.map(cell =>
                        typeof cell === 'string' ? cell.replace(new RegExp(searchQuery, 'gi'), replaceText) : cell
                      )
                    ) }
                  : sheet
              );
              
            
            setExcelData(updatedExcelData);
            //setReplaceText(replaceText)
        };
*/
        const [selectedColumnType, setSelectedColumnType] = useState('');
        const [editedLabel, setEditedLabel] = useState('');
        
        
        const handleLabelChange = (e) => {
            const newLabel = e.target.value.trim();
            
            if (newLabel !== selectedColumn && newLabel !== '') {
                const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
                if (sheetIndex !== -1) {
                    const columnIndex = excelData[sheetIndex].columns.indexOf(selectedColumn);
                    if (columnIndex !== -1) {
                        excelData[sheetIndex].columns[columnIndex] = newLabel;
                    }
                }

                const typesIndex = sheetIndex;
                if (typesIndex !== -1) {
                    dataTypes[typesIndex].data_types[newLabel] = dataTypes[typesIndex].data_types[selectedColumn];
                    delete dataTypes[typesIndex].data_types[selectedColumn];
                }
                
                setSelectedColumn(newLabel);
                setEditedLabel(newLabel);
                console.log("excel data: ",excelData,"data types:", dataTypes)
            }
            
        };
      /*
        const handleColumnTypeChange = (e) => {
            const newType = e.target.value;
            const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);

            if (sheetIndex !== -1) {
              const columnTypes = dataTypes[sheetIndex].data_types;
        
              if (selectedColumn in columnTypes) {
                dataTypes[sheetIndex].data_types[selectedColumn] = newType;
        
                if (newType === 'radio' || newType === 'dropdown') {
                  const options = [...new Set(dataTypes[sheetIndex].radio_options[selectedColumn])].filter(value => value !== "");
                  
                  if (newType === 'radio') {
                    dataTypes[sheetIndex].radio_options[selectedColumn] = options.length ? options : ["option1", "option2"];
                  } else if (newType === 'dropdown') {
                    dataTypes[sheetIndex].dropped_down_select[selectedColumn] = options.length ? options : ["option1", "option2"];
                  }
                }
              }
        
              setDataTypes([...dataTypes]);
              setSelectedColumnType(newType);
            }
          };
*/
          const handleColumnTypeChange = (e) => {
            const newType = e.target.value;
            const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
          
            if (sheetIndex !== -1) {
              const columnTypes = dataTypes[sheetIndex].data_types;
          
              if (selectedColumn in columnTypes) {
                const previousType = dataTypes[sheetIndex].data_types[selectedColumn];
          
                
                dataTypes[sheetIndex].data_types[selectedColumn] = newType;
          
                if (newType === 'radio' || newType === 'dropdown') {
                  
                  //const options = [...new Set(dataTypes[sheetIndex].radio_options[selectedColumn])].filter(value => value !== "");
                    
                    let options = [];
                
                if (previousType === 'radio') {
                    options = dataTypes[sheetIndex].radio_options[selectedColumn] || [];
                } else if (previousType === 'dropdown') {
                    options = dataTypes[sheetIndex].dropped_down_select[selectedColumn] || [];
                }
                
                options = [...new Set(options)].filter(value => value !== "");

                  if (newType === 'radio') {
                    dataTypes[sheetIndex].radio_options[selectedColumn] = options.length ? options : ["option1", "option2"];
                    if (previousType === 'dropdown') delete dataTypes[sheetIndex].dropped_down_select[selectedColumn];
                  } else if (newType === 'dropdown') {
                    dataTypes[sheetIndex].dropped_down_select[selectedColumn] = options.length ? options : ["option1", "option2"];
                    if (previousType === 'radio') delete dataTypes[sheetIndex].radio_options[selectedColumn];
                  }
                } else if (previousType === 'radio' && newType === 'object') {
                  
                  delete dataTypes[sheetIndex].radio_options[selectedColumn];
                }else if (previousType === 'dropdown' && newType === 'object') {
                    delete dataTypes[sheetIndex].dropped_down_select[selectedColumn];
                }
              }
          
              setDataTypes([...dataTypes]);
              setSelectedColumnType(newType);
            }
          };

          
          const limit = 8;

        const addOptionToColumn = (columnName, newOption) => {
            // Find the index of the sheet
            const sheetIndex = dataTypes.findIndex(sheet => sheet.data_types[columnName]);
        
            // Check if the sheet with given columnName exists
            if (sheetIndex !== -1) {
                // Check if the column is of type 'radio' and 'dropdown'
                if (dataTypes[sheetIndex].data_types[columnName] === 'radio' || dataTypes[sheetIndex].data_types[columnName] === 'dropdown') {
                    // Check if radio_options exist for the column
                    if (dataTypes[sheetIndex].radio_options && dataTypes[sheetIndex].radio_options[columnName]) {
                        // Add the new option to the existing options
                        dataTypes[sheetIndex].radio_options[columnName].push(newOption);
                    } else {
                        // If radio_options doesn't exist, create it and add the new option
                        dataTypes[sheetIndex].radio_options = {
                            ...dataTypes[sheetIndex].radio_options,
                            [columnName]: [newOption]
                        };
                    }
        
                    // Update the state or trigger a re-render
                    setDataTypes([...dataTypes]);
                } else {
                    console.error("Column type must be 'radio' or 'dropdown' to add options.");
                }
            } else {
                console.error(`Sheet with column name "${columnName}" not found.`);
            }
        };

        
        


        const renderColumnTypes = () => {
            if (!selectedSheet || !selectedColumn) return null;
            
            const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
            const sheetInfo = dataTypes[sheetIndex]
            if (!sheetInfo) return null;
        
            const columnType = sheetInfo.data_types[selectedColumn];

            const handleInputChange = (event, index) => {
                const newValue = event.target.value;
                const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);

                const newDataTypes = [...dataTypes];

                if (selectedColumnType === 'radio' && newDataTypes[sheetIndex].radio_options[selectedColumn]) {
                newDataTypes[sheetIndex].radio_options[selectedColumn][index] = newValue;
                } else if (selectedColumnType === 'dropdown' && newDataTypes[sheetIndex].dropped_down_select[selectedColumn]) {
                newDataTypes[sheetIndex].dropped_down_select[selectedColumn][index] = newValue;
                }
                
                setDataTypes(newDataTypes);
            };

            
            let additionalOptions: JSX.Element | null = null;

            if (columnType === 'radio' || columnType === 'dropdown' ) {
                //const options = sheetInfo.radio_options[selectedColumn] || sheetInfo.dropped_down_select[selectedColumn] || sheetInfo.pre_filled_values[selectedColumn];
                let options = columnType === 'radio'
                                ? sheetInfo.radio_options[selectedColumn] || []
                                : columnType === 'dropdown'
                                ? sheetInfo.dropped_down_select[selectedColumn] || []
                                : [];

                const addFields = () => {
                    const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
                    const newDataTypes = [...dataTypes];

                    if (!newDataTypes[sheetIndex][selectedColumnType]) {
                        newDataTypes[sheetIndex][selectedColumnType] = {};
                    }

                    if (selectedColumnType === 'radio') {
                        if (!Array.isArray(newDataTypes[sheetIndex].radio_options[selectedColumn])) {
                            newDataTypes[sheetIndex].radio_options[selectedColumn] = [];
                        }
                        newDataTypes[sheetIndex].radio_options[selectedColumn] = [
                            ...newDataTypes[sheetIndex].radio_options[selectedColumn],
                            ""
                        ];
                    } else if (selectedColumnType === 'dropdown') {
                        if (!Array.isArray(newDataTypes[sheetIndex].dropped_down_select[selectedColumn])) {
                            newDataTypes[sheetIndex].dropped_down_select[selectedColumn] = [];
                        }
                        newDataTypes[sheetIndex].dropped_down_select[selectedColumn] = [
                            ...newDataTypes[sheetIndex].dropped_down_select[selectedColumn],
                            ""
                        ];
                    } else {
                        console.error("Invalid selected column type.");
                        return;
                    }
                

                    setDataTypes(newDataTypes);

                
                };

                const handleRemoveOption = (index) => {
                    const newDataTypes = [...dataTypes];
            
                    if (columnType === 'radio') {
                        newDataTypes[sheetIndex].radio_options[selectedColumn] = newDataTypes[sheetIndex].radio_options[selectedColumn].filter((_, i) => i !== index);
                    } else if (columnType === 'dropdown') {
                        newDataTypes[sheetIndex].dropped_down_select[selectedColumn] = newDataTypes[sheetIndex].dropped_down_select[selectedColumn].filter((_, i) => i !== index);
                    }
            
                    setDataTypes(newDataTypes);
                };

                if (Array.isArray(options)) {
                    
                    additionalOptions = (
                        <div>
                            <div style={{paddingTop:'3px'}}>
                                <label htmlFor="options" >Options:</label>
                                {options.length < limit && (
                                    <span className="material-symbols-outlined" onClick={addFields} style={{ float: 'right', color: '#2563EB', cursor: 'pointer' }}>
                                        add
                                    </span>
                                )}
                            </div>
                            <div style={{display:'grid',width:'100%',padding:'0px 20px'}}>
                                
                                {options.map((option, index) => (
                                    <>
                                    <input key={index} type="text" id={`option-${index}`} name={`option-${index}`} value={option} onChange={(event) => handleInputChange(event, index)} style={{border:'1px solid #E2E8F0', borderRadius:'8px',padding:'5px',margin:'3px'}}/>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{ color: '#FF0000', cursor: 'pointer', marginLeft: '5px' }}
                                        onClick={() => handleRemoveOption(index)}
                                        >
                                        Remove
                                    </span>

                                </>
                                ))}
                                
                            </div>
                        </div>
                    );
                } else {
                    additionalOptions = (
                        <div>
                            <div style={{paddingTop:'3px'}}>
                                <label htmlFor="options" >Options:</label>
                                <span className="material-symbols-outlined" style={{ float: 'right', color: '#2563EB', cursor: 'pointer' }}>
                                    add
                                </span>
                            </div>
                            <div style={{display:'grid',width:'100%',padding:'0px 20px'}}>
                            {options.map((option, index) => (
                                <>
                                <input type="text" id="option" name="option" value={options} onChange={(event) => handleInputChange(event, 0)} style={{border:'1px solid #E2E8F0', borderRadius:'8px',padding:'5px'}}/>
                                </>
                                

                            ))}
                            
                            </div>
                        </div>    
                    );
                }
            }
        
            return (
                <>
                <select value={columnType} style={{color:'grey',backgroundColor:'transparent',border:'1px solid #E2E8F0', borderRadius:'8px',padding:'7px'}}
                onChange={handleColumnTypeChange}>
                    <option value="object">Text</option>
                    <option value="datetime">Datetime</option>
                    <option value="int64">Integer</option>
                    <option value="radio">Radio</option>
                    <option value="dropdown">Dropdown</option>
                    <option value="prefilled">Prefilled</option>

                </select>
                
                {additionalOptions}
                
                </>
            );
          };

          const [refineChoice, setRefineChoice] = useState<string | undefined>(undefined);

          const applyRefinement = () => {
            if (!refineChoice) return;
            
            switch (refineChoice) {
                case 'fixInconsistentSpellings':
                    if (selectedColumn){
                        fixSpelling(selectedColumn);
                    } 
                    break;
                case 'fixDuplicateRows':
                    fixDuplicateRows();
                    setCurrentPageMap(({
                        [selectedSheet]: 1,
                    }));
                    break;
                case 'fixMissingRows':
                    fixEmptyRows();
                    setCurrentPageMap(({
                        [selectedSheet]: 1,
                    }));
                    break;
                case 'fixMissingValues':
                    fixMissingValues()
                    break;
                case 'exploreFrequencyDistribution':
                    //if (refineScope === 'global') {
                    //    exploreFrequencyDistribution();
                    //} else if (refineScope === 'specificColumn' && selectedColumn) {
                    //    exploreFrequencyDistributionForColumn(selectedColumn);
                    //}
                    {selectedColumn ? (exploreFrequencyDistributionForColumn(selectedColumn)):(exploreFrequencyDistribution())}
                    break;
                
                case 'standardizeDateTime':
                    
                    break;
                default:
                    break;
            }
        };


        const [duplicateRowsCount, setDuplicateRowsCount] = useState<number>(0);

        const fixDuplicateRows = () => {
            if (!excelData || !excelData.length || !selectedSheet) return;
        
            const sheet = excelData.find(sheet => sheet.name === selectedSheet);
            if (!sheet) return;
        
            const uniqueRowsMap = new Map<string, boolean>();
            let count = 0;
        
            const refinedData = excelData.map(sheetData => {
                if (sheetData.name !== selectedSheet) return sheetData;
        
                const uniqueRows: any[][] = [];
                const duplicates: any[][] = [];
        
                sheetData.data.forEach((row: any[]) => {
                    const rowKey = row.join(',');
        
                    if (!uniqueRowsMap.has(rowKey)) {
                        uniqueRowsMap.set(rowKey, true);
                        uniqueRows.push(row);
                    } else {
                        duplicates.push(row);
                        count++;
                    }
                });
        
                console.log('Duplicate Rows:', duplicates);
        
                return { ...sheetData, data: uniqueRows };
            });
        
            setExcelData(refinedData);
            setDuplicateRowsCount(count);
        };

        const [emptyRowsCount, setEmptyRowsCount] = useState<number>(0);

        const fixEmptyRows = () => {
            if (!excelData || !excelData.length || !selectedSheet) return;
        
            const sheet = excelData.find(sheet => sheet.name === selectedSheet);
            if (!sheet) return;
        
            let count = 0;
        
            const refinedData = excelData.map(sheetData => {
                if (sheetData.name !== selectedSheet) return sheetData;
        
                const nonEmptyRows: any[][] = [];
                const emptyRows: any[][] = [];
        
                sheetData.data.forEach((row: any[]) => {
                    const isEmptyRow = row.every(cell => cell === '' || cell === null);
                    if (isEmptyRow) {
                        emptyRows.push(row);
                        count++;
                    } else {
                        nonEmptyRows.push(row);
                    }
                });
        
                console.log('Empty Rows:', emptyRows);
        
                return { ...sheetData, data: nonEmptyRows };
            });
            console.log("refined : ",refinedData)
            setExcelData(refinedData);
            setEmptyRowsCount(count);
        };

        const missingValueOptions = [
            { value: 'previous', label: 'Fill with previous value' },
            { value: 'input', label: 'Fill with value (input)' },
            { value: 'mostOccurring', label: 'Fill with most occurring value' }
        ];
        
        const [missingValueOption, setMissingValueOption] = useState('');
        
        const handleMissingValueOptionChange = (e) => {
            setMissingValueOption(e.target.value);
        };

        const [inputValue, setInputValue] = useState('');

        const handleInputChange = (e) => {
            setInputValue(e.target.value);
        };

        const fixMissingValues = () => {
            if (!selectedColumn) return;
        
            const columnIndex = getColumnIndex(selectedColumn);
            if (columnIndex === -1) return; 
        
            const columnData = getColumnData(selectedColumn);
            const missingValueIndices: number[] = [];
        
            const filledColumnData = columnData.map((value, index) => {
                if (value === null || value === undefined || value === '') {
                    missingValueIndices.push(index);
                    switch (missingValueOption) {
                        case 'previous':
                            let prevIndex = index - 1;
                            while (prevIndex >= 0 && (columnData[prevIndex] === null || columnData[prevIndex] === undefined || columnData[prevIndex] === '')) {
                                prevIndex--;
                            }
                            return prevIndex >= 0 ? columnData[prevIndex] : null;
                        case 'input':
                            return inputValue;
                        case 'mostOccurring':
                            return getMostOccurringValue(columnData);
                        default:
                            return value;
                    }
                } else {
                    return value;
                }
            });
        
            const updatedData = excelData.map(sheetData => {
                if (sheetData.name === selectedSheet) {
                    return {
                        ...sheetData,
                        data: sheetData.data.map((row, rowIndex) => {
                            const newRow = [...row];
                            if (missingValueIndices.includes(rowIndex)) {
                                newRow[columnIndex] = filledColumnData[rowIndex];
                            }
                            return newRow;
                        })
                    };
                } else {
                    return sheetData;
                }
            });
        
            setExcelData(updatedData);
        };
        

        const getColumnIndex = (column: string): number => {
            const selectedSheetData = excelData.find(sheet => sheet.name === selectedSheet);
            if (selectedSheetData) {
                return selectedSheetData.columns.indexOf(column);
            }
            return -1;
        };
        const getColumnData = (column: string): any[] => {
            const selectedSheetData = excelData.find(sheet => sheet.name === selectedSheet);
            if (selectedSheetData) {
                const columnIndex = selectedSheetData.columns.indexOf(column);
                if (columnIndex !== -1) {
                    return selectedSheetData.data.map(row => row[columnIndex]);
                }
            }
            return [];
        };

        const getMostOccurringValue = (data: any[]): any => {
            const valueCounts = new Map();
            data.forEach(value => {
                if (valueCounts.has(value)) {
                    valueCounts.set(value, valueCounts.get(value) + 1);
                } else {
                    valueCounts.set(value, 1);
                }
            });
        
            let mostOccurringValue: any;
            let maxCount = 0;
            valueCounts.forEach((count, value) => {
                if (count > maxCount) {
                    mostOccurringValue = value;
                    maxCount = count;
                }
            });
        
            return mostOccurringValue;
        };

        console.log("text1",replaceText)

        const [SCase, setSCase] = useState(false);
        const [matchCell, setMatchCell] = useState(false);


        const [filtersArray, setFiltersArray] = useState([]);


        const [frequencyDistribution, setFrequencyDistribution] = useState<{ [key: string]: { [key: string]: number } }>({});
        const [columnFrequency, setColumnFrequency] = useState<{ [key: string]: number }>({});


    const exploreFrequencyDistribution = () => {
        if (!excelData || !selectedSheet) return;

        const sheet = excelData.find(sheet => sheet.name === selectedSheet);
        if (!sheet) return;

        const frequencyDistribution = {};

        sheet.columns.forEach(column => {
            const columnData = getColumnData(column).filter(value => value !== '');
            const columnFrequency = {};

            columnData.forEach(value => {
                if (value in columnFrequency) {
                    columnFrequency[value]++;
                } else {
                    columnFrequency[value] = 1;
                }
            });

            frequencyDistribution[column] = columnFrequency;
        });

        setFrequencyDistribution(frequencyDistribution);
    }

    const exploreFrequencyDistributionForColumn = (columnName) => {
        if (!excelData || !selectedSheet || !columnName) return;

        const sheet = excelData.find(sheet => sheet.name === selectedSheet);
        if (!sheet) return;

        const columnData = getColumnData(columnName).filter(value => value !== '');
        const columnFrequency = {};

        
        columnData.forEach(value => {
            if (value in columnFrequency) {
                columnFrequency[value]++;
            } else {
                columnFrequency[value] = 1;
            }
        });

        setColumnFrequency(columnFrequency);
    }

    const [clusteredSpellings, setClusteredSpellings] = useState<any | null>(null);

    const fixSpelling = async (selectedColumn) => {
        const words = getColumnData(selectedColumn);
        const payload = {
          words,
          eps: 3
        };
      
        try {
          const response = await fetch(`${API_BASE_URL}/cluster_spell`, {
            method: 'POST',
            headers: new Headers({'content-type': 'application/json'}),
            body: JSON.stringify(payload)
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch clustered spellings');
          }
      
          const data = await response.json();
          console.log("cluster data",data);
          setClusteredSpellings(data);
          //console.log("cluster",clusteredSpellings);
        } catch (error) {
          console.error('Error fixing spelling:', error);
          
        }
      };

      useEffect(() => {
        console.log("clusteredSpellings", clusteredSpellings);
      }, [clusteredSpellings]);
      

    const handleToggleChange = (toggleKey: string) => {
        const columnKey = selectedColumn; 
        setAttr((prevState: any) => ({
          ...prevState,
          [selectedSheet]: {
            ...prevState[selectedSheet],
            [columnKey]: {
              ...prevState[selectedSheet][columnKey],
              [toggleKey]: !prevState[selectedSheet][columnKey][toggleKey],
            },
          },
        }));
      };

      const [initialValue, setInitialValue] = useState('');
      const [maxCharacters, setMaxCharacters] = useState('');

      const handleInitialValueChange = (e) => {
        const columnKey = selectedColumn; 
        const newValue = e.target.value;
        setAttr((prevState: any) => ({
          ...prevState,
          [selectedSheet]: {
            ...prevState[selectedSheet],
            [columnKey]: {
              ...prevState[selectedSheet][columnKey],
              initialvalue: newValue,
            },
          },
        }));
      };
      

      const handleMaxCharactersChange = (e) => {
        const columnKey = selectedColumn;
        const newValue = parseInt(e.target.value, 10); 
        setAttr((prevState: any) => ({
          ...prevState,
          [selectedSheet]: {
            ...prevState[selectedSheet],
            [columnKey]: {
              ...prevState[selectedSheet][columnKey],
              maxchar: newValue,
            },
          },
        }));
      };

      const [replacementValue, setReplacementValue] = useState<string[]>([]);

      // Update replacementValues whenever clusteredSpellings changes
      useEffect(() => {
          if (clusteredSpellings) {
              const initialReplacementValues = Array.from({ length: Object.keys(clusteredSpellings).length }, () => '');
              setReplacementValue(initialReplacementValues);
          }
      }, [clusteredSpellings]);

      function replaceClusterWordsWithProbableSpelling(index, replacementValue) {
        if (!clusteredSpellings || !clusteredSpellings[index]) {
          console.error('Cluster data is not available or invalid index provided.');
          return;
        }
      
        const cluster = clusteredSpellings[index];
        const mostProbableSpelling = replacementValue[index] || cluster.most_probable_spelling;

        if (!mostProbableSpelling) {
          console.error('Most probable spelling not found in cluster data.');
          return;
        }

        const sheetIndex = excelData.findIndex(sheet => sheet.name === selectedSheet);
        
        const columnIndex = excelData[sheetIndex].columns.indexOf(selectedColumn);
      
        const updatedExcelData = excelData.map(sheet => {
            if (sheet.name !== selectedSheet) {
                return sheet; // Return unchanged if it's not the selected sheet
            }
            
            const updatedData = sheet.data.map(row => {
                const newRow = [...row];
                const itemToReplace = newRow[columnIndex];
                
                if (itemToReplace && cluster.cluster_words.includes(itemToReplace)) {
                    newRow[columnIndex] = mostProbableSpelling;
                }
                
                return newRow;
            });
            
            return { ...sheet, data: updatedData };
        });

        console.log("updated: ",updatedExcelData,"sheet: ",sheetIndex, "column: ",columnIndex)
      
        setExcelData(updatedExcelData);
      }


      const handleReplacementChange = (value: string, index: number) => {
        const newReplacementValues = [...replacementValue];
        newReplacementValues[index] = value;
        setReplacementValue(newReplacementValues);
    };
      
        const countClusterWords = (index) => {
            if (clusteredSpellings && clusteredSpellings[index]) {
                return clusteredSpellings[index].cluster_words.length;
            }
            return 0;
        };
    
    const [isLoading, setIsLoading] = useState(false);
    const [showExcelModal, setShowExcelModal] = useState(false);
    const [apiResponse, setApiResponse] = useState( null as unknown as Element | undefined);
  
    const toggleExcelModal = () => {
        setShowExcelModal(!showExcelModal);
    };
    const apiUrl =`${API_BASE_URL}/view`;

    const handleClick = () => {
    setIsLoading(true);
    console.log("MODAL");

    const payload = {
        excelData: excelData,
        dataTypes: dataTypes,
        attr: attr,
        selected: selectedSheet,
        selectedId: excelData.findIndex(sheet => sheet.name === selectedSheet)
      };
    
      fetch(apiUrl, {
        method: 'POST',
        headers: new Headers({'content-type': 'application/json'}),
       
        body: JSON.stringify(payload)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Data:', data.xml_view);
        const firstXmlView = data.xml_view[0];
        

        const xmlString = new DOMParser().parseFromString(firstXmlView, "application/xml").documentElement;
        console.log('First XML View:', xmlString);

        setApiResponse(xmlString);
        setIsLoading(false);
        setShowExcelModal(true);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false); 
      });
    };
    let nomask = [
        {
            id: "zzzz",
            name: "",
            mask: ""
        }
        ]

        

        const handleNClick = () => {
            
        
            const payload = {
                excelData: excelData,
                dataTypes: dataTypes,
                attr: attr,
                selected: selectedSheet,
                selectedId: excelData.findIndex(sheet => sheet.name === selectedSheet)
              };
            
              fetch(apiUrl, {
                method: 'POST',
                headers: new Headers({'content-type': 'application/json'}),
               
                body: JSON.stringify(payload)
              })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                console.log('Data:', data.xml_view);
                if(data){
                const firstXmlView = data.xml_view[0];
                
        
                const xmlString = new DOMParser().parseFromString(firstXmlView, "application/xml").documentElement;
                console.log('First XML h View:', xmlString);
                    
                setApiResponse(xmlString);
                console.log("yoyo ",apiResponse);
            }
            
                
              })
              .catch(error => {
                console.error('Error fetching data:', error);
                
              });
        };
    const [isGLoading, setIsGLoading] = useState(false);
    const [apiGResponse, setApiGResponse] = useState( null as unknown as Element | undefined);

    
    async function createDirectory( newModel:NewModel ): Promise<ResourceContent|null>{
        const templates = await getFromStorage('TEMPLATE_MODELS') ;
        if( !templates || !templates[0] ) return null ;
        console.log( "debuging " , newModel, templates[0].modelQN, templates[0].name )
        let repertoire = await cloneModel(newModel.module.name, newModel.model.name, templates[0].modelQN, templates[0].name );
        if( !repertoire ) return null ;
        let moduleJson:any = initModuleJson( newModel ) ;

        try{
            let klsResource:ResourceContent = await fetchKlsResource( newModel.module.name, "klsapp/" + getTenant() + "/" + newModel.module.name + '/module.json' ) ;
            moduleJson = JSON.parse(klsResource.content||'') ;
            moduleJson.children = [...(moduleJson.children||[]),initModelJson(newModel)] ;
        } catch(e) {
            console.log( "fetchKlsResource error : ", e ) ;
        }

        console.log( "moduleJson :", moduleJson ) ;
        moduleJson.owner = currentUser()

        saveGlobaleState(GS);
        let res = await writeKlsResource( newModel.module.name, "klsapp/" + getTenant() + "/" + newModel.module.name + '/module.json' , JSON.stringify( moduleJson ) ) ;
        
        return res;
    }

    const initModelJson = ( newModule:NewModel ) => {
        return {
            name: newModule.model.name,
            title: newModule.model.title,
        }
    }

    const initModuleJson = ( newModule:NewModel ) => {
        return {
            name: newModule.module.name,
            title: newModule.module.title,
            children: [ initModelJson( newModule ) ]
        }
    }
    
    //const API_GEN_URL = 'https://excel.karaz.org:5000'; 
    const apiGUrl =`${API_BASE_URL}/generate`;

    const handleGClick = () => {
        setIsGLoading(true);
        handleNClick();
        const payload = {
            excelData: excelData,
            dataTypes: dataTypes,
            attr: attr,
            selected: selectedSheet,
            selectedId: excelData.findIndex(sheet => sheet.name === selectedSheet)
          };
        
          fetch(apiGUrl, {
            method: 'POST',
            headers: new Headers({'content-type': 'application/json'}),
           
            body: JSON.stringify(payload)
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(async data => {
            console.log('G Data :', data);
            const firstXSD = data[0];
            
    
            const XSDString = new DOMParser().parseFromString(firstXSD, "application/xml").documentElement;
            console.log('First XSD View:', XSDString);
    
            setApiGResponse(XSDString);
            setIsGLoading(false);

            let newModel: NewModel = {
                module: {
                    name: modelName,
                    title:  modelName
                },
                model: {
                    name: modelName,
                    title: modelName
                }
            };

            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };
            
            await createDirectory(newModel)
            console.log("DONE - created")

            const Namemodule = modelName;
            const CapitalName = capitalizeFirstLetter(modelName);
            const tenantPath = `klsapp/${getTenant()}/${modelName}/${modelName}`;

            console.log(`AAAAAAAAAAAAAAA: ${tenantPath}/view-${modelName}.xml`)
            console.log(`BBBBBBBBBBBB: ${tenantPath}/view-${modelName}.xml`)
            
            let ress = await writeKlsResource(Namemodule, `${tenantPath}/${CapitalName}-model.xsd`, xmlElement2str(XSDString));
            console.log('apiGResponse saved:', ress);
            console.log('fffff saved:', apiResponse);
            console.log('error verification: ',xmlElement2str(apiResponse));
            let rizz = await writeKlsResource(Namemodule, `${tenantPath}/view-${CapitalName}.xml`, xmlElement2str(apiResponse));
            console.log('apiResponse saved:', rizz);
            
            console.log("DONE - view & xsd")
            
            history.push(`/editService/${modelName}/${modelName}`);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setIsGLoading(false); 
          });
    };
        
    return <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
        {isLoading && <LoadingApplication key="loading1" message='Votre preview est en cours de création...' />}
        {isGLoading && <LoadingApplication key="loading2" message='Votre application est en cours de création...' />}
        {showExcelModal && (
        <Modal
          onClose={() => setShowExcelModal(false)}
          title="Preview"
          icon="visibility"
          leftBtnArgs={{
            label: 'Annuler',
            args: { onClick: () => setShowExcelModal(false) },
            color: 'neutral',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            label: 'Ajouter',
            args: {
              
            },
            color: 'primary',
            size: 'sm',
            styling: 'solid'
          }}
          width="720px"
          
        >
          
          <div style={{color:"black",width:"700px"}} className="create-app-modal-body">
            {apiResponse && (
                // <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
              
                <PreviewWizard view={apiResponse} activities={nomask}  onClose={() => setShowExcelModal(false)} />
            )}
          </div>
        </Modal>
      )}
        {!excelData && (
            <>
                <LoadingApplication key="loading2" message='Votre application est en cours de création...' />
            </>
        )}
        {excelData && (
        
        <>
        {console.log("excel DATA: ",excelData,"DATA types:", dataTypes, "attrs", attr)}
    <div style={{paddingTop:'10px',paddingBottom:'10px'}} >
        <EditorHeaderBar
        viewName={""/*view.name*/}
        mode={{}}
        metaData={{}}
        handleAction={{}}>
            {/*<h1>{moduleName}</h1>
            <h2>{modelName}</h2>*/}
            <div className='page-header'>
                <div className="text-rg slate-500 font-400">
                    <div className="text-sm blue-600 font-500">{moduleName}</div>
                    <div>{modelName}</div>
                    
                </div>
            </div>
            
        <div className="white-filler" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%', gap:'10px'}}>     
            <button style={{color:'white', backgroundColor:'#60A5FA', border:'1px solid #3B82F6', borderRadius:'8px', padding:'6px'}} onClick={handleGClick}>Generate</button>
            <button style={{color:'#60A5FA',backgroundColor:'white',border:'2px solid #3B82F6', borderRadius:'8px', padding:'6px',fontWeight:'600'}}>Cancel</button>
        </div>
        

        <div style={{ display: "flex", gap: "8px" }}>

          <AutoSaveBadge state={/^\d/.test(lastTimeSaved) ? 'saved' : lastTimeSaved as 'none' | 'saving' | 'disconnected'} time={lastTimeSaved} />
          <UndoRedo
            changeEvent={{}}
            setContent={{}}
          ></UndoRedo>
         <button onClick={handleClick} style={{color:'white', backgroundColor:'#60A5FA', border:'1px solid #3B82F6', borderRadius:'8px', padding:'6px'}}><span style={{marginTop:'5px'}} className="material-symbols-outlined">visibility</span></button>
  
          <IconButton args={{ onClick: () => /*handleConfiguringView()*/ console.log('okz') }} icon="more_vert" size="sm" styling="outline" color="neutral" />
    
        </div>
        
      </EditorHeaderBar>
    </div>

    <div className='exc-container' style={{ display: 'flex' }}>
        <div className='exc-column' style={{ flexBasis: '30%', maxWidth:'400px' }}>
            <div style={{ borderTop: "1px solid #DDD", borderRight: "1px solid #DDD" }}>

                <div className='tab-container remove-pull-right'>
                    
                <Tabs size="medium" tabs={TABS_model}
                    onSelect={(name: string) => setCurrentTabModel(name)}
                    current={currentTabModel}
                    defSelected={currentTabModel} />

                
                
                </div>
                {currentTabModel === "fands" && (
                   <div style={{padding: '15px', display:'grid', gap:'10px'}}>
                        <div style={{border: '1px solid lightgrey',margin: '-16px ',padding: '8px'}}>
                            {selectedSheet}
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                        </div>
                        {!selectedColumn && (<div style={{border: '1px solid lightgrey',margin: '5px -16px 0px -16px',padding: '8px', backgroundColor:'#eff6ff',paddingLeft:'15px' }}>
                            Tout les Colonnes
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                            </div>
                        )
                        }
                        {selectedColumn && (<div style={{border: '1px solid lightgrey',margin: '5px -16px 0px -16px',padding: '8px', backgroundColor:'#eff6ff', paddingLeft:'15px'}}>
                            {selectedColumn}
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                        </div>)}
                    <div style={{marginTop: '20px', display:'grid'}} >
                            <label htmlFor="Search" style={{fontWeight:'600'}}>Mot Recherché</label>
                            <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) =>{ setSearchQuery(e.target.value); handleFind()}}
                            style={{border:'1px solid #E2E8F0', borderRadius:'8px',padding:'6px'}}
                            />
                            
                            {/*<button onClick={handleFind}>Find</button>*/}
                    </div>
                    <div style={{ display:'grid'}}>
                            <label htmlFor="Replace" style={{fontWeight:'600'}}>Remplacer par</label>
                            <input
                            type="text"
                            placeholder="Replace..."
                            onChange={(e) => setReplaceText(e.target.value)}
                            style={{border:'1px solid #E2E8F0', borderRadius:'8px',padding:'6px'}}
                            />
                        <div style={{display:'grid', gap:'8px', margin: '10px 0px 5px 0px'}}>
                            <div style={{display:'flex', gap:'8px'}}>
                                <label htmlFor="setSCase" style={{fontWeight:'600'}}>Respecter la casse</label>
                                <ToggleButton
                                    handleToggle={() => setSCase(prevState => !prevState)}
                                    ischecked={SCase}
                                    label=""
                                    size="md"
                                    />
                            </div>
                            <div style={{display:'flex', gap:'8px'}}>
                                <label htmlFor="setSCase" style={{fontWeight:'600'}}>Matcher la cellule entiere</label>
                                <ToggleButton
                                    handleToggle={() => setMatchCell(prevState => !prevState)} 
                                    ischecked={matchCell}
                                    label=""
                                    size="md"
                                    />
                            </div>
                        </div>
                        <div style={{ display:'inline-flex', gap:'10px', paddingTop:'8px', margin:'0 auto' }}>
                            <button onClick={() => handleReplace(true,false)} style={{border:'1px solid #1D4ED8', borderRadius:'8px', backgroundColor:'transparent', color:'#2563EB', padding: '5px'  }}>Remplacer {/* currentOccurrenceIndex !== null ? currentOccurrenceIndex+1 : '' */}</button>                    
                            {searchQuery && totalOccurrences > 0 && (
                                <div style={{border:'1px solid #1D4ED8', borderRadius:'8px', backgroundColor:'#2563EB', margin:'auto', padding:'5px'  }}>
                                    
                                    <button onClick={goToPreviousOccurrence} style={{backgroundColor: 'transparent', border:'none', color:'white'}}> {'<'} </button>
                                    <span style={{ color:'white'}}>{currentOccurrenceIndex + 1} / {totalOccurrences}</span>
                                    <button onClick={goToNextOccurrence} style={{backgroundColor: 'transparent', border:'none', color:'white'}}> {'>'} </button>
                                </div>
                            )}
                            <button onClick={() => handleReplace(false,true)} style={{border:'1px solid #1D4ED8', borderRadius:'8px', backgroundColor:'transparent', color:'#2563EB', padding: '5px'  }}>Remplacer tout</button>
                        </div>
                    </div>
                    
                  </div>
                 )
                }

                {currentTabModel === "atr" && (
                   <div style={{padding: '15px'}}>
                        <div style={{border: '1px solid lightgrey',margin: '-16px',padding: '8px'}}>
                            {selectedSheet}
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                        </div>
                        {selectedColumn && (<div style={{border: '1px solid lightgrey',margin: '15px -16px 15px -16px',padding: '8px', backgroundColor:'#eff6ff' , paddingLeft:'15px'}}>
                            {selectedColumn}
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                        </div>)}
                        {selectedSheet && selectedColumn && (
                            <>
                              <div style={{display:'grid'}}>
                                <label htmlFor="editedLabel">Edit Label:</label>
                                <input
                                    id="editedLabel"
                                    type="text"
                                    placeholder={selectedColumn}
                                    value={editedLabel}
                                    onChange={handleLabelChange}
                                    style={{border:'1px solid #E2E8F0', borderRadius:'8px',padding:'5px'}}
                                />
                              </div>
                              <div style={{display:'grid',paddingTop:'5px'}}>
                                <label htmlFor="columnType">Column Type:</label>
                                {renderColumnTypes()}

                              </div>
                            
                              <div style={{display:'grid', paddingTop: '5px'}}>
                                <label htmlFor="initialValue">Valeur Initiale:</label>
                                <input
                                    id="initialValue"
                                    type="text"
                                    placeholder={attr && attr[selectedSheet] && attr[selectedSheet][selectedColumn] && attr[selectedSheet][selectedColumn].initialvalue}
                                    onChange={handleInitialValueChange}
                                    style={{border: '1px solid #E2E8F0', borderRadius: '8px', padding: '5px'}}
                                />
                                </div>
                                <div style={{display:'grid', paddingTop: '5px'}}>
                                <label htmlFor="maxCharacters">Nombre Max de Caractères:</label>
                                <input
                                    id="maxCharacters"
                                    type="number"
                                    placeholder={attr && attr[selectedSheet] && attr[selectedSheet][selectedColumn] && attr[selectedSheet][selectedColumn].maxchar}
                                    //value={maxCharacters}
                                    onChange={handleMaxCharactersChange}
                                    style={{border: '1px solid #E2E8F0', borderRadius: '8px', padding: '5px'}}
                                />
                                </div>
                        
                                <div style={{display:'flex', gap:'8px', paddingTop:'12px'}}>
                                <label htmlFor="required">Champs Requis </label>
                                <ToggleButton
                                    handleToggle={() => handleToggleChange('required')} 
                                    ischecked={attr && attr[selectedSheet] && attr[selectedSheet][selectedColumn] && attr[selectedSheet][selectedColumn].required}
                                    
                                    size="md"
                                />
                                </div>
                        
                                <div style={{display:'flex', gap:'8px', paddingTop:'12px'}}>
                                <label htmlFor="uniqueValue">Valeur Unique </label>
                                <ToggleButton
                                    handleToggle={() => handleToggleChange('unique_val')} 
                                    ischecked={attr && attr[selectedSheet] && attr[selectedSheet][selectedColumn] && attr[selectedSheet][selectedColumn].unique_val}
                                    
                                    size="md"
                                />
                                </div>
                                <div style={{display:'flex', gap:'8px', paddingTop:'12px'}}>
                                <label htmlFor="personalData">Donnée Caractère Personnel </label>
                                
                                <ToggleButton
                                    handleToggle={() => handleToggleChange('personal_d')} 
                                    ischecked={attr && attr[selectedSheet] && attr[selectedSheet][selectedColumn] && attr[selectedSheet][selectedColumn].personal_d}
                                    
                                    size="md"
                                />
                                </div>
                                <div style={{display:'flex', gap:'8px', paddingTop:'12px'}}>
                                <label htmlFor="medicalData">Donnée Caractère Médical </label>
                                <ToggleButton
                                    handleToggle={() => handleToggleChange('medical_d')} 
                                    ischecked={attr && attr[selectedSheet] && attr[selectedSheet][selectedColumn] && attr[selectedSheet][selectedColumn].medical_d}
                                    
                                    size="md"
                                />
                                </div>  
                     
                            </>
                            )
                        }
                    
                  </div>
                 )
                }

                {currentTabModel === "transform" && (
                    
                   <div style={{padding: '15px'}}>
                        <div style={{border: '1px solid lightgrey',margin: '-16px',padding: '8px'}}>
                            {selectedSheet}
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                        </div>
                        {selectedColumn && (<div style={{border: '1px solid lightgrey',margin: '15px -16px 0px -16px',padding: '8px', backgroundColor:'#eff6ff', paddingLeft:'15px'}}>
                            {selectedColumn}
                            <span className="material-symbols-outlined" style={{float: 'right'}}>
                                expand_more
                            </span>
                        </div>)}
                        {selectedSheet && (
                            <>
                            <div style={{paddingTop:'20px'}}>
                            {/*<div style={{fontWeight: 'bold', fontSize: '22px'}}>Refine Data</div>*/}
                            <div style={{paddingBottom: '4px'}}>
                                {/*
                                    <select value={refineChoice} onChange={e => setRefineChoice(e.target.value)}>
                                            <option value="">Choose refinement</option>
                                            <option value="fixInconsistentSpellings">Fix Inconsistent Spellings</option>
                                            <option value="fixDuplicateRows">Fix Duplicate Rows</option>
                                            <option value="fixMissingValues">Fix Missing Values</option>
                                            <option value="fixMissingRows">Fix Missing Rows</option>
                                            <option value="exploreFrequencyDistribution">Explore Frequency Distribution</option>
                                            <option value="standardizeDateTime">Standardize Date and Time</option>
                                    </select>
                                */}    
                                    <Dropdown
                                        size="md"
                                        elementSize={32}
                                        value={refineChoice}
                                        setValue={(value) => setRefineChoice(value)}
                                        label="Operation"
                                        elements={[
                                            { value: '', label: 'Choose refinement' },
                                            { value: 'fixInconsistentSpellings', label: 'Fix Inconsistent Spellings', icon: 'spellcheck' },
                                            { value: 'fixDuplicateRows', label: 'Fix Duplicate Rows', icon: 'difference' },
                                            { value: 'fixMissingValues', label: 'Fix Missing Values', icon: 'fact_check' },
                                            { value: 'fixMissingRows', label: 'Fix Missing Rows', icon: 'vertical_split' },
                                            { value: 'exploreFrequencyDistribution', label: 'Explore Frequency Distribution', icon: 'format_align_justify' },
                                            { value: 'standardizeDateTime', label: 'Standardize Date and Time', icon: 'event_repeat' },
                                        ]}
                                    />
                            </div>
                            <div style={{display:'flex', gap:'5px', flexDirection:'row'}}>
                                {refineChoice === 'fixMissingValues'  && selectedColumn && (
                                    <div style={{flex:'1'}}>
                                        <select value={missingValueOption} onChange={handleMissingValueOptionChange} style={{backgroundColor:'transparent', border:'1px solid #ebeff5', borderRadius:'6px', padding:'12px',  width: '100%'}}>
                                            <option value="">Choose option</option>
                                            {missingValueOptions.map(option => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {refineChoice === 'fixMissingValues' && selectedColumn && missingValueOption === 'input' && (
                                    <div style={{flex:'1'}}>
                                        <input 
                                            type="text" 
                                            value={inputValue} 
                                            onChange={handleInputChange} 
                                            placeholder="Enter value"
                                            style={{backgroundColor:'transparent', border:'1px solid #ebeff5', borderRadius:'6px', padding:'11px',  width: '100%'}}
                                        />
                                   </div>
                                )}
                            </div>
                            <button onClick={applyRefinement} style={{color:'white', backgroundColor:'#60A5FA', border:'1px solid #3B82F6', borderRadius:'8px', padding:'6px', float:'right', marginRight:'15px', marginTop:'5px'}}>Apply</button>
                            {refineChoice === 'fixDuplicateRows' && (
                                <div style={{color:'black'}}>
                                    {duplicateRowsCount > 0 ? (
                                        <p>{duplicateRowsCount} duplicate rows found.</p>
                                    ) : (
                                        <p>No duplicate rows found.</p>
                                    )}
                                </div>
                            )}

                        {refineChoice === 'fixInconsistentSpellings' && selectedColumn && (
                            
                            <div style={{padding:'30px', marginTop:'40px', height:'300px', overflowY:'auto', paddingTop:'20px'}}>
                                {clusteredSpellings &&
                                Object.values(clusteredSpellings).map((cluster, index) => (
                                    <>
                                    <div key={index+1} style={{display:'flow-root'}}>
                                        
                                        <div
                                        style={{
                                            width: '100%'
                                        }}
                                        >
                                        <Input
                                            args={{
                                            disabled: true,
                                            value: clusteredSpellings[index].most_probable_spelling
                                            }}
                                            errorMessage=""
                                            helpText=""
                                            infoText= {countClusterWords(index)}
                                            label={`Cluster ${index}`}
                                            size="md"
                                        />
                                        </div>
                                        <div
                                        style={{
                                            width: '100%'
                                        }}
                                        >
                                           
                                         <Input
                                            args={{
                                                onChange: e => handleReplacementChange(e.target.value, index)
                                            }}
                                            errorMessage=""
                                            helpText=""
                                            infoText=""
                                            label="Correction"
                                            size="md"
                                        />
                                        </div>
                                        <button onClick={() => replaceClusterWordsWithProbableSpelling(index, replacementValue)} style={{color:'white', backgroundColor:'#60A5FA', border:'1px solid #3B82F6', borderRadius:'8px', padding:'6px', float:'right', marginRight:'15px', marginTop:'5px'}}>
                                            Replace
                                        </button>
                                    </div>  
                                    </>
                                    
                                ))}
                            </div>
                            )
                        }

                        {refineChoice === 'exploreFrequencyDistribution' && (
                            <div style={{color:'black'}}>
                                {selectedColumn ? (
                                    <div>
                                        <p>Frequency Distribution for {selectedColumn}:</p>
                                        <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            {Object.entries(columnFrequency).map(([value, frequency]) => (
                                                <li key={value}>{value}: {frequency}</li>
                                            ))}
                                        </ul>
                                        <div style={{ display: 'flex', flexWrap: 'wrap',maxHeight: '200px', overflowY: 'auto' }}>
                                            {Object.entries(columnFrequency).map(([value, frequency], index) => (
                                                <div key={index} style={{ textAlign: 'center', margin: '10px', flexBasis: 'calc(33.33% - 20px)' }}>
                                                    <div style={{ width: '80px', height: '80px', borderRadius: '50%', backgroundColor: 'lightblue', margin: 'auto', position: 'relative' }}>
                                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '12px' }}>{value}</div>
                                                    </div>
                                                    <div>{frequency}</div>
                                                </div>
                                            ))}
                                        </div>                                        
                                    </div>
                                ) : (
                                    <div>
                                        <p>Frequency Distribution for all columns:</p>
                                        <ul style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            {Object.entries(frequencyDistribution).map(([column, columnFrequency]) => (
                                                <li key={column}>
                                                    {column}:
                                                    <ul>
                                                        {Object.entries(columnFrequency).map(([value, frequency]) => (
                                                            <li key={value}>{value}: {frequency}</li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            )}

                            
                            </div>

                            </>
                        )}


                    </div>
                    )
                }

                {currentTabModel === "fltr" && (
                <>
                    
                    <div style={{padding: '15px'}}>

                    

                    <FiltersPanel filters={filtersArray} setFilters={setFiltersArray} columns={excelData.find(sheet => sheet.name === selectedSheet)?.columns} selectedSheet={selectedSheet}/>
                    
                    </div>
                </>
                )}
            </div>
        </div>
        <div className='exc-column' style={{ flexBasis: '70%', borderLeft: '1px solid #DDD'  }}>
            <div style={{display:'flex', paddingTop:'16px',height:'48px',marginTop:'0.5px'}}>
                <Tabs
                    tabs={excelData.map((sheet, index) => ({ name: sheet.name, label: sheet.name }))}
                    onSelect={selectedSheet => setSelectedSheet(selectedSheet)}/>
            </div>
            <AdvancedTable
                excelData={excelData}
                selectedSheet={selectedSheet}
                selectedColumn={selectedColumn}
                filterText={filterText}
                handleSort={handleSort}
                renderInputIcon={renderInputIcon}
                handleColumnSelect={handleColumnSelect}
                currentPage={currentPageMap[selectedSheet]} 
                onPageChange={handlePageChange}
                searchQuery={searchQuery}
                replaceText={replaceText}
                isReplace = {isReplace}
                isReplaceAll = {isReplaceAll}
                indexedOccurrences={currentOccurrenceIndex}
                init={()=>{ setisReplace(false); setisReplaceAll(false) }}
                notify={(excelData) => {setExcelData(excelData)}}
                matchEntireCell={matchCell}
                caseSensitive={SCase}
                filters={filtersArray}
                />
        </div>
        
    </div>

    </>
        )}
{/*
        {excelData && selectedSheet && (
                    <div>
                       
                         <table cellPadding={50} cellSpacing={15} style={{ borderCollapse: 'collapse', border: '1px solid black', backgroundColor: 'aliceblue' }}>
                         <thead>
                            <tr>
                                {excelData.find(sheet => sheet.name === selectedSheet)?.columns.map((col, index) => (
                                    <th style={{ border: '1px solid black', backgroundColor: '#3b82f6', textAlign: 'center', padding: '5px' }} key={index}>      
                                        
                                        <div style={{display: 'flex', justifyContent: 'space-between',  alignItems: 'center'}}>
                                            <div style={{display: 'flex', paddingRight:'5px'}}>
                                                {renderInputIcon(col)}
                                            </div>
                                            {col}
                                            <div style={{display: 'flex',  float: 'right', paddingLeft:'8px'}}>
                                        
                                                <a onClick={() => handleSort(index,'asc')}>Λ</a>
                                            
                                                <a onClick={() => handleSort(index,'desc')}>V</a>
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                            <tbody>
                                {(filterText ? excelData.find(sheet => sheet.name === selectedSheet)?.data.filter(row => row.some(cell => String(cell).toLowerCase().includes(filterText.toLowerCase()))) : excelData.find(sheet => sheet.name === selectedSheet)?.data).map((row: any[], rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <td style={{ border: '1px solid black', color: 'black', padding: '4px' }} key={cellIndex}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                   
                        
                    </div>
                    )}
                                        */}

        
          
    
    </>
}

    function generateUniqueId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }  


const FilterEditor = ({ filters, index, columns, notifyChange, selectedSheet ,  onDeleteFilter}) => {

    const filter = filters.find((f) => f.index === index);
    const [selectedColumn, setSelectedColumn] = useState(filter.column);
    const [operation, setOperation] = useState(filter.operation);
    const [value, setValue] = useState(filter.value);
    const [showFilterCard, setShowFilterCard] = useState(true);
  
    const handleColumnChange = (selected) => {
      setSelectedColumn(selected.value);
    };
  
    const handleOperationChange = (selected) => {
      setOperation(selected.value);
    };
  
    const handleValueChange = (event) => {
      setValue(event.target.value);
    };
  
    const updateFilterValue = () => {
         let id = generateUniqueId();
         const formattedFilter = {
          sheet: selectedSheet,
          column: selectedColumn,
          operation: operation,
          value: value,
          index: id,
        };
        notifyChange((prevFilters) => {
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = formattedFilter;
          return updatedFilters;
        });
      };

  
    return (
        <>
    {!showFilterCard && ( 
        <>
      <div className="filter-editor" style={{
            position: 'absolute',
            right: '835px',
            zIndex: 1000,
            display: 'flex',
            width: '240px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: '4px',
            borderBottom: '1px solid #E2E8F0',
            background: '#FFF',
            boxShadow: '2px 0px 4px -2px rgba(0, 0, 0, 0.10), 4px 0px 6px -1px rgba(0, 0, 0, 0.10)',
            padding: '5px 12px 15px',
            gap: '8px',
            marginTop: '0px',
        }}>
        <div style={{display:'flex', justifyContent: 'space-between',  width:'-moz-available', padding:'5px 0px'}}>
            <div>
                Editer Filtre
            </div>
            <div>
                <span className="material-symbols-outlined" style={{ cursor: 'pointer', fontSize: '0.9em', fontWeight:'bolder' }} onClick={() => {setShowFilterCard(!showFilterCard)}}>
                    close
                </span>
            </div>
        </div>
        <div style={{width:'100%'}}>
            <div>Colonne</div>
        <Dropdown
          klsCompatible={true}
          size="xs"
          elementSize={32}
          placeholder="Select column"
          data={columns.map((col) => ({ label: col, value: col }))}
          onSelect={handleColumnChange}
          value={selectedColumn}
        />
        </div>
        <div style={{width:'100%'}}>
        <div>Condition</div>
        <Dropdown
          klsCompatible={true}
          size="xs"
          elementSize={32}
          placeholder="Select operation"
          data={[
            { label: 'Equals', value: '==' },
            { label: 'Not Equals', value: '!=' },
            { label: 'Contains', value: 'contains' },
          ]}
          onSelect={handleOperationChange}
          value={operation}
        />
        </div>
        <div style={{width:'100%'}}>
            <div>Libelle</div>
            <Input
            size="xs"
            args={{ type: 'text', value, onChange: handleValueChange }}
            />
        </div>
        <div style={{margin:'0 auto'}}>
        <Button
          label="Enregistrer"
          args={{ onClick: () => { updateFilterValue();setShowFilterCard(!showFilterCard); }, style: {color:'#2563EB', border: 'none' } }}
          styling="outline"
          color="neutral"
          size="xs"      
        />
        </div>
          
        
      </div>
      </>
      )}
      
      <div className="filter-card" style={{
            display: 'flex',
            height: '32px',
            padding: '4px 8px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            borderRadius: '4px',
            border: '1px solid #E2E8F0',
            background: '#F8FAFC',
            width: '100%',
          }}>
            <span className="filter-var filter-txt" style={{
                display: 'flex',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                flex: '1 0 0',
                overflow: 'hidden',
                color: '#1E293B', 
                textOverflow: 'ellipsis',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '18px',
                alignItems:'center'
                }}
            > <span className="material-symbols-outlined" style={{color:"#7C3AED"}}>filter_alt</span> {selectedColumn && operation && value ? `${selectedColumn} ${operation} ${value}`: null}</span>
            <span
              className="material-icon"
              onClick={() => setShowFilterCard(!showFilterCard)}
              style={{ color: '#64748B', cursor: 'pointer' }}
            >
              format_list_bulleted
            </span>
            <span
              className="material-icon"
              style={{ color: '#DC2626', cursor: 'pointer' }}
              onClick={() => onDeleteFilter(index)}
            >
              delete
            </span>
          </div>
    </>
    );
  };
  

const FiltersPanel = ({ filters, setFilters,columns , selectedSheet}) => {
    const addNewFilter = () => {
      const newFilter = { sheet: selectedSheet, column: '', operation: '==', value: '', index: filters.length };
    
      const newFilters = [...filters, newFilter];
      setFilters(newFilters);
    };

    const handleDeleteFilter = (index) => {
        const updatedFilters = filters.filter((filter) => filter.index !== index);
        setFilters(updatedFilters);
    };
  
    const filteredFilters = filters.filter((filter) => filter.sheet === selectedSheet);


    return (
      <>
        <div style={{margin: '5px -16px 0px -16px',padding: '8px', backgroundColor:'transparent',paddingLeft:'15px' }}>
            Filtres
            <span className="material-symbols-outlined" style={{ float: 'right', color: '#2563EB', cursor: 'pointer' }} onClick={addNewFilter}>
                add
            </span>
        </div>
        {filteredFilters.map((filter) => (
          <FilterEditor
                key={filter.index}
                filters={filters}
                index={filter.index}
                columns={columns}
                notifyChange={setFilters} 
                selectedSheet={selectedSheet}
                onDeleteFilter={() => handleDeleteFilter(filter.index)}/>
        ))}
      </>
    );
  };




let TABS_model = [

    { name: "atr", label: <><span className="material-symbols-outlined">table_chart</span><span className="tabs-trait"></span></> },
    { name: "transform", label: <><span className="material-symbols-outlined">sync_alt</span><span className="tabs-trait"></span></> },
    { name: "fltr", label: <><span className="material-symbols-outlined">filter_alt</span><span className="tabs-trait"></span></> },
    { name: "fands", label: <><span className="material-symbols-outlined">search</span><span className="tabs-trait"></span></> },
    { name: "data2", label: <><span className="material-symbols-outlined">lightbulb</span><span className="tabs-trait"></span></> },
    { name: "data3", label: <><span className="material-symbols-outlined">account_tree</span><span className="tabs-trait"></span></> },
];

  