import { useEffect, useState } from 'react'
import { currentUser } from "kls-commons/service/userService";
import { SettingsHeader } from '../settings/settings';
import { SettingsSideBar } from "./settings-sidebar";
import { GS } from "kls-commons/state/global-state";
export function SettingsModule(props: any) {
  
  return <div>
    <div className="settings-modules">
      <SettingsHeader  type={props?.type} image={currentUser().avatarUrl ?? ""} username={currentUser().fullName} />
      <SettingsSideBar tab={props?.tab} type={props?.type} gs={GS} connected={props.connected}></SettingsSideBar>
    </div>
  </div>
}