export async function getUsersJoinedOrganisation(apiKey, userId, organisationId) {
    try {
      const formData = new URLSearchParams();
      formData.append('apiKey', apiKey);
      formData.append('userId', userId);
      formData.append('organisationId', organisationId);
  
      const response = await fetch("/karazal/ow-kls-extrat/appmanager/organismUsers", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formData
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Response from service getUsersJoinedOrganisation:", responseData);
  
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
  export async function deleteUsersFromOrg(apiKey, userId, organisationId, ownerLogins) {
    try {
      const payload = {
        apiKey,
        userId,
        organisationId,
        ownerLogins: ownerLogins.map(login => ({ login })) // Adjusting the format to match the expected structure
      };
  
      const response = await fetch("/karazal/ow-kls-extrat/usermanager/deletUserFromOrg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Response from service deleteUsersFromOrg:", responseData);
  
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }
  