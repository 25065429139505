import { useState, useEffect } from 'react';
import './settingsProfile.css';
import { GetInitials, AdvancedDropDown } from 'kls-ui';
import { currentUser } from "kls-commons/service/userService";
import { myorganisme } from "kls-auth/service/auth-service";
export const SettingsProfile = ({ image, username, label, type }: { type: string, image: string, username: string, label: string }) => {
    let [avatarType, setavatarType] = useState('image');
    let [displayName, setdisplayName] = useState(username);
    let [inital, setInital] = useState('');
    let [toggle, settoggle] = useState(false);

    let [myorganizationsAll, setmyorganizationsAll] = useState<any[]>([]);
    let [finalresult, setfinalresult] = useState<any[]>([]);

    useEffect(() => {
        if (avatarType == 'inital') {
            setInital(GetInitials(username));
        }
        async function fetchMyOrganization() {
            await myorganisme('', '', currentUser().userId, '')
                .then(
                    (result) => {
                        console.log(result, 'resultresult')
                        if (result?.error) {
                        } else {
                            setmyorganizationsAll(result?.data);
                            let tempResult: any[] = [];

                            result?.data.forEach((val) => {
                                let resp = {
                                    label: val?.name,
                                    value: val?.name,
                                    id: val?.organisationId
                                };
                                tempResult.push(resp);
                            });
                            console.log(finalresult, 'setfinalresult')
                            setfinalresult(tempResult);
                        }
                    },
                    (error => {
                        console.log("LOGIN_ERROR", error);
                    })
                );
        }
        fetchMyOrganization();

    }, []);

    return (
        <>
            <div className="setting-profile" onClick={() => type == 'organisation' && settoggle(!toggle)}>
                <div>
                    {avatarType == 'image' ? <img onError={(e) => setavatarType('inital')} src={image} alt={image} className="set-pro-img" /> : <div data-settings-inital={inital}></div>}
                </div>
                <p className='setting-label '>{label}</p>
                <div className="user-drop">
                    <h4 className='setting-user'>{type == 'organisation' ? displayName : username}</h4>
                    <span className="more-icon-set icon">expand_more</span>
                </div>
            </div>
            {type == 'organisation' && toggle &&
                <div className='organisation-header-dropdown'>
                    <AdvancedDropDown
                        handleChange={(value) => { setdisplayName(value?.label); setInital(GetInitials(value?.label)) }}
                        data={finalresult}
                        enableSearch
                        light
                        selectedValue={displayName}
                        size={3}
                    />
                </div>
            }
        </>
    )
}