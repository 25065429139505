import "./settingsContainerHeader.css"
import { Button } from "kls-ui";
interface SettingsProps {
    title?: string;
    subtitle?: string;
    enableAction?: boolean;
    buttonName?: string;
    icon?: string;
    onClick?: () => void;
}

export const SettingsContainerHeader = (props: SettingsProps) => {
    const { title, subtitle } = props;
    return (
        <div className="container-head">
            <div className="container-content">
                <p className="container-title">{title}</p>  
                <p className="container-subtitle">{subtitle}</p>
            </div>
            {props?.enableAction && <div className="container-button">
                <Button args={{ onClick: props?.onClick, style: { marginLeft: 'auto',float: 'right',marginTop: '-50px' }, className: "settings-action-btn" }} color="primary" label={props?.buttonName} rightIcon="" styling="solid" size={'xs'} leftIcon="add" />
            </div>}
        </div>

    )
}