
import { SettingsContainerHeader } from "../settings";
import '../../app-params/param-app.css'
import { AdvancedDropDown, Button, Dropdown, Input, Modal, SearchInput, ToggleButton } from "kls-ui";
import { OrganisationPermissionsTable } from 'kls-ui/table/table';
import { useEffect, useRef, useState } from "react";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { currentUser, fetchLocalUserDetails } from "kls-commons/service/userService";
import { getAllRequestToJoinOrganization, sendRequestToJoinOrganization } from "./request-service";


export async function getJoinedOrganisme(apiKey, userId, offset) {
  try {
    const formData = new URLSearchParams();
    formData.append('apiKey', apiKey);
    formData.append('userId', userId);
    formData.append('offset', offset);
    //formData.append('limit', limit);

  

    const response = await fetch("/karazal/ow-kls-extrat/appmanager/myorganisme", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Response from service:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
interface Organisation {
  changeTime: string;
  creationTime: string;
  role: string;
  reference: string;
  verification: string;
  organisationId: string;
  industry: string;
  status: string;
  name: string;
  entity: string;
  domainName: string;
  email: string;
  logoGedId: string;
  type: string;
  activation: string;
}


export function Roles(props: any) {

  const [filter, setFilter] = useState('');
  const itemsPerPage: number = 5;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [activated, setActivated] = useState(true);
  const [organisationName, setOrganisationName] = useState('');
  const [organisationId, setOrganisationId] = useState('');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [myOrganisationsData,setMyOrganisationsData] = useState<Organisation[]>([]);
  const [requestSent, setRequestSent] = useState(false);
  const [requestedOrgId, setRequestedOrgId] = useState<string>('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [requestStatus, setRequestStatus] = useState({});
  const [selectedOrg,setSelectedOrg]=useState('');
  const statiqueDataOrg = [
    {
      organisation: 'Organisation0',
      role: 'Proprietaire',
    },
    {
      organisation: 'Organisation1',
      role: 'Administrateur',
    },
    {
      organisation: 'Organisation2',
      role: 'Utilisateur',
    }];
    const rolesOrganisation = [
      { value: "utilisateur", label: "Utilisateur" },
      { value: "administrateur", label: "Administrateur" },
      { value: "citizenDeveloper", label: "Citizen Developer" }
    ];

  const filtredOrganRolesData = myOrganisationsData.filter(r =>
    r && r?.name?.toLowerCase().includes(filter.toLowerCase())
  );
  function MoreActions({ actions, closeCallback, style }: { actions: any, closeCallback?: Function, style?: React.CSSProperties }) {
    const moreOptions = useRef<HTMLDivElement>(null);
    const listObjActions = [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },

      {
        label: 'Dupliquer',
        value: 'duplicate',
        icon: 'content_copy',
        color: '#64748B',
        handleClick: actions?.['duplicate']
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: '#EF4444',
        handleClick: actions?.['delete']
      },

    ]

    return <div ref={moreOptions}>
      <AdvancedDropDown data={listObjActions} enableSearch={false}
        light={true}
        handleChange={(item) => { item?.handleClick?.() }}
        size={1}
        selectedValue={''}
        style={style}
        closeWhenClickOutside={closeCallback}
        closeWhenSelect={closeCallback} />
    </div>
  }

  const handleEditOnTable = (org) => {
    setShowModalEdit(true);
    setOrganisationName(org.name);
    setOrganisationId(org.organisationId);
    setSelectedRole(cleanRoleString(org.role));
    console.log("oooooooooo",organisationId)
  
  }
  function getColorClass(role) {
    switch (role.toLowerCase()) {
      case 'administrateur':
        return 'color-admin';
      case 'proprietaire':
        return 'color-proprietaire';
      case 'citizen developer':
        return 'color-citizen-developer';
      case 'utilisateur':
        return 'color-utilisateur';
      default:
        return '';
    }
  }
  function cleanRoleString(role) {
    return role.replace(/"([^"]+(?="))"/g, '$1');
  }
  const handleCancelRequest = (email: string) => {
 
  }
  useEffect(() => {
    function handleClickOutside(event) {
      const divRef = document.getElementById(`expandableDiv-${expandedRow}`);
      if (divRef && !divRef.contains(event.target)) {
        setExpandedRow(null);
      }
    }
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [expandedRow]);




  function AppTable({ servicesList }) {
    const data = servicesList.map((s, index) => {
      const cleanedRole = cleanRoleString(s.role);
      const role = cleanedRole === 'root' ? 'Proprietaire' : cleanedRole;
      const roleClass = `badge ${getColorClass(role)}`;
  
      // Determine the color based on activation status
      const activationColor = s.activation === 'Activée' ? '#10B981' : '#DC2626';
      const displayAdditionalInfo = requestStatus[s.organisationId] === "Brouillon" && requestSent && requestedOrgId === s.organisationId;
      const isBrouillon = requestStatus[s.organisationId] && requestStatus[s.organisationId].status === true;
      const roleRequest=  requestStatus[s.organisationId] && requestStatus[s.organisationId].role;
      console.log("ssssssssssss",roleRequest )
      return {
        logo: "/karazal/DownloadFile?gedId=" + (s === null || s === undefined ? undefined : s.logoGedId),
        organisation: s?.name || '',
        role: (
          <div style={{ display: 'flex', gap: '5px' }}>
            <span style={{ color: activationColor }}>•</span>
            <div className={roleClass}>{role}</div>
            {isBrouillon && ( 
              <>
                <span className="icon" style={{ color: '#EA580C', fill: '1' }}>schedule</span>
                <span className='material-icon' style={{ cursor: 'pointer', color: '#EA580C' }} onClick={() => setExpandedRow(index)}>expand_more</span>
                {expandedRow === index && (
                  <div
                    id={`expandableDiv-${index}`}
                    style={{ padding: '12px', backgroundColor: 'white', position: 'absolute', borderRadius: '2px', display: 'grid', boxShadow: '2px 2px 2px 2px #d9cece' }}>
                    <div style={{marginBottom:'12px'}}><span className="material-icon" style={{ color: 'green' }}>send</span> Invitation</div>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column',padding:'0 27px' }}>
                      <div className={roleClass}>
                        {roleRequest || 'tst '}
                      </div>
                      <div style={{ width: '112px', display: 'flex', justifyContent: 'center',marginTop:'12px' }}>
                        <Button size={"xs"} color={"error"} styling={"solid"} args={{ onClick: () => handleCancelRequest(s?.email) }} label="Annuler" leftIcon="block"></Button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ),
        actions: <MoreActions
          actions={{
            'edit': () => handleEditOnTable(s),
            'delete': () => handleEditOnTable(s?.organisation),
            'duplicate': () => handleEditOnTable(s?.organisation)
          }}
          style={{ cursor: 'pointer', marginTop: '30px', }}
        />
      };
    });
  
    return <OrganisationPermissionsTable data={data} />;
  }
  
  
  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, myOrganisationsData.length);
  const handlePageChange = (start: number, end: number) => {
    setCurrentPage(start / itemsPerPage + 1);
  };
  const closeModal = () => {

    setShowModalEdit(false);

  };
  const handleEditorganisationRoles = () => {
    console.log("the data to edit", organisationId, organisationName, selectedRole);
  
    const apiKey = "AB90G-BH903-W4EE1-Z66Q9-907E6";
    const userId = currentUser().userId!;
  
    const requestData = {
      apiKey: apiKey,
      userId: userId,
      organizations: [
        {
          organisationId: organisationId,
          name: organisationName
        }
      ],
      role: selectedRole
    };
    console.log("request data to join",requestData)
  
    sendRequestToJoinOrganization(requestData)
      .then((data) => {
        console.log("Response from sendRequestToJoinOrganization:", data);
       // setRequestSent(true);
        setRequestedOrgId(organisationId);
       

        
      })
      .catch((error) => {
        console.error("Error in sendRequestToJoinOrganization:", error);
      });
  
    closeModal();
  };
  
  
  const handleActivation = () => {
    console.log("activation");
    setActivated(!activated);
  }
  
  const handleRoleOrganisationChange = (event) => {
    const selectedRoleOrg = event.label;
    console.log("selectedRoleOrg", selectedRoleOrg);
    setSelectedRole(selectedRoleOrg);

  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = "AB90G-BH903-W4EE1-Z66Q9-907E6";
        const userId = currentUser().userId!;
        const organisationsData = await getJoinedOrganisme(apiKey, userId, 0);
  
        setMyOrganisationsData(organisationsData.data);
  
        // Fetch requests for each organisation
        organisationsData.data.map(async (org) => {
          try {
            const requestData = {
              apiKey: apiKey,
              userId: userId,
              organizations: [{ organisationId: org.organisationId, name: "app" }]
            };
            const responseData = await getAllRequestToJoinOrganization(requestData);
            console.log("Response from getAllRequestToJoinOrganization:", responseData);
            const cleanedDataString = responseData["list of users"]
          .replace(/"\/+/g, '"')
          .replace(/\/+"/g, '"') 
          .replace(/""+/g, '"'); 
          console.log("clean",cleanedDataString);
          const parsedData = JSON.parse(cleanedDataString);
          console.log("parseddata last", parsedData);
  
            const brouillonRequest = parsedData.find((item) => item.status === "Brouillon");
            if (brouillonRequest) {
              console.log("yessss draft",brouillonRequest.Role)
             // setRequestSent(true);
              setRequestedOrgId(org.organisationId);
              setRequestStatus(prevState => ({
                ...prevState,
                [org.organisationId]: { status: true,role: brouillonRequest.Role } 
              }));
              
            }
          } catch (error) {
            console.error("Error fetching requests for organisation:", error);
          }
        });
      } catch (error) {
        console.error("Error fetching organisation data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  
  useEffect(() => {

    let result = fetchLocalUserDetails();
    console.log("lalalala",result?.userID)

console.log("here function org",getJoinedOrganisme("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, 0).then((data) => {
  console.log(" here function org data",data.data);
})); }, []);

useEffect(() => {
  getJoinedOrganisme("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, 0)
    .then((data) => {
      console.log("Organisation data:", data);
      setMyOrganisationsData(data.data); 
      console.log("myOrgan",myOrganisationsData)


      
      // getRequestedBrouillon();
      // console.log("orgwithrequest",OrgWithRequest)
    })
    .catch((error) => {
      console.error("Error fetching organisation data:", error);
    });
  //   if(myOrganisationsData.length > 0){
  //     console.log("gagagagagagag",myOrganisationsData);
  //     myOrganisationsData.map(async (org) => {
     
  //  try {
  //    const requestData = {
  //      apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
  //      userId: currentUser().userId!,
  //      organizations: [
  //        {
  //          organisationId: org.organisationId,
  //          name: "app"
  //        }
  //      ]
  //    };

  //    console.log("myrequest Data",requestData)
  //    const responseData =  await getAllRequestToJoinOrganization(requestData).then((data) => {
  //     console.log("here true",data);
  //       const cleanedDataString = responseData["list of users"]?.replace(/"\/+/g, '"') 
  //      .replace(/\/+"/g, '"')
  //      .replace(/""+/g, '"'); 
  //    const parsedData = JSON.parse(cleanedDataString);
  //    console.log("Parsed response data useefect:", parsedData);
  //    parsedData.forEach((item) => {
  //      if (item.status === 'Brouillon') {
  //        setRequestSent(true);  
  //        setRequestStatus({
  //          organisationId:organisationId,
  //          status: item.status 
  //        });
  //                    //  statusChangeCallback(item.status);      
  //    console.log("statusChangeCallbackSTatus 2nd",requestStatus)
  //      }
  //    });
  //    });
  //    console.log("Response from getAllRequestToJoinOrganization edit useefect:", responseData["list of users"],org.organisationId);
     
  //  } catch (error) {
  //    console.error("Error in fetchData:", error);
  //  }
  //  })  
  //  }

}, [requestSent]);  


const [OrgWithRequest, setOrgWithRequest] = useState<{ organisationId : string;}[]>([]);

// useEffect(() => {
//   myOrganisationsData.forEach(async (org) => {
        
//     try {
//       const requestData = {
//         apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
//         userId: currentUser().userId!,
//         organizations: [
//           {
//             organisationId: org.organisationId,
//             name: "app"
//           }
//         ]
//       };

//       const responseData = await getAllRequestToJoinOrganization(requestData);
//       //console.log("Response from getAllRequestToJoinOrganization new useefect:", responseData["list of users"],org.organisationId);

//       const cleanedDataString = responseData["list of users"]?.replace(/"\/+/g, '"') 
//         .replace(/\/+"/g, '"')
//         .replace(/""+/g, '"'); 
//       const parsedData = JSON.parse(cleanedDataString);

//      // console.log("Parsed response data new useefect:", parsedData);
//       parsedData.forEach(element => {
//        // console.log("arr stat",element.status);
//         setOrgWithRequest((prevOrgWithRequest) => [...prevOrgWithRequest, { ...element, organisationId: org.organisationId }]);        
//       });
     
      
//     } catch (error) {
//       console.error("Error in fetchData:", error);
//     }
      
//     })})
  return (
    <div className="roler-container">
      <SettingsContainerHeader title="Roles et permissions" subtitle="Consultez vos rôles et permissions au sein des organisations et des applications." />

      {myOrganisationsData.length <= 0 ? (
        <div className="container-roles">
          <div className="container-icon-role">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="12.6313" width="25.2632" height="25.2632" rx="12.6316" fill="#FFC6C6" />
              <g clip-path="url(#clip0_10926_52718)">
                <path d="M23.5846 11.6726C23.1873 11.6726 22.8653 12.1019 22.8653 12.6316C22.8653 13.1612 23.1873 13.5906 23.5846 13.5906C23.9818 13.5906 24.3039 13.1612 24.3039 12.6316C24.3039 12.1019 23.9818 11.6726 23.5846 11.6726ZM24.435 5.00226L19.6397 6.20109C18.999 6.36125 18.5495 6.93696 18.5495 7.59737V19.345H17.1109C16.8461 19.345 16.6313 19.5596 16.6313 19.8245C16.6313 20.0893 16.8461 20.304 17.1109 20.304H25.2629C25.7926 20.304 26.222 19.8746 26.222 19.345V6.43201C26.222 6.12799 26.1437 5.82232 25.9665 5.57518C25.6044 5.07011 25.0011 4.86073 24.435 5.00226ZM25.2629 19.345H19.5085V7.59749C19.5085 7.37651 19.6584 7.18545 19.8719 7.13207L24.6673 5.93324C24.8096 5.89485 24.9614 5.92949 25.0784 6.02033C25.1955 6.11213 25.2629 6.24979 25.2629 6.39871V19.345ZM33.415 19.345H31.9764V9.27492C31.9764 7.95075 30.9029 6.87732 29.5788 6.87732H27.6606C27.3958 6.87732 27.1811 7.09197 27.1811 7.35679V7.35685C27.1811 7.62167 27.3958 7.83632 27.6606 7.83632H29.5787C30.3732 7.83632 31.0173 8.48039 31.0173 9.27492V19.345C31.0173 19.8746 31.4467 20.304 31.9764 20.304H33.415C33.6798 20.304 33.8945 20.0893 33.8945 19.8245C33.8945 19.5596 33.6798 19.345 33.415 19.345Z" fill="black" /></g>
              <rect y="15.1577" width="25.2632" height="25.2632" rx="12.6316" fill="#CFFFCB" />
              <g clip-path="url(#clip1_10926_52718)">
                <path d="M10.9532 26.8303C10.556 26.8303 10.2339 27.2597 10.2339 27.7893C10.2339 28.3189 10.556 28.7484 10.9532 28.7484C11.3504 28.7484 11.6725 28.3189 11.6725 27.7893C11.6725 27.2597 11.3504 26.8303 10.9532 26.8303ZM11.8036 20.16L7.00832 21.3588C6.36763 21.519 5.91813 22.0947 5.91813 22.7551V34.5027H4.47953C4.21471 34.5027 4 34.7173 4 34.9822C4 35.247 4.21471 35.4618 4.47953 35.4618H12.6316C13.1613 35.4618 13.5906 35.0323 13.5906 34.5027V21.5897C13.5906 21.2857 13.5124 20.98 13.3352 20.7329C12.973 20.2278 12.3698 20.0184 11.8036 20.16ZM12.6316 34.5027H6.87719V22.7552C6.87719 22.5342 7.02705 22.3432 7.24059 22.2898L12.0359 21.091C12.1783 21.0526 12.33 21.0872 12.4471 21.178C12.5641 21.2698 12.6316 21.4075 12.6316 21.5564V34.5027ZM20.7836 34.5027H19.345V24.4326C19.345 23.1085 18.2716 22.035 16.9474 22.035H15.0292C14.7644 22.035 14.5497 22.2497 14.5497 22.5145V22.5146C14.5497 22.7794 14.7644 22.994 15.0292 22.994H16.9474C17.7419 22.994 18.386 23.6381 18.386 24.4326V34.5027C18.386 35.0323 18.8153 35.4618 19.345 35.4618H20.7836C21.0484 35.4618 21.2632 35.247 21.2632 34.9822C21.2632 34.7173 21.0484 34.5027 20.7836 34.5027Z" fill="black" /> </g>
              <rect x="22.7368" y="22.7368" width="25.2632" height="25.2632" rx="12.6316" fill="#FFDFCE" />
              <g clip-path="url(#clip2_10926_52718)">
                <path d="M33.69 34.4094C33.2928 34.4094 32.9707 34.8388 32.9707 35.3684C32.9707 35.898 33.2928 36.3275 33.69 36.3275C34.0873 36.3275 34.4093 35.898 34.4093 35.3684C34.4093 34.8388 34.0873 34.4094 33.69 34.4094ZM34.5405 27.7391L29.7451 28.9379C29.1044 29.0981 28.6549 29.6738 28.6549 30.3342V42.0818H27.2163C26.9515 42.0818 26.7368 42.2964 26.7368 42.5613C26.7368 42.8261 26.9515 43.0409 27.2163 43.0409H35.3684C35.8981 43.0409 36.3275 42.6114 36.3275 42.0818V29.1688C36.3275 28.8648 36.2492 28.5591 36.072 28.312C35.7099 27.8069 35.1066 27.5976 34.5405 27.7391ZM35.3684 42.0818H29.614V30.3343C29.614 30.1133 29.7639 29.9223 29.9774 29.8689L34.7727 28.6701C34.9151 28.6317 35.0668 28.6663 35.1839 28.7571C35.301 28.849 35.3684 28.9866 35.3684 29.1355V42.0818ZM43.5204 42.0818H42.0818V32.0117C42.0818 30.6876 41.0084 29.6141 39.6842 29.6141H37.7661C37.5012 29.6141 37.2865 29.8288 37.2865 30.0936V30.0937C37.2865 30.3585 37.5012 30.5731 37.7661 30.5731H39.6842C40.4787 30.5731 41.1228 31.2172 41.1228 32.0117V42.0818C41.1228 42.6114 41.5521 43.0409 42.0818 43.0409H43.5204C43.7853 43.0409 44 42.8261 44 42.5613C44 42.2964 43.7853 42.0818 43.5204 42.0818Z" fill="black" /> </g><defs>
                <clipPath id="clip0_10926_52718">
                  <rect width="17.2632" height="17.2632" fill="white" transform="translate(16.6313 4)" />
                </clipPath>
                <clipPath id="clip1_10926_52718">
                  <rect width="17.2632" height="17.2632" fill="white" transform="translate(4 19.1577)" />
                </clipPath>
                <clipPath id="clip2_10926_52718">
                  <rect width="17.2632" height="17.2632" fill="white" transform="translate(26.7368 26.7368)" />
                </clipPath>
              </defs>
            </svg>

          </div>
          <span className="empty-role-text" style={{ fontWeight: 500 }}>Aucune permission pour l'instant</span>
          <span className="title-400">
            La liste des rôles et permissions sera affichée ici
          </span>
        </div>
      ) : (
        <div style={{ padding: '35px' }}>
          <div style={{ marginBottom: '30px' }}> <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} /></div>
          <div style={{ marginBottom: '20px' }}>
            <AppTable servicesList={filtredOrganRolesData.slice(startIndex, endIndex)} /></div>
          <div style={{ height: '50px' }}>
            <Pagination
              light={true}
              totalItems={filtredOrganRolesData.length}
              itemsPerPage={itemsPerPage}
              maxPage={Math.ceil(filtredOrganRolesData.length / itemsPerPage)}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>)}
      {showModalEdit && (
        <Modal
          icon=""
          leftBtnArgs={{
            args: { onClick: closeModal },
            color: 'neutral',
            label: 'Annuler',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            args: { onClick: handleEditorganisationRoles },
            color: 'primary',
            label: 'Ajouter',
            size: 'sm',
            styling: 'solid',

          }}
          title="Détails des permissions"
          onClose={closeModal}
          bodyStyle={{ padding: '20px' }}
        >

          <div style={{ display: 'grid', marginTop: '20px' }}>

            <div style={{ float: 'right', display: 'inline-grid', justifyItems: 'right' }}><ToggleButton label="Activer" handleToggle={handleActivation} ischecked={activated} ></ToggleButton></div>
          <div style={{ display: 'flex',flexDirection: 'column' ,gap: '15px', }}>
           <div style={{ display: 'flex', gap: '205px' }}>
              <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Organisation</span>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Input
                size='sm'
                args={{
                  value: organisationName,
                  disabled: true
                }}
              />
            
            </div>
            <div className="role-organisation-dropdown"  style={{ display: 'flex',flexDirection: 'column' ,gap: '15px', }}>
                <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Role dans l'organisation</span>
                <Dropdown
                  args={{
                    style: { position: 'relative' },
                  }}
                  label=""
                  klsCompatible={true}
                  data={rolesOrganisation}
                  size="xs"
                  elementSize={36}
                  value={selectedRole}
                  onSelect={handleRoleOrganisationChange}

                />

              </div>
              </div>
          </div>
          <div />
        </Modal>
      )}
    </div>
  )
}
