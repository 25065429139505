const URL_API = "https://ai.karaz.org/api/v1/";
//const URL_API = "http://localhost:8080/api/v1/";


export interface DataRef {
  id: number;
  name_field:string;
  field_type:string;
  group_name:string;
  field_value:string;
  field_widget:string;
  required:boolean;
  multiple:boolean;
  parents : number[];
  ref : boolean;
}
export interface GroupedDataRef {
  id:number;
  name_concept:string;
  data_ref:DataRef[];
}
export interface ActorsRef {
  id: number;
  name_actor:string;
  description:string;
}
export interface ProcessRef {
  id: number;
  name_process:string;
  type_process:string;
  actors:number[]
  previous_task:number[];
  next_task:number[];
}
export interface DataRefinement {
  name_app :string;
  data :GroupedDataRef[];
  actors : ActorsRef[];
  activities :ProcessRef[];
}

export interface ApplicationData {
    app_name: string;
    fields_app: fieldApp[];
    process_app: string[];
}
export interface fieldApp {
  actor: string;
  field_name:string[];
}
// MessageStatus.ts
export enum MessageStatus {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR"
}

// Message.ts
export interface Message {
  messageContent: string;
  messageType: MessageStatus;
  field?: string;
}

// Result.ts
export interface Result<T> {
  status: number;
  createdAt: string; // Assuming the date format will be in "dd-MM-yyyy hh:mm:ss"
  message: Message;
  body?: T;
}
export interface ProcessResponse {
  tasks: Task;
  processXml:string;

}

export interface Task {
  userTasks: UserTask[];
  scriptTasks: ScriptTask[];
}

export interface UserTask {
  id: string;
  name: string;
  incoming: string[];
  outgoing: string;
}
export interface ScriptTask {
  id: string;
  name: string;
  incoming: string[];
  outgoing: string;
}
export function generateDescription(data: fieldApp[]): string {
  let description = "Les champs sont : ";

  data.forEach(field => {
    description += `${field.actor} : ${field.field_name.join(', ')} / `;
  });

  // Remove the trailing ' / ' from the last actor's fields
  description = description.slice(0, -3);

  return description;
}

export const getUserID = () => {
  const id = localStorage.getItem('USER_DETAILS');
  if(id !== null){
  const userDetails = JSON.parse(id);
  return userDetails ? userDetails.userID : null;
  }
};

export const hasSeenOnboarding = (userID) => {
  return localStorage.getItem(`hasSeenOnboarding_${userID}`) === 'true';
};

export  const markOnboardingAsSeen = (userID) => {
  localStorage.setItem(`hasSeenOnboarding_${userID}`, 'true');
};

 /**
  * Asynchronously extracts useful information from the given description.
  *
  * @param {any} description - the input description
  * @param {function} setLoading - a function to set the loading state
  * @return {Promise<any>} the parsed JSON data, or null in case of error
  */
 export const extractUsefulInformation = async (description, setLoading) => {
    try {
      setLoading(true); // Set loading to true before fetching data
      const response = await fetch(URL_API + "extract-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userInput: description }),
      });
      const responseData = await response.json(); // Parse response as JSON
      //setToStorage("WIZARD_INFO", responseData);
      setLoading(false); // Set loading back to false after data is fetched
      return responseData; // Return parsed JSON data
    } catch (error) {
      setLoading(false); // Set loading back to false in case of error
      console.error("Error fetching data:", error);
      return null;
    }
  };
    /**
     * Fetches fields from the server based on the provided description.
     *
     * @param {string} description - The user input description.
     * @return {Promise<object|null>} The parsed JSON response data or null if an error occurs.
     */
export const fetchFields = async (description,actors,appName, setLoading) => {
    try {
      setLoading(true);
      const response = await fetch(URL_API + "extract-fields", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userFieldsInput: description, actors: actors ,app_name:appName}),
      });
      const responseData = await response.json(); // Parse response as JSON
      //setToStorage("WIZARD_INFO",responseData);
      setLoading(false);
      return responseData; // Return parsed JSON data
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
export const uploadAudio = async (audioBlob: Blob): Promise<string> => {
  const formData = new FormData();
  formData.append('file', audioBlob, 'recording.wav');

  try {
    const response = await fetch(URL_API + "transcript", {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload audio file');
    }

    const responseData = await response.json();
    return responseData.results[0].transcript;
  } catch (error) {
    console.error('Error uploading audio file:', error);
    throw error;
  }
}


  /**
   * Asynchronously fetches process data from the server based on the provided description.
   *
   * @param {any} description - The user input description.
   * @param {function} setLoading - A function to set the loading state.
   * @return {Promise<any>} The parsed JSON response data, or null in case of error.
   */
  export const fetchProcess= async (description, setLoading) => {
    try {
      setLoading(true);
      const response = await fetch(URL_API + "extract-process", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userInput: description }),
      });
      const responseData = await response.json(); // Parse response as JSON
      //setToStorage("WIZARD_INFO",responseData);
      console.log("responseData in service process :", responseData);
      setLoading(false);
      return responseData; // Return parsed JSON data
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
/**
 * Fetches the view XML for a given application and fields app.
 *
 * @param {string} application_name - The name of the application.
 * @param {string} fieldsApp - The fields app.
 * @return {Promise<Element | null>} The parsed XML root element or null if an error occurred.
 */
export const fetchViewXml = async (dataref,setLoading) => {
    try {

      setLoading(true);
      console.log("xmlViewData",dataref)
      const response = await fetch(URL_API + "generate-view-xml", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataref),
      });
      const responseData3 = await response.json(); // Parse response as JSON

      const parser = new DOMParser();
      const xmlDocument = parser.parseFromString(
        responseData3,
        "application/xml"
      );
      const rootElement = xmlDocument.documentElement;
      setLoading(false);
      return responseData3; // Return parsed JSON data
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };


export const fetchProcessXml = async (processData,setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(URL_API + "generate-process-xml", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(processData),
    });
    const responseData3 = await response.json(); // Parse response as JSON
    console.log(responseData3);
    const parser = new DOMParser();
    const xmlDocument = parser.parseFromString(
        responseData3,
        "application/xml"
    );
    const rootElement = xmlDocument.documentElement;
    setLoading(false);
    return responseData3; // Return parsed JSON data
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};
export const fromStringToXml = (xmlString)  => {
  return new DOMParser().parseFromString(xmlString, "application/xml").documentElement;
};