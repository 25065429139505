import {useEffect, useRef, useState} from "react";
import t from "kls-i18n";
import { Modal } from 'kls-ui';
import {getFromStorage, GS, saveGlobaleState} from "kls-commons/state/global-state";
import {
    addToast,
    AutoSaveBadge,
    Dropdown,
    IconButton,
    Input,
    ListItemPropertyCard,
    RadioButtons,
    TextArea,
    ToggleButton
} from "kls-ui";
import { BpmnEditor } from "kls-bpmn-editor/process-editor/bpmn-editor";
import {strToXmlElement, xmlElement2str} from "kls-commons/help/tools";
import { Button } from "kls-ui/Button/Button";
import "./kls-module.css";
import { LoadingApplication } from "./loading-module";
import { PreviewWizard } from "kls-view-editor/preview/preview-wizard";
import { useHistory } from "react-router-dom";
import {
    cloneModel,
    fetchKlsResource,
    getWorkingDirectorTree,
    listModules,
    writeKlsResource
} from "kls-commons/service/repo-service";
import {
    extractUsefulInformation,
    hasSeenOnboarding,
    markOnboardingAsSeen,
    fetchFields,
    ProcessResponse,
    ApplicationData,
    fetchProcess,
    fromStringToXml,
    generateDescription,
    DataRefinement,
    uploadAudio,
    DataRef,
    ActorsRef,
    ProcessRef,
    fetchViewXml,
    fetchProcessXml,
    getUserID
} from "./new-wizard.service";
import {ResourceContent, WorkingDirTree} from "kls-commons/types/repo-model";
import { Dropstart } from "kls-ui/drop-start/drop-start";
import { Popover } from "kls-ui/pop-over/popover";
import { TagInput } from "kls-ui/tagInput/tag-input";
import React from "react";
import { FormRender } from "kls-view-editor/form-render";
import { TabFieldApp } from "kls-ui/fieldapp-tab/tab-field-app";
import {Tabs} from "kls-ui/tabs/Tabs";
import "./new-model-wizard-assistant.css";
import {EditorHeaderBar} from "kls-view-editor/editor-header-bar";
import {UndoRedo} from "kls-view-editor/undo-redo";
import {TextAreaWithIcon} from "kls-ui/text-area-with-mic/text-area-with-mic";
import {Stepper} from "kls-ui/stepper-wizard/stepper-wizard";
import {AdvancedDropDown} from "kls-ui/advancedDropDown/AdvancedDropDown";
import {getTenant} from "kls-commons/service/resource-service";
import {currentUser} from "kls-commons/service/userService";
import {createDirectory, NewModel} from "./module.service";

const MAX_CARACTER_DESCRIPTION = 5000;
const MIN_CARACTER_DESCRIPTION = 400;
/**
 * Function for creating a new model wizard via the assistant.
 */
export function NewModelWizardViaAssistant() {
  const emptyResourceContent: ResourceContent = {
    content: ``};
  let history = useHistory();
  const [description, setDescription] = useState<string>("");
  const [resourceContent, setResourceContent] = useState(emptyResourceContent);
  const [responseData, setResponseData] = useState<DataRefinement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState(true);
  const [processApp, setProcessApp] = useState<ProcessResponse | null>();
  const [activeStep, setActiveStep] = useState<number>(1);
  const [errorMessageDescription, setErrorMessageDescription] = useState<string>("");
  const [ini, setIni] = useState(null as unknown as Element | undefined);
  const [xsd, setXsd] = useState(null as unknown as Element | undefined);
  const [responseXml, setResponseXml] = useState(null as unknown as Element | undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fieldApps, setFieldApps] = useState([{ actor: '', field_name: [''] }]);
  const [isInputModified, setIsInputModified] = useState(false);
  const [selectedIndexProcess, setSelectedIndexProcess] = useState(0);
  const [selectedIndexField, setSelectedIndexField] = useState([0,0]);
  const [isEditing, setIsEditing] = useState(false);
  const [newActor, setNewActor] = useState("");
  const [showViewModal,setShowViewModal] = useState(false);
  const [isAddingActor, setIsAddingActor] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3; // Example total steps
  const [fields, setFields] = useState<DataRefinement>({
        "name_app": "application_inventaire",
        "data": [
            {
                "id": 1,
                "name_concept": "inventaire",
                "data_ref": [
                    {
                        "id": 101,
                        "name_field": "évaluation des stocks",
                        "field_type": "Monoligne",
                        "group_name": "général",
                        "field_value": "texte",
                        "field_widget": "text",
                        "multiple": false,
                        "required": true,
                        "parents": [],
                        "ref": true
                    },
                    {
                        "id": 102,
                        "name_field": "biens achetés",
                        "field_type": "Multiligne",
                        "group_name": "détails des stocks",
                        "field_value": "texte",
                        "field_widget": "textArea",
                        "multiple": false,
                        "required": true,
                        "parents": [],
                        "ref": true
                    },
                    {
                        "id": 103,
                        "name_field": "unités disponibles",
                        "field_type": "Monoligne",
                        "group_name": "détails des stocks",
                        "field_value": "nombre",
                        "field_widget": "number",
                        "multiple": false,
                        "required": true,
                        "parents": [],
                        "ref": true
                    },
                    {
                        "id": 104,
                        "name_field": "valeur des stocks",
                        "field_type": "Monoligne",
                        "group_name": "détails des stocks",
                        "field_value": "nombre",
                        "field_widget": "number",
                        "multiple": false,
                        "required": true,
                        "parents": [],
                        "ref": true
                    },
                    {
                        "id": 105,
                        "name_field": "montant total des stocks",
                        "field_type": "Monoligne",
                        "group_name": "général",
                        "field_value": "nombre",
                        "field_widget": "number",
                        "multiple": false,
                        "required": true,
                        "parents": [],
                        "ref": true
                    },
                    {
                        "id": 106,
                        "name_field": "période d'inventaire",
                        "field_type": "Date",
                        "group_name": "général",
                        "field_value": "date",
                        "field_widget": "date",
                        "multiple": false,
                        "required": true,
                        "parents": [],
                        "ref": true
                    }
                ]
            }
        ],
        "actors": [
            {
                "id": 1,
                "name_actor": "gestionnaire de stock",
                "description": "Responsable de la gestion des stocks de l'entreprise"
            },
            {
                "id": 2,
                "name_actor": "comptable",
                "description": "Responsable de la comptabilité de l'entreprise"
            },
            {
                "id": 3,
                "name_actor": "responsable des ventes",
                "description": "Responsable des ventes de l'entreprise"
            }
        ],
        "activities": [
            {
                "id": 1223,
                "name_process": "évaluer stocks",
                "type_process": "userTask",
                "actors": [],
                "previous_task": [-1],
                "next_task": [1224]
            },
            {
                "id": 1224,
                "name_process": "vérifier achats",
                "type_process": "userTask",
                "actors": [],
                "previous_task": [1223],
                "next_task": [1225]
            },
            {
                "id": 1225,
                "name_process": "vérifier ventes",
                "type_process": "userTask",
                "actors": [],
                "previous_task": [1224],
                "next_task": [1226]
            },
            {
                "id": 1226,
                "name_process": "conclure inventaire",
                "type_process": "userTask",
                "actors": [],
                "previous_task": [1225],
                "next_task": [-2]
            }
        ]
        // "activities": [
        //     {
        //         "id": 1,
        //         "name_process": "évaluer stocks",
        //         "type_process": "userTask",
        //         "actors": [1],
        //         "previous_task": [-1],
        //         "next_task": [2]
        //     },
        //     {
        //         "id": 2,
        //         "name_process": "vérifier achats",
        //         "type_process": "userTask",
        //         "actors": [2],
        //         "previous_task": [1],
        //         "next_task": [3]
        //     },
        //     {
        //         "id": 3,
        //         "name_process": "vérifier ventes",
        //         "type_process": "userTask",
        //         "actors": [3],
        //         "previous_task": [2],
        //         "next_task": [4]
        //     },
        //     {
        //         "id": 4,
        //         "name_process": "conclure inventaire",
        //         "type_process": "userTask",
        //         "actors": [1, 2],
        //         "previous_task": [3],
        //         "next_task": [-1]
        //     }
        // ]
    });

    const nameConcept = fields.data[selectedIndexField[0]]?.name_concept || "";
    const nameField = fields.data[selectedIndexField[0]]?.data_ref[selectedIndexField[1]]?.name_field || "";
    const [addressBreadcrumb,setAddressBreadcrumb] = useState(["Attributs",nameConcept,nameField]);
    const [error, setError] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedFieldType, setSelectedFieldType] = useState('sequence');
    const [directories, setDirectories] = useState<string[]>([]);

    useEffect(
        () => {
                listModules().then(
                    modules => {
                        setDirectories(modules) ;
                    }
                )
        } , []
    )
    // Function to handle updates from TabsComponent
    const handleUpdate = (updatedFieldApps) => {
        setFieldApps(updatedFieldApps);
    };
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [userID, setUserID] = useState(null);

    useEffect(() => {
        const id = getUserID();
        if (id) {
            setUserID(id);
            setShowOnboarding(!hasSeenOnboarding(id));
        }
    }, []);

    useEffect(() => {
        if (showOnboarding && userID) {
            markOnboardingAsSeen(userID);
        }
    }, [showOnboarding, userID]);

    const handleOnboardingComplete = () => {
        setShowOnboarding(false);
    };
/* handleActorChange = (newTags : string[]) => {
    if(responseData)
    setResponseData({ ...responseData, actors: newTags });
  };*/
 //    const handleProcesssChange = (newTags : string[]) => {
 //   if(responseData)
 //   setResponseData({ ...responseData, process_app: newTags });
 // };


    const handleTextAreaChange = (value) => {
        setDescription(value);
    };

    const fetchUsefulInformation = async () => {
        setLoading(true);
        setIsInputModified(false);
        // Check if the text length is between 400 and 5000 characters
        if (description.length < 400 || description.length > 5000) {
            setLoading(false);
            setErrorMessage("Le texte doit contenir entre 400 et 5000 caractères.");
            return;
        }
        try {
            const data = await extractUsefulInformation(description, setLoading);
            if (data?.status !== 200) {
                console.log(data);
                const errorMessage = data?.message?.messageContent;
                setErrorMessage(errorMessage);
            } else {
                //setActors(data.body.fields_app.map((field) => field.actor));
                //setFieldApps(data.body.fields_app);
                //setResponseData(data.body);
                console.log("fields ... : "+fields);
                setFields(data.body);

                setLoading(false);
                setErrorMessage("");
              if(activeStep === 1)setActiveStep((prevStep) => prevStep + 1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage("An error occurred while fetching data.");
        }
    };
    const mapIndex = (indexIn:number)=>{
        switch (indexIn){
            case 1:
                return 0;
            case 2 || 4:
                return 1;
            case 3:
                return 2;
            case 5 || 6:
                return 3;
            default:
                return 0;
        }
    }
    const Breadcrumb = ({ items }) => {
        return (
            <div className="breadcrumb-wizard">
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <span>{item}</span>
                        {index !== items.length - 1 && <span className="separator-breadcrumb-wizard"> &gt; </span>}
                    </React.Fragment>
                ))}
            </div>
        );
    };
  const renderContent = () => {
    switch (activeStep) {
      case 1:
          const handleAudioRecorded = async (audioBlob: Blob) => {
              try {
                  // Call the service function to upload audio
                  const transcript = await uploadAudio(audioBlob);
                  setDescription( transcript);
              } catch (error) {
                  console.error('Error uploading audio file:', error);
              }
          };
          return (
              <div style={{width:"100%", display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <div style={{width: "60%"}}>
                      <h2 style={{color: "#2563EB", fontSize: "32px", fontWeight: "400"}}>
                          Quelle application souhaitez-vous créer aujourd'hui ?
                      </h2>
                      <div className="create-app-wisard-modal-description">
                          <div className="name-directory">
                              <TextAreaWithIcon minCharacters={MIN_CARACTER_DESCRIPTION}
                                                maxCharacters={MAX_CARACTER_DESCRIPTION} value={description}
                                                onChange={handleTextAreaChange}
                                                onAudioRecorded={handleAudioRecorded}/>
                              {showOnboarding && currentStep === 2 && ( <Popover
                                  header={"Déclencheur d’idées 💡 "}
                                  currentStep={2}
                                  positionStyles={{
                                      left: '27%',
                                      top: '83%',
                                      transform: 'translateX(-50%)'}}
                                  steps={totalSteps}
                                  onStepChange={setCurrentStep}
                                  content={
                                      <>
                                          <div
                                              style={{
                                                  border: "2px solid #AEB4C2",
                                                  borderRadius: "8px",
                                                  width: "300px",
                                              }}
                                          >
                                              <ul>
                                                  <li>
                                                      Quel est le domaine ou la fonction principale de
                                                      l'application ?
                                                  </li>
                                                  <li>
                                                      Qui sont les acteurs principaux et leurs détailles ?
                                                  </li>
                                                  <li>
                                                      Quelles sont les fonctionnalités de chaque acteurs ?
                                                  </li>
                                              </ul>
                                          </div>
                                      </>
                                  }
                              />)}
                              {showOnboarding && currentStep === 3 && ( <Popover
                                  header={"Speech to Text 🎤 "}
                                  currentStep={3}
                                  positionStyles={{
                                      // left: '118%',
                                      // top: '83%',
                                      // transform: 'translateX(-50%)'
                                      }}
                                  steps={totalSteps}
                                  onStepChange={setCurrentStep}
                                  content={
                                      <>
                                          <div
                                              style={{
                                                  border: "2px solid #AEB4C2",
                                                  borderRadius: "8px",
                                                  width: "300px",
                                              }}
                                          >
                                              <div style={{padding: "16px"}}>
                                                  Si vous êtes meilleur à l’oral,
                                                  cliquez sur ce bouton est commencez à décrir oralement votre idée d’application.
                                                  Le texte sera transcri automatiquement et vous pouvez toujours le modifier à la main.

                                              </div>
                                          </div>
                                      </>
                                  }
                              />)}

                          </div>
                      </div>
                      <div
                          style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                          }}
                      >
                          <div
                              style={{
                                  padding: "16px 19px",
                                  color: "#0284C7",
                                  cursor: "pointer",
                              }}
                              onClick={() =>
                                  setDescription(
                                      "L'inventaire est une opération qui consiste à évaluer les stocks d'une entreprise. Les stocks correspondent aux biens achetés mais pas encore vendus. On peut parler de stocks lorsque l’on fait référence aux entrepôts, à la production ou aux ventes. L'inventaire permet de connaître le nombre d'unités disponibles, leur valeur et le montant de l'ensemble des stocks. Cette opération est nécessaire pour établir la comptabilité de l'entreprise et la tenue du registre du commerce. En effet, l'inventaire est une comptabilité qui se fait sur une période de temps donnée (un mois, un trimestre…). Le rôle de l'inventaire est d'indiquer les fluctuations de stocks sur une période donnée en tenant compte des achats et des ventes."
                                  )
                              }
                          >
                          {showOnboarding && currentStep === 1 && (<Popover

                              header={"Exemple d’essai 🪄 "}
                              currentStep={1}
                              steps={totalSteps}
                              onStepChange={setCurrentStep}
                              positionStyles={{
                                  top: '12px',
                                  transform: 'translateX(-50%)'}}
                              content={
                                  <>
                                      <div
                                          style={{
                                              border: "2px solid #AEB4C2",
                                              borderRadius: "8px",
                                              width: "300px",
                                          }}
                                      >
                                          <div style={{padding: "16px"}}>
                                              Vous ne savez pas par où commencer ni quoi écrire, c'est
                                              normal. Essayez notre exemple ici.
                                          </div>
                                      </div>
                                  </>
                              }

                          />)}

                              <span className="material-icon">auto_fix_normal</span>
                              Exemple d'essai
                          </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "end"}}>
                          <Button
                              color="primary"
                              size="xs"
                              styling="solid"
                              args={{
                                  onClick: fetchUsefulInformation
                                  // onClick: async () => {
                                  //     // if (responseData) {
                                  //     //     setLoading(true);
                                  //     //     if (responseData.fields_app && responseData.process_app) {
                                  //     //         const fieldsApp = await fetchFields(
                                  //     //             generateDescription(responseData.fields_app),
                                  //     //             responseData.fields_app.map(field => field.actor),
                                  //     //             responseData.app_name,
                                  //     //             setLoading
                                  //     //         );
                                  //     //         if (fieldsApp?.status !== 200) {
                                  //     //             const errorMessage = fieldsApp?.message?.messageContent || 'Failed to fetch fields';
                                  //     //             setErrorMessage(errorMessage);
                                  //     //         } else {
                                  //     //             setResponseXml(fromStringToXml(fieldsApp.body));
                                  //     //             console.log(fieldsApp.body);
                                  //     //             const processes = await fetchProcess(
                                  //     //                 "Les processus sont :" + responseData.process_app.join(" ,"),
                                  //     //                 setLoading
                                  //     //             )
                                  //     //             if (processes?.status !== 200) {
                                  //     //                 const errorMessage = processes?.message?.messageContent || 'Failed to fetch fields';
                                  //     //                 setErrorMessage(errorMessage);
                                  //     //             } else if (processes) {
                                  //     //
                                  //     //                 setProcessApp(processes.body);
                                  //     //                 setErrorMessage("");
                                  //     //                 setActiveStep(activeStep + 1);
                                  //     //             }
                                  //     //             setLoading(false);
                                  //     //         }
                                  //     //     }
                                  //     //
                                  //     //     else{
                                  //     //         setErrorMessage("Veuillez renseigner les informations requises");
                                  //     //         setLoading(false);
                                  //     //     }
                                  //     //     //fetchFields();
                                  //     //     //fetchViewXml();
                                  //     // }
                                  //         setLoading(true);
                                  //         // const fieldsApp = await fetchViewXml(
                                  //         //     fields,
                                  //         //     setLoading
                                  //         // );
                                  //         // if (fieldsApp?.status !== 200) {
                                  //         //     const errorMessage = fieldsApp?.message?.messageContent || 'Failed to fetch fields';
                                  //         //     setErrorMessage(errorMessage);
                                  //         // } else {
                                  //         //     console.log(fieldsApp.body);
                                  //         //     setResponseXml(fromStringToXml(fieldsApp.body));
                                  //         //     setLoading(false);
                                  //         //     setActiveStep(activeStep+1);
                                  //         // }
                                  //         setTimeout(() => {
                                  //             console.log('This is executed after the current call stack is clear, despite the zero delay.');
                                  //         }, 3000);
                                  //         setActiveStep(activeStep+1);
                                  //         setLoading(false);
                                  // },
                              }}
                              label={t("suivant")}
                              rightIcon="arrow_forward"
                          />
                      </div>
                  </div>
              </div>

          );
         // case 6:
         //
         //     const initModelJson = ( newModule:NewModel ) => {
         //         return {
         //             name: newModule.model.name,
         //             title: newModule.model.title,
         //         }
         //     }
         //     const initModuleJson = ( newModule:NewModel ) => {
         //         return {
         //             name: newModule.module.name,
         //             title: newModule.module.title,
         //             children: [ initModelJson( newModule ) ]
         //         }
         //     }
         //     const viewXml=
         //         `<view name="Test" default="true" label="Test" usage="visualisation:V" className="empty-view">
         //            <screen label="Test" labelWidth="140" usage="cr:R,val:R" labelAlign="left" transContext="/" masterId=""/>
         //            <buttonBar labelWidth="200" transContext="/" masterId="">
         //              <button label="Valider" type="submit" xpath="to" value="false"/>
         //              <button label="Retourner" type="submit" xpath="to" value="true"/>
         //            </buttonBar>
         //            <statusBar indicator="Demande.state.indicator" type="historique" labelWidth="200" transContext="/" masterId="">
         //              <status label="Creer Test" state="CreerTest"/>
         //              <status label="Valider Test" state="ValiderTest"/>
         //              <status label="Rejet" state="Rejet"/>
         //            </statusBar>
         //            <fieldset header="Titre du groupement de champs" id="fieldset01" labelAlign="float" displayKLS="Cadre" labelWidth="200" transContext="/" masterId="" inner-style="display:;" style="display: ;margin:10px;margin-top:10px;margin-right:10px;margin-bottom:10px;margin-left:10px;padding-left:0px;padding-top:0px;padding-right:0px;padding-bottom:0px;width:Autopx;">
         //            </fieldset>
         //         </view>`
         // async function createDirectory( newModel:NewModel ): Promise<ResourceContent|null>{
         //     const templates = await getFromStorage('TEMPLATE_MODELS') ;
         //     if( !templates || !templates[0] ) return null ;
         //     console.log( "debuging " , newModel, templates[0].modelQN, templates[0].name )
         //     let repertoire = await cloneModel(newModel.module.name, newModel.model.name, templates[0].modelQN, templates[0].name );
         //     console.log("rep sh",repertoire);
         //     if( !repertoire ) return null ;
         //     let moduleJson:any = initModuleJson( newModel ) ;
         //
         //     try{
         //         let klsResource:ResourceContent = await fetchKlsResource( newModel.module.name, "klsapp/" + getTenant() + "/" + newModel.module.name + '/module.json' ) ;
         //         moduleJson = JSON.parse(klsResource.content||'') ;
         //         moduleJson.children = [...(moduleJson.children||[]),initModelJson(newModel)] ;
         //     } catch(e) {
         //         console.log( "fetchKlsResource error : ", e ) ;
         //     }
         //
         //     console.log( "moduleJson :", moduleJson ) ;
         //     moduleJson.owner = currentUser()
         //
         //     saveGlobaleState(GS);
         //     let res1 = await writeKlsResource( newModel.module.name, "klsapp/" + getTenant() + "/" + newModel.module.name + '/module.json' , JSON.stringify( moduleJson ) ) ;
         //     let res = await writeKlsResource( "test", "klsapp/testtest6795405/test/test/view-Test.xml" , viewXml) ;
         //        console.log("done view")
         //     return res1;
         // }
         // return (<button onClick={async () => {
         //     await createDirectory(newModel);
         // } } >create directory</button>)
        //       if (description.trim()) {
        //           return <>
        //               <div
        //                   style={{
        //                       color: "#121417",
        //                       padding: "20px",
        //                       fontWeight: "700",
        //                       fontSize: "20px",
        //                       textAlign: "center"
        //                   }}
        //               >
        //                   Révisez et mettez à jour les détails de votre application
        //               </div>
        //               <div style={{display: "flex", justifyContent: "center"}}>
        //                   {responseData && <div style={{width: "50%"}}>
        //                       <div className="recap-info-container">
        //                           <div className="recap-info-title">
        //                               Nom d'application :
      //                           </div>
      //                           {responseData.app_name ? responseData.app_name : "myApp"}
      //                       </div>
      //
      //                       <div className="recap-info-container">
      //                           <div className="recap-info-title">Les acteurs :</div>
      //                           {responseData.fields_app && "Les acteurs sont : " + responseData.fields_app.map(field => field.actor).join(" ,")}
      //                       </div>
      //                       <div className="recap-info-container">
      //                           <div className="recap-info-title">Les champs:</div>
      //                           {generateDescription(responseData.fields_app)}
      //                       </div>
      //                       <div className="recap-info-container">
      //                           <div className="recap-info-title">Les processus:</div>
      //                           Les processus sont : {responseData.process_app.join(",")}
      //                       </div>
      //                   </div>}
      //               </div>
      //               <div
      //                   style={{
      //                       display: "flex",
      //                       justifyContent: "end",
      //                       paddingRight: "29px",
      //                       paddingBottom: "29px",
      //                   }}
      //               >
      //                   <Button
      //                       color="success"
      //                       size="xs"
      //                       styling="solid"
      //                       args={{
      //                           style: {marginRight: "10px"},
      //                           onClick: fetchUsefulInformation,
      //                       }}
      //                       label={t("Régénérer")}
      //                   />
      //                   {/*<Button
      //                       color="neutral"
      //                       size="xs"
      //                       styling="solid"
      //                       args={{
      //                           style: {marginRight: "10px"},
      //                           onClick: () => setActiveStep(5),
      //                       }}
      //                       label={t("Modifier")}
      //                   />*/}
      //                   <Button
      //                       size={"xs"}
      //                       color={"primary"}
      //                       styling={"solid"}
      //                       args={{onClick:()=>{setActiveStep(activeStep + 1)}}}
      //                       label={t("Confirmer")}
      //                   ></Button>
      //
      //       </div>
      //     </>;
      //   }
      //   setActiveStep(activeStep - 1);
      //   return null;
      case 2:
            return (<div>
                    <div style={{    display: "flex",
                        height: "80vh",
                        margin: "40px",
                        /* justify-content: space-around; */
                        gap: "20px"}}>
                        <div className="tab-wizard-container">
                            <div style={{ borderRadius : "12px 12px 0 0" ,overflow:"hidden"}}>
                                <Tabs size="medium" tabs={tabs}
                                      onSelect={handleTabSelect}
                                      current={currentTab}
                                      defSelected={currentTab}
                                />
                            </div>

                            {renderMenuContent()}
                        </div>
                        <div className="data-info-container">
                                <Breadcrumb items={addressBreadcrumb}></Breadcrumb>
                                {/* Start of Custom Fields */}
                            <div>
                                {renderCustomFields()}
                                {/* End of Custom Fields */}
                                <div style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "15px 0 15px 15px",
                                    gap :"10px"}}>
                                    {/*<Button*/}
                                    {/*    color="primary"*/}
                                    {/*    size="xs"*/}
                                    {/*    styling="solid"*/}
                                    {/*    args={{*/}
                                    {/*        onClick:  () => {*/}
                                    {/*            console.log(fields);*/}
                                    {/*        },*/}
                                    {/*    }}*/}
                                    {/*    label={t("Recommencer")}*/}
                                    {/*    leftIcon={"refresh"}*/}
                                    {/*/>*/}
                                    <Button
                                        color="success"
                                        size="xs"
                                        styling="solid"
                                        args={{
                                            onClick: async () => {
                                                // if (responseData) {
                                                //     setLoading(true);
                                                //     if (responseData.fields_app && responseData.process_app) {
                                                //         const fieldsApp = await fetchFields(
                                                //             generateDescription(responseData.fields_app),
                                                //             responseData.fields_app.map(field => field.actor),
                                                //             responseData.app_name,
                                                //             setLoading
                                                //         );
                                                //         if (fieldsApp?.status !== 200) {
                                                //             const errorMessage = fieldsApp?.message?.messageContent || 'Failed to fetch fields';
                                                //             setErrorMessage(errorMessage);
                                                //         } else {
                                                //             setResponseXml(fromStringToXml(fieldsApp.body));
                                                //             console.log(fieldsApp.body);
                                                //             const processes = await fetchProcess(
                                                //                 "Les processus sont :" + responseData.process_app.join(" ,"),
                                                //                 setLoading
                                                //             )
                                                //             if (processes?.status !== 200) {
                                                //                 const errorMessage = processes?.message?.messageContent || 'Failed to fetch fields';
                                                //                 setErrorMessage(errorMessage);
                                                //             } else if (processes) {
                                                //
                                                //                 setProcessApp(processes.body);
                                                //                 setErrorMessage("");
                                                //                 setActiveStep(activeStep + 1);
                                                //             }
                                                //             setLoading(false);
                                                //         }
                                                //     }
                                                //
                                                //     else{
                                                //         setErrorMessage("Veuillez renseigner les informations requises");
                                                //         setLoading(false);
                                                //     }
                                                //     //fetchFields();
                                                //     //fetchViewXml();
                                                // }
                                                if (fields) {
                                                        setLoading(true);
                                                            const fieldsApp = await fetchViewXml(
                                                                fields,
                                                                setLoading
                                                            );
                                                            if (fieldsApp?.status !== 200) {
                                                                const errorMessage = fieldsApp?.message?.messageContent || 'Failed to fetch fields';
                                                                setErrorMessage(errorMessage);
                                                            } else {
                                                                console.log(fieldsApp.body);
                                                                setResponseXml(fromStringToXml(fieldsApp.body));
                                                                setLoading(false);
                                                                setActiveStep(activeStep+1);
                                                            }
                                                        }
                                                        else{
                                                            setErrorMessage("Veuillez renseigner les informations requises");
                                                            setLoading(false);
                                                        }

                                            },
                                        }}
                                        label={t("Valider")}
                                        rightIcon={"arrow_forward"}

                                    />

                                </div>

                            </div>


                        </div>
                    </div>
                </div>);
      case 3:
        let nomask ;
/*        if(processApp){
         nomask = processApp.tasks.userTasks.map(task => ({
          id: task.id,
          name: task.name,
          mask: task.name
        }));}*/
        nomask =[{
            id:"1",
            name:"first",
            mask:"my mask"
        }]

        return (<>
        {showPreview && responseXml && <PreviewWizard view={responseXml} activities={nomask}  onClose={() => setShowPreview(false)} />}
            <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "29px",
                  paddingBottom: "29px",
                }}
              >
                <Button
                  color="neutral"
                  size="xs"
                  styling="solid"
                  args={{
                    style: { marginRight: "10px" },
                    onClick: async () => {
                                const processes = await fetchProcessXml(
                                                fields,
                                                setLoading
                                            )
                                            if (processes?.status !== 200) {
                                                const errorMessage = processes?.message?.messageContent || 'Failed to fetch fields';
                                                setErrorMessage(errorMessage);
                                        } else if (processes) {
                                                console.log("my process ="+processes.body);
                                            emptyResourceContent.content = new XMLSerializer().serializeToString(fromStringToXml(processes.body));
                                            setResourceContent(emptyResourceContent);
                                            setErrorMessage("");
                                            setActiveStep(activeStep + 1);
                                        }
                            //console.log("xml :"+fromStringToXml(processApp.processXml) + "tasks :"+processApp.tasks.userTasks);
                            // emptyResourceContent.content = new XMLSerializer().serializeToString(fromStringToXml(processes.body));
                            // console.log("this is bpmn xml as string :{}",new XMLSerializer().serializeToString(fromStringToXml(processApp.processXml)));
                            // ;
                            // setResourceContent(emptyResourceContent);
                        setActiveStep(activeStep + 1);
                    },
                  }}
                  label={t("Generer")}
                /></div>
        </>

        );
      // case 4:
      //     return (
      //       <>
      //         <div
      //           style={{
      //             color: "#121417",
      //             padding: "20px",
      //             fontWeight: "700",
      //             fontSize: "20px",
      //           }}
      //         >
      //           Révisez et mettez à jour les détails de votre application
      //         </div>
      //         <div style={{ display: "flex", justifyContent: "center" }}>
      //           {responseData && (
      //             <div
      //               style={{
      //                 width: "600px",
      //                 display: "flex",
      //                 flexFlow: "column",
      //                 gap: "6px",
      //               }}
      //             >
      //               <Input
      //                 args={{
      //                   value: responseData.app_name ? responseData.app_name : "myApp",
      //                   onChange: (evt) =>
      //                     setResponseData({
      //                       ...responseData,
      //                       app_name: evt.target.value,
      //                     }),
      //                 }}
      //                 label={"Nom de l'application"}
      //                 size="sm"
      //               />
      //               {/*<TagInput tags={responseData.fields_app.map(field =>field.actor)} libelle={"Les acteurs"}  onTagsChange={(acteurs) => {}} />*/}
      //               <TabFieldApp fieldApps={fieldApps} onUpdate={handleUpdate}></TabFieldApp>
      //                 {/* <Input
      //                 label={"Les acteurs"}
      //                 args={{ value: responseData.actors.join(", ") }}
      //                 size="sm"
      //               /> */}
      //               {/* <TagInput tags={[]} onTagsChange={(tags) => setResponseData(tags)} /> */}
      //               {/*  <TagInput tags={responseData.fields_app.map(field =>field.actor)} textArea={true} onTagsChange={()=>{}} libelle={"Les acteurs"} />*/}
      //                 <TagInput tags={responseData.process_app} textArea={true} onTagsChange={(process_app)=>{handleProcesssChange(process_app)}} libelle={"Les processus"} />
      //             {/*  <TextArea
      //                 label={"les champs"}
      //                 args={{
      //                   value: responseData.fields_app,
      //                   onChange: (evt) =>
      //                     setResponseData({
      //                       ...responseData,
      //                       fields_app: evt.target.value,
      //                     }),
      //                 }}
      //                 size="sm"
      //               />
      //               <TextArea
      //                 label={"les evenements"}
      //                 args={{
      //                   value: responseData.process_app,
      //                   onChange: (evt) =>
      //                     setResponseData({
      //                       ...responseData,
      //                       process_app: evt.target.value,
      //                     }),
      //                 }}
      //                 size="sm"
      //               />*/}
      //             </div>
      //           )}
      //         </div>
      //         <div
      //           style={{
      //             display: "flex",
      //             justifyContent: "end",
      //             paddingRight: "29px",
      //             paddingBottom: "29px",
      //           }}
      //         >
      //           <Button
      //             color="primary"
      //             size="xs"
      //             styling="solid"
      //             args={{ onClick: () => setActiveStep(3) }}
      //             label={t("Confirmer")}
      //           />
      //         </div>
      //       </>
      //     );
      case 4:
          const ensureUniqueDirectoryName = (): string => {
              let directoryName = fields.name_app || "my_app";
              let incrementId = 0;

              while (directories.includes(directoryName + (incrementId || ''))) {
                  incrementId++;
              }

              return directoryName + (incrementId || '');
          };
        const onSaveResource = async (rc: ResourceContent) => {
          if (!rc || !rc.content || !rc.info) {
            return;
          }
          console.log("onSaveResource", rc);
          if (rc.extraContent) {
            Object.keys(rc.extraContent).forEach((k) => {
              let ec = rc.extraContent![k];
              let path = rc.info!.filePath.replace(rc.info!.fileName, k);
              writeKlsResource(GS.currentModuleName, path, ec);
            });
          }
          return await writeKlsResource(
            GS.currentModuleName,
            rc.info.filePath,
            rc.content
          );
        };
        let directoryName=ensureUniqueDirectoryName();
          let newModel: NewModel = {
              module: {
                  name: directoryName,
                  title:  directoryName
              },
              model: {
                  name: directoryName,
                  title: directoryName
              }
          };
      function capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
        return (
            <div className="create-app-wisard-modal-name">
                <h1 style={{fontSize: "13px", color: "#1E293B"}}>
                    Veuillez vérifier votre processus, vous pouvez également le
                    modifier.
                </h1>
                <div style={{height: "500px", overflow: "auto"}}>
                    <div style={{height: "500px", overflow: "auto"}}>
                        <BpmnEditor
                            onSave={onSaveResource}
                            resourceContent={resourceContent}
                        ></BpmnEditor>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        paddingRight: "29px",
                        paddingBottom: "29px",
                    }}
                >
                    <Button
                        color="neutral"
                        size="xs"
                        styling="solid"
                        args={{
                            style: {marginRight: "10px"},
                            onClick: async () => {
                                if(resourceContent.content && responseXml){
                                    await createDirectory(newModel).then(
                                        ()=>{
                                            addToast(` L'application a été ajoutée avec succès !`, "success");
                                        }
                                    );
                                    const pathView= "klsapp/"+getTenant()+"/"+directoryName+"/"+directoryName+"/view-"+capitalizeFirstLetter(directoryName)+".xml";
                                    const pathBpmn= "klsapp/"+getTenant()+"/"+directoryName+"/"+directoryName+"/process/Gestion"+capitalizeFirstLetter(directoryName)+".bpmn";
                                    await writeKlsResource(directoryName,pathView,xmlElement2str(responseXml));
                                    await writeKlsResource(directoryName,pathBpmn,resourceContent.content);
                                    //  setActiveStep(activeStep + 1);
                                    history.push("/editService/"+directoryName+"/"+directoryName);
                                }
                                else addToast(` Un problème est survenu !`, "error");

                            },
                        }}
                        label={t("Generer")}
                    /></div>
            </div>
        );
        // Add cases for other steps as needed
        default:
            return null;
    }
  };
    const EditableHeader = ({initialValue}) => {
        const [value, setValue] = useState(initialValue);
        const [isEditing, setIsEditing] = useState(false);

        const handleEditClick = () => {
            setIsEditing(true);
        };

        const handleSaveClick = () => {
            // Perform any validation or additional actions before saving
            // For simplicity, we'll directly set the value
            setIsEditing(false);
        };

        const handleInputChange = (e) => {
            setValue(e.target.value);
        };

        return (
            <div>
                {isEditing ? (
                    <div className="editable-header">
                        <Input size={"md"} args={{value: value, onChange: handleInputChange}}/>
                        {/*<button onClick={handleSaveClick}>Save</button>*/}
                        <IconButton
                            color="primary"
                            icon="save"
                            size="md"
                            styling="outline"
                            args={{onClick: () => handleSaveClick()}}/>
                    </div>
                ) : (
                    <div className="editable-header">
                        <p>{value}</p>
                        <IconButton
                            color="primary"
                            icon="edit"
                            size="md"
                            styling="outline"
                            args={{onClick: () => handleEditClick()}}/>
                        {/*<span className="material-icon" onClick={handleEditClick} style={{cursor:"pointer" ,fontSize:"25px"}}>edit</span>*/}
                    </div>
                )}
            </div>
        );
    };

    function renderCustomFields() {
        switch (currentTab) {
            case 'Data':
                const handleFieldChange = (e) => {
                    const newValue = e.target.value;
                    const newData = [...fields.data];
                    newData[selectedIndexField[0]].data_ref[selectedIndexField[1]].name_field = newValue;

                    setFields({
                        ...fields,
                        data: newData
                    });
                };

                const handleToggle = (property) => {
                    const newData = [...fields.data];
                    const field = newData[selectedIndexField[0]].data_ref[selectedIndexField[1]];
                    field[property] = !field[property];

                    setFields({
                        ...fields,
                        data: newData
                    });
                };

                return (
                    <div>
                        <div style={{ display: "flex",justifyContent: "flex-end"}}>
                        <Button
                            color="primary"
                            size="xs"
                            styling="solid"
                            args={{
                                onClick:async () => {

                                    if (fields) {
                                        setLoading(true);
                                        const fieldsApp = await fetchViewXml(
                                            fields,
                                            setLoading
                                        );
                                        if (fieldsApp?.status !== 200) {
                                            const errorMessage = fieldsApp?.message?.messageContent || 'Failed to fetch fields';
                                            setErrorMessage(errorMessage);
                                        } else {
                                            console.log(fieldsApp.body);
                                            setResponseXml(fromStringToXml(fieldsApp.body));
                                            setLoading(false);
                                            setShowViewModal(true);
                                        }
                                    } else {
                                        setErrorMessage("Veuillez renseigner les informations requises");
                                        setLoading(false);
                                    }
                                }
                            }}
                            label={t("visualiser")}
                            rightIcon={"visibility"}
                        />
                        </div>
                    <div className="form-container-wizard">

                        {/* Input */}
                        <Input size={"md"} label={"Libellé :"} args={{
                            value: fields.data[selectedIndexField[0]]?.data_ref[selectedIndexField[1]]?.name_field,
                            onChange: handleFieldChange
                        }}/>
                         {/* Type Field */}
                        <div style={{width: "100%"}}>
                            {/*<Dropdown*/}
                            {/*    value={selectedFieldType}*/}
                            {/*    setValue={(value) => setSelectedFieldType(value)}*/}
                            {/*    elementSize={32}*/}
                            {/*    elements={[*/}
                            {/*        {value: 'nombre', label: 'nombre'},*/}
                            {/*        {value: 'sequence', label: 'sequence'},*/}
                            {/*        {value: 'complexe', label: 'complexe'},*/}
                            {/*    ]}*/}
                            {/*    label="Type:"*/}
                            {/*    listTopPos="105%"*/}
                            {/*    size="md"*/}
                            {/*/>*/}
                            <div>
                                <label htmlFor="fieldType">Type:</label>
                                <select
                                    id="fieldType"
                                    className={"select-style"}
                                    value={fields.data[selectedIndexField[0]]?.data_ref[selectedIndexField[1]]?.field_type}
                                    onChange={(event)=>{
                                        const updatedFields = { ...fields };
                                        updatedFields.data[selectedIndexField[0]].data_ref[selectedIndexField[1]].field_type = event.target.value;
                                        setFields(updatedFields);
                                        setSelectedFieldType(event.target.value);
                                    }}
                                    style={{
                                        fontSize: 'md',
                                        width:'100%',

                                    }}
                                >
                                    <option value="Monoligne">Séquence</option>
                                    <option value="Multiligne">Texte</option>
                                    {/*<option value="Texte riche">Texte riche</option>*/}
                                    <option value="Nombre">Nombre</option>
                                    <option value="Date">Date</option>
                                    <option value="Heure">Heure</option>
                                    {/*<option value="complexe">Complexe</option>*/}
                                </select>
                            </div>
                        </div>
                        {/* Widget Field */}
                        {selectedFieldType == 'complexe' && (<>
                            <div>
                                <div style={{width: "100%"}}>
                                    <Dropdown
                                        args={{}}
                                        elementSize={32}
                                        elements={[
                                            {value: 'value1'},
                                        ]}
                                    label="Widget:"
                                    listTopPos="105%"
                                    size="md"
                                />

                            </div>
                        </div>
                            {/* Parent Fields with Select Inputs */}
                        <div>
                            <div style={{width: "100%"}}>
                                <Dropdown
                                    args={{}}
                                    elementSize={32}
                                    elements={[
                                        {value: 'value1', label: 'value2'},
                                        {value: 'val3', label: 'value3'},
                                    ]}
                                    label="Parent Fields:"
                                    listTopPos="105%"
                                    size="md"
                                />
                            </div>
                        </div>
                            {/* Multiple Radio Button */}
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <label>Multiple:</label>
                            <ToggleButton
                                handleToggle={() => handleToggle('multiple')}
                                ischecked ={fields.data[selectedIndexField[0]]?.data_ref[selectedIndexField[1]]?.multiple}
                                size="sm"
                            />
                        </div>
                            {/* Obligatoire Radio Button */}
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <label>Obligatoire:</label>
                            <ToggleButton
                                handleToggle={() => handleToggle('required')}
                                ischecked ={fields.data[selectedIndexField[0]]?.data_ref[selectedIndexField[1]]?.required}
                                size="sm"
                            />
                        </div>
                            {/* Reference Radio Button */}
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <label>Reference:</label>
                            <ToggleButton
                                handleToggle={() => handleToggle('ref')}
                                ischecked ={fields.data[selectedIndexField[0]]?.data_ref[selectedIndexField[1]]?.ref}
                                size="sm"
                            />
                        </div>
                        </>)}
                    </div>
                    </div>
                );
            case 'Activities':
                const handleProcessChange = (e) => {
                    const newValue = e.target.value;
                    const newData = [...fields.activities];
                    newData[selectedIndexProcess].name_process = newValue;

                    setFields({
                        ...fields,
                        activities: newData
                    });
                };

                const handleTaskUpdate = (type, value) => {
                    const updatedFields = { ...fields };
                    const selectedTaskId = value === 'startEvent' ? -1 : value === 'endEvent' ? -2 : parseInt(value);

                    if (type === 'previous') {
                        updatedFields.activities[selectedIndexProcess].previous_task[0] = selectedTaskId;
                    } else if (type === 'next') {
                        updatedFields.activities[selectedIndexProcess].next_task[0] = selectedTaskId;
                    }

                    setFields(updatedFields);
                };
                const handleActorSelectChange = (event) => {
                    const selectedOptions: number[] = [];
                    const options = event.target.selectedOptions;
                    for (let i = 0; i < options.length; i++) {
                        selectedOptions.push(parseInt(options[i].value, 10) as number);
                    }
                    updateActivityActors(selectedOptions);
                };

                const updateActivityActors = (actors: number[]) => {
                    setFields(prevFields => {
                        const updatedActivities = [...prevFields.activities];
                        updatedActivities[selectedIndexProcess].actors = actors;
                        return {
                            ...prevFields,
                            activities: updatedActivities
                        };
                    });
                };

                const handleAddActor = () => {
                    if (newActor.trim() !== "") {
                        const newActorObj = { id: Date.now(), name_actor: newActor.trim(), description: "" };
                        setFields(prevFields => ({
                            ...prevFields,
                            actors: [...prevFields.actors, newActorObj]
                        }));
                        setNewActor("");
                        setIsAddingActor(false);
                    }
                };

                // Filter out already chosen activities and convert the id to a string
                const getActivityById = (id) => {
                    return fields.activities.find(activity => activity.id === id);
                };
                return (
                    <div className="form-container-wizard">
                        {/* Type Field */}
                        <div style={{width: "100%"}}>
                            <Input size={"md"} label={"Nom :"} args={{
                                value: fields.activities[selectedIndexProcess]?.name_process,
                                onChange: handleProcessChange
                            }}/>
                        </div>
                        <div >
                                <label htmlFor="typeProcess">Type:</label>
                                <select
                                    className={"select-style"}
                                    id="typeProcess"
                                    value={fields.activities[selectedIndexProcess]?.type_process}
                                    onChange={(event) => {
                                        const updatedFields = {...fields};
                                        updatedFields.activities[selectedIndexProcess].type_process = event.target.value;
                                        setFields(updatedFields);
                                    }}
                                    style={{fontSize: 'md', width: '100%'}}
                                >
                                    <option value="userTask">user task</option>
                                    <option value="scriptTask">script task</option>
                                </select>
                    </div>
            {/* Widget Field */
            }
                        {/* Predecessor Task Dropdown */}
                        <div style={{ width: "100%" }}>
                            <label htmlFor="previousTask">Prédecesseur:</label>
                            <select
                                id="previousTask"
                                className={"select-style"}
                                value={(fields.activities[selectedIndexProcess]?.previous_task != null && fields.activities[selectedIndexProcess]?.previous_task[0] === -1) ? 'startEvent' : getActivityById(fields.activities[selectedIndexProcess]?.previous_task[0])?.id.toString()}
                                onChange={(event) => handleTaskUpdate('previous', event.target.value)}
                                style={{fontSize: 'md', width: '100%'}}
                            >
                                <option value="-1">Start Event</option>
                                {fields.activities.map(activity => (
                                    <option key={activity.id}
                                            value={activity.id.toString()}>{activity.name_process}</option>
                                ))}
                            </select>
                        </div>

                        {/* Successor Task Dropdown */}
                        <div style={{ width: "100%" }}>
                            <label htmlFor="nextTask">Successeur:</label>
                            <select
                                className={"select-style"}
                                id="nextTask"
                                value={(fields.activities[selectedIndexProcess]?.next_task != null && fields.activities[selectedIndexProcess]?.next_task[0] === -2) ? 'endEvent' : getActivityById(fields.activities[selectedIndexProcess]?.next_task[0])?.id.toString() || ''}
                                onChange={(event) => handleTaskUpdate('next', event.target.value)}
                                style={{fontSize: 'md', width: '100%'}}
                            >
                                <option value="-2">End Event</option>
                                {fields.activities.map(activity => (
                                    <option key={activity.id}
                                            value={activity.id.toString()}>{activity.name_process}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <div style={{width: "100%"}}>
                                <div>
                                    {/* Multi-select for Actors or Add Actor Input */}
                                    {!isAddingActor ? (
                                        <div>
                                            <label htmlFor="actors">Acteurs:</label>
                                            <select
                                                id="actors"
                                                multiple
                                                value={fields.activities[selectedIndexProcess]?.actors.map(actorId => actorId.toString()) || []}
                                                onChange={handleActorSelectChange}
                                                style={{fontSize: 'md', height: '100px', width: '100%'}}
                                            >
                                                {
                                                    fields.actors.map(actor => (
                                                        <option key={actor.id} value={actor.id.toString()}>
                                                            {actor.name_actor}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                            <Button
                                                color="primary"
                                                size="xs"
                                                styling="solid"
                                                args={{
                                                    onClick: () => setIsAddingActor(true)
                                                }}
                                                // leftIcon={"plus"}
                                                label={"Ajouter"}
                                            />

                                        </div>
                                    ) : (
                                        <div style={{ display: "flex",
                                            gap: "4px"}}>
                                            <input
                                                style={{ width: "100%" }}
                                                className={"select-style"}
                                                type="text"
                                                value={newActor}
                                                onChange={(e) => setNewActor(e.target.value)}
                                                placeholder="Ajouter un nouveau acteur"
                                            />
                                            <Button
                                                color="success"
                                                size="xs"
                                                styling="solid"
                                                args={{
                                                    onClick: handleAddActor
                                                }}
                                                // leftIcon={"plus"}
                                                label={"Save"}
                                            />
                                            <Button
                                                color="neutral"
                                                size="xs"
                                                styling="solid"
                                                args={{
                                                    onClick: () => setIsAddingActor(false)
                                                }}
                                                // leftIcon={"plus"}
                                                label={"Cancel"}
                                            />
                                            {/*<button onClick={handleAddActor}>Save Actor</button>*/}
                                            {/*<button onClick={() => setIsAddingActor(false)}>Cancel</button>*/}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    const renderMenuContent = () => {
        const handleAddField = (conceptIndex: number) => {
            const newField = {
                id: Date.now(), // generate a unique ID as needed
                name_field: "new_field",
                field_type: "Monoligne", // default type
                multiple: false,
                field_value: "new field", // default value
                field_widget: "", // default widget
                required: false, // default requirement status
                group_name: "",
                parents: [], // default parents
                ref: false // default reference status
            };

            const newDataRef = [...fields.data[conceptIndex].data_ref, newField];
            const newData = [...fields.data];
            newData[conceptIndex] = {...fields.data[conceptIndex], data_ref: newDataRef};

            setFields({
                ...fields,
                data: newData
            });
        };


        const handleAddGroupedDataRef = () => {

            const newGroupedDataRef = {
                id: Date.now()+1, // generate a unique ID as needed
                name_concept: "concept0",
                data_ref: [{
                    id: Date.now()+2,
                    name_field: "new_field",
                    field_type: "Monoligne",
                    field_value: "string",
                    group_name: "group",
                    field_widget: "string",
                    multiple:false,
                    required: true,
                    parents: [],
                    ref: true
                }]
            };

            setFields({
                ...fields,
                data: [...fields.data, newGroupedDataRef]
            });
        };

        const handleRemoveConcept = (conceptIndex: number) => {
            const newData = [...fields.data];
            newData.splice(conceptIndex, 1);

            setFields({
                ...fields,
                data: newData
            });
        };

        const handleRemoveField = (conceptIndex: number, fieldIndex: number) => {
            const newDataRef = [...fields.data[conceptIndex].data_ref];
            newDataRef.splice(fieldIndex, 1);

            const newData = [...fields.data];
            newData[conceptIndex] = { ...fields.data[conceptIndex], data_ref: newDataRef };

            setFields({
                ...fields,
                data: newData
            });
        };
        const handleAddActivity = () => {
            const newActivity = {
                id: 124, // generate a unique ID as needed
                name_process: "New Process",
                type_process: "userTask",
                actors: [],
                previous_task: [],
                next_task: []
            };

            setFields({
                ...fields,
                activities: [...fields.activities, newActivity]
            });
        };
        const handleRemoveActivity = (activityIndex) => {
            const newActivities = [...fields.activities];
            newActivities.splice(activityIndex, 1);

            setFields({
                ...fields,
                activities: newActivities
            });
        };


        const handleEditClick = (index) => {
            setEditingIndex(index);
            setInputValue(fields.data[index].name_concept);

        };

        const handleInputChange = (e,dataindex) => {
            const newValue = e.target.value;

            if (e.key === 'Enter') {
                if (newValue.trim() === '') {
                    setError('Input should not be empty.');
                    return;
                }

                if (newValue.length > 40) {
                    setError('Input should not surpass 40 characters.');
                    return;
                }
                console.log("selected :"+selectedIndexField);
                const newData = [...fields.data];
                //newData[dataindex].name_concept = newValue;
                newData[dataindex].name_concept = inputValue.trim();
                setFields({
                    ...fields,
                    data: newData
                });

                setEditingIndex(null);
                setError('');
            }
        };


        switch (currentTab) {
            case "Data":
                return (
                    <div>
                        <div className={"data-form-fields"}>
                            {fields.data.map((groupedDataRef, dataIndex) => (
                                    <div key={dataIndex} style={{ display:"flex" ,flexDirection:"column",gap:"10px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            {editingIndex === dataIndex ? ( <div style={{ display: "flex" ,flexDirection:"column"}}>
                                                <Input size={"xs"} args={{
                                                    value:inputValue
                                                    ,onChange: (e) => {
                                                        const newValue = e.target.value;
                                                        setInputValue(newValue);
                                                        const newData = [...fields.data];
                                                        newData[dataIndex].name_concept = newValue;
                                                        setFields({
                                                            ...fields,
                                                            data: newData
                                                        });
                                                    },
                                                    onKeyDown: (e)=> handleInputChange(e,dataIndex)}}  />
                                                {error && <div style={{ color: 'red' ,fontSize: "small",
                                                    textWrap: "nowrap" }}>{error}</div>}</div>
                                                ): (
                                                <div style={{
                                                    fontFamily: 'Inter',
                                                    fontWeight: "600",
                                                    fontSize: "12px"
                                                }}>{groupedDataRef.name_concept}</div>
                                                )}

                                            <div className={"buttons-data-edit"}>
                                                {editingIndex !== dataIndex && <span className="material-icon slate-500" onClick={() => handleEditClick(dataIndex)}>edit</span>}
                                                <span className="material-icon blue-600" onClick={() => handleAddField(dataIndex)}>add</span>
                                                <span className="material-icon red-600" onClick={() => handleRemoveConcept(dataIndex)}>close</span>
                                            </div>
                                        </div>
                                        {groupedDataRef.data_ref.map((field, index) => (
                                            <div key={index}>
                                                <ListItemPropertyCard
                                                    label={field.name_field}
                                                    value={field.field_value}
                                                    onClick={()=>{
                                                        setAddressBreadcrumb(["Attributs",groupedDataRef.name_concept,field.name_field]);
                                                        setSelectedIndexField([dataIndex,index])
                                                    }}
                                                    index={index}
                                                    onXClick={() => handleRemoveField(dataIndex, index)}
                                                    onLabelChange={(label) => {
                                                        // Implement label change functionality
                                                    }}
                                                    onValueChange={(value) => {
                                                        // Implement value change functionality
                                                    }}
                                                    onBlur={() => {
                                                        // Implement blur functionality
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>

                            ))}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "end",
                            padding: "15px"
                        }}>
                        <Button
                            color="primary"
                            size="xs"
                            styling="outline"
                            args={{
                                onClick: handleAddGroupedDataRef
                            }}
                            leftIcon={"add"}
                            label={"Ajouter"}
                        />
                        </div>
                    </div>
                );
            case "Activities":
                return (
                    <div>
                        <div className={"data-form-fields"}>
                            {fields.activities.map((activity, index) => (
                                <div key={index}>
                                    <ListItemPropertyCard
                                        label={activity.name_process}
                                        value={activity.name_process}
                                        index={index}
                                        onXClick={() => handleRemoveActivity(index)}
                                        onClick={()=>{
                                            setAddressBreadcrumb(["Actions",activity.name_process]);
                                            setSelectedIndexProcess(index);

                                        }}
                                        onLabelChange={(label) => {
                                            // Implement label change functionality
                                        }}
                                        onValueChange={(value) => {
                                            // Implement value change functionality
                                        }}
                                        onBlur={() => {
                                            // Implement blur functionality
                                        }}
                                    />
                                </div>
                            ))}

                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "end",
                            padding: "15px"
                        }}>
                            <Button
                                color="primary"
                                size="xs"
                                styling="outline"
                                args={{
                                    onClick: handleAddActivity
                                }}
                                leftIcon={"add"}
                                label={"Ajouter"}
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };


    let onChangeDescription = async (value: string) => {
        setIsInputModified(true);
        if (MAX_CARACTER_DESCRIPTION && value.length > MAX_CARACTER_DESCRIPTION) {
            return;
        }

        setDescription(value);
    };
    const tabs = [
        {name: "Data", label: "Attributs"},
        {name: "Activities", label: "Actions"}
    ];
    const [currentTab, setCurrentTab] = useState("Data");

    // Handler function for tab selection changes
    const handleTabSelect = (tabName) => {
        setCurrentTab(tabName);
        // You can add any other logic you need when a tab is selected
    };
    let nomask = [{
        id: "1",
        name: "first",
        mask: "my mask"
    }]
    return (
        <div>
            {loading && (
                <LoadingApplication message="Nous configurons votre application..."/>
            )}
            <div style={{display: "flex", alignItems: "center"}}>
                <span
                    className="material-icon"
                    onClick={() => {
                        if (activeStep === 1) history.goBack();
                        else if (activeStep === 5) setActiveStep(2);
                        else if (activeStep === 6) setActiveStep(4);
                        else setActiveStep(activeStep - 1);
                    }}
                    style={{margin: "50px", fontSize: "20px", cursor: "pointer"}}
                >
                  arrow_back
                </span>
                <div>
                    <div
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "8px",
                          }}
                      >
                    <span
                        className="material-icon"
                        style={{fontSize: "20px", color: "#5C7CFA"}}
                    >
                      auto_fix_normal
                    </span>
                          L’assistant intelligent
                      </div>
                      <div>
                          Générez votre application en fournissant simplement du texte ou en dictant votre éconcé
                          à l’Assistant intelligent Karaz. Suivez ensuite le guide !
                      </div>
                  </div>
              </div>
              <div style={{display: "flex" ,flexDirection:"column" ,justifyContent:"center" ,alignItems:"center"}}>

                  <div style={{width: "100%"}}>
                      <Stepper steps={[{name:"Décrire"},{name:"Confirmer"},{name:"Générer"}]} currentStep={mapIndex(activeStep)} />
                      {/*<div style={{padding: "20px"}}>*/}
                      {/*    {errorMessage && (*/}
                      {/*        <div className="login-error-message">{errorMessage}</div>*/}
                      {/*    )}*/}
                      {/*</div>*/}
                      {showViewModal &&
                          <Modal onClose={()=>{setShowViewModal(false)}} title="View App" icon=""

                                 bodyStyle={ {minHeight:"fit-content"} }>
                              {showPreview && responseXml && <PreviewWizard view={responseXml} activities={nomask}  onClose={() => setShowPreview(false)} />}

                          </Modal>
                      }
                      {renderContent()}

                  </div>
              </div>
              <div/>
          </div>
    );
}
