import React from 'react'
import { postMap } from 'kls-commons/service/karaz-remote-action'

export async function OrganizationSettingsService(userID: any, organisationID: any) {

    let actionUrl = '/karazal/ow-kls-extrat/appmanager/organismedetails';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organismId", organisationID);
    urlencoded.append("userId", userID);


    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}
