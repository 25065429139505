import { useState } from 'react';
import './organisation-deletion.css';
import { SettingsContainerHeader } from '../../settings/settings';
import { Button, ErrorMessage, Input } from 'kls-ui'
export function OrganisationDeletion(props: any) {
  const [errorMessage, setErrorMessage] = useState(false);
  let [showPassword, setshowPassword] = useState(false);
  let [password, setpassword] = useState('');
  let [passwordErr, setpasswordErr] = useState('');
  let [password2, setpassword2] = useState('');
  let [password2Err, setpassword2Err] = useState('');
  let [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  let [userName, setuserName] = useState('');

  return (<div className="organisation-deletion-container">
    <SettingsContainerHeader title="Suppression d’organisation" subtitle="Supprimer votre organisation" />
    {errorMessage && 
    <div className='org-del-err-mes-btn'><ErrorMessage img='icons/error_outline.svg' label="Le nom d'utilisateur ou le mot de passe saisi est incorrect !" /></div>}
    <div className='organisation-deletion-authContainer'>
      <div className='organisation-deletion-auth'>
        <div className='organisation-deletion-usernameContent org-deletion-auth'>
          <Input
            // errorMessage={userNameErr}
            size="md"
            args={{
              placeholder: `Entrez votre nom d’utilisateur`, required: true, id: "userName", onChange: (e) => {
                setuserName(e.target.value);
                // setuserNameErr('');
                // if (e.target.value == '') {
                //   setuserNameErr("Nom d'utilisateur est nécessaire")
                // } else {
                //   let strRegex = new RegExp(/^[a-zA-Z0-9.]*$/);
                //   let result = strRegex.test(e.target.value);
                //   if (!result) {
                //     setuserNameErr("Renseignez nom d'utilisateur (sans espaces ni caractères spéciaux)")
                //   }
                // }
              }
            }}
            label="Nom d’utilisateur"
          ></Input>
        </div>
        <div className='organisation-deletion-passwordContent org-deletion-auth'>
          <Input
            errorMessage={passwordErr}
            args={{
              id: "password",
              type: showPassword ? "text" : "password", required: true, placeholder: 'Entrez votre mot de passe', onChange: (e) => {
                // setpasswordStrength(passwordStrengthValidator(e.target.value));
                setpassword(e.target.value);
                // setpasswordErr('');
                // if (e.target.value == '') {
                //   setpasswordErr("Mot de passe requis")
                // }
              }
            }}
            infoText=""
            label="Mot de passe"
            size="md"
          />
          {passwordErr == '' && <img className="eyeIcon org-deletion-eyeIcon" src={showPassword ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPassword(!showPassword)}></img>}
        </div>
        <div className='organisation-deletion-confirmPasswordContent org-deletion-auth'>
          <Input
            errorMessage={password2Err}
            args={{
              type: showPasswordConfirm ? "text" : "password", required: true, placeholder: 'Confirmez votre mot de passe', onChange: (e) => {
                setpassword2(e.target.value);
                setpassword2Err('');
                // if (e.target.value == '') {
                //   setpassword2Err("Confirmer mot de passe requis")
                // }
              }
            }}
            infoText=""
            label="Confirmer mot de passe"
            size="md"
          />
          {password2Err == '' && <img className="eyeIcon org-deletion-eyeIcon" src={showPasswordConfirm ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}></img>}
        </div>
      </div>
      <div className='organisation-deletion-btn'>
        <Button label='Supprimer organisation' leftIcon='delete' size='md' color='error' styling='outline' args={{disabled: userName.length > 0 && password.length > 0 && password2.length > 0 ? false : true, id: `${userName.length > 0 && password.length > 0 && password2.length > 0 ? `org-delete-undisable` : ``}`}} />
      </div>
    </div>
  </div>)
}

