import { useState, useEffect, useRef } from "react";
import "./appearance.css";
import { Input, Button,RadioButtons } from "kls-ui";
import { SettingsContainerHeader } from "../settings";
export function Appearance(props: any) {
  const [selectedColor, setSelectedColor] = useState(null);

  const setColor = (color) => {
    setSelectedColor(color);
  };
  return (
    <div className="theme-container">
      <SettingsContainerHeader title="Apparence" subtitle="Modifiez le thème et les couleurs selon vos préférences. Exprimez-vous à travers votre style !" />
      <div className="theme-mode">
      <RadioButtons label="" color="" onclick={()=>console.log('click')}/>

        <p className="theme-title2">Mode</p>
        <div className="theme-mode-color">
          <div className="auth-settings-apperance-div">
            <p className="auth-settings-apperance-type">Clair</p>
            {/* <Input size="xs" args={{ type: "radio", id: "clair", name: "theme-mode", className: "auth-settings-apperance-selectTheme" }} /> */}
            <label htmlFor="clair">
              <img src="photos/Mode-clair-theme.svg" alt="mode-clair" className="mode-img clair" />
            </label>
          </div>
          <div className="auth-settings-apperance-div">
            <p className="auth-settings-apperance-type somber-type">Sombre</p>
            {/* <Input size="xs" args={{ type: "radio", id: "sombre", name: "theme-mode", className: "auth-settings-apperance-selectTheme" }} /> */}
            <label htmlFor="sombre">
              <img src="photos/Mode-sombre-theme.svg" alt="mode-sombre" className="mode-img sombre" />
            </label>
          </div>
          <div className="auth-settings-apperance-div">
            <p className="auth-settings-apperance-type">Système</p>
            {/* <Input size="xs" args={{ type: "radio", id: "system", name: "theme-mode", className: "auth-settings-apperance-selectTheme" }} /> */}
            <label htmlFor="system">
              <img src="photos/Mode-system-theme.svg" alt="mode-system" className="mode-img system" />
            </label>
          </div>
        </div>
      </div>
      <div className="auth-settings-apperance-colorThemeContainer">
        <div className="auth-settings-apperance-colorTheme">
          <p className="auth-settings-apperance-colorTitle">Couleur</p>
          <div className="auth-settings-apperance-colorThemeRadio">

            <div className={`app-appearance-colorPicker appearance-color1 ${selectedColor === 'appearance-color1' ? 'selected-color-theme appearance-purple' : ''}`} onClick={() => setColor('appearance-color1')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color1" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color1" ? '#B27FF9' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-color2 ${selectedColor === 'appearance-color2' ? 'selected-color-theme appearance-blue' : ''}`} onClick={() => setColor('appearance-color2')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color2" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color2" ? '#2563EB' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-color3 ${selectedColor === 'appearance-color3' ? 'selected-color-theme appearance-green' : ''}`} onClick={() => setColor('appearance-color3')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color3" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color3" ? '#27C177' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-color4 ${selectedColor === 'appearance-color4' ? 'selected-color-theme appearance-sandal' : ''}`} onClick={() => setColor('appearance-color4')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color4" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color4" ? '#EFCD70' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-color5 ${selectedColor === 'appearance-color5' ? 'selected-color-theme appearance-orange' : ''}`} onClick={() => setColor('appearance-color5')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color5" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color5" ? '#F88149' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-color6 ${selectedColor === 'appearance-color6' ? 'selected-color-theme appearance-pink' : ''}`} onClick={() => setColor('appearance-color6')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color6" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color6" ? '#F764A4' : '' }}></div></div>
            <div className={`app-appearance-colorPicker appearance-color7 ${selectedColor === 'appearance-color7' ? 'selected-color-theme appearance-red' : ''}`} onClick={() => setColor('appearance-color7')}><div className="appearance-colorpicker" style={{ display: selectedColor === "appearance-color7" ? 'block' : 'none', backgroundColor: selectedColor === "appearance-color7" ? '#FF6868' : '' }}></div></div>
          </div>
        </div>
        <div className="auth-settings-apperance-dateContainer">
          <p className="auth-settings-apperance-colorTitle">Format de la date</p>
          <div className="auth-settings-apperance-dateRatio">
            <div className="auth-settings-apperance-dataRadio1">
              <Input size="xs" args={{ type: "radio", id: "date", name: "date-change" }} />
              <label htmlFor="date">
                <p className="auth-settings-apperance-dateChangeFont">jj-mm-aaaa</p>
              </label>
            </div>
            <div className="auth-settings-apperance-dataRadio1">
              <Input size="xs" args={{ type: "radio", id: "date-reverse", name: "date-change" }} />
              <label htmlFor="date-reverse">
                <p className="auth-settings-apperance-dateChangeFont">aaaa-mm-jj</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="app-appearance-btn">
        <Button size="md" color="primary" styling="solid" label="Enregistrer" args={{ style: { display: "flex", justifyContent: "flex-end" } }} />
      </div>
    </div>
  )
}