import { useEffect, useState } from "react"
import './menu.css';
import { WorkingDirTree } from "kls-commons/types/repo-model";
import  t from "kls-i18n"; 
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { NavLink } from "kls-ui/navLink/navLink";
import { CreationModal } from "kls-ui/creation-modal/creation-modal";
import { generateResourcesFromModel } from "../module/module.service";


export function ModelContentMenu( {moduleName, model, currentResource, handleChangeResource}:ModelContentMenuType ) {

    const [menuItems, setMenuItems] = useState<any[]>( [] ) ;
    const [createNew, setCreateNew] = useState<any>( "" ) ;
    const [clicked,setClicked] = useState(false);
    useEffect(
        () => {
            let menu = generateResourcesFromModel( model ) ;
            if( menu ) {
                setMenuItems( menu ) ;

                console.log("currentResource :currentResource :",currentResource, menu[0], menu[0]?.children?.[0] );

                if( !currentResource && menu[0]?.children?.[0] ){
                    handleChangeResource( { value:menu[0].children[0].resourceQN } )               
                }
            }
            console.log("menu:",menu);
        } , [model,clicked]
    );


    return (
        model?.name && menuItems ? <div className="menu-container">
                {
                    menuItems.map(
                        (menuItem,index) => <NavLink key={index} menuItem={menuItem} isSelected={menuItem.id===getResourceType(currentResource)} onClick={(e)=>{setClicked(!clicked);}} >

                                <AdvancedDropDown   data={menuItem.children.map(item => ({ value: item.resourceQN, label: item.label, icon: item.icon, color: item.color }))} 
                                                    light={true}
                                                    handleChange={(item)=>{handleChangeResource(item); console.log( "debuging advanced search : item ", item )}} 
                                                    size={3} 
                                                    enableSearch={true} 
                                                    addNew={menuItem.newElement?{ label: menuItem.newElement.label, handleClick: () => { setCreateNew(menuItem.id); } }:null} 
                                                    selectedValue={ currentResource }
                                                    style={{top:'60px', minWidth:'270px'}} />
                                
                        </NavLink>
                    )
                }
                {createNew && <CreationModal currentModel={model.name} currentModule={moduleName} handleChange={(item)=>handleChangeResource(item)} resourceType={createNew} onClose={()=>{setCreateNew("")}} />}
        </div> : <></>
    )
}

const getResourceType = ( currentResource:string ) => {
    if( currentResource.includes("view-") && currentResource.endsWith(".xml") ) {
        return "view" ;
    } else if( currentResource.endsWith(".bpmn") ) {
        return "process" ;
    } else if( currentResource.includes("taskList") ) {
        return "tasklist" ;
    } else if( currentResource.includes("/search/") ) {
        return "search" ;
    } else if( currentResource.includes("/reporting/") ) {
        return "report" ;
    } else if( currentResource.endsWith("-menu.xml") ) {
        return "configuration" ;
    } else if( currentResource.endsWith("-dynamiclists.xml") ) {
        return "configuration" ;
    } else if( currentResource.endsWith("/kconfig.xml") ) {
        return "configuration" ;
    }
}

interface ModelContentMenuType {
    moduleName:string,
    model:WorkingDirTree, 
    currentResource:string, 
    handleChangeResource:Function
}
