import "./course-details.css";
import "./home-page.css";
import { Hint } from "kls-ui"
import { useEffect, useState } from "react"
import { NewModelWizard } from "../module/new-model-wizard"
import { CourseDetails } from "./course-details"
import MyAppsPage from "./my-apps"
import { MyOrganization } from "kls-catalog";


export function Home(props: any) {
    useEffect(() => {
        !props.themeHandler && props.sethintType("")
    }, [props.themeHandler])
    return <div>

        <MyOrganization gs={props.gs} hintType={props.hintType}></MyOrganization>
        <div className="organizationSec">
            <MyAppsPage gs={props.gs} />
        </div>

        <CourseDetails />

        {props.themeHandler && props.hintType === "theme" ? <div className="theme-hint-main">
            <div className="theme-hint">
                <Hint
                    title="Choisissez le thème de votre espace de travail"
                    content="Vous pouvez choisir entre le mode sombre et le mode clair en utilisant l'icône en forme de soleil ou de lune située en haut à droite."
                    button="Suivant"
                    currentCount={1}
                    totalCount={9}
                    position="righttop"
                    close={props.themeHandler}
                    onClose={() => props.setthemeHandler(!props.themeHandler)}
                />
            </div>
        </div> : props.themeHandler && props.hintType === "orgData" ? <><div className="orgData-hint-main">
            <div className="orgData-hint">
                <Hint
                    title="Paramètres de l’organisation"
                    content='Vous pouvez compléter les informations de votre organisation en cliquant sur "Paramètres".'
                    button="Suivant"
                    currentCount={2}
                    totalCount={9}
                    position="left"
                    close={props.themeHandler}
                    onClose={() => props.setthemeHandler(!props.themeHandler)}
                />
            </div>
        </div></> : props.themeHandler && props.hintType === "users" ? <div className="orgData-hint-main">
            <div className="orgData-hint">
                <Hint
                    title="Ajouter des collaborateurs"
                    content='Vous pouvez ajouter des collaborateurs en cliquant sur "Paramètres", puis sur "Utilisateurs".'
                    button="Suivant"
                    currentCount={3}
                    totalCount={9}
                    position="left"
                    close={props.themeHandler}
                    onClose={() => props.setthemeHandler(!props.themeHandler)}
                />
            </div>
        </div> : props.themeHandler && props.hintType === "application" ? <div className="application-hint-main">
            <div className="application-hint">
                <Hint
                    title="Editer une application"
                    content='Vous pouvez éditer cette application à Vous pouvez regarder le tutoriel pour les étapes complètes.'
                    button="Terminer"
                    currentCount={9}
                    totalCount={9}
                    position="left"
                    close={props.themeHandler}
                    onClose={() => props.setthemeHandler(!props.themeHandler)}
                />
            </div>
        </div> : <></>
        }
    </div>
}


function creationWizard() {
    return (onOk: Function, onClose: Function) => <NewModelWizard onClose={onClose} />
}