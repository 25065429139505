import { createContext, useEffect, useRef, useState } from 'react'
import { ResourceContent, WorkingDirTree } from "kls-commons/types/repo-model";
import { getTenant } from 'kls-commons/service/resource-service';
import { fetchKlsResource, getWorkingDirectorTree, writeKlsResource } from 'kls-commons/service/repo-service';
import { GS, saveGlobaleState } from 'kls-commons/state/global-state';
import { useHistory, useParams } from 'react-router-dom';
import { ModelContentMenu } from '../menu/menu';
import { FormViewEditor } from "kls-view-editor/view-editor";  
import { FormSearchEditor } from "kls-search-editor/";
import { BpmnEditor } from "kls-bpmn-editor/process-editor/bpmn-editor"; 
import { Header } from '../layout/header';
import { LocalModelesSearch } from './module-navigation';
import { ParameterModule } from './module-parameter';
import { MenuEditor } from "kls-generic-editor/menu-editor";
import { KConfigEditor } from "kls-generic-editor/kconfig-editor";
import { menuEditorSchemaRoot } from "kls-generic-editor/menu-editor-schema";
import { configsEditorSchemaRoot } from "kls-generic-editor/kconfig-editor-schema";
import { onStartEdit } from './module.service';
import { Loading } from 'kls-ui';
import { ReportEditor } from 'kls-report-editor';
import { validationController } from '../validation/validation-controller';
import { ValidationResult } from '../validation/validation-result';
import ValidationPanel from '../validation/validation-panel';
import {ResourceCoordinate} from 'kls-validation/types/validation.type';

 
const emptyWDT : WorkingDirTree = {
  name: ''
}
const emptyResourceContent: ResourceContent = {
  content: ''
}

export default function ModuleEditor() {

    const {moduleName, modelName, resourceType, resourceName} = useParams() as any;  
    const [currentModel, setCurrentModel] = useState<WorkingDirTree>(emptyWDT);
    const [currentResource, setCurrentResource] = useState<string>("");
    const [resourceContent, setResourceContent] = useState(emptyResourceContent);
    const [loading, setLoading] = useState<boolean>(false);
    const [spotResource, setSpotResource] = useState<string>();

    const history = useHistory();
    
    const lunchResource = (resource:any, module:string, model:string) => {
      handleChangeResource( resource ) ;
      history.push( "/editService/" + module + '/' + model ) ;
    } 

    useEffect(
      () => {
        setLoading(true)

        const createResourceQN = ( module:string, model:string, resourceType:string, resourceName:string ):string => {
          return 'klsapp/' + getTenant() + '/' + module + '/' + model + '/' + (!resourceType || resourceType==='view'?'':(resourceType + '/')) + resourceName ;
        }

        console.log( 'resourceName : ' , moduleName, modelName, resourceType, resourceName )

        if( moduleName && modelName && resourceName ) {
          lunchResource( {value: createResourceQN(moduleName, modelName, resourceType, resourceName)}, moduleName, modelName )
        } 

        if( !resourceName ) {
          setCurrentResource( "" ) ;
        }

        if( !modelName ) {
          getWorkingDirectorTree(moduleName).then(
            (tree:WorkingDirTree) => {
              for(let k of Object.keys((tree.children)||{})) {
                  let c = tree?.children?.[k];
                  if(c && c.directory) {
                      history.push( "/editService/" + moduleName + '/' + c.name );
                      break;
                  }
              }
              setLoading(false) ;
            }
          ).catch(
            () => {
              setLoading(false)
            }
          )
        } else { 
          const timeout = setTimeout(() => {
            setLoading(false);
          }, 2000);
          
          return () => clearTimeout(timeout);
        }
      } , [moduleName, modelName, resourceType, resourceName]
    )

    useEffect(
      () => {
        getWorkingDirectorTree(moduleName).then(wdt=> {
          if(wdt.children && modelName) { 
                setCurrentModel(wdt.children[modelName]);
                console.log( "wdt.children[modelName] ", wdt, wdt.children[modelName] );
                GS.currentModuleName=moduleName;
                GS.currentModelName=modelName;
                saveGlobaleState(GS);
          }
        }) 
      } , [moduleName, modelName, resourceType, resourceName]
    )

    const onSaveResource = async (rc: ResourceContent) => {
      if (!rc || !rc.content || !rc.info) {
        return;
      }
      console.log("onSaveResource", rc);
      let validationResult : ValidationResult | null = await validationController.verifiyResource(rc, currentResource);
      if(validationResult && validationResult.error && validationResult.blocking) { 
          return;
      }
  
      if (rc.extraContent) {
        Object.keys(rc.extraContent).forEach(k => {
          let ec = rc.extraContent![k];
          let path = rc.info!.filePath.replace(rc.info!.fileName, k);
          writeKlsResource(GS.currentModuleName, path, ec);
        })
      }
      return await writeKlsResource(GS.currentModuleName, rc.info.filePath, rc.content);
    }

    const onSpotValidationError = (resourceLocation: ResourceCoordinate ) => {
      console.log("onSpotValidationError",resourceLocation);
      if(resourceLocation.resourceBundlePath !== currentResource)
          handleChangeResource(resourceLocation.resourceBundlePath, resourceLocation.resourcePath);
      else 
          setSpotResource(resourceLocation.resourcePath);
    }


    const handleChangeResource = async (resource:any, spot?:any) => {
      console.log( "debuging handleChangeResource ", resource )
      setCurrentResource( resource.value ) ;
      let updatedRc:ResourceContent|undefined = await onStartEdit( GS.currentModuleName, resource.value ) ; 
      if( updatedRc ){
        setResourceContent( updatedRc ) ;
        if(spot)
            setSpotResource(spot);
        console.log("DBGX FETCH END "+ resource);

      }
    }
    

    return <>

        <Header>
            <div style={headerContent}>
              <LocalModelesSearch module={moduleName} model={modelName} setCurrentResource={setCurrentResource}/>
              {   currentModel && <ModelContentMenu moduleName={moduleName} model={currentModel} currentResource={currentResource} handleChangeResource={handleChangeResource}/>   }
              <ParameterModule module={moduleName}/>
            </div>
        </Header>

        {
            loading && <Loading message='Nous configurons votre application...'/>
        }

        {
            currentResource &&
            <div className="service-editor">
                <div className="file-editor">
                    {currentResource.endsWith("-menu.xml") &&  !currentResource.includes("/view-") && <MenuEditor onSave={onSaveResource} rubrique={currentResource} resourceContent={resourceContent} schema={menuEditorSchemaRoot}></MenuEditor>} 

                    {currentResource.endsWith("config.xml") && !currentResource.includes("/view-") && <KConfigEditor onSave={onSaveResource} rubrique={currentResource} resourceContent={resourceContent} schema={configsEditorSchemaRoot}></KConfigEditor>} 

                    {currentResource.includes("/search/") &&  <FormSearchEditor  onSave={onSaveResource}    resourceContent={resourceContent} ></FormSearchEditor>}
                    {currentResource.includes("/taskList") && currentResource.endsWith(".xml") &&  <FormViewEditor  onSave={onSaveResource}    resourceContent={resourceContent} spotXpath={spotResource} ></FormViewEditor>}

                    {currentResource.endsWith(".bpmn") &&  <BpmnEditor  onSave={onSaveResource}    resourceContent={resourceContent}        ></BpmnEditor>}
                    {currentResource.includes("/reporting/") &&  <ReportEditor  onSave={onSaveResource}    resourceContent={resourceContent}        ></ReportEditor>}

                    {(currentResource.endsWith(".xml") && currentResource.includes("/view-")) &&  <FormViewEditor onSave={onSaveResource}    resourceContent={resourceContent} spotXpath={spotResource} ></FormViewEditor>}   
                </div>
            </div>
        }

        {/* <div className='validation-panel-container'>
          <ValidationPanel activeRubrique={currentResource} activeModule={modelName} onMoveTo={onSpotValidationError}></ValidationPanel>
        </div> */}

    </>
}

const headerContent = {
  display:'flex', 
  justifyContent:'space-between', 
  alignItems: 'center', 
  flexGrow: 1, 
  gap: "20px"
}