import t from 'kls-i18n';
import { Dropdown } from 'kls-ui';
import {Button} from 'kls-ui/Button/Button' ;
import {IconButton} from 'kls-ui/Button/IconButton' ;
import { AdvancedDropDown } from 'kls-ui';
import { useState } from 'react';
import VersionWizar from '../publication/change-version';
import PublicationWizar from '../publication/publication';


export function ParameterModule( {module}:{module:string} ) {

    const publicationList = [
        { label : "Publier en production", value : 'prod' },
        { label : "Publier en pré-production", value : 'preprod'},
        { label : "Revenir à une version antérieure", value : 'change-version' }
    ] ;

    const [showDeliverList, setShowDeliverList] = useState<boolean>( false ) ;
    const [wizardToShow, setWizardToShow] = useState<string>( '' ) ;
    
    return <div style={{display: "flex", gap: "8px"}}>

        { wizardToShow === 'change-version' && <VersionWizar moduleName={module} onClose={()=>{setWizardToShow('')}} />}
        { ['prod','preprod'].includes(wizardToShow) && <PublicationWizar moduleName={module} toProd={wizardToShow==='prod'} onClose={()=>{setWizardToShow('')}} />}
        
        <IconButton
            color="neutral"
            icon="more_vert"
            size="md"
            styling="solid" 
            args={{onClick: () => {console.log( "more_vert" )}}}
        />

        <div className='list-actions-deliver' style={ {position:'relative'} }>
            <Button args={
                        { 
                            onClick: () => {  setShowDeliverList( true )  }, 
                            style: { padding: '12px' } 
                        }
                    } 
                    color="primary" 
                    label={t("Publier")} styling="solid" 
                    size={'xs'}
                    rightIcon='expand_more' />
            
            {
                showDeliverList && 
                <AdvancedDropDown   data={publicationList} enableSearch={false} light={true}
                                    handleChange={ (action:any) => setWizardToShow( action?.value ) } 
                                    size={2} 
                                    selectedValue={ "" }
                                    isLunchAction={true}
                                    style={{top:'52px', right:'0px'}} 
                                    closeWhenSelect={() => {  setShowDeliverList( false )  }}
                                    closeWhenClickOutside={() => {  setShowDeliverList( false )  }}/>
            }
        </div>

    </div>
}
