import { useEffect, useRef, useState } from "react";
import '../../app-params/param-app.css'
import React from 'react';
import { Input, Modal, Tabs, addToast } from 'kls-ui';
import { ModalDelete } from 'kls-ui/modal/ModalDelete';
import { SelectTabs } from 'kls-ui/selectTabs/SelectTabs';
import TreeElementsModules from 'kls-ui/treeModules/TreeElementsModules';
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";
import { Model } from "kls-commons/types/application-model";
import { useLocation,useParams } from "react-router-dom";
import { fetchKlsResource, getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { SouchesTable } from 'kls-ui/table/table';
import { SearchInput } from "kls-ui/data entry/search-input";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { currentUser } from "kls-commons/service/userService";
import localforage from "localforage";
import { createRole, deleteRole, getAllRoles, getDetailsRole } from "../../app-params/role-service";
import { getAppsDetails } from "kls-catalog/my-applications/myapps.service";
import { group } from "console";
import { OrganizationSettingsService } from "../organization-settings-service";


interface TreeNode {
  id: number;
  name: string;
  isOpen: boolean;
  children: TreeNode[];
  checked?: boolean;
  display?: boolean;

}
export function Strains(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [displayNode, setDisplayNode] = useState(false);
  const [soucheName, setSoucheName] = useState('');
  const [helpText, setHelpText] = useState('');
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const [activeTabEdit, setActiveTabEdit] = useState<string>('general');
  const { moduleName } = useParams() as any;
  const [appObj, setAppObj] = useState<WorkingDirTree>();
  const [direc, setDirec] = useState<string[]>([]);
  const [tr, setTr] = useState<TreeNode[]>([]);
  const [trRessource, setTrRessource] = useState<TreeNode[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 5;
  const [codeInputs, setCodeInputs] = useState<string[]>(["", "", "", "", "", ""]);
  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageValidation, setErrorMessageValidation] = useState<string>('');
  const [currentInputIndex, setCurrentInputIndex] = useState<number>(0);
  const [isInputModified, setIsInputModified] = useState(false);
  const [souchesData, setSouchesData] = useState<{ souche: string; profils: number; ressources: number; utilisateurs: number; }[]>([]);
  const [soucheBackData, setSouchesBackData] = useState<{ souche: string; profils: number; ressources: number; utilisateurs: number; dateCreation: string; }[]>([]);
  const [randomNumber, setRandomNumber] = useState('');
  const [myOrganisationName,setMyOrganisationName] = useState('');
  const location = useLocation();

  const [soucheNameError, setSoucheNameError] = useState('');
  const [rolesData, setRolesData] = useState<TreeNode[]>([]);
  const [aboutData, setAboutData] = useState<any>({
    creationDate: '05/01/2024 22:15',
    lastModification: '10/01/2024 08:44',
    numberOfUsers: 15
  });


  const [treeData, setTreeData] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Module : Gestion des demandes de projets',
      isOpen: false,
      checked: false,
      children: [
        {
          id: 2,
          name: 'Processus : Demande de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 3,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 4,
              name: 'Évaluation Initiale',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 5,
              name: 'Vérification Budgétaire',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 6,
              name: 'Examen Juridique',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 7,
              name: 'Préparation de la Proposition',
              isOpen: false,
              checked: false,
              children: []
            }
          ]
        },
        {
          id: 8,
          name: 'Processus : Correction de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 14,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
          ]
        },
        {
          id: 9,
          name: 'Processus : Annulation de projet',
          isOpen: false,
          checked: false,
          children: [
            {
              id: 10,
              name: 'Initiation de la Demande',
              isOpen: false,
              checked: false,
              children: []
            },
            {
              id: 11,
              name: 'Examen du comité',
              isOpen: false,
              checked: false,
              children: []
            }
          ]
        }
      ]
    },
    {
      id: 12,
      name: 'Gestion des ventes',
      isOpen: false,
      checked: false,
      children: [
        {
          id: 13,
          name: 'Processus : Demande de vente',
          isOpen: false,
          checked: false,
          children: []
        }
      ]
    }
  ]);
  const [treeDataResource, setTreeDataResource] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Module : Gestion des demandes de projets',
      isOpen: false,
      children: [
        {
          id: 2,
          name: 'Processus',
          isOpen: false,
          children: [
            {
              id: 3,
              name: 'Demande de projet',
              isOpen: false,
              children: []
            },
          ]
        },
        {
          id: 4,
          name: 'Tasklist',
          isOpen: false,
          children: [

            {
              id: 5,
              name: 'Initiation de la Demande',
              isOpen: false,
              children: []
            },
            {
              id: 6,
              name: 'Vérification Budgétaire',
              isOpen: false,
              children: []
            },

          ]
        },
        {
          id: 7,
          name: 'Recherches',
          isOpen: false,
          children: [
            {
              id: 8,
              name: 'Liste de toutes les demandes reçues',
              isOpen: false,
              children: []
            },
            {
              id: 9,
              name: 'Liste des demandes reçues - Statut = Urgent',
              isOpen: false,
              children: []
            }
          ]
        }
      ]
    },

  ]);
  const [rolesStatiqueData, setRolesStatiqueData] = useState<TreeNode[]>([
    {
      id: 1,
      name: 'Application : Gestion des demandes de projets',
      isOpen: false,
      children: [
        {
          id: 2,
          name: 'Demandeur de projet ',
          isOpen: false,
          children: []
        },
        {
          id: 3,
          name: 'Controleur de gestion',
          isOpen: false,
          children: []
        },
        {
          id: 4,
          name: 'Gestionnaire des opérations',
          isOpen: false,
          children: [
          ]
        },
        {
          id: 5,
          name: 'Auditeur de conformité',
          isOpen: false,
          children: [
          ]
        }
      ]
    },
  ]);

  const [soucheDataStatique, setSoucheDataStatique] = useState<any[]>([
    {
      souche: 'souche-1',
      profils: 5,
      ressources: 10,
      utilisateurs: 20,
    },
    {
      souche: 'souche-2',
      profils: 3,
      ressources: 8,
      utilisateurs: 15,
    },
    {
      souche: 'souche-3',
      profils: 3,
      ressources: 8,
      utilisateurs: 15,
    },
  ]);
  const sortedSouchesBackData = [...soucheBackData].sort((a, b) => {
    return new Date(b.dateCreation).getTime() - new Date(a.dateCreation).getTime();
  });

  const filteredSouchesData = sortedSouchesBackData.filter(r =>
    r && r.souche.toLowerCase().includes(filter.toLowerCase())
  );


  useEffect(() => {
    const cleanedAndFormattedName = cleanAndFormatNameResource(soucheName);
    setHelpText(`ID : ${cleanedAndFormattedName}`);
  }, [soucheName]);


  useEffect(() => {
    getWorkingDirectorTree(moduleName).then(
      (tree: WorkingDirTree) => {
        setAppObj(tree);
      }
    )
    listModules().then(
      modules => {
        setDirec(modules);
      }
    )

  }, [moduleName])

  useEffect(() => {
    console.log("appObjet structure", appObj);

  }, [appObj])

  useEffect(() => {
    setAllInputsFilled(codeInputs.every(input => input !== ''));
  }, [codeInputs]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);
  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };
  const handlePageChange = (start: number, end: number) => {
    setCurrentPage(start / itemsPerPage + 1);
  };

  ////////////////////////////////////////////////////////////////////

  const parseXmlMenu = (xmlContent: string): any[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xmlContent, "application/xml");
    const elements = Array.from(doc.documentElement.children);
    return elements.map(parseElement);
  };

  const parseResource = (resourceElement: Element): any => {
    return {
      type: resourceElement.getAttribute("type"),
      content: resourceElement?.textContent?.trim()
    };
  };
  const parseElement = (element: Element): any => {
    const tagName = element.tagName;
    if (tagName === "menu") {
      return parseMenu(element);
    } else if (tagName === "resource") {
      return parseResource(element);
    }
  }
  const parseMenu = (menuElement: Element): any => {
    const id = menuElement.getAttribute("relId" || "absId");
    const label = menuElement.getAttribute("label");
    const childrenElements = Array.from(menuElement.getElementsByTagName("menu"));
    const resourcesElements = Array.from(menuElement.getElementsByTagName("resource"));
    const children = childrenElements.map(parseMenu);
    const resources = resourcesElements.map(parseResource);

    return { id, label, children, resources };
  };
  const getActivitiesFromXml = (xml) => {
    const activities = Array();
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "application/xml");
    Array.from(doc.getElementsByTagName("bpmn2:userTask")).map((task) => {
      const id = task.getAttribute("id");
      const name = task.getAttribute("name");
      const mask = task.getAttribute("tns:screenMask");
      activities.push({ id, name, mask });
    });

    return activities;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////



  const handleNouvelleSoucheClick = () => {
    setShowModal(true);

  };
  const handleSoucheNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSoucheName(event.target.value);
    setIsInputModified(true);
  };
  const resetCheckedNodes = (nodes) => {
    return nodes.map(node => {
      if (node.checked) {
        node.checked = false;
        console.log("the node is checked", node)
        resetCheckedNodes(node.children);
        return { ...node, checked: false };
      } else if (node.children && node.children.length > 0) {
        return { ...node, children: resetCheckedNodes(node.children) };
      } else {
        return node;
      }
    });
  };

  const resetProfileTreeCheckedNodes = () => {
    const updatedProfileTree = resetCheckedNodes(tr);
    setTr(updatedProfileTree);
  };
  const resetResourceTreeCheckedNodes = () => {

    const updatedResourceTree = resetCheckedNodes(trRessource);
    setTrRessource(updatedResourceTree);
  };
  const closeModal = () => {
    setShowModal(false);
    setShowModalEdit(false);
    setActiveTabEdit('general');
    setIsInputModified(false);
    setSoucheName('');
    resetProfileTreeCheckedNodes();
    resetResourceTreeCheckedNodes();
    setShowSelectedOnly(false);
    setShowSelectedOnlyResources(false);


  };
  const closeModalDelete = () => {
    setShowModalDelete(false);
    setErrorMessage('');
  };
  
  const handleDeleteButtonClick = (enteredCode: string) => {
   
    console.log('random number:', randomNumber);
    if (enteredCode !== randomNumber) {
      setErrorMessage("Le code saisi n'est pas valide. Essayez-le à nouveau !");
    } else {
      //const updatedRolesData = rolesData.filter(grp => grp.Souche !== SoucheNameDeleted);
      const updatedRolesDataBack = soucheBackData.filter(grp => grp.souche !== soucheNameDeleted);
      
     deleteRole("AB90G-BH903-W4EE1-Z66Q9-907E6",currentUser().userId,soucheNameDeleted).then(() => {
    //  fetchDataRoles();
     }).catch(error => {
       console.log(' deleting role:', error);
     });
     
     // setRolesData(updatedRolesData);
      setShowModalDelete(false);
      setErrorMessage('');
      //setSouchesBackData(updatedRolesDataBack);
      //fetchDataRoles();
      addToast(' La souche a été supprimé avec succès', 'success');
    }
  };
  const fetchData = async () => {
    try {

      const fetchDataResult = await OrganizationSettingsService(currentUser().userId, location.pathname.split("/")?.[2]);
    //  console.log("fetchDataResult", fetchDataResult.data);
     setMyOrganisationName(fetchDataResult.data.name);
      // console.log("MyOrganisationName", myOrganisationName);
    
    
    }
    catch (error) {
      console.error("Error fetching organization settings:", error);
    }
  }
  useEffect(() => {
    fetchData();
    // console.log("myOrganisationName inside useEffect", myOrganisationName);
  })
  const handleTabsSelect = (tabName: string) => {
    setActiveTab(tabName);
  }
  const handleTabsEdit = (tabName: string) => {
    setActiveTabEdit(tabName);
  }
  const handleTabChange = (tabName: string) => {
    if (tabName === 'edit') {
      setShowModalEdit(true);
      setShowModal(true);

    }
    else if (tabName === 'delete') {
      setShowModalEdit(false);
      setShowModalDelete(true);

    }
    else if (tabName === 'duplicate') {
      setShowModal(true);

    }


  };

  const toggleChildren = (parentId: number) => {
    setTr(prevData =>
      prevData.map(node => {
        if (node.id === parentId) {
          return { ...node, isOpen: !node.isOpen };
        } else {
          return node;
        }
      })
    );
  };


  const handleCheckboxChangeRoles = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(mygroupes, childId, checked);
    setMygroupes(updatedTreeData);
  };
  const handleCheckboxChange = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(tr, childId, checked);
    setTr(updatedTreeData);
  };

  const handleCheckboxChangeResource = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(trRessource, childId, checked);
    setTrRessource(updatedTreeData);
  };

  const updateCheckedState = (nodes: TreeNode[], childId: number, checked: boolean): TreeNode[] => {
    return nodes.map(node => {
      if (node.id === childId) {
        return { ...node, checked: checked };
      } else if (node.children && node.children.length > 0) {
        const updatedChildren = updateCheckedState(node.children, childId, checked);
        const someChildrenChecked = updatedChildren.some(child => child.checked);
        const allChildrenChecked = updatedChildren.every(child => child.checked);
        return {
          ...node,
          checked: someChildrenChecked ? true : false,
          indeterminate: someChildrenChecked && !allChildrenChecked ? true : false,
          children: updatedChildren
        };
      } else {
        return node;
      }
    });
  };
  const [mygroupes, setMygroupes] = useState<TreeNode[]>([]);

  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        const roles = await getAllRoles("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, currentUser().email);
        console.log("Roles data in groups:", roles);
        
        const myLocalEntity = (currentUser().entity as string).split("/kls/")[1].split("/dev")[0];
        console.log("myLocalEntity:", myLocalEntity);
        const formattedRoles = roles.data
          .filter(role => {
            const entityParts = role['Entité'].split('/');
            const entityOrganisation = entityParts[2] || '';
            const entityLocal = entityParts[1] || '';
            console.log("splithhh",entityOrganisation,"[[]]",myOrganisationName);
            console.log("splitkkk",entityLocal,"[[]]",myLocalEntity);
            
            return role.Nom.trim() !== '' && entityOrganisation === myOrganisationName ;
          })
          .map((role, index) => ({
            id: index + 1,
            name: role.Nom || role.Description,
            isOpen: false,
            children: []
          }));
  
        setMygroupes(formattedRoles);
  
        console.log("mygroupes:", formattedRoles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
  
    fetchRolesData();
  }, [myOrganisationName]);
  

  useEffect(() => {
    console.log("mygroupes from use:", mygroupes,myOrganisationName);
  }, [myOrganisationName]);
  const [allModules, setAllModules] = useState<WorkingDirTree[]>([]);

  useEffect(
    () => {
      getAppsDetails(null).then((apps) => { setAllModules(apps) })
    }, []
  )
  useEffect(() => {
    console.log("allModules from use:", allModules);
    const uniqueAppNames = new Set();

    allModules.map((app) => {
      if (!uniqueAppNames.has(app.name)) {
        console.log("appname", app.name)
        var moduleIdCounter = 1;

        getWorkingDirectorTree(app.name).then(
          (trees: WorkingDirTree) => {
            // setAppObj(tree);
            console.log("tree from grp", trees);
            const appNode: TreeNode = {
              id: moduleIdCounter++,
              name: `Application: ${app.name}`,
              isOpen: false,
              children: [],
            };

            const fetchDataProfilesSouches = async () => {
              try {
                const processModulesChildren = (modulesChildren: any) => {
                  const tree: TreeNode[] = [];

                  //console.log("modulesChildreLog", modulesChildren)
                  for (const m in modulesChildren) {
                    if (!m.endsWith(".json")) {
                      const modeleName = m.split(".")[0];
                      const moduleChildren = modulesChildren[m]?.children;
                      const moduleNode: TreeNode = {
                        id: moduleIdCounter++,
                        name: `Module : ${modeleName}`,
                        isOpen: false,
                        children: [],
                      };
                      const processChildren = moduleChildren?.process?.children;
                      //  console.log("ProcessLOG", processChildren);
                      for (const c in processChildren) {
                        if (c.endsWith(".bpmn")) {
                          const processName = c.split(".")[0];
                          const processNode: TreeNode = {
                            id: moduleIdCounter++,
                            name: `Processus : ${processName}`,
                            isOpen: false,
                            children: [],
                          };
                          moduleNode.children.push(processNode);
                          let activities: any[] = [];
                          const processFilePath = trees?.children?.[modeleName].children?.process?.children?.[`${processName}.bpmn`]?.info?.filePath ?? "";
                          // console.log("processFilePath ", modeleName, processFilePath);
                          fetchKlsResource(app.name, processFilePath)
                            .then((resource) => {
                              // console.log("resource tttttt for", resource, "Process", processName);
                              activities = getActivitiesFromXml(resource.content ?? "");
                              // console.log("resresres",resource.content);
                              //console.log("activities ggg", activities);
                              //  console.log(`Activities for process work ${processName}:`, activities);
                              for (const activity of Object.values(activities)) {
                                //    console.log(`Activity afficher : ${activity.name}`);
                                processNode.children.push({
                                  id: moduleIdCounter++,
                                  name: `Activite : ${activity.name}`,
                                  isOpen: false,
                                  children: [],
                                });
                              }
                            })
                            .catch((error) => {
                              console.error(`Error fetching activities for process ${processName}:`, error);
                            });
                        }
                      }

                      if (moduleNode.children.length > 0) {
                        appNode.children.push(moduleNode);
                      }
                    }
                  }
                  if (appNode.children.length > 0) {
                    tree.push(appNode);

                  }

                  return tree;
                };

                // const treeDaaBack = processModulesChildren(trees?.children);
                // setTr(prevData=>{
                //   return [...prevData, ...treeDaaBack];
                // });
                // setTr(treeDaaBack);
                const treeDaaBack = processModulesChildren(trees?.children);
                setTr(prevData => {
                  const existingIds = new Set(prevData.map(node => node.name));
                  const newData = treeDaaBack.filter(node => !existingIds.has(node.name));
                  const newDataWithIds = newData.map(node => ({ ...node, id: moduleIdCounter++ }));
                  // Add only the nodes that don't already exist
                  return [...prevData, ...newDataWithIds];
                });



                console.log("treeDaaBackkk", treeDaaBack);
                console.log("myTr", tr)




                //setTrRessource([appNodeResource]);
              } catch (error) {
                console.error('Error fetching data nana:', error);

              }
            };

            fetchDataProfilesSouches();
            uniqueAppNames.add(app.name);
          }
        )
      }
    })
  }, [allModules]);
  useEffect(() => {
    console.log("allModules from use:", allModules);
    const uniqueAppNamesR = new Set();

    var moduleIdCounters = 1;

    const fetchMenusForAllApps = async () => {
      try {
        let menuNodesAccumulated: TreeNode[] = [];

        for (const app of allModules) {
          console.log("appname", app.name);

          const trees: WorkingDirTree = await getWorkingDirectorTree(app.name);

          const processMenusForApp = async (modulesChildrenRessources: any) => {
            const menuNodes: TreeNode[] = [];
            for (const moduleName in modulesChildrenRessources) {
              if (!moduleName.endsWith(".json")) {
                const moduleChildren = modulesChildrenRessources[moduleName]?.children;
                const resourcesNodes: Set<string> = new Set();
                await processMenus(moduleChildren, menuNodes, resourcesNodes, new Set<string>());
              }
            }
            return menuNodes;
          };

          const processMenus = async (menus: any, menuNodes: TreeNode[], processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode) => {
            if (!menus) return;
            for (const menuName in menus) {
              if (menuName.endsWith("-menu.xml")) {
                const menuFilePath = menus[menuName]?.info?.filePath;
                try {
                  const resource = await fetchKlsResource(app.name, menuFilePath);
                  const xmlContent = resource?.content ?? '';
                  const parsedMenus = parseXmlMenu(xmlContent);
                  for (const menu of parsedMenus) {
                    const menuNode = await buildMenuTree(menu, processedMenuIds, processResourcesIds);
                    if (menuNode) {
                      menuNodes.push(menuNode);
                    }
                  }
                } catch (error) {
                  console.error(`Error fetching resources for menu ${menuName}:`, error);
                }
              }
            }
          };

          const buildMenuTree = async (menu: any, processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode): Promise<TreeNode | null> => {
            if (processedMenuIds.has(menu.label)) {
              for (const resource of menu.resources) {
                if (processResourcesIds.has(resource.content)) {
                  return null;
                }
              }
            }

            const menuNode: TreeNode = {
              id: moduleIdCounters++,
              name: menu.label,
              isOpen: false,
              children: [],
            };

            processedMenuIds.add(menu.label);
            const menuChildren = menu.children || [];
            for (const childMenu of menuChildren) {
              const childNode = await buildMenuTree(childMenu, processedMenuIds, processResourcesIds, menuNode);
              if (childNode) {
                menuNode.children.push(childNode);
              }
            }

            if (menuChildren.length === 0 && menu.resources) {
              for (const resource of menu.resources) {
                if (!resource) {
                  setDisplayNode(false);
                }
                processResourcesIds.add(resource.content);
                const resourceNode: TreeNode = {
                  id: moduleIdCounters++,
                  name: `Resource:${resource.content} Type: ${resource.type}`,
                  isOpen: false,
                  children: [],
                  display: false
                };
                menuNode.children.push(resourceNode);
              }
            }

            return menuNode;
          };

          const menuNodesForApp = await processMenusForApp(trees?.children);

          const appNodeWithMenus: TreeNode = {
            id: moduleIdCounters++,
            name: `Application: ${app.name}`,
            isOpen: false,
            children: menuNodesForApp,
          };
          menuNodesAccumulated.push(appNodeWithMenus);
        }

        setTrRessource(menuNodesAccumulated);
        console.log("myTrRes", trRessource)
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenusForAllApps();
  }, [allModules]);

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [showSelectedOnlyRoles, setShowSelectedOnlyRoles] = useState(false);
  const [showSelectedOnlyResources, setShowSelectedOnlyResources] = useState(false);
  const generateRandomNumber = () => {
    const randomNum = Math.floor(100000 + Math.random() * 900000);
    setRandomNumber(randomNum.toString());
  };
  const renderContent = () => {
    let filteredTreeData = tr;
    if (showSelectedOnly) {
      filteredTreeData = filterCheckedNodes(tr);
    }
    let filteredTreeDataResource = trRessource;
    if (showSelectedOnlyResources) {
      filteredTreeDataResource = filterCheckedNodes(trRessource);
    }
    let filteredTreeDataRoles = mygroupes;
    if (showSelectedOnlyRoles) {
      filteredTreeDataRoles = filterCheckedNodes(mygroupes);
    }
    switch (activeTab) {

      case 'tab1':
        const totalChildrenRoles = countLastChildren(mygroupes);
        const checkedChildrenRoles = countCheckedChildren(mygroupes);

        return mygroupes.length > 0 ? (
          <>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildrenRoles}/{totalChildrenRoles} roles alloués
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnlyRoles(!showSelectedOnlyRoles);

                }}>
                {showSelectedOnlyRoles ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataRoles}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeRoles}
              nameTab=""
            /></>
        ) : (
          <div style={{ color: '#94A3B8', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>Aucun résultat</div>
        );
      case 'tab2':
        const totalChildren = countLastChildren(tr);
        const checkedChildren = countCheckedChildren(tr);
        console.log("myTr inside render", tr)

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildren}/{totalChildren} activités allouées
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnly(!showSelectedOnly);

                }}>
                {showSelectedOnly ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeData}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChange}
              nameTab="profiles"
            />
          </div>
        );
      case 'tab3':
        const totalChildrenR = countLastChildren(trRessource);
        const checkedChildrenR = countCheckedChildren(trRessource);
        console.log("myTrRessource inside render", trRessource)

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildrenR}/{totalChildrenR} ressources allouées
              </span>
              <span className="text-select-tree" onClick={() =>
                setShowSelectedOnlyResources(!showSelectedOnlyResources)
              }>
                {showSelectedOnlyResources ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataResource}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeResource}
              nameTab="ressources"
            />
          </div>
        ); default:
        return null;
    }
  };
  function MoreActions({ actions, closeCallback, style }: { actions: any, closeCallback?: Function, style?: React.CSSProperties }) {
    const moreOptions = useRef<HTMLDivElement>(null);
    const listObjActions = [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },

      {
        label: 'Dupliquer',
        value: 'duplicate',
        icon: 'content_copy',
        color: '#64748B',
        handleClick: actions?.['duplicate']
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: '#EF4444',
        handleClick: actions?.['delete']
      },

    ]

    return <div ref={moreOptions}>
      <AdvancedDropDown data={listObjActions} enableSearch={false}
        light={true}
        handleChange={(item) => { item?.handleClick?.() }}
        size={1}
        selectedValue={''}
        style={style}
        closeWhenClickOutside={closeCallback}
        closeWhenSelect={closeCallback} />
    </div>
  }
  const [soucheNameDeleted, setSoucheNameDeleted] = useState('');

  const handleDeleteOnTable = (soucheDeleted) => {
    setShowModalEdit(false);
    setShowModalDelete(true);
    setSoucheNameDeleted(soucheDeleted);
    generateRandomNumber();


  }
  const handleDuplicateOnTable = (soucheDuplicated) => {
    setShowModal(true);
    const traverseAndUpdate = (node) => {
      if (node.name === "Activite : Creer Article") {
        node.checked = true;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdate(child);
        });
      }
    };

    tr.forEach(node => {
      traverseAndUpdate(node);
      let updatedTreeData = updateCheckedState(tr, node.id, node.checked || false);
      setTr(updatedTreeData);
    });
    const traverseAndUpdateR = (node) => {
      if (node.name.includes("autorisations/articleae/process/GestionArticleAEProcess") || node.name === "Resource:autorisations/renouvellementodp/process/GestionRenouvellementODPProcess Type: launchProcess") {
        node.checked = true;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdateR(child);
        });
      }
    };
    trRessource.forEach(node => {
      traverseAndUpdateR(node);
      let updatedTreeDataResources = updateCheckedState(trRessource, node.id, node.checked || false);
      console.log("updatedTreeDataResources", updatedTreeDataResources);
      setTrRessource(updatedTreeDataResources);
    });

    // createSoucheObject(soucheName);


  }
  function AppTable({ servicesList }) {

    const data = servicesList.map((s) => {
      return {
        souche: s?.souche || '',
        profils: s?.profils || '',
        ressources: s?.ressources || '',
        utilisateurs: s?.utilisateurs || '',
        actions: <MoreActions
          actions={{
            'edit': () => handleEditOnTable(s?.souche),
            'delete': () => handleDeleteOnTable(s?.souche),
            'duplicate': () => handleDuplicateOnTable(s?.souche)
          }}
          style={{ cursor: 'pointer', marginTop: '30px', }}
        />
      }
    })

    return <SouchesTable data={data} />
  }
  const filterCheckedNodes = (nodes: TreeNode[]): TreeNode[] => {
    let filteredNodes: TreeNode[] = [];
    nodes.forEach(node => {
      if (node.checked) {
        filteredNodes.push({
          ...node,
          children: node.children ? filterCheckedNodes(node.children) : []
        });
      }
    });
    return filteredNodes;
  };
  const countLastChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0) {
        count++;
      } else {
        count += countLastChildren(node.children);
      }
    });
    return count;
  };

  const countCheckedChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0 && node.checked) {
        count++;
      } else if (node.children.length > 0) {
        count += countCheckedChildren(node.children);
      }
    });
    return count;
  };

  const getLastCheckedNodeNames = (node, names: string[] = []) => {
    if (node.children && node.children.length > 0) {
      const lastCheckedChildren = node.children.filter(child => child.checked);
      if (lastCheckedChildren.length > 0) {
        lastCheckedChildren.forEach(child => {
          getLastCheckedNodeNames(child, names);
        });
      }
    } else if (node.checked) {
      names.push(node.name);
    }
    return names;
  };
  const getLastCheckedNodeNamesN = (node, namesN: TreeNode[] = []) => {
    if (node.children && node.children.length > 0) {
      const lastCheckedChildren = node.children.filter(child => child.checked);
      if (lastCheckedChildren.length > 0) {
        lastCheckedChildren.forEach(child => {
          getLastCheckedNodeNamesN(child, namesN);
        });
      }
    } else if (node.checked) {
      namesN.push(node);
    }
    return namesN;
  };

  const fetchDataGroupes = async () => {
    try {
      const dataRoles = await getAllRoles("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, currentUser().email).catch((error) => {
        console.error("Error fetching data:", error);
      });
      console.log("dataRoles", dataRoles);
      setSouchesBackData([]);
      await Promise.all(dataRoles.data.map(async (role) => {
        console.log("role name:", role.Nom);
        if (role.Nom && typeof role.Nom === 'string' || role.Nom.trim() !== '') {
          let temporaryRPermission = 0;
          let temporaryProfiles = 0;
          try {
            const DataDetailsRoles = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, role.Nom).catch((error) => {
              console.error("Error fetching data:", error);
            });
            console.log("DataDetailsRoles here", DataDetailsRoles);
            try {

              const permissions = JSON.parse(DataDetailsRoles.Permission);
              console.log("permissions", permissions);
              console.log("Number of permissions:", role.Nom, permissions.length);
              temporaryRPermission = permissions.length;

              const profileDetails = JSON.parse(DataDetailsRoles.Profile);
              console.log("profileDetails", profileDetails);
              console.log("Number of profiles:", role.Nom, profileDetails.length);
              temporaryProfiles = profileDetails.length;
            } catch (error) {
              console.error("Error parsing JSON data:", error);
            }
            // setSouchesBackData(prevData => [...prevData, {
            //   souche: role.Nom,
            //   profils: temporaryProfiles,
            //   ressources: temporaryRPermission,
            //   utilisateurs: 0,
            //   dateCreation: role["Date de Création"]
            // }]);
          } catch (error) {
            console.error("Error fetching role details:", error);
          }
        }
      }));
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const handleAjouterClick = async () => {
    if (soucheName.trim() === '') {
      setSoucheNameError('Ce champ est requis');
      return;
    }
    setSoucheNameError('');
    const newRoleObject = await createSoucheObject(soucheName);
    const currentdate = new Date();
    const formattedDate = currentdate.getFullYear() + "-" + (currentdate.getMonth() + 1) + "-" + currentdate.getDate();
    const newRole = {
      souche: newRoleObject.nameRole,
      profils: newRoleObject.profile.length,
      ressources: newRoleObject.resources.length,
      utilisateurs: 0,
      dateCreation: formattedDate,
    };
    setSouchesBackData(prevData => [...prevData, newRole]);
   // fetchDataGroupes();
    localforage.setItem('rolesBackData', [...soucheBackData, newRole]);
    addToast('Le rôle a été ajouté avec succès', 'success');
    closeModal();
  };

  const getFullPath = (node, path = '') => {
    const currentPath = `${path} / ${node.name}`;
    if (!node.children || node.children.length === 0) {
      return currentPath;
    }

    const checkedChildren = node.children.filter(child => child.checked);

    return checkedChildren.reduce((acc, child) => getFullPath(child, acc), currentPath);
  };

  useEffect(() => {
    fetchDataGroupes();
  }, []);


  const createSoucheObject = async (roleName) => {
    const apiKey = "AB90G-BH903-W4EE1-Z66Q9-907E6";
    type ProfileData = {
      profilename: string;
      penable: string;
      ptag: string;
      asset: string;
      addedby: string;
    };
    type ResourceData = {
      rname: string;
      type: string;
      disabled: string;
      afficheMenu: string;
      asset: string;

    }
    const profileRoles: ProfileData[] = [];
    const resourcesRoles: ResourceData[] = [];
    console.log("filterCheckedNodes", filterCheckedNodes(mygroupes));
    const checkedRoles = filterCheckedNodes(mygroupes);
    for (const checkRole of checkedRoles) {
      console.log("role name check:", checkRole.name);
      try {
        const DataDetailsRoles = await getDetailsRole("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, checkRole.name).catch((error) => {
          console.error("Error fetching datagrp:", error);
        });
        console.log("DataDetailsRoles", DataDetailsRoles);
        try {

          const permissions = JSON.parse(DataDetailsRoles.Permission);
          console.log("permissions inside add", permissions);
          for (const permission of permissions) {
            console.log("resource of permission", permission.resource);
            resourcesRoles.push({
              rname: permission.resource,
              type: permission.type,
              disabled: "true",
              afficheMenu: "true",
              asset: "BASIC",
            })

          }

          const profileDetails = JSON.parse(DataDetailsRoles.Profile);
          console.log("profileDetails inside add", profileDetails);
          for (const profile of profileDetails) {
            console.log("profile of profile", profile.refProfile.description);
            profileRoles.push({
              profilename: profile.refProfile.description,
              penable: "true",
              ptag: "true",
              asset: "BASIC",
              addedby: currentUser().id || '',
            })
          }


        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }

      } catch (error) {
        console.error("Error fetching role details:", error);
      }
    }
    console.log("profileRoles", profileRoles);
    console.log("resourcesRoles", resourcesRoles);
    const profileData = prepareProfileData(tr);
    profileData.push(...profileRoles);
    console.log("prepared profile data:", profileData);
    const resourceData = prepareResourceData(trRessource);
    resourceData.push(...resourcesRoles);

    const userId = currentUser().userId!;
    const roleObject = {
      userId: userId,
      nameRole: roleName,
      profile: profileData,
      resources: resourceData
    };
    const data = {
      apiKey: apiKey,
      userId: userId,
      name: roleName,
      appname:'',
      orgname:'',
      description: '',
      profiles: profileData,
      resources: resourceData
    };


    console.log("Profile data and resource data:", data);
    try {
      //const response = await createRole(data);
      //console.log("Role created successfully:", response);
    } catch (error) {
      console.error("Failed to create role:", error);
    }
    return roleObject;
  };
  const prepareProfileData = (treeProfiles) => {
    const traverseAndAddProfileData = (node, path = '') => {
      type ProfileData = {
        profilename: string;
        penable: string;
        ptag: string;
        asset: string;
        addedby: string;
      };
      const currentPath = `${path} / ${node.name}`;
      let profileData: ProfileData[] = [];

      if (node.checked && node.name.startsWith("Activite")) {
        profileData.push({
          profilename: currentPath,
          penable: "true",
          ptag: "true",
          asset: "BASIC",
          addedby: currentUser().id || ''
        });
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          profileData = profileData.concat(traverseAndAddProfileData(child, currentPath));
        });
      }

      return profileData;
    };

    return filterCheckedNodes(treeProfiles).flatMap(node => {
      const lastCheckedNodeNames = getLastCheckedNodeNamesN(node);
      return traverseAndAddProfileData(node);
    });
  };

  const prepareResourceData = (trRessource) => {
    const resourceData = filterCheckedNodes(trRessource).flatMap(node => {
      const lastCheckedNodeNames = getLastCheckedNodeNames(node);
      return lastCheckedNodeNames.map(name => ({
        rname: name?.split("Resource:")[1]?.split("Type")[0]?.trim() || '',
        type: name?.split(" Type: ")[1] || "",
        disabled: "false",
        afficheMenu: "true",
        asset: "BASIC"
      }));
    });

    return resourceData;
  };

  const [soucheNameEdit, setSoucheNameEdit] = useState('');

  const handleEdit = () => {

    const updatedSouchesData = souchesData.map(souche => {
      if (souche.souche === soucheNameEdit) {
        return {
          ...souche,
          souche: soucheName
        };
      }
      return souche;
    });

    setSouchesData([...updatedSouchesData]);
    //setRolesData([...rolesData]);
    closeModal();

  }
  const handleEditOnTable = (soucheEdited) => {
    setShowModalEdit(true);
    setShowModal(true);
    setSoucheName(soucheEdited);
    setSoucheNameEdit(soucheEdited);


    const traverseAndUpdate = (node) => {
      if (node.name === "Activite : Creer Article") {
        node.checked = true;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdate(child);
        });
      }
    };

    tr.forEach(node => {
      traverseAndUpdate(node);
      let updatedTreeData = updateCheckedState(tr, node.id, node.checked || false);
      setTr(updatedTreeData);
    });
    const traverseAndUpdateR = (node) => {
      if (node.name.includes("autorisations/articleae/process/GestionArticleAEProcess") || node.name === "Resource:autorisations/renouvellementodp/process/GestionRenouvellementODPProcess Type: launchProcess") {
        node.checked = true;
      }
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          traverseAndUpdateR(child);
        });
      }
    };
    trRessource.forEach(node => {
      traverseAndUpdateR(node);
      let updatedTreeDataResources = updateCheckedState(trRessource, node.id, node.checked || false);
      console.log("updatedTreeDataResources", updatedTreeDataResources);
      setTrRessource(updatedTreeDataResources);
    });

  };


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, soucheBackData.length);

  return (
    <div className='roles-empty-container'>
      {/* div1 */}
      <div style={{ display: 'block' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 500, fontSize: '15px' }}>Souches</span>

                    <div className="add-role">
                        <span className="material-icon" >
                            add
                        </span><span
                            style={{ fontWeight: 600, fontSize: '13px' }}
                            onClick={handleNouvelleSoucheClick}
                        >
                            Nouvelle Souche
                        </span></div>
                </div>
                <div className="title-400">
                    Gérez vos profils utilisateurs à l'aide des souches
                </div>
            </div>
      {/* horizontal line */}
      <hr className="horizontal-line" />
      {/* div2 */}

      {soucheBackData.length <= 0 ? (
       
       <div className="container-roles">
       <div className="container-icon-role">
           <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g clip-path="url(#clip0_6021_10511)">
                   <path d="M24.6094 3.51562H5.85938V24.6094H24.6094V3.51562ZM38.8281 18.75H29.2969C28.6491 18.75 28.125 19.2741 28.125 19.9219V25.7812H17.5781C16.9304 25.7812 16.4062 26.3054 16.4062 26.9531V33.9844C16.4062 34.6321 16.9304 35.1562 17.5781 35.1562H28.8116L33.1558 39.6564C33.4967 39.9973 34.0069 40.0897 34.433 39.9105C34.647 39.8217 34.83 39.6715 34.9587 39.4788C35.0874 39.2861 35.1562 39.0596 35.1562 38.8279V35.1562H38.8281C39.4759 35.1562 40 34.6321 40 33.9844V19.9219C40 19.2741 39.4759 18.75 38.8281 18.75Z" fill="#FBCFE8" />
                   <path d="M29.2969 0H1.17188C0.515547 0 0 0.515547 0 1.17187V26.9531C0 27.6095 0.515547 28.125 1.17188 28.125H4.6875V31.6406C4.6875 32.1094 4.96875 32.5312 5.41406 32.7188C5.75789 32.8905 6.33555 32.8434 6.67961 32.4609L11.0391 28.125H29.2969C29.9532 28.125 30.4688 27.6095 30.4688 26.9531V1.17187C30.4688 0.515547 29.9532 0 29.2969 0ZM23.4375 22.2656C23.4375 22.922 22.922 23.4375 22.2656 23.4375H8.20312C7.5468 23.4375 7.03125 22.922 7.03125 22.2656V15.2344C7.03125 14.578 7.5468 14.0625 8.20312 14.0625H9.375V10.5469C9.375 7.3125 12 4.6875 15.2344 4.6875C18.4688 4.6875 21.0938 7.3125 21.0938 10.5469V14.0625H22.2656C22.922 14.0625 23.4375 14.578 23.4375 15.2344V22.2656ZM15.2344 7.03125C13.2891 7.03125 11.7188 8.60148 11.7188 10.5469V14.0625H18.75V10.5469C18.75 8.60156 17.1798 7.03125 15.2344 7.03125Z" fill="#DB2777" />
               </g>
               <defs>
                   <clipPath id="clip0_6021_10511">
                       <rect width="40" height="40" fill="white" />
                   </clipPath>
               </defs>
           </svg>
       </div>
       <span className="empty-role-text">Aucune souche pour l'instant</span>
       <span className="title-400">
           La liste des souches créés sera affichée ici
       </span>


   </div>
      ) : (
        <div style={{ padding: '35px' }}>
          <div style={{ marginBottom: '30px' }}> <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} /></div>
          <div style={{ marginBottom: '20px' }}>
            <AppTable servicesList={filteredSouchesData.slice(startIndex, endIndex)} /></div>
          <div style={{ height: '50px' }}>
            <Pagination
              light={true}
              totalItems={filteredSouchesData.length}
              itemsPerPage={itemsPerPage}
              maxPage={Math.ceil(filteredSouchesData.length / itemsPerPage)}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>)}

      {showModal && (
        <Modal
          icon=""
          leftBtnArgs={{
            args: { onClick: closeModal },
            color: 'neutral',
            label: 'Annuler',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            args: { onClick: showModalEdit ? handleEdit : handleAjouterClick },
            color: 'primary',
            label: { 'Ajouter': 'Ajouter', 'Enregistrer': 'Enregistrer' }[showModalEdit ? 'Enregistrer' : 'Ajouter'],
            size: 'sm',
            styling: 'solid'
          }}
          title={showModalEdit ? "Détails du souche" : "Nouveau souche"}
          onClose={closeModal}
          bodyStyle={{ padding: '20px' }}
        >
          {showModalEdit && (
            <div className='tabs-roles-edit'>
              <Tabs current={'general'}
                align="horizontal"
                defSelected="general"
                icon={[]}
                iconAndName={[]}
                icons={[]}
                item={[]}
                onSelect={handleTabsEdit}
                size="small"
                tabs={[
                  {
                    label: 'Général',
                    name: 'general'
                  },
                  {
                    label: 'À propos',
                    name: 'propos'
                  },

                ]}
              /></div>)}
          {activeTabEdit === "general" ? (
            <>
              <span className="add-name-modal-text">Nom du souche</span>
              <Input
                size='sm'
                args={{
                  placeholder: 'Entrer le nom du souche',
                  onChange: handleSoucheNameChange,
                  value: soucheName
                }}
                helpText={helpText}
                errorMessage={!isInputModified ? soucheNameError : ''}
              />

              <SelectTabs current={activeTab}
                align="horizontal"
                defSelected={activeTab}
                icon={[]}
                iconAndName={[]}
                icons={[]}
                item={[]}
                onSelect={handleTabsSelect}
                size="small"
                tabs={[
                  {
                    label: 'Groupes',
                    name: 'tab1'
                  },
                  {
                    label: 'profils',
                    name: 'tab2'
                  },
                  {
                    label: 'Ressources',
                    name: 'tab3'
                  },
                ]}
              />
              <div>
                {renderContent()}
              </div>
            </>
          ) : (activeTabEdit === "propos" &&
            <div className="about-tabs-informations">

              <div className='flex-space'><span className='title-propos'>Date de création:</span> <span className='descp-propos'>{aboutData.creationDate}</span></div>
              <div className='flex-space'><span className='title-propos'>Dernière modification: </span><span className='descp-propos'>{aboutData.lastModification}</span></div>
              <hr style={{ border: 'none', borderTop: '1px solid #cdd4e0', margin: '10px 0' }} />
              <div className='flex-space'><span className='title-propos'>Nombre d'utilisateurs:</span> <span className='descp-propos'>{aboutData.numberOfUsers}</span></div>
            </div>)}
          <div />
        </Modal>
      )}
      {showModalDelete && (
        <ModalDelete
        closeModalDelete={closeModalDelete}
        handleDeleteButtonClick={handleDeleteButtonClick}
        errorMessage={errorMessage}
        randomNumber={randomNumber&&randomNumber}></ModalDelete>

      )}
    </div>
  );
}