import { useState, useEffect, useRef } from "react";
import { Tabs, Input, Button } from "kls-ui";
import { AccessSecurity, Appearance, AccountDeletion, Performance, Roles, Workspace } from "../settings/settings";
import { OrganisationSettings, VerificationSettings, DomainSettings, Users, Groups, Strains, PaymentSettings, Support, OrganisationDeletion } from "./index";
export function SettingsSideBar(props: any) {
  let [selectedTab, setSelectedTab] = useState(props?.tab);
  let [selectedType, setselectedType] = useState(props?.type);
  useEffect(() => {
    setSelectedTab(props?.tab);
  }, [props?.tab]);
  const listParamOrganization = [
    { name: 'Organisation', label: <><span className="icon">corporate_fare</span>Organisation</>, component: <><OrganisationSettings /></> },
    { name: 'Verification', label: <><span className="icon">verified</span>Vérification</>, component: <VerificationSettings /> },
    { name: 'Domain name', label: <><span className="icon">language</span>Nom de domaine</>, component: <DomainSettings /> },
    { name: 'Users', label: <><span className="icon">people_alt</span>Utilisateurs</>, component: <Users /> },
    { name: 'Groups', label: <><span className="icon">workspaces</span>Groupes</>, component: <Groups /> },
    { name: 'Strains', label: <><span className="icon">rule</span>Souches</>, component: <Strains /> },
    { name: 'Payments', label: <><span className="icon">payment</span>Paiements</>, component: <PaymentSettings /> },
    { name: 'Support and help', label: <><span className="icon">help_outline</span>Support et aide</>, component: <Support /> },
    { name: 'Organization deletion', label: < ><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression d’organisation</span> </>, component: <OrganisationDeletion /> },

  ]
  const listParamSettings = [
    { name: 'workspace', label: <><span className="icon">work_outline</span>Espace de travail</>, component: <Workspace /> },
    { name: 'appearance', label: <><span className="icon">format_paint</span>Apparence</>, component: <Appearance /> },
    { name: 'accesssecurity', label: <><span className="icon">lock</span>Accès et sécurité</>, component: <AccessSecurity /> },
    { name: 'roles', label: <><span className="icon">rule</span>Rôles et permissions</>, component: <Roles /> },
    { name: 'performance', label: <><span className="icon">multiline_chart</span>Performance</>, component: <Performance /> },
    { name: 'delete', label: <><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression de compte</span></>, component: <AccountDeletion /> },

  ]
  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
  };

  return (
    <div className='tab-container'>
      <div className='side-navbar'>
        <Tabs tabs={props?.type == 'settings' ? listParamSettings : listParamOrganization} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab}
          defSelected={"app"} />
      </div>
      <div className="settings-content">
        {props?.type == 'settings' ? listParamSettings.find(tab => tab.name === selectedTab)?.component : listParamOrganization.find(tab => tab.name === selectedTab)?.component}
      </div>
    </div>
  )
}



