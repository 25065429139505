import { useEffect, useRef, useState } from "react";
import "../../app-params/param-app.css"
import React from 'react';
import { Dropdown, Input, Modal, Tabs, ToggleButton, addToast, Button, CheckboxLabel, ProfileAvatar } from 'kls-ui';
import { ModalDelete } from 'kls-ui/modal/ModalDelete';
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";
import { Model } from "kls-commons/types/application-model";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchKlsResource, getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { UsersTable, UsersInvitationsTable, UsersDemandesTable } from 'kls-ui/table/table';
import { SearchInput } from "kls-ui/data entry/search-input";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { SettingsContainerHeader } from "../../settings/settings";
import { UsersCard } from './users-card';
import { OrganizationSettingsService } from "./../organization-settings-service";
import { currentUser } from "kls-commons/service/userService";
import { acceptRequestToJoinOrganization, getAllRequestToJoinOrganization, refuseRequestToJoinOrganization } from "../../settings/role/request-service";
import TreeElementsModules from 'kls-ui/treeModules/TreeElementsModules';
import { SelectTabs } from 'kls-ui/selectTabs/SelectTabs';
import { getAllRoles } from "../../app-params/role-service";
import { getAppsDetails } from "kls-catalog/my-applications/myapps.service";
import { deleteUsersFromOrg, getUsersJoinedOrganisation } from "./users-service";

interface Invitation {
  num: number,
  email: string,
  souche: string,
  role: string,
  dateInvitation: string,

}
interface TreeNode {
  id: number;
  name: string;
  isOpen: boolean;
  children: TreeNode[];
  checked?: boolean;
  display?: boolean;
}

export function Users(props: any) {
  let [viewDelete, setViewDelete] = useState<any>()
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [myOrganisationName, setMyOrganisationName] = useState('');
  const [displayNode, setDisplayNode] = useState(false);
  const [nomProprietaire, setNomProprietaire] = useState('');
  const [userNameDeleted, setUserNameDeleted] = useState('');
  const [searchSouche, setSearchSouche] = useState(false);
  const [emailUser, setEmailUser] = useState('');
  const [nomUser, setNomUser] = useState('');
  const [prenomUser, setPrenomUser] = useState('');
  const [helpText, setHelpText] = useState('');
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const [activeTabEdit, setActiveTabEdit] = useState<string>('general');
  const { moduleName } = useParams() as any;
  const location = useLocation();
  const [directories, setDirectories] = useState<string[]>([]);
  const [appObj, setAppObj] = useState<WorkingDirTree>();
  const [direc, setDirec] = useState<string[]>([]);
  const [selectedSouche, setSelectedSouche] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [mesProcess, setMesProcess] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>("");
  const itemsPerPageUsers: number = 6;
  const itemsPerPageInvitations: number = 5;
  const itemsPerPageDemandes: number = 5;
  const [codeInputs, setCodeInputs] = useState<string[]>(["", "", "", "", "", ""]);
  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageValidation, setErrorMessageValidation] = useState<string>('');
  const [currentInputIndex, setCurrentInputIndex] = useState<number>(0);
  const [showFilter, setShowFilter] = useState(false);
  const [filterUsers, setFilterUsers] = useState<any>('Status');
  const [isInputModified, setIsInputModified] = useState(false);
  const [activated, setActivated] = useState(true);
  const [errorExistingUser, setErrorExistingUser] = useState(false);
  const [checker, setchecker] = useState<any[]>([])
  const [listView, setlistView] = useState(false)
  const [viewType, setviewType] = useState('list');
  const [requestAction, setrequestAction] = useState(false)
  const [allChecked, setallChecked] = useState(false)
  const [mobileMoreActions, setmobileMoreActions] = useState(false)
  const [aboutData, setAboutData] = useState<any>({
    creationDate: '05/01/2024 22:15',
    lastModification: '10/01/2024 08:44',
    lastConnexion: "10/01/2024 08:44"
  });
  const [usersJoined, setUsersJoined] = useState<{ num: number; profile: string; nom: string; email: string; role: string, status: string; username: string }[]>([]);
  const [usersData, setUsersData] = useState<{ num: number; profile: string; nom: string; email: string; role: string, status: string; username: string }[]>([]);
  const [invitationsData, setInvitationsData] = useState<Invitation[]>([]);
  const [mygroupes, setMygroupes] = useState<TreeNode[]>([]);
  const [treeProfiles, setTreeProfiles] = useState<TreeNode[]>([]);
  const [trRessource, setTrRessource] = useState<TreeNode[]>([]);


  useEffect(() => {
    console.log("envi", currentUser());
    const myLocalEntity = (currentUser().entity as string).split("/kls/")[1].split("/dev")[0];

    const fetchRolesData = async () => {
      try {
        const roles = await getAllRoles("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, currentUser().email);
        console.log("Roles data in groups:", roles);
        console.log("mylocal", myLocalEntity, myOrganisationName)

        const formattedRoles = roles.data
          .filter(role => role.Nom.trim() !== '' && role['Entité'] !== '')
          .filter(role => myOrganisationName && role['Entité'].split('/')[2] === myOrganisationName)
          // .filter(role=>role['Entité'].split('/')[1]===myLocalEntity)
          .map((role, index) => ({
            id: index + 1,
            name: role.Nom,
            isOpen: false,
            children: []
          }));
        setMygroupes(formattedRoles);

        console.log("Mygroupes:", formattedRoles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRolesData();
  }, [myOrganisationName]);

  const resetCheckedNodes = (nodes) => {
    return nodes.map(node => {
      if (node.checked) {
        node.checked = false;
        console.log("the node is checked", node)
        resetCheckedNodes(node.children);
        return { ...node, checked: false };
      } else if (node.children && node.children.length > 0) {
        return { ...node, children: resetCheckedNodes(node.children) };
      } else {
        return node;
      }
    });
  };
  const resetProfileTreeCheckedNodes = () => {
    const updatedProfileTree = resetCheckedNodes(treeProfiles);
    setTreeProfiles(updatedProfileTree);
  };
  const resetGroupesTreeCheckedNodes = () => {
    const updatedGrpTree = resetCheckedNodes(mygroupes);
    setMygroupes(updatedGrpTree);
  };

  const handleAllChecked = () => {
    setallChecked(!allChecked)
  }

  useEffect(() => {
    console.log("usershahahah", usersData)
    const PopupVerification = window.innerWidth >= 600 ? <ModalDelete
      closeModalDelete={closeModalDelete}
      handleDeleteButtonClick={handleDeleteButtonClick}
      errorMessage={errorMessage}></ModalDelete> :
      <></>
    // <MobileViewVerification title="Suppression du rôle" subtitle="Gérez votre organisation" label="Modification d’adresse email" labelContent="Veuillez saisir ci-dessous le code à 6 caractères envoyé à votre nouvelle adresse email :" handleSubmit={handleDeleteButtonClick} handleVerificationClose={closeModalDelete} labelbtn="Mettre à jour email" findTitle="Vous ne trouvez pas votre code ?" findSubtitle="RENVOYER LE CODE" />
    setViewDelete(PopupVerification);
  }, [])
  const fetchData = async () => {
    try {

      const fetchDataResult = await OrganizationSettingsService(currentUser().userId, location.pathname.split("/")?.[2]);
      console.log("fetchDataResult", fetchDataResult.data);
      setMyOrganisationName(fetchDataResult.data.name);
      setNomProprietaire(fetchDataResult.data.proprietaire);
      console.log("MyOrganisationName", myOrganisationName);


    }
    catch (error) {
      console.error("Error fetching organization settings:", error);
    }
  }
  var userNUM = 0;


  const fetchDataUsersJoined = async () => {
    try {
      const fetchUsersResult = await getUsersJoinedOrganisation(
        "AB90G-BH903-W4EE1-Z66Q9-907E6",
        currentUser().userId,
        location.pathname.split("/")?.[2]
      );

      console.log("fetchUsersResult", fetchUsersResult);

      const usersDataaa = fetchUsersResult.users;
      console.log("userdataaa", usersDataaa);

      let parsedData;
      try {
        let cleanedData = usersDataaa
          .replaceAll('"/"', '"')
          .replaceAll('/""', '"')

        console.log('cleaneddata', cleanedData);
        parsedData = JSON.parse(cleanedData);
      } catch (parseError) {
        console.error("Error parsing JSON users:", parseError);
        parsedData = [];
      }

      console.log("parsedData", parsedData);

      const mappedUsers = parsedData.map((user, index) => ({
        num: userNUM++,
        profile: "",
        nom: user.Name || "",
        email: user.Email,
        role: user.Role,
        status: "",
        username: user.UserName
      }));
      console.log("mappedUsers", mappedUsers);


      setUsersJoined(mappedUsers);
      //  setUsersData([...usersData, ...usersJoined]);

    } catch (error) {
      console.error("Error fetching organization joined users:", error);
    }
  };


  useEffect(() => {
    fetchDataUsersJoined();
  }, []);

  useEffect(() => {
    console.log("getDatafron", usersJoined);
    if (usersJoined && usersJoined.length > 0) {
      const uniqueUsersJoined = usersJoined.filter((joinedUser) => {
        return !usersData.some((dataUser) => dataUser.email === joinedUser.email);
      });
      setUsersData((prevUsersData) => [...prevUsersData, ...uniqueUsersJoined]);
    }
  }, [usersJoined]);



  useEffect(() => {
    fetchData();
    console.log("NomProprietaire", nomProprietaire, myOrganisationName);

    const userProprietaire = {
      num: userNUM++,
      nom: nomProprietaire,
      email: '',
      profile: "",
      role: 'Proprietaire',
      status: "Actif",
      username: nomProprietaire
    };

    const isProprietaireExist = usersData.some((user) => user.nom === nomProprietaire);

    if (nomProprietaire && !isProprietaireExist) {
      console.log("userProprietaire", userProprietaire);
      setUsersData([userProprietaire, ...usersData]);
    }
  }, [props, nomProprietaire, myOrganisationName, usersData]);

  useEffect(() => {
    console.log("heeeere the user test", usersData)
  }, [])
  // useEffect(() => {
  //   fetchDataUsersJoined();
  // }, []);

  // useEffect(() => {
  //   console.log("getDatafron", usersJoined);
  //   if (usersJoined && usersJoined.length > 0) {
  //     const uniqueUsersJoined = usersJoined.filter((joinedUser) => {
  //       return !usersData.some((dataUser) => dataUser.email === joinedUser.email);
  //     });
  //     setUsersData((prevUsersData) => [...prevUsersData, ...uniqueUsersJoined]);
  //   }
  // }, [usersJoined]);

  const filteredUsersData = usersData.filter(r =>
    r && r.nom.toLowerCase().includes(filter.toLowerCase()) || r && r.email.toLowerCase().includes(filter.toLowerCase()) || r && r.username.toLowerCase().includes(filter.toLowerCase())
  );
  // const filteredUsersInvitationsData = invitationsData.filter(r =>
  //   r && r.email.toLowerCase().includes(filter.toLowerCase())
  // );
  // const filteredUsersDemandesData = usersDemandesData.filter(r =>
  //   r && r.email.toLowerCase().includes(filter.toLowerCase())
  // );

  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
  const [currentPageInvitations, setCurrentPageInvitations] = useState<number>(1);
  const [currentPageDemandes, setCurrentPageDemandes] = useState<number>(1);
  const rolesOrganisation = [
    { value: "utilisateur", label: "Utilisateur" },
    { value: "administrateur", label: "Administrateur" },
    { value: "citizenDeveloper", label: "Citizen Developer" }
  ];

  const generateSouches = (label, value) => ({
    icon: 'rule',
    label: label,
    value: value,
    color: '#3B82F6',
  });

  const souches = [
    generateSouches('Souche demandeur citoyen', 'Souche demandeur citoyen'),
    generateSouches('Souche demandeur architecte', 'Souche demandeur architecte'),
    generateSouches('test4', 'test4'),
    generateSouches('test5', 'test5'),
  ];
  const listFilter = [
    {
      label: "Status",
      value: "Status"
    },
    // {
    //   label: "Invitations en attente",
    //   value: "Invitations en attente"
    // },
    // {
    //   label: "Demandes en attente",
    //   value: "Demandes en attente"
    // }
  ];


  const getStartIndexUsers = () => {
    return (currentPageUsers - 1) * itemsPerPageUsers;
  }
  const getStartIndexInvitations = () => {
    return (currentPageInvitations - 1) * itemsPerPageInvitations;
  }
  const getStartIndexDemandes = () => {
    return (currentPageDemandes - 1) * itemsPerPageDemandes;
  }
  const getEndIndexUsers = (table: any) => {
    return Math.min(getStartIndexUsers() + itemsPerPageUsers, table.length);
  }

  const usersFilteredData = filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData))
  // const getEndIndexInvitations = (table: any) => {
  //   return Math.min(getStartIndexInvitations() + itemsPerPageInvitations, table.length);
  // }
  // const getEndIndexDemandes = (table: any) => {
  //   return Math.min(getStartIndexDemandes() + itemsPerPageDemandes, table.length);
  // }
  useEffect(() => {
    const cleanedAndFormattedName = cleanAndFormatNameResource(emailUser);
    setHelpText(`ID : ${cleanedAndFormattedName}`);
  }, [emailUser]);

  useEffect(() => {
    if (checker.length === filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).length) {
      setallChecked(true)
    }
    else {
      setallChecked(false)
    }
  }, [setallChecked, checker])

  useEffect(() => {
    console.log(allChecked, "allcheckedljalkdf")
    const allCheckedData: any[] = [];
    let serviceList = filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData));
    if (allChecked) {
      serviceList.map((s) => {
        allCheckedData.push(s?.num);
      });
      setchecker(allCheckedData);
    }
    else if (checker.length === serviceList.length) setchecker([])
  }, [setchecker, allChecked])

  const [usersList, setUsersList] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = {
          apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
          userId: currentUser().userId!,
          organizations: [
            {
              organisationId: location.pathname.split("/")?.[2],
              name: "app"
            }
          ]
        };

        const responseData = await getAllRequestToJoinOrganization(requestData);
        console.log("Response from getAllRequestToJoinOrganization:", responseData["list of users"]);

        const cleanedDataString = responseData["list of users"]
          .replace(/"\/+/g, '"')
          .replace(/\/+"/g, '"')
          .replace(/""+/g, '"');

        const parsedData = JSON.parse(cleanedDataString);

        console.log("Parsed response data:", parsedData);

        parsedData.forEach((item) => {
          if (item.status === 'Brouillon') {
            const demandesData = {
              num: userNUM++,
              nom: item.OwnerName,
              email: '',
              profile: '',
              role: item.Role,
              status: 'En attente approbation',
              username: item.OwnerID
            };

            console.log("demanmndesdata", demandesData, currentUser().id, "hh");
            setUsersData(prevUsersData => [...prevUsersData, demandesData]);

          }
        });
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("usersListFromState", usersList);
  }, [usersList]);


  useEffect(() => {
    getWorkingDirectorTree(moduleName).then(
      (tree: WorkingDirTree) => {
        setAppObj(tree);
        setDirectories(Object.keys(tree.children || {}));
      }
    )
    listModules().then(
      modules => {
        setDirec(modules);
      }
    )

  }, [moduleName])

  useEffect(() => {
    console.log("appObjet structure", appObj);
    console.log("selected role", selectedRole);
  }, [appObj, selectedRole])

  useEffect(() => {
    setAllInputsFilled(codeInputs.every(input => input !== ''));
  }, [codeInputs]);
  useEffect(() => {
    console.log("currentwhhhh", currentPageUsers, currentPageInvitations, currentPageDemandes);
    setCurrentPageUsers(1);
    setCurrentPageInvitations(1);
    setCurrentPageDemandes(1);
  }, [filter]);
  useEffect(() => {
    if (props.hintType === "userModal") {
      setShowModal(true);
    }
  }, [props.hintType])

  useEffect(() => {
    let count = 0
    filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).map((c) => {
      checker && checker.map((d) => {
        if (c.num === d) {
          count += 1;
        }
      })
    })
    if (count > 0) setrequestAction(true)
    else setrequestAction(false)
  }, [checker, setrequestAction, filteredUsersData])

  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };
  const handleSearchSoucheChange = (value: boolean) => {
    setSearchSouche(value);
  };
  const handlePageChangeUsers = (startIndex: number) => {
    setCurrentPageUsers(Math.floor(startIndex / itemsPerPageUsers) + 1);
    if (checker.length === filteredUsersData.slice(((Math.floor(startIndex / itemsPerPageUsers) + 1) - 1) * itemsPerPageUsers, Math.min(((Math.floor(startIndex / itemsPerPageUsers) + 1) - 1) * itemsPerPageUsers + itemsPerPageUsers, usersData.length)).length) setallChecked(true)
    else setallChecked(false)
    console.log("currentwPageUsers", currentPageUsers);
  };

  const handlePageChangeInvitations = (startIndex: number) => {
    setCurrentPageInvitations(Math.floor(startIndex / itemsPerPageInvitations) + 1);
    console.log("currentwPageInvitations", currentPageInvitations);
  };

  const handlePageChangeDemandes = (startIndex: number) => {
    setCurrentPageDemandes(Math.floor(startIndex / itemsPerPageDemandes) + 1);
    console.log("currentwPageDemandes", currentPageDemandes);
  };

  const handleSoucheSelect = (selectedSouche: any) => {

    setSelectedSouche(selectedSouche.label);
    setSearchSouche(false);
  };
  const handleNouvelUserClick = () => {
    setShowModal(true);
  };
  const handleRoleOrganisationChange = (event) => {
    const selectedRoleOrg = event.label;
    console.log("selectedRoleOrg", selectedRoleOrg);
    setSelectedRole(selectedRoleOrg);

  };

  const handleEmailUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUser(event.target.value);
    setIsInputModified(true);
  };
  const handleNomUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNomUser(event.target.value);
  };
  const handlePrenomUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrenomUser(event.target.value);
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);


  const handleDropdownOpen = () => {
    if (dropdownRef.current) {
      dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  const closeModal = () => {
    setShowModal(false);
    setShowModalEdit(false);
    setActiveTabEdit('general');
    setIsInputModified(false);
    setErrorExistingUser(false);
    setSelectedRole('');
    setEmailUser('');
    setSelectedSouche('');
    resetGroupesTreeCheckedNodes();
    resetProfileTreeCheckedNodes();
  };
  const closeModalDelete = () => {
    setShowModalDelete(false);
    setErrorMessage('');

  };
  const handleDeleteButtonClick = (enteredCode: string) => {
    if (enteredCode !== '111111') {
      setErrorMessage("Le code saisi n'est pas valide. Essayez-le à nouveau !");
    } else {
      const updatedRolesData = usersData.filter(role => role.role !== userNameDeleted);
      // setUsersData(updatedRolesData);
      setShowModalDelete(false);
      setErrorMessage('');
      addToast(' Le rôle a été supprimé avec succès', 'success');
    }
  };
  const handleTabsEdit = (tabName: string) => {
    setActiveTabEdit(tabName);
  }
  const handleTabChange = (tabName: string) => {
    if (tabName === 'edit') {
      setShowModalEdit(true);
      setShowModal(true);

    }
    else if (tabName === 'delete') {
      setShowModalEdit(false);
      setShowModalDelete(true);

    }
    else if (tabName === 'duplicate') {
      setShowModal(true);

    }
  };

  const handleDeleteOnTable = (userDeleted) => {
    setShowModalEdit(false);
    setShowModalDelete(true);
    setUserNameDeleted(userDeleted);
    window.scrollTo({ top: 0 })
    document.body.classList.add("mobile-verify-tab-view")
  }

  useEffect(() => {
    if (!showModalDelete) {
      document.body.classList.remove("mobile-verify-tab-view")
    }
  }, [showModalDelete])

  const userTableChecker = {
    'filteredData': filteredUsersData,
    'setchecker': setchecker
  }

  const handleActivation = () => {
    console.log("activation");
    setActivated(!activated);
  }
  // console.log(requestAction)
  const handleCancelRequest = (email: string) => {
    setUsersData(usersData.filter((c) => c.email !== email))
    addToast(" L'invitation a été annulée avec succès", 'success');
  }
  function MoreActions({ actions, closeCallback, style, status,roleDemande }: { actions: any, closeCallback?: Function, style?: React.CSSProperties, status: string,roleDemande:string}) {
    const moreOptions = useRef<HTMLDivElement>(null);
    const moreOptionsClass = viewType == 'list' ? status === "Actif" ? "user-moreoptions-list-active" : status === "Demande envoyée" ? "user-moreoptions-list-request" : "user-moreoptions-list" : status === "Actif" ? "user-moreoptions-card-active" : status === "Demande envoyée" ? "user-moreoptions-card-request" : "user-moreoptions-card"
    const listObjActions = status === "Actif" || status === "Demande envoyée" ? [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },
      // {
      //   label: 'Activer',
      //   value: 'activate',
      //   icon: 'group_on',
      //   color: '#64748B ',
      //   handleClick: actions?.['activate'],
      // },
      {
        label: status === "Actif" ? 'Désactiver' : status === "Demande envoyée" ? 'Annuler la demande' : '',
        value: status === "Actif" ? 'deactivate' : status === "Demande envoyée" ? 'cancelRequest' : '',
        icon: status === "Actif" ? 'group_off' : status === "Demande envoyée" ? 'do_not_disturb_alt' : '',
        color: roleDemande === 'Proprietaire' ? '#aab5a7' : '#64748B',
        handleClick: roleDemande !== 'Proprietaire' ? (status === "Actif" ? actions?.['deactivate'] : status === "Demande envoyée" ? actions?.['cancelRequest'] : null) : null,
        labelColor: roleDemande === 'Proprietaire' ? '#aab5a7' : '#64748B',
        disabled: roleDemande === 'Proprietaire'
    },

      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: roleDemande === 'Proprietaire' ? '#aab5a7' : '#EF4444',
        handleClick: roleDemande !== 'Proprietaire' ? actions?.['delete'] : null,
        labelColor: roleDemande === 'Proprietaire' ? '#aab5a7' : '#DC2626',
        disabled: roleDemande === 'Proprietaire'
    },

    ] : status === "En attente approbation" || status === "Actif" ? [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },
      {
        label: 'Approuver '+roleDemande,
        value: 'approveRole',
        icon: 'check_circle',
        color: '#059669',
        handleClick: actions?.['approveRole'],
        labelColor: '#059669',

        
      },
      {
        label: 'Refuser '+roleDemande,
        value: 'refuseRole',
        icon: 'thumb_down_alt',
        color: '#EF4444',
        handleClick: actions?.['refuseRole'],
        labelColor: '#DC2626',

        
      },
      {
        label: 'Désactiver',
        value: 'deactivate',
        icon: 'group_off',
        color: '#64748B ',
        handleClick: actions?.['deactivate'],
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: '#EF4444 ',
        handleClick: actions?.['delete'],
        labelColor: '#DC2626',
      },




    ] : [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },
      {
        label: 'Désactiver',
        value: 'deactivate',
        icon: 'group_off',
        color: '#64748B ',
        handleClick: actions?.['deactivate'],
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: '#EF4444 ',
        handleClick: actions?.['delete'],
        labelColor: '#DC2626',
      },
    ]


    return <div ref={moreOptions} className={moreOptionsClass}>
      <AdvancedDropDown data={listObjActions} enableSearch={false}
        light={true}
        handleChange={(item) => { item?.handleClick?.() }}
        size={1}
        selectedValue={''}
        style={style}
        closeWhenClickOutside={closeCallback}
        closeWhenSelect={closeCallback} />
    </div>
  }

  function getColorClass(role) {
    switch (role?.toLowerCase()) {
      case 'administrateur':
        return 'color-admin';
      case 'proprietaire':
        return 'color-proprietaire';
      case 'citizen developer':
        return 'color-citizen-developer';
      case 'utilisateur':
        return 'color-utilisateur';
      case 'N/A':
        return 'color-na';
      default:
        return '';
    }
  }

  const getProfileColor = (role) => {
    switch (role && role?.toLowerCase()) {
      case 'administrateur':
        return '#60A5FA';
      case 'proprietaire':
        return 'color-proprietaire';
      case 'citizen developer':
        return '#A78BFA';
      case 'utilisateur':
        return '#FACC15';
      default:
        return 'black';
    }
  }

  const handleApprove = async (s) => {
    const dataToAccept = {
      apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6',
      userId: currentUser().userId!,
      ownerlogin: s.username,
      organizations: [{
        organisationId: location.pathname.split("/")?.[2],
        name: "app"
      }]
    };
    console.log("dataToAccept", dataToAccept);

    try {
      const response = await acceptRequestToJoinOrganization(dataToAccept);
      console.log("Response from acceptRequestToJoinOrganization:", response);
      addToast("La demande est acceptée avec succès", 'success');
      console.log("actual data in users.tsx", usersData);
      setUsersData(prevUserData => prevUserData.filter(user => user.num !== s.num));
    } catch (error) {
      console.error("Error in handleApprove:", error);
    }
  };

  const handleRefuse = async (s) => {
    const dataToRefuse = {
      apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6',
      userId: currentUser().userId!,
      ownerlogin: s.username,
      organizations: [{
        organisationId: location.pathname.split("/")?.[2],
        name: "app"
      }]
    };
    console.log("dataToRefuse", dataToRefuse);

    try {
      const response = await refuseRequestToJoinOrganization(dataToRefuse);
      console.log("Response from refuseRequestToJoinOrganization:", response);
      addToast(" La demande est refusee avec succès", 'success');
    } catch (error) {
      console.error("Error in handleRefuse:", error);
    }
  };
  const [showDiv, setShowDiv] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  useEffect(() => {
    function handleClickOutside(event) {
      const divRef = document.getElementById(`expandableDiv-${expandedRow}`);
      if (divRef && !divRef.contains(event.target)) {
        setExpandedRow(null);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [expandedRow]);

  function getRoleForUser(username) {
    const userNameSplit = username.split('@karaz.org')[0]; // Get the part before @karaz.org
    const user = usersData.find(user => user.username === userNameSplit);
    return user ? user.role : 'Utilisateur'; // Return role if user found, otherwise default to 'Utilisateur'
  }

  const exportToCSV = (servicesList, checker) => {
    const checkedRows = servicesList.filter((s) => checker.includes(s.num));
    const headers = ['Num', 'Email', 'Username', 'Role'];
    const rows = checkedRows.map(({ num, email, username, role }) =>
      [num, email, username, role]);

    let csvContent = "data:text/csv;charset=utf-8,"
      + headers.join(", ") + "\n"
      + rows.map(e => e.join(", ")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Liste_utilisateurs.csv");
    document.body.appendChild(link);
    link.click();
  };
  const deleteUsers = (servicesList, checker) => {
    const checkedRows = servicesList.filter((s) => checker.includes(s.num));
    const headers = ['Num', 'Email', 'Username', 'Role'];
    const rows = checkedRows.map(({ num, email, username, role }) =>
      [num, email, username, role]);


    console.log("deleeeeeeeete", checkedRows)
    deleteUsersFromOrg("AB90G-BH903-W4EE1-Z66Q9-907E6", currentUser().userId, location.pathname.split("/")?.[2], checkedRows.map((s) => s.username + '@karaz.org')).then(() => {
      addToast("Les utilisateurs selectionnes sont supprimers avec succes", "success");
      setUsersData([...usersData.filter((s) => !checker.includes(s.num))]);
      setchecker([]);
    })

  };
  function AppTable({ servicesList }) {

    const exportToCSV = () => {
      const checkedRows = servicesList.filter((s) => checker.includes(s.num));
      const headers = ['Num', 'Profile', 'Nom', 'Email', 'Username', 'Role', 'Status', 'DateModification'];
      const rows = checkedRows.map(({ num, profile, nom, email, username, role, status, dateModification }) =>
        [num, profile, nom, email, username, role, status, dateModification]);

      let csvContent = "data:text/csv;charset=utf-8,"
        + headers.join(",") + "\n"
        + rows.map(e => e.join(",")).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Liste_utilisateurs.csv");
      document.body.appendChild(link);
      link.click();
    };
    const data = [servicesList.map((s, index) => {
      const roleClass = `badge ${getColorClass(s.role)}`;

      const handleChangeValue = (row) => {
        let newChecker;
        if (checker.includes(row.num)) {
          newChecker = checker.filter((d) => d !== row.num);
          console.log(`Unchecked row:`, row);
        } else {
          newChecker = [...checker, row.num];
          console.log(`Checked row:`, row);
        }
        setchecker(newChecker);

        const checkedRows = servicesList.filter((s) => newChecker.includes(s.num));
        console.log('Checked rows:', checkedRows);
      };


      return ({
        activation: (
          <div>
            <CheckboxLabel Check={checker.includes(s?.num) ? true : false} handleChangeValue={() => handleChangeValue(s)}
              disabeled={s?.role === "Propriétaire" ? true : false} label={''} />
          </div>
        ),
        profile: s?.profile === "" && s?.nom !== "" ? <span style={{ color: getProfileColor(s?.role) }}>{s?.nom?.toUpperCase().split(" ")[0][0] + s?.nom?.toUpperCase().split(" ")[1][0]}</span> : s?.profile === "" && s?.nom === "" ? <span>{s?.email.toUpperCase().slice(0, 2)}</span> : <img className="user-grid-profile-image" src={s?.profile} alt="avatar" />,
        nom: s?.nom || 'N/A',
        email: s?.email || '',
        userName: s?.username === "" ? <span style={{ textAlign: 'center', width: '50%', display: 'block' }}>N/A</span> : s?.username,
        role:

          s?.status === "Demande envoyée" ? (
            <div style={{ display: 'ruby', alignItems: 'center', gap: '5px' }}>
              <div className='badge-color-na'>{s?.role || 'N/A'}</div>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33333 5.70573H8.45833V8.03906L10.2083 9.20573L10.6458 8.48823L9.33333 7.60156V5.70573ZM9.04167 3.66406L0.875 0.164062V4.2474L6.125 5.41406L0.875 6.58073V10.6641L5.11583 8.8499C5.59417 10.5649 7.16917 11.8307 9.04167 11.8307C11.2933 11.8307 13.125 9.99906 13.125 7.7474C13.125 5.49573 11.2933 3.66406 9.04167 3.66406ZM9.04167 10.6641C7.4375 10.6641 6.13667 9.36906 6.125 7.7649V7.72406C6.13667 6.12573 7.4375 4.8249 9.04167 4.8249C10.6517 4.8249 11.9583 6.13156 11.9583 7.74156C11.9583 9.35156 10.6517 10.6641 9.04167 10.6641Z" fill="#EA580C"/>
</svg>

              <span className='material-icon' style={{ cursor: 'pointer', color: '#EA580C' }} onClick={() => setExpandedRow(index)}>expand_more</span>
              {expandedRow === index && (
                <div
                  id={`expandableDiv-${index}`}
                  style={{ minWidth: '200px', height: '130px', backgroundColor: 'white', position: 'absolute', borderRadius: '2px', display: 'grid', padding: '10px', boxShadow: '2px 2px 2px 2px #d9cece' }}>

                  <div><span className="material-icon" style={{ color: 'green' }}>send</span> Invitation</div>
                  <br />
                  <div style={{ display: 'grid', justifyContent: 'center', gap: '8px' }}>
                    <div className="color-na">
                      {s?.role || ''}
                    </div>
                    <br />
                    <div style={{ width: '112px', display: 'flex', justifyContent: 'center' }}><Button size={"xs"} color={"error"} styling={"solid"} args={{ onClick: () => handleCancelRequest(s?.email) }} label="Annuler" leftIcon="block"></Button></div></div>


                </div>)}
            </div>
          ) : s?.status === "En attente approbation" ? (
            <div style={{ display: 'ruby', alignItems: 'center', gap: '5px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <span style={{ color: '#10B981' }}>•</span>
                <div className={roleClass}>
                  {getRoleForUser(s.username)}               </div>
              </div>
              <span className="icon" style={{ color: '#EA580C', fill: '1' }}>
                schedule
              </span>
              {/* <span className='material-icon' style={{ cursor: 'pointer', color: '#EA580C' }} onClick={() => setExpandedRow(index)}>expand_more</span>
              {expandedRow === index && (
                <div
                  id={`expandableDiv-${index}`}
                  style={{ width: '200px', height: '130px', backgroundColor: 'white', position: 'absolute', borderRadius: '2px', display: 'grid', padding: '10px', boxShadow: '2px 2px 2px 2px #d9cece' }}>

                  <div><span className="material-icon" style={{ color: '#EA580C', rotate: '180deg' }}>send</span> Demande</div>
                  <div style={{ display: 'grid', justifyItems: 'center' }}><div className={roleClass}>
                    {s?.role || ''}
                  </div>
                    <br />
                    <div style={{ width: '112px', display: 'flex', justifyContent: 'center', gap: '8px' }}>
                      <Button size={"xs"} color={"error"} styling={"solid"} args={{ onClick: () => handleRefuse(s) }} label="Refuser" leftIcon="thumb_down"></Button>
                      <Button size={"xs"} color={"success"} styling={"solid"} args={{ onClick: () => handleApprove(s) }} label="Accepter   " leftIcon="thumb_up"></Button>

                    </div></div>


                </div>)}
 */}

                
                </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ color: '#10B981' }}>•</span>
              <div className={roleClass}>
                {s?.role || ''}
              </div>
            </div>
          )

        ,
        // status: <span style={{ color: s?.status === "Actif" ? '#10B981' : s?.status === "Demande envoyée" ? '#2C61E7' : s?.status === "En attente approbation" ? '#F59E0B' : "black" }}>• {s?.status}</span>,
        dateModification: s?.dateModification || '',
        actions: (
          <MoreActions
            actions={{
              'edit': () => handleEdit(s),
              'delete': () => handleDeleteOnTable(s?.email),
              'duplicate': () => handleTabChange('duplicate'),
              'cancelRequest': () => handleCancelRequest(s?.email),
              'approve': () => handleApprove(s),
              'refuse': () => handleRefuse(s),
              'approveRole':()=>handleApprove(s),
              'refuseRole':()=>handleRefuse(s),
            }}
            style={{ cursor: 'pointer', marginTop: '30px' }}
            status={s?.status}
            roleDemande={s?.role}
          />
        ),
        checker: checker.includes(s?.num) ? "yes" : "no"
      })
    }), { selectAll: allChecked, isSelectAll: handleAllChecked }]
    return (<div>
      <UsersTable data={data} />
      {/* <button onClick={exportToCSV} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#2563EB', color: 'white', border: 'none', borderRadius: '5px' }}>
        Exporter les utilisateurs sélectionnés
      </button> */}
    </div>);
  }


  const [nom, setNom] = useState<string>('');
  const [prenom, setPrenom] = useState<string>('');
  const handleEdit = (userInfo) => {

    console.log("check user infos",userInfo)
    setShowModalEdit(true);
    setShowModal(true);
    setNom(userInfo.nom.split(' ')[0]);
    setPrenom(userInfo.nom.slice(userInfo.nom.indexOf(' ') + 1));
    setEmailUser(userInfo.email);
    setSelectedRole(userInfo.role);
    setSelectedSouche(userInfo.selectedSouche);
  //setActivated(userInfo.activation);

  }
  const handleAddUserInvitationClick = () => {

    // if(emailUser==='tst'){
    //   console.log("emailUser",emailUser);
    //   console.log("invitationsData",invitationsData);
    //   setErrorExistingUser(true);
    //   return;
    // }

    for (let i = 0; i < invitationsData.length; i++) {
      if (invitationsData[i].email === emailUser) {
        setErrorExistingUser(true);
        return;
      }
    }


    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const UserObject = {
      num: invitationsData.length + 1,
      email: emailUser,
      souche: selectedRole === 'Utilisateur' ? selectedSouche : 'Aucune souche',
      role: selectedRole,
      dateInvitation: formattedDate,

    }
    //num: number, email: string, souche: string, role: string, dateInvitation: string

    const userInvitation = {
      num: userNUM++,
      nom: "",
      email: emailUser,
      profile: "",
      role: selectedRole,
      status: "Demande envoyée",
      username: emailUser
    }

    console.log("UserObject added", userInvitation);


    setUsersData([userInvitation, ...usersData]);
    invitationsData.push(UserObject);
    setInvitationsData([...invitationsData]);
    addToast("L'invitation a été envoyée avec succès", 'success');
    console.log("invitationsData added", invitationsData);
    if (props.hintType === "userModal") {
      props.sethintType("groupHint")
    }
    else {
      closeModal();
    }
  }
  const handleEnregistrerClick = () => {

    closeModal();
    setActivated(!activated);

  }

  const UserMobileMoreActions = ({ actions }: { actions: any }) => {

    const [showActions, setShowActions] = useState<boolean>(false);

    return (<div>
      <span className='material-icon' onClick={() => setShowActions(true)}>more_horiz</span>
      {showActions && React.cloneElement(actions, { closeCallback: () => { setShowActions(false) }, style: { top: '0' } })}
    </div>)
  }

  const [showSelectedOnlyGroups, setShowSelectedOnlyGroups] = useState(false);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [showSelectedOnlyResources, setShowSelectedOnlyResources] = useState(false);
  const filterCheckedNodes = (nodes: TreeNode[]): TreeNode[] => {
    let filteredNodes: TreeNode[] = [];
    nodes.forEach(node => {
      if (node.checked) {
        filteredNodes.push({
          ...node,
          children: node.children ? filterCheckedNodes(node.children) : []
        });
      }
    });
    return filteredNodes;
  };




  const countLastChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0) {
        count++;
      } else {
        count += countLastChildren(node.children);
      }
    });
    return count;
  };

  const countCheckedChildren = (nodes: TreeNode[]): number => {
    let count = 0;
    nodes.forEach(node => {
      if (node.children.length === 0 && node.checked) {
        count++;
      } else if (node.children.length > 0) {
        count += countCheckedChildren(node.children);
      }
    });
    return count;
  };

  const toggleChildren = (parentId: number) => {
    setTreeProfiles(prevData =>
      prevData.map(node => {
        if (node.id === parentId) {
          return { ...node, isOpen: !node.isOpen };
        } else {
          return node;
        }
      })
    );
  };
  const handleCheckboxChangeGroupe = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(mygroupes, childId, checked);
    setMygroupes(updatedTreeData);
  };
  const handleCheckboxChange = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(treeProfiles, childId, checked);
    setTreeProfiles(updatedTreeData);
  };

  const handleCheckboxChangeResource = (childId: number, checked: boolean) => {
    console.log("Checkbox clicked - Child ID:", childId, "Checked:", checked);
    let updatedTreeData = updateCheckedState(trRessource, childId, checked);
    setTrRessource(updatedTreeData);
  };

  const updateCheckedState = (nodes: TreeNode[], childId: number, checked: boolean): TreeNode[] => {
    return nodes.map(node => {
      if (node.id === childId) {
        return { ...node, checked: checked };
      } else if (node.children && node.children.length > 0) {
        const updatedChildren = updateCheckedState(node.children, childId, checked);
        const someChildrenChecked = updatedChildren.some(child => child.checked);
        const allChildrenChecked = updatedChildren.every(child => child.checked);
        return {
          ...node,
          checked: someChildrenChecked ? true : false,
          indeterminate: someChildrenChecked && !allChildrenChecked ? true : false,
          children: updatedChildren
        };
      } else {
        return node;
      }
    });
  };
  const [allModules, setAllModules] = useState<WorkingDirTree[]>([]);
  var moduleIdCounter = 1;
  useEffect(
    () => {
      getAppsDetails(null).then((apps) => { setAllModules(apps) })
    }, []
  )
  useEffect(() => {
    // console.log("allModules from use:", allModules);
    const uniqueAppNames = new Set();

    allModules.map((app) => {
      if (!uniqueAppNames.has(app.name)) {
        // console.log("appname", app.name)

        getWorkingDirectorTree(app.name).then(
          (trees: WorkingDirTree) => {
            // setAppObj(tree);
            // console.log("tree from grp", trees);
            const appNode: TreeNode = {
              id: moduleIdCounter++,
              name: `Application: ${app.name}`,
              isOpen: false,
              children: [],
            };

            const fetchDataProfilesGroupes = async () => {
              try {
                const processModulesChildren = (modulesChildren: any) => {
                  const tree: TreeNode[] = [];

                  //console.log("modulesChildreLog", modulesChildren)
                  for (const m in modulesChildren) {
                    if (!m.endsWith(".json")) {
                      const modeleName = m.split(".")[0];
                      const moduleChildren = modulesChildren[m]?.children;
                      const moduleNode: TreeNode = {
                        id: moduleIdCounter++,
                        name: `Module : ${modeleName}`,
                        isOpen: false,
                        children: [],
                      };
                      const processChildren = moduleChildren?.process?.children;
                      //  console.log("ProcessLOG", processChildren);
                      for (const c in processChildren) {
                        if (c.endsWith(".bpmn")) {
                          const processName = c.split(".")[0];
                          const processNode: TreeNode = {
                            id: moduleIdCounter++,
                            name: `Processus : ${processName}`,
                            isOpen: false,
                            children: [],
                          };
                          moduleNode.children.push(processNode);
                          let activities: any[] = [];
                          const processFilePath = trees?.children?.[modeleName].children?.process?.children?.[`${processName}.bpmn`]?.info?.filePath ?? "";
                          // console.log("processFilePath ", modeleName, processFilePath);
                          fetchKlsResource(app.name, processFilePath)
                            .then((resource) => {
                              // console.log("resource tttttt for", resource, "Process", processName);
                              activities = getActivitiesFromXml(resource.content ?? "");
                              // console.log("resresres",resource.content);
                              //console.log("activities ggg", activities);
                              //  console.log(`Activities for process work ${processName}:`, activities);
                              for (const activity of Object.values(activities)) {
                                //    console.log(`Activity afficher : ${activity.name}`);
                                processNode.children.push({
                                  id: moduleIdCounter++,
                                  name: `Activite : ${activity.name}`,
                                  isOpen: false,
                                  children: [],
                                });
                              }
                            })
                            .catch((error) => {
                              console.error(`Error fetching activities for process ${processName}:`, error);
                            });
                        }
                      }

                      if (moduleNode.children.length > 0) {
                        appNode.children.push(moduleNode);
                      }
                    }
                  }
                  if (appNode.children.length > 0) {
                    tree.push(appNode);

                  }

                  return tree;
                };

                // const treeDaaBack = processModulesChildren(trees?.children);
                // setTreeProfiles(prevData=>{
                //   return [...prevData, ...treeDaaBack];
                // });
                // setTreeProfiles(treeDaaBack);
                const treeDaaBack = processModulesChildren(trees?.children);
                setTreeProfiles(prevData => {
                  const existingIds = new Set(prevData.map(node => node.name));
                  const newData = treeDaaBack.filter(node => !existingIds.has(node.name));
                  const newDataWithIds = newData.map(node => ({ ...node, id: moduleIdCounter++ }));
                  return [...prevData, ...newDataWithIds];
                });
                //   console.log("treeDaaBackkk", treeDaaBack);
                //     console.log("myTr", treeProfiles)
                //setTrRessource([appNodeResource]);
              } catch (error) {
                console.error('Error fetching data nana:', error);

              }
            };

            fetchDataProfilesGroupes();
            uniqueAppNames.add(app.name);
          }
        )
      }
    })
  }, [allModules]);
  useEffect(() => {
    // console.log("allModules from use:", allModules);
    const uniqueAppNamesR = new Set();

    var moduleIdCounters = 1;

    const fetchMenusForAllApps = async () => {
      try {
        let menuNodesAccumulated: TreeNode[] = [];

        for (const app of allModules) {
          //console.log("appname", app.name);

          const trees: WorkingDirTree = await getWorkingDirectorTree(app.name);

          const processMenusForApp = async (modulesChildrenRessources: any) => {
            const menuNodes: TreeNode[] = [];
            for (const moduleName in modulesChildrenRessources) {
              if (!moduleName.endsWith(".json")) {
                const moduleChildren = modulesChildrenRessources[moduleName]?.children;
                const resourcesNodes: Set<string> = new Set();
                await processMenus(moduleChildren, menuNodes, resourcesNodes, new Set<string>());
              }
            }
            return menuNodes;
          };

          const processMenus = async (menus: any, menuNodes: TreeNode[], processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode) => {
            if (!menus) return;
            for (const menuName in menus) {
              if (menuName.endsWith("-menu.xml")) {
                const menuFilePath = menus[menuName]?.info?.filePath;
                try {
                  const resource = await fetchKlsResource(app.name, menuFilePath);
                  const xmlContent = resource?.content ?? '';
                  const parsedMenus = parseXmlMenu(xmlContent);
                  for (const menu of parsedMenus) {
                    const menuNode = await buildMenuTree(menu, processedMenuIds, processResourcesIds);
                    if (menuNode) {
                      menuNodes.push(menuNode);
                    }
                  }
                } catch (error) {
                  console.error(`Error fetching resources for menu ${menuName}:`, error);
                }
              }
            }
          };

          const buildMenuTree = async (menu: any, processedMenuIds: Set<string>, processResourcesIds: Set<string>, parentMenu?: TreeNode): Promise<TreeNode | null> => {
            if (processedMenuIds.has(menu.label)) {
              for (const resource of menu.resources) {
                if (processResourcesIds.has(resource.content)) {
                  return null;
                }
              }
            }

            const menuNode: TreeNode = {
              id: moduleIdCounters++,
              name: menu.label,
              isOpen: false,
              children: [],
            };

            processedMenuIds.add(menu.label);
            const menuChildren = menu.children || [];
            for (const childMenu of menuChildren) {
              const childNode = await buildMenuTree(childMenu, processedMenuIds, processResourcesIds, menuNode);
              if (childNode) {
                menuNode.children.push(childNode);
              }
            }

            if (menuChildren.length === 0 && menu.resources) {
              for (const resource of menu.resources) {
                if (!resource) {
                  setDisplayNode(false);
                }
                processResourcesIds.add(resource.content);
                const resourceNode: TreeNode = {
                  id: moduleIdCounters++,
                  name: `Resource:${resource.content} Type: ${resource.type}`,
                  isOpen: false,
                  children: [],
                  display: false
                };
                menuNode.children.push(resourceNode);
              }
            }

            return menuNode;
          };

          const menuNodesForApp = await processMenusForApp(trees?.children);

          const appNodeWithMenus: TreeNode = {
            id: moduleIdCounters++,
            name: `Application: ${app.name}`,
            isOpen: false,
            children: menuNodesForApp,
          };
          menuNodesAccumulated.push(appNodeWithMenus);
        }

        setTrRessource(menuNodesAccumulated);
        //console.log("myTrRes", trRessource)
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenusForAllApps();
  }, [allModules]);

  const parseXmlMenu = (xmlContent: string): any[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xmlContent, "application/xml");
    const elements = Array.from(doc.documentElement.children);
    return elements.map(parseElement);
  };

  const parseResource = (resourceElement: Element): any => {
    return {
      type: resourceElement.getAttribute("type"),
      content: resourceElement?.textContent?.trim()
    };
  };
  const parseElement = (element: Element): any => {
    const tagName = element.tagName;
    if (tagName === "menu") {
      return parseMenu(element);
    } else if (tagName === "resource") {
      return parseResource(element);
    }
  }
  const parseMenu = (menuElement: Element): any => {
    const id = menuElement.getAttribute("relId" || "absId");
    const label = menuElement.getAttribute("label");
    const childrenElements = Array.from(menuElement.getElementsByTagName("menu"));
    const resourcesElements = Array.from(menuElement.getElementsByTagName("resource"));
    const children = childrenElements.map(parseMenu);
    const resources = resourcesElements.map(parseResource);

    return { id, label, children, resources };
  };
  const getActivitiesFromXml = (xml) => {
    const activities = Array();
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "application/xml");
    Array.from(doc.getElementsByTagName("bpmn2:userTask")).map((task) => {
      const id = task.getAttribute("id");
      const name = task.getAttribute("name");
      const mask = task.getAttribute("tns:screenMask");
      activities.push({ id, name, mask });
    });

    return activities;
  };
  const renderContent = () => {
    let filteredTreeDataG = mygroupes;
    if (showSelectedOnlyGroups) {
      filteredTreeDataG = filterCheckedNodes(mygroupes);
    }
    let filteredTreeData = treeProfiles;
    if (showSelectedOnly) {
      filteredTreeData = filterCheckedNodes(treeProfiles);
    }
    let filteredTreeDataResource = trRessource;
    if (showSelectedOnlyResources) {
      filteredTreeDataResource = filterCheckedNodes(trRessource);
    }
    switch (activeTab) {

      case 'tab1':
        const totalChildrenG = countLastChildren(mygroupes);
        const checkedChildrenG = countCheckedChildren(mygroupes);

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildrenG}/{totalChildrenG} groupes allouées
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnlyGroups(!showSelectedOnlyGroups);

                }}>
                {showSelectedOnlyGroups ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataG}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeGroupe}
            />
          </div>
        );
      case 'tab2':
        const totalChildren = countLastChildren(treeProfiles);
        const checkedChildren = countCheckedChildren(treeProfiles);

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildren}/{totalChildren} activités allouées
              </span>
              <span className="text-select-tree"
                onClick={() => {
                  setShowSelectedOnly(!showSelectedOnly);

                }}>
                {showSelectedOnly ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeData}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
        );
      case 'tab3':
        const totalChildrenR = countLastChildren(trRessource);
        const checkedChildrenR = countCheckedChildren(trRessource);

        return (
          <div>
            <div className="header-selection-tree">
              <span className="text-activity">
                {checkedChildrenR}/{totalChildrenR} ressources allouées
              </span>
              <span className="text-select-tree" onClick={() =>
                setShowSelectedOnlyResources(!showSelectedOnlyResources)
              }>
                {showSelectedOnlyResources ? 'Afficher tous' : 'Afficher uniquement la sélection'}
              </span>
            </div>
            <TreeElementsModules
              treeData={filteredTreeDataResource}
              toggleChildren={toggleChildren}
              handleCheckboxChange={handleCheckboxChangeResource}
            />
          </div>
        ); default:
        return null;
    }
  };
  const handleTabsSelect = (tabName: string) => {
    setActiveTab(tabName);
  }

  return (
    <div className='roles-empty-container' style={{ padding: '0px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
      {/* div1 */}
      <div className="group-header-settings">
        <SettingsContainerHeader title="Utilisateurs" subtitle="Gérer les utilisateurs de votre organisation" enableAction={true}
          buttonName="Nouvel utilisateur" icon="add" onClick={handleNouvelUserClick} />
      </div>
      {/* div2 */}
      <div className="table-users-container">
        <div className="user-search-div">
          <span>
            <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} />
          </span>
          <div className="users-search-view">
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ display: 'flex', alignItems: 'center', color: '#64748B', fontSize: '13px', textWrap: 'nowrap' }}>Trier par :</span>
              <div className='sort-name' onClick={() => { setShowFilter(!showFilter) }}>
                <span>{listFilter.find((f: any) => f?.value === filterUsers)?.label}</span>
                <div className='sort-expand-more' >
                  <span className='material-icon' >expand_more</span>
                  <div style={{ position: 'absolute', top: '25px', right: '0' }}>
                    {showFilter &&
                      <AdvancedDropDown data={listFilter} enableSearch={false}
                        light={true}
                        handleChange={(item) => { setFilterUsers(item.value) }}
                        size={1}
                        selectedValue={filterUsers.value}
                        style={{ top: '25px', minWidth: '100px', right: '0px' }}
                        closeWhenSelect={() => { setShowFilter(false) }}
                        closeWhenClickOutside={() => { setShowFilter(false) }} />
                    }
                  </div>
                </div>
              </div>
              <div style={{ width: '1px', height: '24px', backgroundColor: '#E2E8F0' }}></div>
            </div>
            <div className="users-change-view">
              <div className="user-grid-view-icon" style={{ cursor: 'pointer', backgroundColor: viewType == 'grid' ? '#F8FAFC' : 'white' }} onClick={() => { setviewType('grid'); }}><span className="icon" style={{ color: viewType == 'grid' ? '#2563EB' : '#64748B' }}>grid_view</span></div>
              <div className="user-list-icon" style={{ cursor: 'pointer', backgroundColor: viewType == 'list' ? '#F8FAFC' : 'white' }} onClick={() => { setviewType('list'); }}><span className="icon" style={{ color: viewType == 'list' ? '#2563EB' : '#64748B', backgroundColor: viewType == 'list' ? '#F8FAFC' : 'white' }}>format_list_bulleted</span></div>
            </div>
          </div>
        </div>
      </div>
      {/* optional div */}
      {requestAction && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '14px' }}>
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#2563EB' }}>
          <span className="icon">file_download</span>
          <span style={{ cursor: 'pointer' }} onClick={() => exportToCSV(filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)), checker)}>Exporter l’utilisateur</span>
        </div>
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#DC2626' }}>
          <span className="icon">delete</span>
          <span style={{ cursor: 'pointer' }} onClick={() => deleteUsers(filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)), checker)}>Supprimer l’utilisateur</span>
        </div>
      </div>}
      {/*div 3*/}

      {viewType == 'grid' ? <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div className="user-cards">
          {
            filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).map((c, index) => {
              return (<UsersCard actions={<MoreActions
                actions={{
                  'edit': () => handleEdit(c),
                  'delete': () => handleDeleteOnTable(c?.email),
                  'duplicate': () => handleTabChange('duplicate'),
                  'cancelRequest': () => handleCancelRequest(c?.email)
                }}
                style={{ cursor: 'pointer', marginTop: '30px' }}
                status={c.status}
                roleDemande={c.role}
              />}
                avatarInital={c.username} image={c.profile} type={'organization'} role={c.role} email={c.username+'@karaz.orghhhhhhh'} viewType={viewType} setviewType={setviewType}
                indexUser={index}
                demande={c.status === "En attente approbation" ? true : false}
                invitation={c.status === "Demande envoyée" ? true : false}
                handleApprove={() => handleApprove(c)}
                handleCancelRequest={() => handleCancelRequest(c?.email)}
              />)
            })
          }
        </div>
        <div style={{ height: '50px' }}>
          <Pagination
            light={true}
            totalItems={filteredUsersData.length}
            itemsPerPage={itemsPerPageUsers}
            maxPage={Math.ceil(filteredUsersData.length / itemsPerPageUsers)}
            handlePageChange={handlePageChangeUsers}
          />
        </div></div> : viewType === 'list' ?
        <><div className="users-table-data-main">
          <div className="users-table-data-scroll"><AppTable servicesList={filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData))} /></div>
          {filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).map((c) => {
            return (
              <div className="users-table-mobile">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <UserMobileMoreActions actions={<MoreActions
                    actions={{
                      'edit': () => handleEdit(c),
                      'delete': () => handleDeleteOnTable(c?.email),
                      'duplicate': () => handleTabChange('duplicate'),
                      'cancelRequest': () => handleCancelRequest(c?.email)
                    }}
                    style={{ cursor: 'pointer', marginTop: '30px' }}
                    status={c.status}
                    roleDemande={c.role}
                  />} />
                </div>
                <div className="users-list-mobile">
                  <div style={{ width: '20%' }}>{c.profile !== "" ? <img className="user-list-profile-mobile-image" src={c.profile} alt="avatar" /> : <div style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', boxShadow: '0px 4px 6px -4px #0000001A, 0px 10px 15px -3px #0000001A', color: getProfileColor(c.role) }}>{c.nom !== "" ? c?.nom?.split(" ")[0][0].toUpperCase() + c?.nom?.split(" ")[1][0].toUpperCase() : c.email.substring(0, 2).toUpperCase()}</div>}</div>
                  <div style={{ width: '45%' }}>
                    <div style={{ fontSize: '16px', fontWeight: '600', paddingLeft: c.nom !== "" ? '0' : '30px', display: 'flex', alignItems: 'center' }}>{c.nom !== "" ? c.nom : '-'}</div>
                    <div style={{ fontSize: '12px', color: '#636363' }}>{c.username}</div>
                  </div>
                  <div>
                    <div className={`badge ${getColorClass(c.role)}`} style={{ height: 'max-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{c.role}</div>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
          <div style={{ height: '50px' }}>
            <Pagination
              light={true}
              totalItems={filteredUsersData.length}
              itemsPerPage={itemsPerPageUsers}
              maxPage={Math.ceil(filteredUsersData.length / itemsPerPageUsers)}
              handlePageChange={handlePageChangeUsers}
            />
          </div></> : <></>
      }
      {showModal && (
        <Modal
          icon=""
          leftBtnArgs={{
            args: { onClick: closeModal },
            color: 'neutral',
            label: 'Annuler',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            args: { onClick: showModalEdit ? handleEnregistrerClick : handleAddUserInvitationClick },
            color: 'primary',
            label: { 'Ajouter': 'Ajouter', 'Enregistrer': 'Enregistrer' }[showModalEdit ? 'Enregistrer' : 'Ajouter'],
            size: 'sm',
            styling: 'solid',

          }}
          title={showModalEdit ? "Détails de l'utilisateur" : "Nouvel utilisateur"}
          onClose={closeModal}
          bodyStyle={{ padding: '20px' }}
        >
          {showModalEdit && (
            <div className='tabs-roles-edit'>
              <Tabs current={'general'}
                align="horizontal"
                defSelected="general"
                icon={[]}
                iconAndName={[]}
                icons={[]}
                item={[]}
                onSelect={handleTabsEdit}
                size="small"
                tabs={[
                  {
                    label: 'Général',
                    name: 'general'
                  },
                  {
                    label: 'À propos',
                    name: 'propos'
                  },

                ]}
              /></div>)}
          {activeTabEdit === "general" ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {showModalEdit &&
                <div style={{ display: 'grid', marginTop: '20px' }}>

                  <div style={{ float: 'right', display: 'inline-grid', justifyItems: 'right' }}><ToggleButton label="Activer" handleToggle={handleActivation} ischecked={activated} ></ToggleButton></div>
                  <div style={{ display: 'flex', gap: '205px' }}>
                    <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Prénom</span>
                    <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Nom</span>
                  </div>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <Input
                      size='sm'
                      args={{
                        onChange: handleEmailUserChange,
                        value: nom,
                        disabled: true
                      }}
                    />
                    <Input
                      size='sm'
                      args={{
                        onChange: handleEmailUserChange,
                        value: prenom,
                        disabled: true
                      }}
                    />
                  </div>
                </div>

              }

              <div>
                {errorExistingUser && <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='error-div' style={{ width: '100%', marginBottom: '20px' }}>
                    <span className="material-icon" style={{ color: '#DC2626' }}>error_outline</span>
                    <span className='error-msg'>Un utilisateur avec l'adresse e-mail fournie est déjà ajouté.</span>
                  </div>
                </div>}
                {!showModalEdit &&
                <div style={{ display: 'grid', marginTop: '20px' ,marginBottom:'10px'}}>

                  <div style={{ display: 'flex', gap: '205px' }}>
                    <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Prénom</span>
                    <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Nom</span>
                  </div>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <Input
                      size='sm'
                      args={{
                        onChange: handleNomUserChange,
                        value: nomUser,
                      }}
                    />
                    <Input
                      size='sm'
                      args={{
                        onChange: handlePrenomUserChange,
                        value: prenomUser,
                      }}
                    />
                  </div>
                </div>

              }
                <span className="add-name-modal-text">Email</span>
                <Input
                  size='sm'
                  args={{
                    placeholder: 'Saisissez l’adresse email d’utilisateur',
                    onChange: handleEmailUserChange,
                    value: emailUser,
                    disabled: showModalEdit
                  }}
                  errorMessage={isInputModified && emailUser.trim() === '' ? 'Ce champ est requis' : ''}
                />
              </div>
              <hr className="horizontal-line" />
              <div className="role-organisation-dropdown" ref={dropdownRef} >
                <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Role dans l'organisation</span>
                <Dropdown
                  args={{
                    style: { position: 'relative' },
                  }}
                  label=""
                  klsCompatible={true}
                  data={rolesOrganisation}
                  size="xs"
                  elementSize={36}
                  value={selectedRole}
                  onSelect={handleRoleOrganisationChange}
                  onOpen={handleDropdownOpen} 

                
                />

              </div>
              {selectedRole === "Utilisateur" && (
                <div>
                  {/* <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Souche</span>
                  <SearchInput light={true} size={3} value={selectedSouche} setValue={handleSearchSoucheChange} placeholder="Choisissez une souche" onClick={() => { setSearchSouche(!searchSouche) }}
                  />
                  {searchSouche && (
                    <div className="drop-souche">
                      <AdvancedDropDown
                        handleChange={handleSoucheSelect}
                        data={souches}
                        enableSearch
                        selectedValue={selectedSouche}
                        size={3}
                        maxItemsPerPage={2}
                        style={{ width: '100%' }}
                        closeWhenClickOutside={() => { setSearchSouche(false) }}
                      /></div>
                  )} */}

                  <SelectTabs current={activeTab}
                    align="horizontal"
                    defSelected={activeTab}
                    icon={[]}
                    iconAndName={[]}
                    icons={[]}
                    item={[]}
                    onSelect={handleTabsSelect}
                    size="small"
                    tabs={[
                      {
                        label: 'Groupes',
                        name: 'tab1'
                      },
                      {
                        label: 'profils',
                        name: 'tab2'
                      },
                      {
                        label: 'Ressources',
                        name: 'tab3'
                      },
                    ]}
                  />
                  <div>
                    {renderContent()}
                  </div>

                </div>)}

            </div>
          ) : (activeTabEdit === "propos" &&
            <div className="about-tabs-informations">

              <div className='flex-space'><span className='title-propos'>Date d'ajout:</span> <span className='descp-propos'>{aboutData.creationDate}</span></div>
              <div className='flex-space'><span className='title-propos'>Dernière modification: </span><span className='descp-propos'>{aboutData.lastModification}</span></div>
              <hr style={{ border: 'none', borderTop: '1px solid #cdd4e0', margin: '10px 0' }} />
              <div className='flex-space'><span className='title-propos'>Dernière connexion:</span> <span className='descp-propos'>{aboutData.lastConnexion}</span></div>
            </div>)}

          <div />
        </Modal>
      )}
      {showModalDelete &&
        viewDelete
      }
    </div>
  );
}

