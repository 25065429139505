import React from "react"
import Spinner from "./spinner"


export const LoadingApplication = ( {message}:{message:string} ) => {
    return <div style={loadingPageStyle}>
        <div style={spinnerContainerStyle}>
            <Spinner scale={1}/>

            <div style={ {width: '100%'} }>
                <div style={textTopStyle}>{message}</div>

                <div style={textBottomContainerStyle}>
                    <span style={textBottomStyle}>Cela ne devrait prendre que quelques instants</span>
                </div>
            </div>
        </div>
    </div>
}


const loadingPageStyle:React.CSSProperties = {
    position:'fixed',
    width:'100vw',
    height:'100vh',
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
    left: 0,
    top: 0,
    zIndex:1000000,
    background: 'rgba(30, 41, 59, 0.24)'
}

const spinnerContainerStyle:React.CSSProperties = {
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
    display: 'flex',
    width: '320px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px'
}  

const textTopStyle:React.CSSProperties = {
    overflow: 'hidden',
    color: '#1E293B',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px', 
    whiteSpace: 'nowrap'
}

const textBottomContainerStyle:React.CSSProperties = {
    overflow: 'hidden',
    color: '#64748B',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    
} 

const textBottomStyle:React.CSSProperties = {
    fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '19px', 
    whiteSpace: 'nowrap'
}