import { useEffect, useState } from "react";
import "../../app-params/param-app.css";
import React from 'react';
import { Button } from "kls-ui";

export function UsersCard(props: any) {
    const [usersMoreOptions, setusersMoreOptions] = useState(false);
    const [usersMoreOptionsHovered, setusersMoreOptionsHovered] = useState(false);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            const divRef = document.getElementById(`expandableDiv-${expandedRow}`);
            if (divRef && !divRef.contains(event.target)) {
                setExpandedRow(null);
            }
        }

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [expandedRow]);
    const moreOptionsIcon = {
        borderRadius: '4px',
        cursor: 'pointer',
        background: usersMoreOptions ? '#E2E8F0' : (usersMoreOptionsHovered ? '#F1F5F9' : '#fff'),
        color: usersMoreOptions ? '#1E293B' : (usersMoreOptionsHovered ? '#475569' : ' #64748B'),
        fontVariationSettings: "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 16",
        fontSize: '16px'
    };

    let [avatarType, setavatarType] = useState('image');
    let inital = '';
    const GetInitials = (string) => {
        if (string !== '' && string != null) {
            var names = string.split(' '),
                initials = names[0].substring(0, 1).toUpperCase();

            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        } else if (string === '') {
            return initials = props.email.substring(0, 2).toUpperCase();
        } else {
            return '';
        }
    };

    if (avatarType == 'inital') {
        inital = GetInitials(props.avatarInital);
    }
    function getColorClass(role) {
        switch (role.toLowerCase()) {
            case 'administrateur':
                return 'color-admin';
            case 'proprietaire':
                return 'color-proprietaire';
            case 'citizen developer':
                return 'color-citizen-developer';
            case 'utilisateur':
                return 'color-utilisateur';
            case 'N/A':
                return 'color-na';
            default:
                return '';
        }
    }
    const RoleColor = props.role === "Proprietaire" ? '#34D399' :
        props.role === "Administrateur" ? '#60A5FA' :
            props.role === "Citizen Developer" ? '#A78BFA' :
                props.role === "Utilisateur" ? '#FACC15' : 'grey';

    const roleClass = `badge ${getColorClass(props.role)}`;
    return (
        <div className="user-card-main">
            <div className="user-card-container"
                onMouseLeave={() => setusersMoreOptionsHovered(false)}
                onMouseOver={() => setusersMoreOptionsHovered(true)}>
                {
                    ((usersMoreOptions || usersMoreOptionsHovered) &&
                        <div className="user-moreoptions-container">
                            <span className="material-icon"
                                style={moreOptionsIcon}
                                onClick={() => setusersMoreOptions(true)}
                                onMouseLeave={() => setusersMoreOptionsHovered(false)}
                                onMouseOver={() => setusersMoreOptionsHovered(true)}>
                                more_horiz
                            </span>
                            {
                                (usersMoreOptions) &&
                                React.cloneElement(props.actions, {
                                    closeCallback: () => { setusersMoreOptions(false) },
                                    style: { top: '25px', right: '0', minWidth: '100px' }
                                })
                            }
                        </div>
                    )
                }
                <div className="user-card-avatar">
                    <div className="user-card-profile"
                        style={{
                            borderRight: `2.28px solid ${RoleColor}`,
                            borderBottom: `2.28px solid ${RoleColor}`,
                            borderTop: `2.28px solid ${RoleColor}`
                        }}>
                        <div className="user-card-profile-outline"
                            style={{ border: `1px solid ${RoleColor}` }}>
                            {
                                avatarType == 'image' ?
                                    <div>
                                        <img className="user-card-profile-image"
                                            src={props.image}
                                            alt="avatar"
                                            onError={(e) => setavatarType('inital')} />
                                    </div>
                                    : <div className="user-card-profile-initial"
                                        style={{ color: RoleColor }}>
                                        {inital}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="user-card-details">
                    <span style={{ fontWeight: '700', fontSize: '16px', textAlign: 'center' }}>
                        {props.avatarInital !== '' ? props.avatarInital : '-'}
                    </span>
                    <span style={{ color: '#26235C', fontSize: '12px' }}>
                        {props.role}
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <span style={{ color: '#10B981' }}>•</span>

                        <span className="user-card-email"
                            style={{
                                backgroundColor: RoleColor, whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                maxWidth: '140px',
                            }}>
                            {props.email}
                        </span>
                        {props.demande && (
                            <div style={{ display: 'flex' }}>
                                <span className="icon"
                                    style={{ color: '#EA580C', fill: '1' }}>
                                    schedule
                                </span>
                                <span className='material-icon'
                                    style={{ cursor: 'pointer', color: '#EA580C' }}
                                    onClick={() => setExpandedRow(props.indexUser)}>
                                    expand_more
                                </span>
                                {expandedRow === props.indexUser && (
                                    <div id={`expandableDiv-${props.indexUser}`}
                                        style={{
                                            width: '200px', height: '130px', backgroundColor: 'white',
                                            position: 'absolute', borderRadius: '2px', display: 'grid',
                                            padding: '10px', boxShadow: '2px 2px 2px 2px #d9cece',
                                            right: '-25px', top: '200px', zIndex: '1'

                                        }}>
                                        <div>
                                            <span className="material-icon"
                                                style={{ color: '#EA580C', rotate: '180deg' }}>
                                                send
                                            </span>
                                            Demande
                                        </div>
                                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                                            <div className={roleClass}>{props.role || ''}</div>
                                            <br />
                                            <div style={{
                                                width: '112px', display: 'flex', justifyContent: 'center', gap: '8px'
                                            }}>
                                                <Button size={"xs"} color={"error"} styling={"solid"}
                                                    args={{ onClick: () => props.handleRefuse(props) }}
                                                    label="Refuser" leftIcon="thumb_down" />
                                                <Button size={"xs"} color={"success"} styling={"solid"}
                                                    args={{ onClick: () => props.handleApprove(props) }}
                                                    label="Accepter" leftIcon="thumb_up" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {props.invitation && (
                            <div style={{ display: 'ruby', alignItems: 'center', gap: '5px' }}>
                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.33333 5.70573H8.45833V8.03906L10.2083 9.20573L10.6458 8.48823L9.33333 7.60156V5.70573ZM9.04167 3.66406L0.875 0.164062V4.2474L6.125 5.41406L0.875 6.58073V10.6641L5.11583 8.8499C5.59417 10.5649 7.16917 11.8307 9.04167 11.8307C11.2933 11.8307 13.125 9.99906 13.125 7.7474C13.125 5.49573 11.2933 3.66406 9.04167 3.66406ZM9.04167 10.6641C7.4375 10.6641 6.13667 9.36906 6.125 7.7649V7.72406C6.13667 6.12573 7.4375 4.8249 9.04167 4.8249C10.6517 4.8249 11.9583 6.13156 11.9583 7.74156C11.9583 9.35156 10.6517 10.6641 9.04167 10.6641Z" fill="#EA580C" />
                                </svg>

                                <span className='material-icon' style={{ cursor: 'pointer', color: '#EA580C' }} onClick={() => setExpandedRow(props.indexUser)}>expand_more</span>
                                {expandedRow === props.indexUser && (
                                    <div
                                        id={`expandableDiv-${props.indexUser}`}
                                        style={{
                                            width: '200px', height: '130px', backgroundColor: 'white',
                                            position: 'absolute', borderRadius: '2px', display: 'grid',
                                            padding: '10px', boxShadow: '2px 2px 2px 2px #d9cece',
                                            right: '-25px', top: '200px', zIndex: '1'

                                        }}>
                                        <div><span className="material-icon" style={{ color: 'green' }}>send</span> Invitation</div>
                                        <br />
                                        <div style={{ display: 'grid', justifyContent: 'center', gap: '8px' }}>
                                            <div className="color-na">
                                                {props.role || ''}
                                            </div>
                                            <br />
                                            <div style={{ width: '112px', display: 'flex', justifyContent: 'center' }}><Button size={"xs"} color={"error"} styling={"solid"} args={{ onClick: () => props.handleCancelRequest(props) }} label="Annuler" leftIcon="block"></Button></div></div>


                                    </div>)}
                            </div>
                        )}
                    </div>

                </div>

            </div>
        </div>
    )
}
