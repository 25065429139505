import { useState, useEffect, useRef } from 'react';
import { Modal } from 'kls-ui';
import { GS, saveGlobaleState } from "kls-commons/state/global-state";
import { Input} from "kls-ui";
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";
import { NewModel} from "./module.service";
import {LoadingApplication} from './loading-module';
import { time } from 'console';
import {FormRender} from 'kls-view-editor/form-render';
import { Tabs } from 'kls-ui/tabs/Tabs';
import { useHistory } from 'react-router-dom';

import './excel-upload.css';


const MAX_CARACTER_NAME = 40 ;
const MAX_STEPS = 6;

interface ExcelData {
    columns: string[];
    data: any[][]; 
}


export function ExcelUploadModal({moduleName,onExcelUpload,onClose}:{moduleName?:string,onExcelUpload:Function,onClose:Function}) {
    
    
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [fileWarning, setFileWarning] = useState<string | null>(null);

    const [isValidFileSelected, setIsValidFileSelected] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");

    const [directories, setDirectories] = useState<string[]>([]);

    const [showExcelModal,setShowExcelModal] = useState(true);

    const[excelPrev,setExcelPrev]= useState(false);
 
    const [showLoading, setShowLoading] = useState(false);

    const [showSteps, setShowSteps] = useState(false);

    const [activeStep, setActiveStep] = useState<number>(1);
    
    const [view, setView] = useState(null as unknown as (Element | undefined));

    const [ini, setIni] = useState(null as unknown as (Element | undefined));
    const [xsd, setXsd] = useState(null as unknown as (Element | undefined));
    const [currentUsage, setCurrentUsage] = useState(null as unknown as any);
    const [currentPersona, setCurrentPersona] = useState("");
    const [currentScreenSize, setCurrentScreenSize] = useState("large");
    const [extraViewer, setExtraViewer] = useState("");
    const [changeEvent, setChangeEvent] = useState({});
    const [viewUsage, setViewUsage] = useState([]);
    const [lastTimeSaved, setlastTimeSaved] = useState("none");
    const [lastView, setLastView] = useState("");
    const [lastXsd, setLastXsd] = useState("");
    const [lastIni, setLastIni] = useState("");

    let [newModel, setNewModel] = useState<NewModel>({
        module: {
            name : moduleName,
            title : '',
        },
        model: {
            name : '',
            title : '',
        }
    } as any);

    let onChange = async (value:string, path:string)=> {
        if( MAX_CARACTER_NAME && value.length > MAX_CARACTER_NAME ) {
          return ;
        }
        setErrorMessage( "" ) ;
        let listProperties = path.split( "." ) ;
        if( listProperties && listProperties.length===2 )
          newModel[listProperties[0]][listProperties[1]] = value
        if( listProperties[1] === 'title' ) {
            newModel[listProperties[0]]['name'] = cleanAndFormatNameResource( value ) ;
            
            if( !value ) return setErrorMessage( "Veuillez saisir le nom " + (!moduleName?"de l'application ":"du module ") ) ;
            if( value.length < 2 ) return setErrorMessage( "Le nom " + (!moduleName?"de l'application ":"du module ") + "est invalide" ) ;
      
            let directoryName:string = newModel[listProperties[0]]['name'] ;
            let incrementId:any = '' ;
    
            while( directories.includes( directoryName + incrementId ) ) { 
                if(!incrementId) {incrementId=1; continue;}
                incrementId++ ;
            }
            newModel[listProperties[0]]['name'] = directoryName + incrementId;
        }
    
        setNewModel( {...newModel} )
      }

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        
        setFileWarning(null);
        setSelectedFile(null);
        if (event.target.files && event.target.files.length > 0) {
            
            const lastFileIndex = event.target.files.length - 1;
            const file = event.target.files[lastFileIndex];
            setFileWarning(null);
            if (file) {
                
                if (file.name.endsWith('.xsl') || file.name.endsWith('.csv') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                    if(file.name.endsWith('.xlsx')|| file.name.endsWith('.xls')){
                        //console.log("it is a .xlsx");

                        const mergedCellsFound = await checkForMergedCells(file); 
                        //console.log("merged cells done", mergedCellsFound.found)
                        
                        const graphsfound = await checkForGraphs(file);
                        //console.log("graph check done", graphsfound.found)

                        const headerfound = await checkForHeaders(file);  
                        //console.log("header check done", headerfound)

                        const isValidFile = !(mergedCellsFound.found || graphsfound.found || !headerfound);

                        if (isValidFile) {
                           
                            if(!fileWarning){
                                
                                setSelectedFile(file);
                                const dt_check = await checkForDT(file);  
                                console.log("DATA TYPE CHECK: ",dt_check);
                                //const render = await renderFormFromExcel(file);
                                //console.log("RENDERED: ",render)
                                setFileWarning(null);
                            }   
                        }
                    }else{
                        setSelectedFile(file);
                        setIsValidFileSelected(true);
                        setFileWarning(null);
                    }
                } else {
                    setSelectedFile(null);
                    setIsValidFileSelected(false);
                    setFileWarning('Veuillez importez un fichier Excel');
                }
            }else{
                setFileWarning("Veuillez importez un fichier");
            }
        }
    };
    
    const onAction = async (name:string) => { 
        if (name === "close" || name === "cancel") {
            saveGlobaleState(GS); 
            onClose();
            return;
        }
    }
    const onAction1 = async (name:string) => { 
        if (name === "close" || name === "cancel") {
            saveGlobaleState(GS); 
            onClose();
            return;
        }
    }

    const history = useHistory() ;

    const handleUpload = async () => {

        if (!selectedFile) {
            setFileWarning('Veuillez sélectionner un fichier.');
        }

        if (!newModel.module.title || newModel.module.title.length < 2) {
            setErrorMessage(!moduleName ? "Veuillez saisir le nom de l'application." : "Le nom du module est invalide.");
            return;
        } else {
            setErrorMessage('');
        }

        if (!fileWarning && !errorMessage && selectedFile) {
            //setShowLoading(true);
            onExcelUpload(selectedFile);
            handleReader(selectedFile);
            setShowExcelModal(false);
            //setExcelPrev(true);
            if(selectedFile){
                //const route = `/editExcel/${selectedFile.name}`;
                //const state = { file: selectedFile };
                const location = {
                    pathname: `/editExcel/${newModel.module.title}/${selectedFile.name}`,
                    state: {eFile: selectedFile}
                 }
                history.push(location);            
            }
            /*
            setTimeout( async () => {
            
                console.log('Hiding loading modal...');
                setShowLoading(false);                
                //setShowExcelModal(true);       
                // onClose(); 
                setShowSteps(true);
                

            }, 3000);
            
            */
        }
    };

    


    const handleExcelReader = async () => {
        setExcelPrev(false);
        setShowLoading(true);
            
            setTimeout( async () => {
            
                console.log('Hiding loading modal...');
                setShowLoading(false);                
                //setShowExcelModal(true);       
                // onClose(); 
                
                //setShowSteps(true);
                
                

            }, 3000);
        
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setFileWarning(null);
    
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const lastFileIndex = event.dataTransfer.files.length - 1;
            const file = event.dataTransfer.files[lastFileIndex];
            if (file.name.endsWith('.xsl') || file.name.endsWith('.csv') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                
                if(file.name.endsWith('.xlsx')|| file.name.endsWith('.xls')){

                    const mergedCellsFound = await checkForMergedCells(file); 
                    //console.log("merged cells done", mergedCellsFound.found)
                    
                    const graphsfound = await checkForGraphs(file);     
                    //console.log("graph check done", graphsfound.found)
     
                    const headerfound = await checkForHeaders(file);    
                    //console.log("header check done", headerfound)

                    const isValidFile = !(mergedCellsFound.found || graphsfound.found || !headerfound );
                        
                    if (isValidFile) {
                           
                        if(!fileWarning){
                            
                            setSelectedFile(file);
                            const dt_check = await checkForDT(file);  
                            console.log("DATA TYPE CHECK: ",dt_check);

                            setFileWarning(null);
                        }   
                    }
                }else{

                    setSelectedFile(file);
                    setIsValidFileSelected(true);
                    setFileWarning(null);

                }
            } else {

                setSelectedFile(null);
                setIsValidFileSelected(false);
                setFileWarning('Veuillez importez un fichier Excel');

            }
        }
    };
    
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setFileWarning(null);
    };

    const handleClick = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
            setFileWarning(null);
        }
    };

    //const API_BASE_URL = 'https://excel.karaz.org'; 
    //const API_BASE_URL = 'http://localhost:5000'; 
    const API_BASE_URL = 'https://internship.karaz.org/excelapi'; 
    console.log("https")
    const checkForGraphs = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${API_BASE_URL}/check-graphs`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to check for graphs');
            }

            const responseData = await response.json();

            if(responseData.found){
                setFileWarning("Veuillez importer un fichier Excel valide (sans graphiques, sans cellules fusionnées...)")
            }
            return {
                    found: responseData.found,
                    message: responseData.message
            };
         
        } catch (error) {
            console.error('Error checking for graphs:', error);
            return {
                found: false,
                message: "Erreur lors de la vérification des graphiques."
            };
        }
    };

    const checkForMergedCells = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${API_BASE_URL}/check-merged-cells`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to check for merged cells');
            }

            const responseData = await response.json();

            if(responseData.found){
                setFileWarning("Veuillez importer un fichier Excel valide (sans graphiques, sans cellules fusionnées...)")
            }

            return {
                    found: responseData.found,
                    message: responseData.message
            };

        } catch (error) {
            console.error('Error checking for merged cells:', error);
            return {
                found: false,
                message: "Erreur lors de la vérification des cellules."
            };
        }
    };

    const checkForHeaders = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
    
            const response = await fetch(`${API_BASE_URL}/check-header`, {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Failed to check for headers');
            }
    
            const responseData = await response.json();
    
            return responseData.has_header;
        } catch (error) {
            console.error('Error checking for headers:', error);
            return true;
        }
    };

    const checkForDT = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
    
            const response = await fetch(`${API_BASE_URL}/check-data-consistency`, {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error(`Failed to check for data types: ${response.statusText}`);
            }
    
            const responseData = await response.json();
    
            return responseData;
        } catch (error) {
            console.error('Error checking for data types:', error);
            throw error;
        }
    };
    
    const renderFormFromExcel = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            
            const response = await fetch(`${API_BASE_URL}/render`, {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Failed to render form');
            }
    
            const responseData = await response.text();

            const container = document.getElementById('for-mcontainer');

            if (container) {
                container.innerHTML = responseData;
            } else {
                console.error('Form container element not found');
            }
            
            return responseData;
        } catch (error) {
            console.error('Error rendering form:', error);
        }
    };
/*
    const renderXMLFromExcel = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            
            const response = await fetch(`${API_BASE_URL}/renderXML`, {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Failed to render XML');
            }
    
            const responseData = await response.text();

            const container = document.getElementById('for-mcontainer');

            const parser = new DOMParser();
            const xmlDocument = parser.parseFromString(responseData, 'application/xml');
            const rootElement = xmlDocument.documentElement;
            console.log("Root ELEMENT: ",rootElement)
            setView(rootElement);
           
            return responseData;
        } catch (error) {
            console.error('Error rendering XML form:', error);
        }
    };
*/
useEffect(() => {
    const fetchData = async () => {
        try {
            const formData = new FormData();
            if(selectedFile){
            formData.append('file', selectedFile);
            }
            const response = await fetch(`${API_BASE_URL}/renderXML`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to render XML');
            }

            const responseData = await response.text();

            const parser = new DOMParser();
            const xmlDocument = parser.parseFromString(responseData, 'application/xml');
            const rootElement = xmlDocument.documentElement;
            console.log("Root ELEMENT: ", rootElement);
            setView(rootElement);
        } catch (error) {
            console.error('Error rendering XML form:', error);
        }
    };

    fetchData();
}, [selectedFile]);

    const renderContent = () => {
        
        switch (activeStep) {
          case 1:
             try {
                /*
                renderXMLFromExcel(selectedFile).then(responseData => {
                   
                    console.log(responseData); 

                    if (responseData) {
                        
                    } else {
                        console.error('Empty response data received.');
                    }

                }).catch(error => {
                    console.error("Error rendering formmmmmmm:", error);
                });*/
                return (
                    <>
                    <div className="Scontainer">
                        <div className='Scircle'>
                            <div className="Snumber">1</div>
                            <div className="Slabel">Saisie</div>
                        </div>
                        
                        <div className="Sarrow"></div>

                        <div className="Scircle">
                            <div className="Snumber">2</div>
                            <div className="Slabel">Validation</div>
                        </div>
                        
                        <div className="Sarrow"></div>

                        <div className="Scircle">
                            <div className="Snumber">3</div>
                            <div className="Slabel">Signature</div>
                        </div>
                        
                    </div>
                    <div
                  className="formcontainer"
                  id="for-mcontainer"
                  style={{ color: 'black', padding: '15px 15px 0px 15px', minWidth: '450px' }}>
                    {view && <FormRender
                        ini={ini}
                        xsd={xsd}
                        view={view}
                        >
                   </FormRender>}
                    
                  </div>
                    </>
                ); 
            } catch (error) {
                console.error("Error rendering formmm:", error);
                return null;
            };
            
          case 2:
           
          case 3:
            
            
          case 4:
            
          case 5:
            
          case 6:
            
          
          default:
            return null;
        }
      };

      let onChangeStep = (actualStep: number) => {
        switch (actualStep) {
          case 1:
            return {
              title: "Aperçu de votre application", 
              nextStep: () => setActiveStep(actualStep + 1),
            };
          case 2:
            return {
              title: "Questionnaire", 
              nextStep: () => setActiveStep(actualStep + 1),
            };
          case 3:
            return {
              title: "Votre formulaire", 
              nextStep: () => setActiveStep(actualStep + 1),
            };
          case 4:
            return {
              title: "Votre formulaire final", 
              nextStep: () => setActiveStep(actualStep + 1),
            };
          case 5:
            return {
              title: "Votre processus", 
              nextStep: () => setActiveStep(actualStep + 1),
            };
          case 6:
            return {
              title: "Votre processus", 
              nextStep: () => setActiveStep(actualStep + 1),
            };
          
          default:
            return null;
        }
      };

    const [excelData, setExcelData] = useState<any | null>(null);
    const [dataTypes, setDataTypes] = useState<any | null>(null);
    const [attr, setAttr] = useState<any | null>(null);

    const handleReader = async (file) => {

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${API_BASE_URL}/reader`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }

            const data = await response.json();
            console.log("data1 ", data);
            console.log("data2 ", data.sheets);
            console.log("data3",data.sheets[0].name)
            setExcelData(data.sheets);
            setDataTypes(data.types)
            setAttr(data.col_attrs);
            setSelectedSheet(data.sheets[0].name); 
            setInitialDataOrder(data.sheets);
        } catch (error) {
            console.error('Error:', error);
        }
    };
      
    const [filterText, setFilterText] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[][]>([]);

    
    const handleFilter = () => {
        if (excelData && excelData.data) {
            if (!filterText) {
                setFilteredData([]);
                return;
            }
    
            const filtered = excelData.data.filter(row =>
                row.some(cell => String(cell).toLowerCase().includes(filterText.toLowerCase()))
            );
            setFilteredData(filtered); 
        }
    };
    
    const [findText, setFindText] = useState('');
    const [replaceText, setReplaceText] = useState('');

    const handleFind = () => {
        if (excelData && selectedSheet) {
            const sheet = excelData.find(sheet => sheet.name === selectedSheet);
            if (sheet) {
                const results: { sheetName: string; rowIndex: number; cellIndex: number; }[] = [];
                sheet.data.forEach((row, rowIndex) => {
                    row.forEach((cell, cellIndex) => {
                        if (typeof cell === 'string' && cell.toLowerCase().includes(findText.toLowerCase())) {
                            results.push({ sheetName: selectedSheet, rowIndex, cellIndex });
                        }
                    });
                });
                console.log('Find Results:', results);
            }
        }
    };
    
    const handleReplace = () => {
        if (!findText) {
            console.log("Find text is not set. Replace operation aborted.");
            return;
        }
        if (excelData && selectedSheet) {
            const updatedData = excelData.map(sheet => {
                if (sheet.name === selectedSheet) {
                    return {
                        name: sheet.name,
                        columns: sheet.columns,
                        data: sheet.data.map(row =>
                            row.map(cell =>
                                typeof cell === 'string' ? cell.replace(new RegExp(findText, 'gi'), replaceText) : cell
                            )
                        )
                    };
                } else {
                    return sheet;
                }
            });
            setExcelData(updatedData);
            console.log('Data after Replace:', updatedData);
        }
    };
    
    const [selectedSheet, setSelectedSheet] = useState<string>(''); 

    const [refineChoice, setRefineChoice] = useState<string | undefined>(undefined);


    const applyRefinement = () => {
        if (!refineChoice) return;

        
        switch (refineChoice) {
            case 'fixInconsistentSpellings':
                
                break;
            case 'fixDuplicateRows':
                if (refineScope === 'global') {
                    fixDuplicateRows();
                } else if (refineScope === 'specificColumn' && selectedColumn) {
                    console.log('selected column ', selectedColumn);
                    //fixDuplicateRowsForColumn(selectedColumn);
                }
                break;
            case 'fixMissingRows':
                fixEmptyRows();
                break;
            case 'fixMissingValues':
                fixMissingValues()
                break;
            case 'exploreFrequencyDistribution':
                if (refineScope === 'global') {
                    exploreFrequencyDistribution();
                } else if (refineScope === 'specificColumn' && selectedColumn) {
                    exploreFrequencyDistributionForColumn(selectedColumn);
                }
                break;
            
            case 'standardizeDateTime':
                
                break;
            default:
                break;
        }
    };


const fixDuplicateRows = () => {
    if (!excelData || !excelData.length || !selectedSheet) return;

    const sheet = excelData.find(sheet => sheet.name === selectedSheet);
    if (!sheet) return;

    const uniqueRowsMap = new Map<string, boolean>();
    let count = 0;

    const refinedData = excelData.map(sheetData => {
        if (sheetData.name !== selectedSheet) return sheetData;

        const uniqueRows: any[][] = [];
        const duplicates: any[][] = [];

        sheetData.data.forEach((row: any[]) => {
            const rowKey = row.join(',');

            if (!uniqueRowsMap.has(rowKey)) {
                uniqueRowsMap.set(rowKey, true);
                uniqueRows.push(row);
            } else {
                duplicates.push(row);
                count++;
            }
        });

        console.log('Duplicate Rows:', duplicates);

        return { ...sheetData, data: uniqueRows };
    });

    setExcelData(refinedData);
    setDuplicateRowsCount(count);
};

const fixEmptyRows = () => {
    if (!excelData || !excelData.length || !selectedSheet) return;

    const sheet = excelData.find(sheet => sheet.name === selectedSheet);
    if (!sheet) return;

    let count = 0;

    const refinedData = excelData.map(sheetData => {
        if (sheetData.name !== selectedSheet) return sheetData;

        const nonEmptyRows: any[][] = [];
        const emptyRows: any[][] = [];

        sheetData.data.forEach((row: any[]) => {
            const isEmptyRow = row.every(cell => cell === '' || cell === null);
            if (isEmptyRow) {
                emptyRows.push(row);
                count++;
            } else {
                nonEmptyRows.push(row);
            }
        });

        console.log('Empty Rows:', emptyRows);

        return { ...sheetData, data: nonEmptyRows };
    });
    console.log("refined : ",refinedData)
    setExcelData(refinedData);
    setEmptyRowsCount(count);
};


    
    
    const [duplicateRowsCount, setDuplicateRowsCount] = useState<number>(0);
    
    const [emptyRowsCount, setEmptyRowsCount] = useState<number>(0);

    const rowsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(0);

    let startIndex;
    let endIndex;

    const [numColumns, setNumColumns] = useState(0);
    const [numRows, setNumRows] = useState(0);

    if(excelData && selectedSheet){
        startIndex = currentPage * rowsPerPage;
        endIndex = Math.min(startIndex + rowsPerPage, (filterText ? filteredData.length : (excelData.find(sheet => sheet.name === selectedSheet)?.data || []).length));
    }

    useEffect(() => {
        let selectedSheetData;
    
        if (selectedSheet && excelData) {

            selectedSheetData = excelData.find(sheet => sheet.name === selectedSheet);
            
            if (selectedSheetData) {
                const data = selectedSheetData.data || [];
                setNumColumns(selectedSheetData.columns.length);
                setNumRows(data.length);
            }
        }
    }, [selectedSheet,refineChoice, duplicateRowsCount, emptyRowsCount]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const [refineScope, setRefineScope] = useState('global');
    const [selectedColumn, setSelectedColumn] = useState('');
    
    const fixMissingValues = () => {
        if (!selectedColumn || !refineScope) return;
    
        const columnIndex = getColumnIndex(selectedColumn);
        if (columnIndex === -1) return; 
    
        const columnData = getColumnData(selectedColumn);
        const missingValueIndices: number[] = [];
    
        const filledColumnData = columnData.map((value, index) => {
            if (value === null || value === undefined || value === '') {
                missingValueIndices.push(index);
                switch (missingValueOption) {
                    case 'previous':
                        let prevIndex = index - 1;
                        while (prevIndex >= 0 && (columnData[prevIndex] === null || columnData[prevIndex] === undefined || columnData[prevIndex] === '')) {
                            prevIndex--;
                        }
                        return prevIndex >= 0 ? columnData[prevIndex] : null;
                    case 'input':
                        return inputValue;
                    case 'mostOccurring':
                        return getMostOccurringValue(columnData);
                    default:
                        return value;
                }
            } else {
                return value;
            }
        });
    
        const updatedData = excelData.map(sheetData => {
            if (sheetData.name === selectedSheet) {
                return {
                    ...sheetData,
                    data: sheetData.data.map((row, rowIndex) => {
                        const newRow = [...row];
                        if (missingValueIndices.includes(rowIndex)) {
                            newRow[columnIndex] = filledColumnData[rowIndex];
                        }
                        return newRow;
                    })
                };
            } else {
                return sheetData;
            }
        });
    
        setExcelData(updatedData);
    };

    const getColumnIndex = (column: string): number => {
        const selectedSheetData = excelData.find(sheet => sheet.name === selectedSheet);
        if (selectedSheetData) {
            return selectedSheetData.columns.indexOf(column);
        }
        return -1;
    };
    const getColumnData = (column: string): any[] => {
        const selectedSheetData = excelData.find(sheet => sheet.name === selectedSheet);
        if (selectedSheetData) {
            const columnIndex = selectedSheetData.columns.indexOf(column);
            if (columnIndex !== -1) {
                return selectedSheetData.data.map(row => row[columnIndex]);
            }
        }
        return [];
    };
    
    const getMostOccurringValue = (data: any[]): any => {
        const valueCounts = new Map();
        data.forEach(value => {
            if (valueCounts.has(value)) {
                valueCounts.set(value, valueCounts.get(value) + 1);
            } else {
                valueCounts.set(value, 1);
            }
        });
    
        let mostOccurringValue: any;
        let maxCount = 0;
        valueCounts.forEach((count, value) => {
            if (count > maxCount) {
                mostOccurringValue = value;
                maxCount = count;
            }
        });
    
        return mostOccurringValue;
    };

    const missingValueOptions = [
        { value: 'previous', label: 'Fill with previous value' },
        { value: 'input', label: 'Fill with value (input)' },
        { value: 'mostOccurring', label: 'Fill with most occurring value' }
    ];
    
    
    const [missingValueOption, setMissingValueOption] = useState('');
    
    const handleMissingValueOptionChange = (e) => {
        setMissingValueOption(e.target.value);
    };

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const [frequencyDistribution, setFrequencyDistribution] = useState<{ [key: string]: { [key: string]: number } }>({});
    const [columnFrequency, setColumnFrequency] = useState<{ [key: string]: number }>({});


    const exploreFrequencyDistribution = () => {
        if (!excelData || !selectedSheet) return;

        const sheet = excelData.find(sheet => sheet.name === selectedSheet);
        if (!sheet) return;

        const frequencyDistribution = {};

        sheet.columns.forEach(column => {
            const columnData = getColumnData(column).filter(value => value !== '');
            const columnFrequency = {};

            columnData.forEach(value => {
                if (value in columnFrequency) {
                    columnFrequency[value]++;
                } else {
                    columnFrequency[value] = 1;
                }
            });

            frequencyDistribution[column] = columnFrequency;
        });

        setFrequencyDistribution(frequencyDistribution);
    }

    const exploreFrequencyDistributionForColumn = (columnName) => {
        if (!excelData || !selectedSheet || !columnName) return;

        const sheet = excelData.find(sheet => sheet.name === selectedSheet);
        if (!sheet) return;

        const columnData = getColumnData(columnName).filter(value => value !== '');
        const columnFrequency = {};

        
        columnData.forEach(value => {
            if (value in columnFrequency) {
                columnFrequency[value]++;
            } else {
                columnFrequency[value] = 1;
            }
        });

        setColumnFrequency(columnFrequency);
    }


    const initialSortingState = excelData ? excelData.find(sheet => sheet.name === selectedSheet)?.columns.map(() => ({ direction: '' })) : [];

    const [sorting, setSorting] = useState(initialSortingState);

    

    const handleSort = (columnIndex, sortType) => {
        const sortedData = excelData.map(sheet => {
            if (sheet.name === selectedSheet) {
                const sortedSheetData = [...sheet.data];
                sortedSheetData.sort((a, b) => {
                    const aValue = a[columnIndex];
                    const bValue = b[columnIndex];
    
                    if (aValue === bValue) return 0;
    
                    if (sortType === 'asc') {
                        return aValue < bValue ? -1 : 1;
                    } else {
                        return aValue > bValue ? -1 : 1;
                    }
                });
                
    
                return { ...sheet, data: sortedSheetData };
            }
            return sheet;
        });
    
        setExcelData(sortedData);
    };

    const [initialDataOrder, setInitialDataOrder] = useState<any | null>(null);

    const handleResetOrder = () => {
        setExcelData(initialDataOrder);
    };
        
    const renderInputIcon = (columnName) => {
        const dataType = dataTypes.find((type) => type.data_types[columnName]);
    
        if (dataType) {
            switch(dataType.data_types[columnName]) {
                case 'object':
                    return <span className="material-symbols-outlined">title</span>
                case 'int64':
                    return <span className="material-symbols-outlined">tag</span>;
                case 'datetime':
                    return <span className="material-symbols-outlined">event</span>;
                case 'radio':
                    return <span className="material-symbols-outlined">radio_button_checked</span>;
                case 'dropdown':
                    return <span className="material-symbols-outlined">dropdown</span>;
                case 'prefilled':
                    return <span className="material-symbols-outlined">variables</span>;
                default:
                    return null;
            }
        }
    
        return null;
    }
        
    

    return (
        <>
        {showExcelModal &&
        <Modal onClose={()=>onAction("cancel")} title="Application à partir d'Excel" icon="" 
        leftBtnArgs={ {
            label:'Annuler',
            args:{onClick:()=>onAction("cancel")},
            color: 'neutral',
            size: 'sm',
            styling: 'outline'
        } } 
        rightBtnArgs={ {
            label:'Ajouter',
            args: { onClick: handleUpload , disabled: !isValidFileSelected && !(!errorMessage) && !(!fileWarning)},
            color: 'primary',
            size: 'sm',
            styling: 'solid'  
        } }
        bodyStyle={ {minHeight:"fit-content"} }>

            
            <div className="create-app-modal-body">
                    {fileWarning && (
                        <div style={{ backgroundColor: "#FEF2F2", border: "1px solid #FCA5A5", borderRadius:"6px", margin: '0px 0px',display:"flex",
                        alignItems: "center"}}>
                            <span className="material-symbols-outlined" style={{ color: "red", padding:'0px 8px 0px 10px', fontSize: 'medium'}}>
                                error
                            </span>
                            <div style={{ color: '#334155', padding: "5px 0",fontSize: "smaller"}}>{fileWarning}</div>
                        </div>
                    )}
                <div className="name-directory">
                    <Input  label={!moduleName ?"Nom de l'application":"Nom du module"} size="sm" 
                                errorMessage={ errorMessage }
                                args={
                                    {
                                        placeholder:!moduleName ?"Nom de l'application":"Nom du module",
                                        value:newModel[!moduleName ?"module":"model"].title||"",
                                        onChange:(evt) => { 
                                            onChange(evt.target.value, (!moduleName ?"module":"model") + ".title" )
                                        }
                                    }
                                }  
                                charCounter={MAX_CARACTER_NAME}
                        />
                    <div className="id-directory">
                        <span>ID :</span>
                        <span>{ " " + newModel[!moduleName ?"module":"model"].name||""}</span>
                    </div>
                </div>
            </div>
            {/*<div style={{ color: '#334155', padding: "5px 0" }}>{errorMessage}</div>*/}
           <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
           <div style={{padding: '0px 16px 24px 16px'}} >
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={handleClick}
                    style={{ cursor: 'pointer', border: '1px solid #E2E8F0', textAlign: 'center',backgroundColor: '#F8FAFC', borderRadius: '8px', paddingTop: '20px' }}
                >
                    {selectedFile && !fileWarning && (<div style={{color: "#2563EB", textDecoration: 'underline #2563EB', fontWeight: '501' }}>
                        {selectedFile.name}
                    </div>)}
                    <span className="material-symbols-outlined" style={{color: "#2563EB", padding:'10px'}}>
                        upload
                    </span>
                    <div style={{ color: '#1E293B', fontWeight: '501', fontSize: '14px'}}>Glissez et déposez un fichier de feuille ici</div>
                    <div style={{color:'#64748B', fontWeight:'400', fontSize:'14px', paddingBottom: '25px'}}>Formats supportés : xsl, csv, xlsx, xls</div>
                </div>
                <input
                    type="file"
                    id="fileInput"
                    onChange={handleFileSelect}
                    style={{ display: 'none' }} 
                />
            </div>
            
            
        </Modal>}

        {excelPrev &&

        <Modal onClose={()=>onAction("cancel")} title="Application à partir d'Excel" icon="" 
        leftBtnArgs={ {
            label:'Annuler',
            args:{onClick:()=>{setExcelPrev(false);setShowExcelModal(true);}},
            color: 'neutral',
            size: 'sm',
            styling: 'outline'
        } } 
        rightBtnArgs={ {
            label:'Ajouter',
            args: { onClick: ()=>{setExcelPrev(false);handleExcelReader()} },
            color: 'primary',
            size: 'sm',
            styling: 'solid'  
        } }
        bodyStyle={ {minHeight:"fit-content",overflowY:"auto"} } width={"auto"}>
        <div>testing mate</div>
        {excelData && (
             
                <div style={{ minWidth: 'max-content', margin: '25px' }}>
                <div className="parent" style={{display: 'grid', gridTemplateColumns : '3fr 1fr', gap: '16px'}}>

                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
           
                    <div className="child1" style={{display: 'inherit'}}>
                    <div style={{ padding: "5px 0px 15px 0px", display:"flex" }}>
                        <Tabs
                            tabs={excelData.map((sheet, index) => ({ name: sheet.name, label: sheet.name }))}
                            onSelect={selectedSheet => setSelectedSheet(selectedSheet)}
                        />
                        <div>
                            <span className="material-symbols-outlined" style={{color: "#2563EB"}} onClick={handleResetOrder} >
                                restart_alt
                            </span>
                        </div>
                        <div style={{ paddingRight: "5px", paddingLeft:"5px" }} >
                            <input
                                type="text"
                                placeholder="Filter..."
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                                onBlur={handleFilter}
                                style={{ color: 'black' }}
                            />
                        </div>
                        <div style={{ padding: "0px 0px 5px 0px", display: 'flex' }}>
                            <div style={{ padding: "0px 5px 0px 5px" }}>
                            <input
                                type="text"
                                placeholder="Find..."
                                value={findText}
                                onChange={e => setFindText(e.target.value)}
                                style={{ color: 'black' }}
                            />
                            </div>
                            
                            <div style={{ padding: "0px 5px 0px 5px" }}>
                            <input
                                type="text"
                                placeholder="Replace..."
                                value={replaceText}
                                onChange={e => setReplaceText(e.target.value)}
                                style={{ color: 'black' }}
                            />
                            </div>
                            <button onClick={handleReplace}>Replace</button>


                            </div>
                        
                    </div>
                    
                    {excelData && selectedSheet && (
                    <div>
                       
                         <table cellPadding={50} cellSpacing={15} style={{ borderCollapse: 'collapse', border: '1px solid black', backgroundColor: 'aliceblue' }}>
                         <thead>
                            <tr>
                                {excelData.find(sheet => sheet.name === selectedSheet)?.columns.map((col, index) => (
                                    <th style={{ border: '1px solid black', backgroundColor: '#3b82f6', textAlign: 'center', padding: '5px' }} key={index}>      
                                        
                                        <div style={{display: 'flex', justifyContent: 'space-between',  alignItems: 'center'}}>
                                            <div style={{display: 'flex', paddingRight:'5px'}}>
                                                {renderInputIcon(col)}
                                            </div>
                                            {col}
                                            <div style={{display: 'flex',  float: 'right', paddingLeft:'8px'}}>
                                        
                                                <a onClick={() => handleSort(index,'asc')}>Λ</a>
                                            
                                                <a onClick={() => handleSort(index,'desc')}>V</a>
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                            <tbody>
                                {(filterText ? excelData.find(sheet => sheet.name === selectedSheet)?.data.filter(row => row.some(cell => String(cell).toLowerCase().includes(filterText.toLowerCase()))) : excelData.find(sheet => sheet.name === selectedSheet)?.data).map((row: any[], rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <td style={{ border: '1px solid black', color: 'black', padding: '4px' }} key={cellIndex}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                   
                        
                    </div>
                    )}
                    
                    </div>
                    <div className="child1">
                        <div style={{ display: 'flex', float: 'left'}}>
                            { numColumns && numRows &&(
                                <div style={{ color: 'black', display: 'flex' }}>
                                    <div style={{ paddingRight: '4px', paddingLeft: '4px', margin: 'auto' }}>Cols: {numColumns}</div> 
                                    <div style={{margin:'auto'}}> | </div>
                                    <div style={{ paddingRight: '4px', paddingLeft: '4px', margin: 'auto' }}>Rows: {numRows}</div>
                                </div>
                            )}
                            
                        </div>
                        <div style={{ color: 'black' }}>
                            <p style={{fontWeight: 'bold', fontSize: '22px'}}>Refine Data</p>
                            <div style={{paddingBottom: '4px'}}>
                                    <select value={refineChoice} onChange={e => setRefineChoice(e.target.value)}>
                                            <option value="">Choose refinement</option>
                                            <option value="fixInconsistentSpellings">Fix Inconsistent Spellings</option>
                                            <option value="fixDuplicateRows">Fix Duplicate Rows</option>
                                            <option value="fixMissingValues">Fix Missing Values</option>
                                            <option value="fixMissingRows">Fix Missing Rows</option>
                                            <option value="exploreFrequencyDistribution">Explore Frequency Distribution</option>
                                            <option value="standardizeDateTime">Standardize Date and Time</option>
                                    </select>
                            </div>
                            {refineChoice !== 'fixMissingRows' && refineChoice !== 'fixDuplicateRows'  &&(
                            <div style={{paddingBottom: '4px'}}>
                                <select value={refineScope} onChange={e => setRefineScope(e.target.value)}>
                                    <option value="global">Global</option>
                                    <option value="specificColumn">Specific Column</option>
                                </select>
                            </div>
                            )}
                            {refineChoice === 'fixMissingValues' && refineScope === 'specificColumn' && (
                                <div style={{paddingBottom: '4px'}}>
                                    <select value={selectedColumn} onChange={e => setSelectedColumn(e.target.value)}>
                                        <option value="">Choose column</option>
                                        {excelData.find(sheet => sheet.name === selectedSheet)?.columns.map((col, index) => (
                                            <option value={col} key={index}>{col}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                        {refineChoice === 'fixMissingValues' && refineScope === 'specificColumn' && selectedColumn && (
                            <select value={missingValueOption} onChange={handleMissingValueOptionChange}>
                                <option value="">Choose option</option>
                                {missingValueOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            
                        )}
                        {refineChoice === 'fixMissingValues' && missingValueOption === 'input' && refineScope === 'specificColumn' && (
                            <div>
                                <input 
                                    type="text" 
                                    value={inputValue} 
                                    onChange={handleInputChange} 
                                    placeholder="Enter value"
                                />
                            </div>
                        )}

                            <button onClick={applyRefinement}>Apply</button>
                        </div>
                        {refineChoice === 'fixDuplicateRows' && (
                            <div style={{color:'black'}}>
                                {duplicateRowsCount > 0 ? (
                                    <p>{duplicateRowsCount} duplicate rows found.</p>
                                ) : (
                                    <p>No duplicate rows found.</p>
                                )}
                            </div>
                        )}
                        {refineChoice === 'fixMissingRows' && (
                            <div style={{color:'black'}}>
                                {emptyRowsCount > 0 ? (
                                    <p>{emptyRowsCount} empty rows found.</p>
                                ) : (
                                    <p>No empty rows found.</p>
                                )}
                            </div>
                        )}
                        {refineChoice === 'exploreFrequencyDistribution' && (
                            <div style={{color:'black'}}>
                                {refineScope === 'global' && (
                                    <div>
                                        {/* Render frequency distribution for all columns */}
                                        <p>Frequency Distribution for all columns:</p>
                                        <ul style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                            {Object.entries(frequencyDistribution).map(([column, columnFrequency]) => (
                                                <li key={column}>
                                                    {column}:
                                                    <ul>
                                                        {Object.entries(columnFrequency).map(([value, frequency]) => (
                                                            <li key={value}>{value}: {frequency}</li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {refineScope === 'specificColumn' && (
                                    <div>
                                        <p>Frequency Distribution for {selectedColumn}:</p>
                                        <ul style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                            {Object.entries(columnFrequency).map(([value, frequency]) => (
                                                <li key={value}>{value}: {frequency}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}



                    </div>

                </div> 
                </div>
            )}

        
                     
        </Modal>}
        
        
        {showLoading && <LoadingApplication message='Votre application est en cours de création...' />}
        {showSteps &&
        <Modal
            onClose={() => onAction1("cancel")}
            title={onChangeStep(activeStep)?.title || ""}
            icon=""
            zIndex={100}
            width="fit-content"
            leftBtnArgs={{
                label: activeStep === 1 ? "Annuler" : "Précédent",
                args: {
                onClick: () => {
                    if (activeStep === 1) {
                    onAction1("cancel");
                    } else {
                    setActiveStep(activeStep - 1);
                    }
                },
                
                },
                color: "neutral",
                size: "sm",
                styling: "outline",
            }}
            rightBtnArgs={{
                label: activeStep < MAX_STEPS ? "Suivant" : "Valider",
                args: { onClick: () => {
                if (activeStep === MAX_STEPS) {
                    onAction("cancel");
                } else {
                    onChangeStep(activeStep)?.nextStep();
                }
                }},
                color: "primary",
                size: "sm",
                styling: "solid",
            }}
            bodyStyle={{ minHeight: "fit-content",overflowY:"auto" }}
            >
                {renderContent()}
    
        </Modal>
        }
        </>
    );
}


/*
Logic behind both input and validation
Validation button label
add xslx to File Type
Work on Notifications
Loop through file for merged cells etc

import xslx - npm install xslx-

first upload -doesnt show file name in modal for some reason - check the conditions-

*/