import { useState, useEffect } from "react";
import "./organization-settings.css";
import { SettingsContainerHeader } from "../settings/settings";
import { Tabs, Input, Button, TextArea, SearchInput, GenericTable, TelephoneInput, ColorInput, OraganizationAddressTable, Modal, VerificationCode } from "kls-ui";
import { Dropdown, MoreActions } from "kls-ui";
import { checkFirstConnect, fetchLocalUserDetails, fetchLocalUserProfile } from "kls-commons/service/userService"
import { testEmail } from "kls-auth/signup/validation"

export function OrganisationSettings(props: any) {
  let [industries, setindustries] = useState<any[]>([]);
  let [searchvalue, setSearchvalue] = useState('')
  let [organizationnameErr, setorganizationnameErr] = useState('');
  let [organizationname, setorganizationname] = useState('');
  let [addresslineErr, setaddresslineErr] = useState('');
  let [addressline, setaddressline] = useState('');
  let [organizationMail, setorganizationMail] = useState('');
  let [organizationMailErr, setorganizationMailErr] = useState('');
  let [organizationDrop, setorganizationDrop] = useState('');
  let [organizationDropErr, setorganizationDropErr] = useState('');
  let [addressLocalite, setaddressLocalite] = useState('');
  let [addressLocaliteErr, setaddressLocaliteErr] = useState('');
  let [addressPays, setaddressPays] = useState('');
  let [addressPaysErr, setaddressPaysErr] = useState('');
  let [industryErr, setindustryErr] = useState('');
  let [industry, setindustry] = useState('');
  let [errorMessage, setErrorMessage] = useState("");
  let[verificationcodeErr,setverificationcodeErr]=useState("");
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const[verification,setVerification] =useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('Général');


  


  const handleTableEditClick = (row) => {
    setRowData(row);
    setIsEditFormOpen(true);
  };

  const handleTableAddClick = (row) => {
    setRowData(row);
    setIsAddFormOpen(true);
  };

  const handleTableDeleteClick = (row) => {
    setRowData(row);
    setVerification(true);
  }

  const handleEditFormClose = () => {
    setIsEditFormOpen(false);
    setaddresslineErr('')
    setaddressLocaliteErr('')
    setaddressPaysErr('')
    setIsAddFormOpen(false);
    setActiveTab('Général')
  };

  const handleVerificationClose = () => {
    setVerification(false)
  }

  const handleSubmit = (verificaitoncode) => {
    console.log(verificaitoncode,'handleSubmit')
  }

  useEffect(() => {
    let loggedinUser = fetchLocalUserProfile();
    if (loggedinUser?.userId != undefined)
      checkFirstConnect(loggedinUser?.userId).then(
        (authenticated) => {
          if (authenticated) {
            let result = fetchLocalUserDetails();
            if (result) {
              let data1 = { "label": 'Toute industrie', "value": '' };
              let array = [data1, ...result?.industries];
              setindustries(array)
            }
          }
        },
        (error => {
          console.log("LOGIN_ERROR", error);
        })
      );
  }, []);

  const moreActions = [
    {
      label: 'Editer',
      value: 'edit',
      handleClick: (row) => handleTableEditClick(row),
      icon: 'edit',
      color: 'black'
    },
    {
      label: 'Paramétres',
      value: 'parametre',
      handleClick: (row) => handleTableDeleteClick(row),
      icon: 'delete',
      labelColor: '#DC2626',
      color: '#EF4444'
    }
  ];


  const data:any = [
    {
      id: 1, address: '123 Main St', action: <MoreActions actions={moreActions} />
    }, {
      id: 2, address: '123 Test Main St', action: <MoreActions actions={moreActions} />
    }, {
      id: 3, address: '123 Test Main St', action: <MoreActions actions={moreActions} />
    }, {
      id: 4, address: '123 Test Main St', action: <MoreActions actions={moreActions} />
    }
  ];

   // Filter data based on searchQuery
   const filteredData = data.filter(item =>
    item?.address?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const datas = filteredData.length > 0 ? filteredData : [{ id: '', address: 'Aucun résultat' }];

  //----Upload HandleClick----//

  function handleEditClick() {
    const fileInput = document.querySelector('.file-upload') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  }

  function handleFileUpload(event) {
    const selectedFile = event.target.files[0];
    console.log('Selected file:', selectedFile);

    if (!selectedFile) {
      setErrorMessage("Veuillez sélectionner un fichier.");
    } else {
      // alert(`Selected file: ${selectedFile.name}`);
      setErrorMessage("");
    }
  }
  // 

  const createOrganismeSubmit = async () => {
    let isValid = true;

    if (organizationname === '') {
      setorganizationnameErr("Nom champ est requis");
      isValid = false;
    } else {
      setorganizationnameErr("");
    }

    if (organizationMail === '') {
      setorganizationMailErr("Mail champ est requis");
      isValid = false;
    } else {
      setorganizationMailErr("");
      const emailErrMsg = testEmail(organizationMail);
      if (emailErrMsg !== '') {
        setorganizationMailErr(emailErrMsg);
        isValid = false;
      }
    }

    if (industry === '') {
      setindustryErr("Industrie est requis");
      isValid = false;
    } else {
      setindustryErr("");
    }

    const fileInput = document.querySelector('.file-upload') as HTMLInputElement;
    if (fileInput && fileInput.files && fileInput.files[0]) {
      setErrorMessage("");
    } else {
      setErrorMessage("Veuillez attacher le logo de votre organisation");
      isValid = false;
    }

    return isValid;
  }


  const submitEditAddress = async () => {
    let isValid = true;

    if (addressline === '') {
      setaddresslineErr("Adresse champ est requis");
      isValid = false;
    } else {
      setaddresslineErr("");
    }

    if (addressLocalite === '') {
      setaddressLocaliteErr("Localite champ est requis");
      isValid = false;
    } else {
      setaddressLocaliteErr("");
    }

    if (addressPays === '') {
      setaddressPaysErr("Pays champ est requis");
      isValid = false;
    } else {
      setaddressPaysErr("");
    }

    return isValid;

  }

  // function TabContent2() {
  //   return ;
  // }

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div className="organisation-component">
      <SettingsContainerHeader title="Organisation" subtitle="Gérez votre organisation" />
      <div className="organisation-imgSec-main">
        {errorMessage && <div className="error-message">
          <span className="icon error-logo-org">error_outline</span>
          <div className="orgination-error-message">{errorMessage}</div>
        </div>}
        <div className="organisation-imgSec">
          <div className="organisation-logo">
            <img className="login-logo-organization" src="/photos/OrganizationLogo.svg" alt="logo" />
            <span className="icon org-edit-icon" onClick={handleEditClick}>
              <span className="icon new-edit-logo-org">edit</span>
            </span>
            <input type="file" className="file-upload" onChange={handleFileUpload} required />
          </div>
          <div className="Imgrestriction-Organisation">
            <p className="Img-title">Formats autorisés : <span>.png et .svg</span></p>
            <p className="Img-title">Taille maximale autorisée : <span>2 Mo</span></p>
            <p className="Img-title">Dimensions idéales de l’image : <span>100px * 100 px</span> </p>
          </div>
        </div>
      </div>
      <div className="organisation-form">
        <div className="organisation-form-header">
          <h1 className="organisation-form-head">Informations générales</h1>
        </div>
        <div className="organisation-personal-detials">
          <div className="organisation-name-detials">
            <h1>Nom</h1>
            <Input size='md' args={{
              placeholder: 'Entrez le nom de votre organisation', required: true,
              onChange: (e) => {
                setorganizationname(e.target.value);
                setorganizationnameErr('');
                if (e.target.value == '') {
                  setorganizationnameErr("Ce champ est requis")
                }
              }
            }} errorMessage={organizationnameErr} />
            <p>ID : royal_air_maroc</p>
          </div>
          <div className="organisation-subname-detials">
            <h1>Acronyme</h1>
            <Input size='md' args={{ placeholder: 'Entrez l’acronyme de votre organisation' }} />
          </div>
          <div className="organisation-owner-detials">
            <h1>Propriétaire</h1>
            <Input size='md' args={{ placeholder: 'Jalal ALE', disabled: true }} />
          </div>
        </div>
        <div className="organisation-transport-detials">
          <div className="oragnisation-industryList">
            <h1>Industrie</h1>
            <Dropdown
              value={searchvalue}
              size="sm"
              elementSize={36}
              setValue={(e) => { setindustry(e); setindustryErr('') }}
              elements={industries}
              label=""
              listTopPos="105%"
              listBottomPos=""
              listLeftPos=""
              listRightPos=""
              errorMessage={industryErr}
              args={{
                placeholder: 'Choisissez une industrie', required: true,
                onChange: (e) => {
                  setorganizationDrop(e.target.value);
                  setindustryErr('');
                  if (e.target.value === '') {
                    setindustryErr("Ce champ est requis")
                  }
                }
              }}

            />
          </div>
          <div className="organisation-car-color">
            <h1>Couleur</h1>
            {/* <ColorInput color="" onColorChange={(e) => console.log(e)} /> */}
            <Input size='md' args={{ placeholder: 'Couleur' }} />
          </div>
        </div>


        <div className="organisation-description">
          <h1>Description</h1>
          <TextArea size='md' args={{ placeholder: 'Décrivez brièvement votre organisation' }} />
        </div>
        <div className="organisation-tags">
          <h1>Tags</h1>
          <div className="org-icon-input input-icons">
            <SearchInput
              light={true}
              value={searchvalue}
              setValue={setSearchvalue}
              size={1}
              placeholder="Entrez les tags de votre organisation..."
              addClearbutton={true}
            />
          </div>
        </div>
      </div>
      <div className="organisation-Contact">
        <div className="organisation-contact-header">
          <h1 className="organisation-contact-head">Coordonnées</h1>
        </div>
        <div className="organisation-contact-detials">
          <div className="organisation-contact-firstdiv">
            <div className="organisation-email-detials">
              <h1>Email</h1>
              <Input size='md' args={{
                placeholder: 'Entrez l’email de votre organisation', required: true,
                onChange: (e) => {
                  setorganizationMail(e.target.value);
                  setorganizationMailErr('');
                  if (e.target.value == '') {
                    setorganizationMailErr("Ce champ est requis")
                  }
                }
              }} 
              errorMessage={organizationMailErr} 
              />
            </div>
            <div className="organisation-number-detials">
              <h1>Téléphone</h1>
              <TelephoneInput />
            </div>
          </div>
          <div className="organisation-contact-seconddiv">
            <div className="organisation-identification-detials">
              <h1>N° identifiant</h1>
              <Input size='md' args={{ placeholder: 'Entrez l’identifiant de votre organisation' }} />
            </div>
            <div className="organisation-web-detials">
              <h1>Site web</h1>
              <Input size='md' args={{ placeholder: 'Entrez le site web de votre organisation' }} />
            </div>
          </div>
        </div>
      </div>
      <div className="organisation-address">
        <div className="organisation-address-header">
          <div className="organisation-address-topic">
            <h1>Adresses</h1>
            <p>Les adresses des locaux de votre organisation</p>
          </div>
          <div className="organisation-address-primary">
            <Button args={{ className: 'organisation-settings-address-button', style: { height: '40px', gap: '8px' },onClick: handleTableAddClick }} color="primary" label={"Nouvelle adresse"} rightIcon="" styling="solid" size={'xs'} leftIcon="add" />
          {
            isAddFormOpen && <div className="organisation-table-Modal-Addform">
              <Modal onClose={handleEditFormClose} title={"Nouvelle adresse"} icon={""} >
                <div className="organisation-table-Modal-primary">
                  <div className="organisation-table-Modal">
                    <div className="organisation-table-popup-tab-content">
                      <div className="Organisation-editor-TabContent1">
                        <div className="Organisation-editor-TabContent1-header">
                          <Dropdown value={'Siège social'}
                            size="md"
                            elementSize={36}
                            elements={industries}
                            label="Type du local"
                            listTopPos="105%"
                            listBottomPos=""
                            listLeftPos=""
                            listRightPos=""
                            errorMessage={''} />
                        </div>
                        <div className="Organisation-editor-Tabcontent1-address">
                          <Input size='md' label="Adresse" args={{
                            placeholder: 'Entrez l’adresse de votre local', required: true,
                            onChange: (e) => {
                              setaddressline(e.target.value);
                              setaddresslineErr('');
                              if (e.target.value == '') {
                                setaddresslineErr("Ce champ est requis")
                              }
                            }
                          }} errorMessage={addresslineErr} />
                        </div>
                        <div className="Organisation-editor-Tabcontent1-dropdown">
                          <div className="Organisation-editor-Tabcontent1-Localitydropdown">
                            <Dropdown value={addressLocalite}
                              size="md"
                              elementSize={36}
                              elements={industries}
                              setValue={(e) => { setaddressLocalite(e); setaddressLocaliteErr('') }}
                              label="Localité"
                              listTopPos="105%"
                              listBottomPos=""
                              listLeftPos=""
                              listRightPos=""
                              errorMessage={addressLocaliteErr}
                              args={{
                                placeholder: 'Choisissez une industrie', required: true,
                              }}
                            />
                          </div>
                          <div className="Organisation-editor-Tabcontent1-Paysdropdown">
                            <Dropdown value={''}
                              size="md"
                              elementSize={36}
                              elements={industries}
                              setValue={(e) => { setaddressPays(e); setaddressPaysErr('') }}
                              label="Pays"
                              listTopPos="105%"
                              listBottomPos=""
                              listLeftPos=""
                              listRightPos=""
                              errorMessage={addressPaysErr}
                              args={{
                                placeholder: 'Choisissez un pays', required: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="organisation-table-Modal-Button">
                    <div className="organisation-table-Modal-Button-cancel">
                      <Button size="md" label="Annuler" styling="solid" color="primary" args={{ onClick: handleEditFormClose, className: 'organistaion-cancel-button-address-model' }} />
                    </div>
                    <div className="organisation-table-Modal-Button-Success">
                      <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { submitEditAddress() } }} />
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          }

          </div>
        </div>
        <div className="organisation-search">
          <div className="organisation-search-bar" style={{ width: "100%" }}>
            <Input
              args={{
                value: searchQuery,
                id: "search",
                type: "text",
                placeholder: 'Rechercher',
                onChange: handleSearchChange,
              }}
              infoText=""
              label=""
              size="md"
            />
          </div>
          <div className="iconsSection-org">
            <img className="clearSearch" src="/photos/close.svg" alt="clear" onClick={handleClearSearch}></img>
            <img className="searchIcon" src="/icons/search.svg" alt="search" onClick={handleSearchChange} ></img>
          </div>
        </div>
        <div className="organisation-table">
          <OraganizationAddressTable data={datas} />
          {
            isEditFormOpen &&
            <div className="organisation-table-Modal-Editform">
              <Modal onClose={handleEditFormClose} title={"Détails de l’adresse"} icon={""} >
                <div className="organisation-table-Modal-primary">
                  <div className="organisation-table-Modal">
                    <div className="organisation-table-Modal-navbar">
                    <Tabs
                       align="horizontal"
                       current={activeTab}
                       defSelected={activeTab}

                       onSelect={handleTabClick}
                       size="small"
                       tabs={[
                         {
                           label: 'Général',
                           name: 'Général',
                           type: 'tab'
                         },
                         {
                           label: 'À propos',
                           name: 'À propos',
                           type: 'tab'
                         }]}
                      />
                    </div>
                    <div className="tab-content">
                      {activeTab === 'Général' && <div className="Organisation-editor-TabContent1">
                        <div className="Organisation-editor-TabContent1-header">
                          <Dropdown value={'Siège social'}
                            size="md"
                            elementSize={36}
                            elements={industries}
                            label="Type du local"
                            listTopPos="105%"
                            listBottomPos=""
                            listLeftPos=""
                            listRightPos=""
                            errorMessage={''} />
                        </div>
                        <div className="Organisation-editor-Tabcontent1-address">
                          <Input size='md' label="Adresse" args={{
                            placeholder: 'Entrez l’adresse de votre local', required: true,
                            onChange: (e) => {
                              setaddressline(e.target.value);
                              setaddresslineErr('');
                              if (e.target.value == '') {
                                setaddresslineErr("Ce champ est requis")
                              }
                            }
                          }} errorMessage={addresslineErr} />
                        </div>
                        <div className="Organisation-editor-Tabcontent1-dropdown">
                          <div className="Organisation-editor-Tabcontent1-Localitydropdown">
                            <Dropdown value={addressLocalite}
                              size="md"
                              elementSize={36}
                              elements={industries}
                              setValue={(e) => { setaddressLocalite(e); setaddressLocaliteErr('') }}
                              label="Localité"
                              listTopPos="105%"
                              listBottomPos=""
                              listLeftPos=""
                              listRightPos=""
                              errorMessage={addressLocaliteErr}
                              args={{
                                placeholder: 'Choisissez une industrie', required: true,
                              }}
                            />
                          </div>
                          <div className="Organisation-editor-Tabcontent1-Paysdropdown">
                            <Dropdown value={''}
                              size="md"
                              elementSize={36}
                              elements={industries}
                              setValue={(e) => { setaddressPays(e); setaddressPaysErr('') }}
                              label="Pays"
                              listTopPos="105%"
                              listBottomPos=""
                              listLeftPos=""
                              listRightPos=""
                              errorMessage={addressPaysErr}
                              args={{
                                placeholder: 'Choisissez un pays', required: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>}
                      {activeTab === 'À propos' && <div className="Organisation-editor-TabContent2">
                        <div className="Organisation-editor-TabContent2-CreationDate">
                          <h1>Date de création</h1>
                          <p>05/01/2024 22:15</p>
                        </div>
                        <div className="Organisation-editor-TabContent2-ModificationDate">
                          <h1>Dernière modification</h1>
                          <p>10/01/2024 08:44</p>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                  <div className="organisation-table-Modal-Button">
                    <div className="organisation-table-Modal-Button-cancel">
                      <Button size="md" label="Annuler" styling="solid" color="primary" args={{ onClick: handleEditFormClose, className: 'organistaion-cancel-button-address-model' }} />
                    </div>
                    <div className="organisation-table-Modal-Button-Success">
                      <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { submitEditAddress() } }} />
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          }
          {
            verification &&
            <VerificationCode handleVerificationClose={handleVerificationClose} verificationcodeErr={verificationcodeErr} handleSubmit={handleSubmit}/>
          }

        </div>
      </div>
      <div className="organisation-button">
        <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { createOrganismeSubmit() } }} />
      </div>
    </div>
  );
}
