
import { MyApps } from 'kls-catalog'
import { NewModelWizard } from '../module/new-model-wizard'
import { ExcelUploadModal } from '../module/excel-upload-modal'

export default function MyAppsPage(props) {
    return (
        <div style={{ margin: '1.5rem auto', maxWidth: '1250px', width: '100%' }} >
            <MyApps gs={props.gs} creationWizard={creationWizard()}  excelWizard={excelWizard(props.gs, props.editings)} />
        </div>
    )
}

function creationWizard() {
    return (onOk: Function, onClose: Function) => <NewModelWizard onClose={onClose} />
}
  function excelWizard ( gs:any, editing: any )  { 
    return (onOk:Function, onClose:Function)=><ExcelUploadModal onClose={ onClose } onExcelUpload={(file) => {}}/>
  }