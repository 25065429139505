import { useState, useEffect, useRef } from "react";
import "./domain.css";
import { Button, ErrorMessage, Input } from "kls-ui";
import { SettingsContainerHeader } from "../../settings/settings";
export function DomainSettings(props: any) {
  const [errorMessage, setErrorMessage] = useState(false);
  const [organisationName, setOrganisationName] = useState('');
  const [organisationNameErr, setOrganisationNameErr] = useState('');
  const [domainVisible, setDomainVisible] = useState(true);

  const createOraganisationSubmit = () => {
    if (organisationName === '') {
      setOrganisationNameErr('Ce champ est requis');
      setDomainVisible(false);
    }
  }

  return (
    <div className="domain-container">
      <SettingsContainerHeader title="Nom de domaine" subtitle="Point d'accès public de votre organisation" />
        {errorMessage && <ErrorMessage img="icons/error_outline.svg" label="Le nom de domaine que vous avez saisi est déjà utilisé. Veuillez essayer un autre nom." />}
      <div className="bottom-domain">
        <div className="inputcontainer input-placeholder-domain">
          <Input
            size="md" label="Nom de domaine" errorMessage={organisationNameErr}
            args={{
              placeholder: "Entrez un acronyme sans espaces ni caractères spécieux", required: true, id: "organizationdoamin",
              onChange: (e) => {
                setOrganisationName(e.target.value);
                setOrganisationNameErr('');
                if (e.target.value == '') {
                  setOrganisationNameErr('Ce champ est requis');
                }
              }
            }}></Input>
          {organisationNameErr == '' && <span className="domain-org">.karaz.org</span>}
        </div>
        <div className="domain-button">
          <Button label="Enregistrer" size="md" color="primary" styling="solid" args={{ style: { backgroundColor: '#3B82F6' }, onClick: (e) => { createOraganisationSubmit() } }} />
        </div>
      </div>
    </div>
  )
}

