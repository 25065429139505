import { OraganizationTable } from "kls-ui"
import { SettingsContainerHeader } from "../../settings/settings"
import './payments.css'
export function PaymentSettings(props: any) {
  return (
    <div className="app-payment-container">
      <SettingsContainerHeader title="Paiements" subtitle="Gérez vos paiements et factures" />
      <div>
        <OraganizationTable type="payments" data={[{
          Facture: "FCTR45893",
          Date: "10/09/2023",
          Utilisateurs: "10",
          Montant: "2,0000",
          Statut: "Non payé",
          action: "Facture"
        }, {
          Facture: "FCTR45893",
          Date: "10/09/2023",
          Utilisateurs: "10",
          Montant: "2,0000",
          Statut: "Payé",
          action: "Facture"
        }, {
          Facture: "FCTR45893",
          Date: "10/09/2023",
          Utilisateurs: "10",
          Montant: "2,0000",
          Statut: "Payé",
          action: "Facture"
        }, {
          Facture: "FCTR45893",
          Date: "10/09/2023",
          Utilisateurs: "10",
          Montant: "2,0000",
          Statut: "Payé",
          action: "Facture"
        }, {
          Facture: "FCTR45893",
          Date: "10/09/2023",
          Utilisateurs: "4",
          Montant: "800",
          Statut: "Payé",
          action: "Facture"
        }
        ]} />
      </div>
    </div>
  )
}

