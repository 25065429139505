import { GreenProgressBar, ProgressContainer, StepsCard, StepsCardSuccess } from "kls-ui";
import { useState } from "react";

function CardSteps({ handleEvent }: { handleEvent: Function }) {
    return (<div className="step-cards-container">
        <StepsCard title="Créez votre organisation" status="completed" content="Je crée une organisation" />
        <StepsCard title="Choisissez le thème de votre espace de travail" status="inprogress" content="Je choisis le thème de mon espace de travail" handleClick={() => handleEvent('theme', 'hint-stepbar-theme', 0)} />
        <StepsCard title="Complétez les informations de votre organisme et définissez votre espace de travail" status="inprogress" content="Je complète les données de mon organisme" handleClick={() => handleEvent('orgData', 'hint-stepbar-theme', 0)} />
        <StepsCard title="Ajoutez vos collaborateurs, confiez leurs des rôles et organisez vos groupes" status="inprogress" content="J’invite mes collaborateurs" handleClick={() => handleEvent('users', 'hint-stepbar-theme', 0)} />
        <StepsCard title="Explorez et personnalisez votre première application à base d’un modèle" status="inprogress" content="J’édite ma première application " handleClick={() => handleEvent('application', 'hint-stepbar-theme', 500)} />
    </div>);
}
export function StepsBarMobile({ themeHandler, setthemeHandler, hintType, sethintType }: { themeHandler?: boolean, setthemeHandler?: Function, hintType?: String, sethintType?: Function }) {

    let [showProgressContainer, setshowProgressContainer] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [completeTask, setCompleteTask] = useState(1);
    const [totalTasks, settotalTasks] = useState(5);

    function handleClickFunc(hinttype: any, className?: any, popupPosition?: number) {
        setshowProgressContainer(false);
        sethintType && sethintType(hinttype);
        setthemeHandler && setthemeHandler(!themeHandler)
        window.scrollTo({ top: popupPosition });
        if (!themeHandler) {
            document.addEventListener("mousedown", (e) => {
                setthemeHandler && setthemeHandler(false);
            }, { once: true });
        }
        if (className) {
            document.body.classList.add(className)
        }
    }
    if (!themeHandler) {
        sethintType && sethintType("")
        document.body.classList.remove("hint-stepbar-theme")
    }

    const handleClick = () => {
        if (completeTask >= totalTasks) {
            setShowPop(!showPop);
        }
        else {
            setshowProgressContainer(!showProgressContainer);
        }
    }

    return (
        <div className={showProgressContainer ? "steps-bar-mobile open" : "steps-bar-mobile close"}>
            <div className="stepbar-header" onClick={() => setshowProgressContainer(!showProgressContainer)}>

                {showProgressContainer ? <span>Masquer le guide</span> : <span onClick={() => handleClick()}>Afficher le guide</span>}
                {showProgressContainer ? <span className='icon'>expand_less</span> : <span className='icon'>expand_more</span>}
            </div>
            {showProgressContainer &&
                <div className="stepbar-section">
                    <div className="stepbar-subhead">
                        <span className="guide">Guide de démarrage</span>
                        <span className="steps-count">{completeTask}/{totalTasks} complété</span>
                    </div>
                    <div className="blur-content">{showPop && <StepsCardSuccess title="Bravo 👏  ! " content="Vous avez appris les bases de KLS" btntext="Commencer à créer" />}</div>
                    <CardSteps handleEvent={handleClickFunc} />
                </div>
            }
        </div>
    )
}

export function StepsBar({ themeHandler, setthemeHandler, hintType, sethintType }: { themeHandler?: boolean, setthemeHandler?: Function, hintType?: String, sethintType?: Function }) {

    let [showProgressContainer, setshowProgressContainer] = useState(false);
    const [showPop, setShowPop] = useState(false);
    const [completeTask, setCompleteTask] = useState(1);
    const [totalTasks, settotalTasks] = useState(5);

    function handleClickFunc(hinttype: any, className?: any, popupPosition?: number) {
        setshowProgressContainer(false);
        sethintType && sethintType(hinttype);
        setthemeHandler && setthemeHandler(!themeHandler)
        window.scrollTo({ top: popupPosition });
        if (!themeHandler) {
            document.addEventListener("mousedown", (e) => {
                setthemeHandler && setthemeHandler(false);
            }, { once: true });
        }
        if (className) {
            document.body.classList.add(className)
        }
    }

    if (!themeHandler) {
        sethintType && sethintType("")
        document.body.classList.remove("hint-stepbar-theme")
    }


    const handleClick = () => {
        if (completeTask >= totalTasks) {
            setShowPop(!showPop);
        }
        else {
            setshowProgressContainer(!showProgressContainer);
        }
    }

    return (
        <div className="steps-bar">
            {showPop && <StepsCardSuccess title="Bravo 👏  ! " content="Vous avez appris les bases de KLS" btntext="Commencer à créer" />}
            <div className="stepbar" onClick={() => handleClick()}>
                <span className='icon'>play_circle</span>
                <span className="steps-count">{completeTask}/{totalTasks}</span>
            </div>
            {showProgressContainer && <div className="progress-conatiner"> <ProgressContainer title="Commencer" onClose={() => setshowProgressContainer(!showProgressContainer)}>
                <GreenProgressBar completedcount={1} totalcount={5} />
                <CardSteps handleEvent={handleClickFunc} />
            </ProgressContainer></div>}
        </div>
    )

}
