import { CheckboxLabel, Modal, TextArea, ToggleButton, addToast } from 'kls-ui'
import React, { useEffect, useState } from 'react'
import { HLabel, Status, Tag } from './aux-component';
import './pub.css'
import { VersionType, getActualVersion, getLastVersion, loadAllModel, mapTreeToModels, setModelJson } from '../module/module.service';
import { Model } from 'kls-commons/types/application-model';
import Spinner from '../module/spinner';
import { Pagination } from "kls-ui/paginationKls/pagination";
import { SearchInput } from "kls-ui";
import { commitModule, getWorkingDirectorTree, listTags } from 'kls-commons/service/repo-service';
import OTPInput, { OtpForm } from 'kls-ui/data entry/otp';
import { Loading } from 'kls-ui';

const START_MODEL_FROM = 1 ;
const NB_MODELS_PER_PAGE = 3 ;

export default function PublicationWizar( { toProd, moduleName, onClose } ) {

    const [step,setStep] = useState<number>( 1 ) ;

    const [commitMessage, setCommitMessage] = useState<string>( '' ) ;
    const [pushToProd, setPushToProd] = useState<boolean>( toProd ) ;
    const [models, setModels] = useState<any[]>( [] ) ;
    const [allModels, setAllModels] = useState<Model[]>( [] ) ;
    const [selectAllModels, setSelectAllModels] = useState<boolean>(false) ;
    const [margeModels, setMargeModels] = useState<number[]>([START_MODEL_FROM,NB_MODELS_PER_PAGE])
    const [filter, setFilter] = useState<string>( '' ) ;
    const [filteredModels, setFilteredModels] = useState<any[]>( ) ;

    const [otpValue, setOtpValue] = useState<string>() ;
    const otp = "986357" ;
    const [lastVersion, setLastVersion] = useState<string>( '' ) ;
    let [actualVersion, setActualVersion] = useState<VersionType>( ) ;
    const [isCommiting, setIsCommiting] = useState<boolean>( false ) ;

    useEffect(
        () => {
            setFilteredModels( allModels?.filter( (d:any) => d.label?.toLowerCase()?.trim().includes(filter?.toLowerCase()?.trim()) ) ) ;
        } , [allModels, filter]
    )

    useEffect(
        () => {
            getActualVersion(moduleName).then(   (v:VersionType) => {  setActualVersion( v ) ; setLastVersion( v.preprod ) ;  }   )
            getLastVersion(moduleName).then(  (v:string) => {  if(v) setLastVersion( v )  }  ) ;
            loadAllModel( setAllModels, moduleName ) ;
            
        } , [moduleName]
    )

    const updateListModels = (model:Model) => { 
        console.log( models ) ; 
        if(models.map( m=>m.name ).includes( model.modelName )){ 
            setModels( models.filter( m => m.name!==model.modelName ) ) ;
            setSelectAllModels( false ) ;
        } else { 
            models.push( {name:model.modelName, label:model.label} ) ; 
            setModels( [...models] ) 
        } 
    }

    const handleSelectAllModelsChange = (checked:boolean) => {
        setSelectAllModels(checked) ;

        if( checked )
            setModels( allModels.map( m => ({name:m.modelName, label:m.label}) )  ) ;
        else 
            setModels( [] ) ;
    } 

    return (
        isCommiting ? <Loading message='Nous publions votre application...'/> :
        <Modal  title={ toProd?"Publier l'application en production":"Publier l'application en pré-production" }
                icon=''
                width="600px"
                zIndex={100001}
                onClose={onClose as () => void}
                leftBtnArgs={{ size: "md", color: "neutral", label: "Annuler", styling: "outline", args: { onClick: () => { onClose(); } } }} 
                rightBtnArgs={{
                    size: "md", color: "primary", label:"Publier", styling: "solid",
                    args: {
                        style: {
                            backgroundColor :  step===1 ? '#3B82F6' : '#DC2626',
                            borderColor : step===1 ? '#2563EB' : '#B91C1C'
                        },
                        onClick: () => {
                            let push = true ;
                            if( step === 1 ) {
                                let passToSecondStep = true ;
                                if( !models?.length ) {
                                    addToast('Veuillez sélectionner au moins un module !', 'warning');
                                    passToSecondStep = false ;
                                    push = false ;
                                }
                                if( !commitMessage || !commitMessage.trim() ) {
                                    addToast('Veuillez saisir le message de commit!', 'warning');
                                    passToSecondStep = false ;
                                    push = false ;
                                }
                                if( pushToProd && passToSecondStep ) {
                                    setStep( step + 1 ) ;
                                    push = false ;
                                }
                            } else {
                                if( otp !== otpValue ) {
                                    addToast('Le code saisi est incorrect!', 'warning');
                                    push = false ;
                                    return ;
                                }                          
                            }

                            if( push ) {

                                setIsCommiting( true ) ;

                                let newVersion = incrementTag( lastVersion , pushToProd ) ; 

                                commitModule(moduleName, commitMessage, newVersion, pushToProd).then(r=>{
                                    console.log("commitModule", r);
                                    setIsCommiting( false ) ;
                                    if(r.error) {
                                        addToast( <>La publication que vous essayez d'accomplir a échoué.<br/>La ressource demandée n'a pas été trouvée sur le serveur (Erreur 404). <br/>Veuillez vérifier vos paramètres et réessayer.<br/></>, "error" ) ;
                                    } else { 
                                        addToast( "La publication a été accomplie avec succès.", "success" ) ;
                                        
                                        if( !actualVersion ) actualVersion = {} as VersionType ;
                                        if( pushToProd ) {
                                            actualVersion.prod = newVersion ;
                                        }
                                        actualVersion.preprod = newVersion ;
                                        setModelJson( moduleName, 'version', actualVersion ) ;
                                    }
                                    onClose();
                                })
                            }
                        }
                    }
                }}>


                <div className={'publication-content step' + step} >

                    {
                        step === 1 ?
                        <>
                            <HLabel label='Statut actuel' rightContent={<Status status={'Publique'} />} />
                            <HLabel label={'Version actuelle en '+(toProd?'production':'pré-production')} rightContent={<Tag tag={actualVersion&&(toProd?actualVersion.prod:actualVersion?.preprod)}/>} />
                            <div style={{background: '#E2E8F0', height: '1px', width: '100%'}}></div>
                            
                            {
                                allModels?.length ?
                                <>
                                    <HLabel label='Choisissez la liste des models à publier :' rightContent={<></>} />
                                    
                                            <SearchInput light={true} value={filter} setValue={setFilter} placeholder='Rechercher des modules'/>
                                            <div className='all-models-container'>
                                            {   
                                                filteredModels?.length &&
                                                <>
                                                    <CheckboxLabel handleChangeValue={ (checked:boolean)=>handleSelectAllModelsChange( checked ) } label={{label:'Tous les modules'}} Check={ selectAllModels }></CheckboxLabel>
                                                    <div className='all-models-item'> 
                                                        {
                                                            filteredModels.slice(margeModels[0]-1,margeModels[1]).map( (model,i) => {return <CheckboxLabel handleChangeValue={ () => updateListModels(model) } key={i} label={{label:model.label}} Check={ models.map( m=>m.name ).includes( model.modelName ) }></CheckboxLabel>} ) 
                                                        }{
                                                            (filteredModels.length||0) > NB_MODELS_PER_PAGE && <div className='models-pagination-container'><Pagination light={true} totalItems={filteredModels.length} itemsPerPage={NB_MODELS_PER_PAGE} maxPage={7} handlePageChange={ (start:number, end:number)=>{ setMargeModels( [start, end] ) } }   /></div>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            </div>
                                </>
                                : <div className='loading-models-pub'><Spinner scale={0.4}/></div>
                            }

                            <div style={{background: '#E2E8F0', height: '1px', width: '100%'}}></div>

                            <TextArea label='Message de commit' args={{ value: commitMessage, onChange: (e) => { setCommitMessage(e.target.value); } }} size={'xs'} ></TextArea>

                            { !toProd && <ToggleButton className='important-color' label='Publier en production' handleToggle={() => {setPushToProd( !pushToProd )}} ischecked={pushToProd}></ToggleButton>}
                        </>
                        :
                        <OtpForm code={otp} handleChange={setOtpValue} />
                    }
                </div>


        </Modal>
    )
}


const incrementTag = (tag:string, toProd:boolean):string => {

    let versionNumber = tag.split( "." ) ;
    let indexNumberToChange = versionNumber.length-(toProd?2:1) ; 
    versionNumber[indexNumberToChange] = '' + (parseInt(versionNumber[indexNumberToChange])+1) ;
    if( toProd ) {
        versionNumber[versionNumber.length-1] = '0' ;
    }
    return versionNumber.join('.') ;
}
